import { map, distinctUntilChanged } from 'rxjs/operators'
import { payloads$, actions, store, handlers, store$ } from '../../../Store'

payloads$(actions.DEBUG_OPTIONS_FORM_GET)
  .subscribe(() => {
    const { phrase, persist } = store.getState()
    const { isEnabled, branchesList, defaultBranch } = phrase || {}
    const { selectedPhraseBranch } = persist || {}
    handlers.debugOptionsFormPopulate({ phraseActive: isEnabled, branchesList, defaultBranch: selectedPhraseBranch || defaultBranch })
  })

// Save
payloads$(actions.DEBUG_OPTIONS_FORM_SAVE)
  .subscribe(async ({ options }) => {
    const isStaging = ['development', 'staging'].includes(process.env.REACT_APP_ENV)

    if (isStaging) {
      handlers.phraseUpdate(options)
      handlers.debugOptionsFormReady()
    }
  })

// Form phraseActive change
store$
  .pipe(
    map(state => state.forms.debugOptions.phraseActive ? state.forms.debugOptions.phraseActive.value : ''),
    distinctUntilChanged()
  ).subscribe(async (phraseActive) => {
    if (phraseActive) handlers.phraseBranchesListGet()
  })