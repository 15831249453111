import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FontAwesome5, FormGroup, Error, Select, t } from '../../../Common'

import './SingleSelect.css'

class SingleSelect extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.renderArrows = this.renderArrows.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange (selectValue) {
    const { mandatory, options, form, name, onChange, onChangeAddon } = this.props
    const _options = options || form.options
    let value = selectValue ? selectValue.value : ''
    if (mandatory && !value && _options.length > 0) value = _options[0].value
    this.setState({ filled: !!value })
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(this.props.formName, { [this.props.name]: { ...this.props.form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  renderArrows () {
    const { icon, iconType } = this.props

    return (
      <div>
        <FontAwesome5 icon={icon || 'sort'} type={iconType || 'solid'} />
      </div>
    )
  }

  render () {
    const { focused } = this.state
    const {
      label,
      name,
      hintText,
      mandatory,
      disabled,
      hideError,
      value,
      options,
      clearable,
      searchable,
      width,
      form = {}
    } = this.props
    let { placeholder = form.placeholder } = this.props

    if (!focused && (value === undefined || value === '' || form.value === '')) placeholder = label || form.label

    if (mandatory || form.mandatory) placeholder = `${placeholder} *`
    const styles = {
      width: width && width
    }

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} style={styles}>
        <FormGroup
          focused={focused}
          filled={!!value || !!form.value}
          disabled={disabled !== undefined ? disabled : form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory !== undefined ? mandatory : form.mandatory}
        >
          <Select
            className='ta-single-select'
            noResultsText={t('global.noResults')}
            name={name}
            value={value !== undefined ? value : form.value}
            arrowRenderer={this.renderArrows}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            options={options || form.options}
            clearable={clearable || false}
            searchable={searchable || false}
            placeholder={placeholder}
            disabled={disabled !== undefined ? disabled : form.disabled}
          />
          {hintText &&
            <div className='ta-form-control__hint'>{hintText}</div>
          }
          {!hideError &&
            <Error name={name} />
          }
        </FormGroup>
      </div>
    )
  }
}

SingleSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  hintText: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: (state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' }
})

export default feedContextInProps(connect(maps)(SingleSelect), FormContext)
