import React from 'react'
import { t } from '../../Common'

const NoSlots = props => {
  return (
    <div className='ta-no-slots'>
      <i className='ta-no-slots__icon fas fa-meh' />
      <div className='ta-no-slots__title'>{t('availability.list.section.noAvailableSlots.title')}</div>
      <div className='ta-no-slots__text'>{t('availability.list.section.noAvailableSlots.text')}</div>
    </div>
  )
}

export default NoSlots
