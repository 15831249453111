import React from 'react'
import PropTypes from 'prop-types'
import {
  FontAwesome5,
  DashedTitle,
  PhoneInput,
  NoteBox,
  IconList
} from '../../../Common'

import './ChangePhone.css'

const ChangePhone = props => {
  const { isActive } = props
  const classNames = ['ta-popup', 'ta-auth-changephone']
  if (isActive) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      {isActive && (
        <>
          <button className='ta-popup-close'>
            <FontAwesome5 icon='times' type='light' />
          </button>
          <div className='ta-auth-changephone__container'>
            <div className='ta-auth-changephone__inner'>

              <DashedTitle label='Change mobile number' className='ta-popup__main_title' />
              <div className='ta-text-box center'>
                Please note that the mobile number should be correct in order to receive the verification code.
              </div>

              <PhoneInput />

              <div className='ta-auth-confirmation__buttons'>
                <button className='ta-cta-primary ta-cta-fluid'>
                  Save and continue to verification
                </button>
              </div>

            </div>
          </div>

          <NoteBox canHide>
            <DashedTitle label='Why should you register?' />
            <div className='ta-text-box'>
              With Timify you can book appointments at any time from everywhere in a matter of seconds with many providers.
            </div>
            <IconList>
              <li>Donec id elit non mi porta gravida at eget metus.</li>
              <li>Nullam quis risus eget urna mollis ornare vel eu leo.</li>
              <li>Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</li>
              <li>Aenean lacinia bibendum nulla sed consectetur.</li>
            </IconList>
          </NoteBox>
        </>
      )}
    </div>
  )
}

ChangePhone.propTypes = {
  isActive: PropTypes.bool
}

export default ChangePhone
