import React from 'react'
import { format, getCustomDateFormat } from '../../Common'
import moment from 'moment-timezone'

const OtherDays = props => {
  const {
    availableDays,
    onClickOtherDay,
    isWidgetWeeklyView
  } = props

  const onClick = (date) => {
    const { attributes = {} } = props
    const { dateRange = null } = attributes
    const selectedDays = []

    if (isWidgetWeeklyView) {
      // Prepare dateRange dates
      if (dateRange && dateRange[0]) dateRange[0] = moment(dateRange[0])
      if (dateRange && dateRange[1]) dateRange[1] = moment(dateRange[1])

      let startWeekDate = moment(date).clone()
      let endWeekDate = moment(date).clone()
      let currentDate = moment()
      currentDate.hour(0)
      currentDate.minute(0)
      currentDate.second(0)

      startWeekDate.startOf('week')
      endWeekDate.endOf('week')

      for (let index = 0; index < 7; index++) {
        // If we have date range we must apply additional validation
        if (dateRange && dateRange[0] && dateRange[0].isValid() && dateRange[1] && dateRange[1].isValid()) {
          if (startWeekDate.isSameOrAfter(dateRange[0]) && startWeekDate.isSameOrBefore(dateRange[1])) selectedDays.push(startWeekDate.format('YYYY-MM-DD'))
        } else {
          selectedDays.push(startWeekDate.format('YYYY-MM-DD'))
        }
        startWeekDate.add(1, 'days')
      }
    } else {
      selectedDays.push(moment(date).format('YYYY-MM-DD'))
    }

    onClickOtherDay(selectedDays)
  }

  return (
    <div className='ta-other-days'>
      {availableDays.map((date, index) => (
        <div key={index} className='ta-cta ta-cta-secondary ta-cta-middle'
          onClick={() => onClick(date)}
        >
          {format(date, getCustomDateFormat() || 'longWithLongWeekDay', { isUTC: true })}
        </div>
      ))}
    </div>
  )
}

export default OtherDays
