import moment from 'moment-timezone'
import * as Sentry from '@sentry/react'
import { payloads$, actions, store, handlers } from '../../../Store'
import { q } from '../../API'
import { MRSPEX_PARTNER_ENTERPRISE_ID, SENTRY_KEY, SENTRY_PROJECT } from '../../../Settings'
import { getRouteQuery, removeSpaces } from '../../../Utils'
import { customerFieldFormValidate } from './utils'
import { registeredBookerCustomerFields } from '../Booking/utils'

// Save
const setCustomerFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('customerFieldsBeforeConfirmation', errors)
  scrollToError && scrollToError(errors)
  // handlers.customerFormReady()
}

payloads$(actions.CUSTOMER_FIELDS_FORM_SAVE)
  .subscribe(async ({ fields, scrollToError }) => {
    const state = store.getState()
    const { auth, router, services, courses, companies, slots, booking, enterprise, attributes } = state
    const { selectedId: selectedCompanyId = null, list: companiesList } = companies || {}
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId)
    const { enterprise: companyEnterprise } = selectedCompany || {}
    const { customersMiddlewareUrl } = companyEnterprise || {}
    const isGuest = !auth.tokens
    const { id: enterpriseId = null } = enterprise || {}
    const { isEnterprise = null } = attributes || {}

    // Hardcoded fix for MisterSpex and their partners when we have integration on the MisterSpex's website
    // we must show aways the checkbox for terms and conditions
    if (isEnterprise && enterpriseId && MRSPEX_PARTNER_ENTERPRISE_ID.includes(enterpriseId)) {
      selectedCompany.hasCustomTermsOrPrivacyPolicyCheckbox = true
    }

    const errors = customerFieldFormValidate(fields, isGuest, selectedCompany)
    if (errors.length) return setCustomerFormSaveErrors(errors, scrollToError)
    const routeQuery = getRouteQuery(router)
    const { selectedId: selectedServiceId = null, list: servicesList } = services || {}
    const { selectedId: selectedCourseId = null, extraParticipants } = courses || {}
    const { selectedEventId = null, coursesList = [] } = slots || {}
    const { eventId, secret } = booking
    let selectedService
    if (selectedServiceId) selectedService = servicesList.find(item => item.id === selectedServiceId)
    if (selectedCourseId) selectedService = coursesList.find(item => item.id === selectedEventId)

    // If the customer is trying to book a course we must provide additional validation. If the customer already
    // booked this course he/she is not able to book it again and we must show an error message.
    if (selectedCourseId) {
      const { account, customerFields, forms } = state
      const { booker = {}, email: bookerEmail } = account
      const { list: customerFieldsList } = customerFields
      const companyCustomerFields = customerFieldsList.filter(cf => cf.companyId === selectedCompanyId)
      const { customerFieldsOnService, customerFieldsBeforeConfirmation } = forms

      // If the user is not guest we need to fill firstName, lastName, phone, email and append them to the customer fields
      const bookerCustomerFields = (state.auth.tokens && booker && Object.keys(booker).length > 0)
        ? registeredBookerCustomerFields({ ...booker, email: bookerEmail }, companyCustomerFields)
        : {}

      const allCustomerFields = { ...bookerCustomerFields, ...customerFieldsOnService, ...customerFieldsBeforeConfirmation }
      const customerFieldsWithValues = Object.entries(allCustomerFields).reduce((acc, item) => {
        if (item[0].indexOf('customerField') !== -1 && item[0].indexOf('Secondary') === -1 && item[0].indexOf('Zoom') === -1 && item[0].indexOf('CropRadius') === -1) {
          const id = item[0].split('customerField')[1]
          const field = item[1]
          let value = ''
          let values

          // Send email of the booker if exist
          if (field.defaultId && field.defaultId === 'email' && bookerCustomerFields && typeof bookerCustomerFields[item[0]] !== 'undefined' && bookerCustomerFields[item[0]].value) {
            value = bookerCustomerFields[item[0]].value
          }
          // Send firstName & lastName of the booker if the value is not filled
          if (field.defaultId && ['firstName', 'lastName'].includes(field.defaultId) && !field.value && bookerCustomerFields && typeof bookerCustomerFields[item[0]] !== 'undefined' && bookerCustomerFields[item[0]].value) {
            value = bookerCustomerFields[item[0]].value
          }
          if (field.type === 'DATE' && field.value) {
            value = moment.utc(field.value, 'YYYY-MM-DD').format('YYYY-MM-DD')
          }
          if (field.type === 'PHONE' && field.phone) {
            value = JSON.stringify({
              number: removeSpaces(field.phone),
              country: field.phoneCountry
            })
          }
          if (field.type === 'CHECKBOX') {
            value = field.value + ''
          }
          if (field.type === 'FILE' && field.defaultId === 'avatar') {
            if (field.value === null) value = null
            if (field.avatarFile) value = field.avatarFile
          }
          if (field.type === 'ADDRESS' && field.value) {
            const data = field.data || null
            const newValue = (data && {
              placeId: data && data.placeId,
              latitude: data && data.lat,
              longitude: data && data.lng,
              street: data && data.streetName,
              streetNumber: data && data.streetNumber,
              city: data && data.city,
              country: data && data.country,
              zipCode: data && data.postalCode,
              formatted: data && data.formattedAddress
            }) || null
            if (allCustomerFields[`${item[0]}Secondary`] && allCustomerFields[`${item[0]}Secondary`].value) newValue.details = allCustomerFields[`${item[0]}Secondary`].value
            value = JSON.stringify(newValue)
          }
          if (field.type === 'FILES') {
            values = {
              valuesAdd: (field.valuesAdd || []).map(file => JSON.stringify(file)),
              valuesRemove: field.valuesRemove || []
            }
          }
          if (!value && field.type !== 'FILE') value = field.value || null

          if (value || values) {
            acc.push({
              id,
              type: field.type,
              value,
              valuesAdd: values && values.valuesAdd,
              valuesRemove: values && values.valuesRemove
            })
          }
        }
        return acc
      }, [])

      let additionalParticipantsCount = parseInt(extraParticipants, 10)
      if (isNaN(additionalParticipantsCount)) additionalParticipantsCount = 0

      let query = 'checkOnlineCourseEventReservation'
      if (customersMiddlewareUrl) query = `${query}CustomersMiddleware`
      const checkResult = await q(query, {
        event: {
          companyId: selectedCompany.id,
          region: selectedCompany.region,
          eventId,
          secret,
          fields: customerFieldsWithValues,
          extraPersons: additionalParticipantsCount
        }
      }, null, customersMiddlewareUrl)
      const { error: checkError } = checkResult || { checkError: {} }
      if (checkError && checkError.code) {
        if (SENTRY_KEY && SENTRY_PROJECT && ['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
          Sentry.captureMessage(`CUSTOMER_FIELDS_FORM_SAVE checkOnlineCourseEventReservation error: ${checkError.code}`)
        }
        if (checkError.code === 'NoSpotsLeft') {
          const { maxParticipants, participantsCount } = selectedService || {}
          const availableParticipantsSlots = maxParticipants - participantsCount - 1
          setCustomerFormSaveErrors([{
            key: 'extraParticipantsErrors',
            value: 'errors.guests.noSpotsLeft',
            replace: [{ key: 'SLOTS_LEFT', value: availableParticipantsSlots }]
          }], scrollToError)
          return
        }
        handlers.conflictErrorPopulate(checkError.code || null)
        handlers.navigateToPath(`/conflict${routeQuery}`)
        return
      }
      handlers.formErrorsSet('customerFieldsBeforeConfirmation', [])

      // Update reservation to have the participants
      handlers.appLoaderShow()
      const params = {
        companyId: selectedCompany.id,
        region: selectedCompany.region,
        eventId,
        participantSecret: secret,
        extraPersons: additionalParticipantsCount
      }
      const { resourceExternalIds } = attributes || {}
      if (resourceExternalIds) params.selectedResourceExternalIds = resourceExternalIds
      const reservationUpdate = (await q('reserveOnlineCourse', { params }))
      handlers.appLoaderHide()
      const { error: reservationUpdateError } = reservationUpdate || { error: {} }
      if (reservationUpdateError) {
        handlers.conflictErrorPopulate(reservationUpdateError.code || null)
        handlers.navigateToPath(`/conflict${routeQuery}`)
        return
      }
    }

    if (
      selectedService &&
      selectedService.hasOnlinePayment &&
      selectedService.price &&
      selectedCompany
    ) {
      const { hasStripeConnected, paypalConnectionStatus } = selectedCompany || {}
      const hasPaypalConnected = paypalConnectionStatus === 'ON'
      if (selectedService.isPaymentMandatory) {
        handlers.pagesNextSet(`/payments/${hasPaypalConnected ? 'online-payment' : 'stripe'}${routeQuery}`)
        handlers.navigateToPath(`/confirm${routeQuery}`)
      } else {
        if (!hasPaypalConnected && !hasStripeConnected) { // optional payment but no online payment methods active
          handlers.navigateToPath(`/confirm${routeQuery}`)
          return
        }
        handlers.navigateToPath(`/payments${routeQuery}`)
      }
      return
    }
    handlers.navigateToPath(`/confirm${routeQuery}`)
  })
