import React from 'react'
import PropTypes from 'prop-types'
import {
  t,
  Accordion,
  AccordionTitle,
  AccordionBody,
  SectionTitle
} from '../../Common'
import { ServicesList } from '../../Beauties'

const ServiceCategory = props => {
  const {
    category = {},
    isOffer,
    items = [],
    expand = false,
    fileLoading,
    setFileLoading
  } = props
  let label = category.name
  if (category.name === 'default') label = t('services.list.section.category.default.label')
  if (category.name === 'miscCourseCategory') label = t('groups.categoryTitle')
  const classNames = ['ta-services__category']
  return (
    <>
      {(items.length > 0 &&
        <div className={classNames.join(' ')}>
          <Accordion expand={expand}>
            <AccordionTitle>
              <SectionTitle
                noIcon
                label={label}
              />
            </AccordionTitle>
            <AccordionBody>
              <ServicesList
                items={items}
                category={category}
                isOffer={isOffer}
                fileLoading={fileLoading}
                setFileLoading={setFileLoading}
              />
            </AccordionBody>
          </Accordion>
        </div>
      )}
    </>
  )
}

ServiceCategory.propTypes = {
  category: PropTypes.object,
  items: PropTypes.array,
  expand: PropTypes.bool
}

export default ServiceCategory
