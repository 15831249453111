import React from 'react'
import PropTypes from 'prop-types'
import { t, NoResults } from '../../Common'
import { ServicesListItemWrapper } from '../../Beasts'

const ServicesList = props => {
  const { items, category, isOffer, fileLoading, setFileLoading } = props
  const classNames = ['ta-services__list']
  if (items.length === 1) classNames.push('single-service')

  return (
    <div className={classNames.join(' ')}>
      {items.map(item => (
        <ServicesListItemWrapper
          key={item.id}
          item={item}
          category={category}
          isOffer={isOffer}
          fileLoading={fileLoading}
          setFileLoading={setFileLoading}
        />
      ))}
      {(!items.length &&
        <NoResults>{t('services.list.section.category.noResults.text')}</NoResults>
      )}
    </div>
  )
}

ServicesList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default ServicesList
