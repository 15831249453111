import React, { Component } from 'react'
import { connect, handlers, store } from '../../Store'
import { Confirm, ConfirmMrSpex } from '../../Beauties'
import { getDiscountedPrice, getRouteQuery, extractFromHtml, checkIfVouchersAreAvailableForThisSlot } from '../../Utils'

class ConfirmWrapper extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hasNotification: true
    }

    this.onNotificationClose = this.onNotificationClose.bind(this)
    this.onCourseParticipantsUpdate = this.onCourseParticipantsUpdate.bind(this)
  }

  componentDidMount () {
  }

  onNotificationClose () {
    this.setState({
      hasNotification: false
    })
  }

  onCourseParticipantsUpdate () {
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/confirm/participants${routeQuery}`)
  }

  render () {
    const {
      routeName,
      appMode,
      nextPage,
      companies,
      selectedCompanyId,
      services,
      selectedServiceId,
      selectedCourseId,
      resources,
      selectedResourceId,
      selectedDay,
      selectedMinutes,
      offers,
      selectedOfferId,
      customerFieldsOnService,
      customerFieldsBeforeConfirmation,
      enterpriseId,
      resourceExternalIds,
      initVoucherCode,
      rescheduledData,
      timezones,
      selectedTimezoneCode,
      upsellingCategories,
      upsellingServices,
      upsellingSelectedIds,
      attributes,
      extraParticipants,
      coursesList,
      selectedEventId,
      customisations,
      countries,
      voucherInput,
      vouchers
    } = this.props

    const { payload: customisationsPayload } = customisations || {}
    const { settings: customisationsSettings } = customisationsPayload || {}
    const {
      hideDisclaimer,
      disclaimerType: disclaimerTypeCustomisation,
      disclaimerText: disclaimerTextCustomisation
    } = customisationsSettings || {}
    const { hasNotification } = this.state
    const {
      duration: rescheduledDuration,
      durationsPatternCalc: rescheduledDurationsPatternCalc,
      combination: rescheduledCombination
    } = rescheduledData || {}
    const info = {}
    const company = companies.find(item => item.id === selectedCompanyId) || {}
    const { settings = {}, addOns = [], stripeFeePercentage, hasStripeConnected, locale = 'en-gb' } = company || {}
    const selectedTimezone = timezones.find(tz => tz.code === selectedTimezoneCode)
    const selectedService = services.find(item => item.id === selectedServiceId)
    const selectedCourse = coursesList.find(item => item.id === selectedEventId)
    const selectedItem = selectedService || selectedCourse
    const selectedResource = resources.find(item => item.id === selectedResourceId)
    const country = countries.find(({ code }) => code.toLowerCase() === locale.split('-')[1])
    const { hasStripeActive, hasStripeFeesNotAllowed } = country || {}
    const { isEnterprise = false, hideCustomerFields, hideExternalCustomerFields } = attributes
    let additionalParticipantsCount = parseInt(extraParticipants, 10)
    if (isNaN(additionalParticipantsCount)) additionalParticipantsCount = 0

    // Service
    if (selectedItem) {
      const offer = selectedOfferId ? offers.find(item => item.id === selectedOfferId) : null
      let selectedItemName = selectedItem.name || selectedItem.title
      let selectedItemDuration =
        rescheduledDurationsPatternCalc ||
        rescheduledDuration ||
        selectedItem.durationsPatternCalc ||
        selectedItem.duration ||
        0
      let selectedItemPrice = selectedItem.price

      // If we have selected upselling services we need to append them to the name and duration
      let upsellingRelations = upsellingServices.find((item) => item.id === selectedItem.id)
      if (!upsellingRelations) upsellingRelations = upsellingCategories.find((item) => item.id === selectedItem.id)
      if (upsellingRelations && upsellingRelations.relations) {
        upsellingSelectedIds.map(selectedId => {
          const upsellingSelectedService = upsellingRelations.relations.find(service => service.id === selectedId) || null

          if (upsellingSelectedService) {
            selectedItemName = `${selectedItemName}, ${upsellingSelectedService.name}`

            if (upsellingSelectedService.add_extra_duration) {
              selectedItemDuration = selectedItemDuration + upsellingSelectedService.duration
            }

            selectedItemPrice = selectedItemPrice + (upsellingSelectedService.price || 0)
          }

          return true
        })
      }

      // combination services duration calculation
      if (rescheduledCombination && rescheduledCombination.events && rescheduledCombination.events.length) {
        selectedItemDuration = rescheduledCombination.events.reduce((duration, item) => duration + (item || {}).duration, 0)
      }

      info.service = { name: selectedItemName }
      if (!settings.hideServiceDuration) info.service.duration = selectedItemDuration
      if (selectedItemPrice) info.service.price = selectedItemPrice
      // if online payment show price with all transaction fees. Here we determine if user has clicked online payment if there is a next page
      if (selectedItem.hasOnlinePayment &&
        (['paymentOnlinePayment', 'paymentStripe'].includes(routeName) || (routeName === 'confirm' && !!nextPage))
      ) {
        const initialPrice = selectedItem && selectedItem.price
        if (offer) {
          info.service.discountedPrice = getDiscountedPrice(selectedItemPrice, offer.discount)
        }
        info.service.price = initialPrice
        info.service.taxProcent = stripeFeePercentage || 0
        if (!hasStripeFeesNotAllowed && info.service.taxProcent > 0) { // add 2 fields for transaction fee and total price if stripeFeePercentage is defined
          info.service.transactionFeePrice = initialPrice - getDiscountedPrice(initialPrice, info.service.taxProcent)
          info.service.totalPrice = initialPrice + info.service.transactionFeePrice
          if (offer) {
            // service has discount
            const discountedInitialPrice = getDiscountedPrice(info.service.price, offer.discount)
            info.service.transactionFeePrice = discountedInitialPrice - getDiscountedPrice(discountedInitialPrice, info.service.taxProcent)
            info.service.totalPrice = discountedInitialPrice + info.service.transactionFeePrice
          }
          if (additionalParticipantsCount > 0) {
            // if course booking with extra participants
            const totalInitialPrice = (initialPrice * (additionalParticipantsCount + 1))
            info.service.transactionFeePrice = totalInitialPrice - getDiscountedPrice(totalInitialPrice, info.service.taxProcent)
            info.service.totalPrice = (initialPrice * (additionalParticipantsCount + 1)) + info.service.transactionFeePrice
            if (offer) {
              // course has discount
              const discountedInitialPrice = getDiscountedPrice(totalInitialPrice, offer.discount)
              info.service.transactionFeePrice = discountedInitialPrice - getDiscountedPrice(discountedInitialPrice, info.service.taxProcent)
              info.service.totalPrice = discountedInitialPrice + info.service.transactionFeePrice
            }
          }
        }
      }
      if (selectedItem.discountedPrice) info.service.discountedPrice = selectedItem.discountedPrice
      if (offer && selectedItemPrice) info.service.discountedPrice = getDiscountedPrice(selectedItemPrice, offer.discount)
      if (selectedCourse) {
        info.service.extraPersonsPerParticipant = additionalParticipantsCount
        info.service.maxExtraPersonsPerParticipant = selectedCourse.extraPersonsPerParticipant
        info.service.courseId = selectedCourseId

        if (selectedCourse.resources && selectedCourse.resources.length > 0 && !settings.hideResourcesSection) {
          info.courseResources = selectedCourse.resources.reduce((acc, item) => { acc.push(item.name); return acc }, []).join(', ')
        }
      }
    }
    // Resource
    if (selectedResource && !settings.hideResourcesSection) info.resource = { name: selectedResource.name }
    // Slot
    if (selectedDay && selectedMinutes) info.slot = { day: selectedDay, minutes: selectedMinutes }
    const allCustomerFields = { ...customerFieldsBeforeConfirmation, ...customerFieldsOnService }
    const customerFieldsWithValues = Object.entries(allCustomerFields).reduce((acc, item) => {
      if (item[0].indexOf('customerField') !== -1 && item[0].indexOf('Secondary') === -1 &&
        item[0].indexOf('Zoom') === -1 && item[0].indexOf('CropRadius') === -1 &&
        (
          (item[1].type === 'ADDRESS' && item[1].value) ||
          (item[1].type === 'PHONE' && item[1].phone && item[1].phone !== '') ||
          (item[1].value && item[1].value !== '') ||
          (item[1].values && item[1].values.length > 0) ||
          (item[1].selected && item[1].selected.length > 0)
        )
      ) {
        if (allCustomerFields[`${item[0]}Secondary`] && allCustomerFields[`${item[0]}Secondary`].value) item[1].details = allCustomerFields[`${item[0]}Secondary`].value
        if ((hideCustomerFields || hideExternalCustomerFields) && !item[1]?.isMandatory) {
          if (!hideCustomerFields?.find((customerFieldId) => item[0]?.includes(customerFieldId)) && !hideExternalCustomerFields?.find((externalCustomerFieldId) => item[1]?.externalId === externalCustomerFieldId)) {
            acc.push(item[1])
          }
        } else acc.push(item[1])
      }
      return acc
    }, [])
    const rescheduledEvent = { ...rescheduledData }
    if (rescheduledEvent && rescheduledEvent.id) {
      rescheduledEvent.appointmentDate = rescheduledEvent.startDate
      if (!settings.hideResourcesSection) rescheduledEvent.resourcesNames = (rescheduledEvent.resources && rescheduledEvent.resources.reduce((acc, item) => { acc.push(item.name); return acc }, [])) || []
    }
    // disclaimer
    let { disclaimerConfig } = settings || {}
    disclaimerConfig = disclaimerConfig || {}
    if (disclaimerTypeCustomisation && disclaimerTextCustomisation) {
      disclaimerConfig.active = true
      disclaimerConfig.displayOn = disclaimerTypeCustomisation
      disclaimerConfig.text = disclaimerTextCustomisation
    }
    if (hideDisclaimer) disclaimerConfig = {}
    const {
      active: hasDisclaimer,
      text: disclaimerText,
      displayOn: disclaimerPlacement
    } = disclaimerConfig || {}
    const shouldDisplayDisclaimer = !!hasDisclaimer && disclaimerPlacement === 'BOOKING_SUMMARY_VIEW' && !!extractFromHtml(disclaimerText)

    const isActive = ['confirm', 'confirmParticipants'].includes(routeName)
    const hasVoucher = checkIfVouchersAreAvailableForThisSlot({ vouchers, info, selectedTimezone, serviceId: selectedServiceId, courseId: selectedCourseId })
    const voucherCode = voucherInput.voucherValue?.value || ''
    const voucherStatus = voucherInput.voucherStatus?.value || ''
    const voucherFrom = voucherInput.voucherFrom?.value?.split(' ')[0] || ''
    const voucherUntil = voucherInput.voucherUntil?.value?.split(' ')[0] || ''
    const voucherDiscount = voucherInput.voucherDiscount?.value || ''
    const voucherError = voucherInput.globalErrors && (voucherInput.globalErrors.error || '')
    return (
      <>
        {((!isEnterprise || !addOns || !addOns.includes('MRSPEX')) &&
          <Confirm
            info={info}
            appMode={appMode}
            isActive={isActive}
            enterpriseId={enterpriseId}
            disclaimerText={disclaimerText}
            hasStripeActive={hasStripeActive}
            hasNotification={hasNotification}
            selectedTimezone={selectedTimezone}
            rescheduledEvent={rescheduledEvent}
            hasStripeConnected={hasStripeConnected}
            resourceExternalIds={resourceExternalIds}
            onNotificationClose={this.onNotificationClose}
            hasStripeFeesNotAllowed={hasStripeFeesNotAllowed}
            shouldDisplayDisclaimer={shouldDisplayDisclaimer}
            customerFieldsWithValues={customerFieldsWithValues}
            onCourseParticipantsUpdate={this.onCourseParticipantsUpdate}
            voucherCode={voucherCode}
            hasVoucher={hasVoucher}
            initVoucherCode={initVoucherCode}
            voucherDiscount={voucherDiscount}
            voucherStatus={voucherStatus}
            voucherFrom={voucherFrom}
            voucherUntil={voucherUntil}
            voucherError={voucherError}
            routeName={routeName}
          />
        )}
        {(isEnterprise && addOns && addOns.includes('MRSPEX') &&
          <ConfirmMrSpex
            isActive={isActive}
            appMode={appMode}
            info={info}
            customerFieldsWithValues={customerFieldsWithValues}
            enterpriseId={enterpriseId}
            resourceExternalIds={resourceExternalIds}
            rescheduledEvent={rescheduledEvent}
            hasNotification={hasNotification}
            onNotificationClose={this.onNotificationClose}
            onCourseParticipantsUpdate={this.onCourseParticipantsUpdate}
            selectedTimezone={selectedTimezone}
            company={company}
            shouldDisplayDisclaimer={shouldDisplayDisclaimer}
            disclaimerText={disclaimerText}
          />
        )}
      </>
    )
  }
}

const maps = (state) => ({
  routeName: (state.router && state.router.name) || '',
  appMode: state.app.mode || 'NORMAL',
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  services: state.services.list || [],
  selectedServiceId: state.services.selectedId || null,
  selectedCourseId: state.courses.selectedId || null,
  resources: state.resources.list || [],
  selectedResourceId: state.resources.selectedId || null,
  selectedDay: state.slots.selectedDay || null,
  selectedMinutes: state.slots.selectedMinutes || null,
  offers: (state.offers.list || []),
  vouchers: (state.offers.listVouchers || []),
  selectedOfferId: state.slots.selectedOfferId || null,
  customerFields: state.customerFields.list || [],
  customerFieldsOnService: state.forms.customerFieldsOnService || {},
  voucherInput: state.forms.voucherInput || {},
  customerFieldsBeforeConfirmation: state.forms.customerFieldsBeforeConfirmation || {},
  enterpriseId: state.enterprise.id || null,
  resourceExternalIds: state.attributes.resourceExternalIds || [],
  initVoucherCode: state.attributes.voucherCode || '',
  rescheduledData: state.booking.rescheduledData || null,
  timezones: state.timezones.list || [],
  selectedTimezoneCode: state.timezones.selectedCode || null,
  upsellingCategories: state.upselling.categories || [],
  upsellingServices: state.upselling.services || [],
  upsellingSelectedIds: state.upselling.selectedIds || [],
  attributes: state.attributes || {},
  extraParticipants: state.courses.extraParticipants || '',
  coursesList: state.slots.coursesList || [],
  selectedEventId: state.slots.selectedEventId || null,
  disclaimerConfig: state.slots.selectedEventId || null,
  customisations: state.customisations || {},
  nextPage: state.pages.nextPage || null,
  countries: state.countries
})

export default connect(maps)(ConfirmWrapper)
