import React, { PureComponent } from 'react'
import moment from 'moment'
import {
  t,
  format,
  FontAwesome5,
  Row,
  Col,
  Price
} from '../../Common'
import { convertMinutesToHours } from '../../Utils'

import './BookingInfoMrSpex.css'

class BookingInfoMrSpex extends PureComponent {
  render () {
    const { isActive, info } = this.props
    const {
      service,
      selectedDay,
      selectedMinutes,
      price,
      taxPrice,
      taxProcent,
      totalPrice
    } = info || {}
    const classNames = ['ta-booking-info-mrspex']
    if (isActive) classNames.push('active')

    return (
      <div className={classNames.join(' ')}>
        {(service &&
          <>
            <div className='ta-booking-info-mrspex__title'>
              {service.name}
            </div>
            <div className='ta-booking-info-mrspex__additional'>
              {(service.duration &&
                <div className='ta-booking-info-mrspex__additional-element'>
                  <FontAwesome5 icon='clock' className='ta-booking-info-mrspex__additional-icon' />
                  ca {service.duration} {t('services.list.section.category.service.min.label')}
                </div>
              )}
              {(selectedDay && selectedMinutes &&
                <div className='ta-booking-info-mrspex__additional-element'>
                  <FontAwesome5 icon='calendar-check' className='ta-booking-info-mrspex__additional-icon' />
                  {t('misterspex.booking_info.date.label', [{
                    key: 'DAY',
                    value: moment.utc(selectedDay).format('dd')
                  }, {
                    key: 'DATE',
                    value: format(selectedDay, 'long', { isUTC: true, format: 'YYYY-MM-DD' })
                  }, {
                    key: 'TIME',
                    value: convertMinutesToHours(selectedMinutes)
                  }])}
                </div>
              )}
            </div>
            {(service.price && service.price > 0 &&
              <div className='ta-booking-info-mrspex__price'>
                {!service.discountedPrice && <Price price={!service.extraPersonsPerParticipant ? service.price : service.price * (service.extraPersonsPerParticipant + 1)} />}
                {(service.discountedPrice &&
                  <>
                    <strike className='ta-services__price'><Price price={service.price} /></strike>
                    <FontAwesome5 icon='long-arrow-right' type='regular' />
                    <Price price={!service.extraPersonsPerParticipant ? service.discountedPrice : service.discountedPrice * (service.extraPersonsPerParticipant + 1)} />
                  </>
                )}
              </div>
            )}
          </>
        )}
        {(totalPrice &&
          <div className='ta-booking-info__online-payment-fields'>
            <Row noOffset>
              <Col size={70} className='ta-booking-info__title border'>
                {t('global.price')}: <Price price={!service.extraPersonsPerParticipant ? price : price * (service.extraPersonsPerParticipant + 1)} />
              </Col>
            </Row>
            {(taxPrice > 0 &&
              <Row noOffset>
                <Col size={70} className='ta-booking-info__title border'>
                  {t('global.fee')}: <Price price={taxPrice} /> {t('payments.stripe.transactionFee', [{ key: 'FEE', value: taxProcent }])}
                </Col>
              </Row>
            )}
            <Row noOffset>
              <Col size={70} className='ta-booking-info__title border'>
                {t('global.totalPrice')}: <Price price={!service.extraPersonsPerParticipant ? totalPrice : totalPrice * (service.extraPersonsPerParticipant + 1)} />
              </Col>
            </Row>
          </div>
        )}
      </div>
    )
  }
}

export default BookingInfoMrSpex
