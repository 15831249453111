import React, { useEffect } from 'react'
import { connect, handlers, store } from '../../Store'
import { DebugOptions } from '../../Beauties'
import { Loader } from '../../Common'
import { stringifyQuery } from '../../Utils'

const DebugOptionsWrapper = props => {
  const { persist, phraseActive, pending } = props
  const { isDebuggingActive } = persist || {}
  const isStaging = ['development', 'staging'].includes(process.env.REACT_APP_ENV)

  useEffect(() => {
    if (isStaging && isDebuggingActive) {
      const { query, path } = store.getState().router || {}
      const newQuery = { ...(query || {}), debug: '' }
      handlers.navigateToPath(`${path}${stringifyQuery(newQuery)}`)
      handlers.debugOptionsFormGet()
    }
  }, [isStaging, isDebuggingActive])

  const onSubmit = (cb) => {
    const form = store.getState().forms.debugOptions
    handlers.debugOptionsFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  const closeDebugMode = () => {
    handlers.persistSet({ isDebuggingActive: false })
  }

  if (!isStaging) return null
  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <DebugOptions
          active={isDebuggingActive}
          closeDebugMode={closeDebugMode}
          phraseActive={phraseActive}
          pending={pending}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}

const maps = state => ({
  persist: state.persist,
  phraseActive: state.forms.debugOptions && state.forms.debugOptions.phraseActive && state.forms.debugOptions.phraseActive.value,
  pending: state.debugOptions && state.debugOptions.pendingForm
})

export default connect(maps)(DebugOptionsWrapper)
