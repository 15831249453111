import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '../../Common'

import './NoteBox.css'

class NoteBox extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: true,
      height: '1000px',
      timer: null
    }

    this.switchOpenState = this.switchOpenState.bind(this)
  }

  componentDidMount () {
    const { canHide } = this.props
    if (canHide) {
      const timer = setTimeout(() => {
        this.switchOpenState()
      }, 2000)
      this.setState({ timer: timer })
    }
  }

  componentWillUnmount () {
    const { timer } = this.state
    clearTimeout(timer)
    this.setState({ timer: null })
  }

  switchOpenState () {
    const { isOpen, timer } = this.state
    let height = isOpen ? '14px' : '1000px'
    clearTimeout(timer)
    this.setState({ isOpen: !isOpen, height: height, timer: null })
  }

  render () {
    const { children, isFixed, canHide } = this.props
    const { isOpen, height } = this.state
    const classNames = ['ta-note-box']
    if (isFixed) classNames.push('fixed')

    return (
      <div className={classNames.join(' ')}>
        {canHide && (
          <div className='ta-note-box__switcher' onClick={this.switchOpenState}>
            <FontAwesome5 icon={isOpen ? 'angle-down' : 'angle-up'} type='regular' />
          </div>
        )}
        <div className='ta-note-box__container' style={{ maxHeight: height }}>
          {children}
        </div>
      </div>
    )
  }
}

NoteBox.propTypes = {
  children: PropTypes.any,
  isFixed: PropTypes.bool,
  canHide: PropTypes.bool
}

export default NoteBox
