import React from 'react'
import { CoursesWeekWrapper } from '../../Beasts'

const CoursesWeeklySlots = props => {
  const { slotsByDays, hideResourcesSection } = props

  return (
    <>
      {slotsByDays && slotsByDays.length > 0 && slotsByDays.map((item, index) => (
        <CoursesWeekWrapper
          key={`week-${index}`}
          hideResourcesSection={hideResourcesSection}
          item={item}
        />
      ))}
    </>
  )
}

export default CoursesWeeklySlots
