import React, { PureComponent } from 'react'
import { connect, handlers } from '../../Store'
import { DEFAULT_LOCALE } from '../../Settings'
import { App } from '../../Beauties'
import { ErrorBoundary } from '../../Common'
import { getDeviceType } from '../../Utils'

class AppWrapper extends PureComponent {
  constructor (props, context) {
    super(props)
    this.state = {
      fileLoading: false,
      deviceType: null
    }
  }

  componentDidMount () {
    handlers.appInit()
    this.setState({ deviceType: getDeviceType() })
  }

  render () {
    const {
      isLoaded,
      isPending,
      isPendingLanguage,
      isLanguageOpen,
      isDataLoaded,
      isFullScreen,
      hideCloseButton,
      routeName,
      hasOverwriteCss,
      enterpriseId,
      attributes,
      language,
      isUpsellingActive,
      companies,
      selectedCompanyId,
      phraseLoaded
    } = this.props
    const { fileLoading, deviceType } = this.state
    const isBlur = ['authLogin', 'authRegister', 'authForgotten', 'authConfirmation', 'authChangePhone', 'authGuestBooking', 'authEnforcePasswordChange'].includes(routeName)
    const { overlayColor = null, overlayOpacity = null, isEnterprise = false } = attributes
    const company = companies.find(item => item.id === selectedCompanyId) || {}

    const onCloseClick = () => {
      const { attributes } = this.props
      if (window && window.parent && window.parent.postMessage) {
        window.parent.postMessage('timify:widget:close', '*')
        window.parent.postMessage('closeIframe', '*')
        window.parent.postMessage({
          action: 'timify:widget:close',
          language: language.substring(0, 2).toUpperCase(),
          country: (company && company.locale ? company.locale.substring(3, 5).toUpperCase() : ''),
          company: (company && company.name) || '',
          companyId: (company && company.id) || '',
          companyExternalId: (company && company.externalId) || null
        }, '*')
      }

      // In case the widget is opened in new tab, instead of iframe
      if (attributes.inNewTab && window) {
        window.close()
      }
    }

    return (
      <ErrorBoundary>
        {isLoaded && (
          <App
            isPending={isPending}
            isPendingLanguage={isPendingLanguage}
            isLanguageOpen={isLanguageOpen}
            isDataLoaded={isDataLoaded}
            isFullScreen={!!isFullScreen}
            isBlur={isBlur}
            hideCloseButton={hideCloseButton}
            onCloseClick={onCloseClick}
            onContainerResize={this.onContainerResize}
            hasOverwriteCss={hasOverwriteCss}
            enterpriseId={enterpriseId}
            overlayColor={overlayColor}
            overlayOpacity={overlayOpacity}
            language={language}
            isUpsellingActive={isUpsellingActive}
            isEnterprise={isEnterprise}
            phraseLoaded={phraseLoaded}
            setFileLoading={loading => this.setState({ fileLoading: loading })}
            fileLoading={fileLoading}
            deviceType={deviceType}
            hasStripeConnected={company?.hasStripeConnected}
          />
        )}
      </ErrorBoundary>
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  language: (state.app && state.app.locale) || DEFAULT_LOCALE,
  isLoaded: state.app.isLoaded || false,
  isPending: state.app.isPending || false,
  isPendingLanguage: state.app.isPendingLanguage,
  isLanguageOpen: state.app.isLanguageOpen || false,
  isDataLoaded: state.app.isDataLoaded || false,
  isFullScreen: state.attributes.fullscreen || false,
  hideCloseButton: state.attributes.hideCloseButton || false,
  hasOverwriteCss: (state.app && state.app.customisationCSS) || false,
  enterpriseId: state.enterprise.id || null,
  attributes: state.attributes || {},
  isUpsellingActive: state.upselling.isActive || false,
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  phraseLoaded: state.phrase.isEnabled || false
})

export default connect(maps)(AppWrapper)
