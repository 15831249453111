import { handlers } from '../../../Store'
import { debugOptionsFormTransform } from './utils'

const reducer = {}

reducer.initialState = {
  pendingForm: false
}

reducer.handlers = (dispatch, actions) => ({
  debugOptionsFormGet: () => dispatch(actions.DEBUG_OPTIONS_FORM_GET),
  debugOptionsFormPopulate: (options) => {
    handlers.debugOptionsFormReady()
    handlers.formSet('debugOptions', debugOptionsFormTransform(options))
  },
  debugOptionsFormReady: () => dispatch(actions.DEBUG_OPTIONS_FORM_READY),
  debugOptionsFormSave: (options, scrollToError = () => { }) => dispatch(actions.DEBUG_OPTIONS_FORM_SAVE, { options, scrollToError })
})

reducer.DEBUG_OPTIONS_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.DEBUG_OPTIONS_FORM_READY = state => ({
  pendingForm: false
})

reducer.DEBUG_OPTIONS_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

export default reducer
