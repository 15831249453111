import React, { Component } from 'react'
import { connect, handlers } from '../../Store'
import { UpSelling } from '../../Beauties'
import { prepareUpsellingInfo, areShapesCompatible } from '../../Utils'

class UpSellingWrapper extends Component {
  componentDidMount () {
  }

  render () {
    const {
      companies,
      selectedCompanyId,
      isUpsellingActive,
      isUpsellingOpenOnMobile,
      upsellingCategories,
      upsellingServices,
      upsellingSelectedIds,
      services,
      selectedServiceId
    } = this.props
    const company = companies.find(item => item.id === selectedCompanyId) || {}
    const { hideServiceDuration = false } = company.settings || {}
    const selectedService = services.find(service => service.id === selectedServiceId)
    let upsellingRelations = null
    if (selectedServiceId) {
      upsellingRelations = upsellingServices.find((item) => item.id === selectedServiceId)
      if (!upsellingRelations) upsellingRelations = upsellingCategories.find((item) => item.id === selectedServiceId)

      if (upsellingRelations && upsellingRelations.relations) {
        const shapes = [selectedService.shape]
        upsellingRelations.relations.map(item => {
          if (upsellingSelectedIds.includes(item.id)) shapes.push(item.shape)
          return null
        })

        upsellingRelations.relations = upsellingRelations.relations.map(item => {
          if (!item || !item.shape || !selectedService || !selectedService.shape || !areShapesCompatible(...shapes, item.shape)) item.shapeDisabled = true
          else item.shapeDisabled = false
          return item
        })
      }
    }

    // We need to calculate additional duration & price for the service
    // If we have selected upselling services we need to append them to the name and duration
    let {
      duration: selectedItemDuration,
      price: selectedItemPrice
    } = prepareUpsellingInfo({ selectedItem: { ...selectedService }, services: upsellingServices, categories: upsellingCategories, relationsIds: upsellingSelectedIds })

    // Use this function when select an upselling service
    const onUpsellingServiceClick = (serviceId) => {
      const { upsellingSelectedIds = [] } = this.props
      const selectedIds = [...upsellingSelectedIds]
      const selectedServiceIndex = selectedIds.findIndex((itemId) => itemId === serviceId)
      if (selectedServiceIndex > -1) {
        selectedIds.splice(selectedServiceIndex, 1)
      } else {
        selectedIds.push(serviceId)
      }
      handlers.upsellingSelectedIds(selectedIds)
    }

    const onUpsellingCancel = () => {
      handlers.upsellingSelectedIds([])
      handlers.upsellingOpen(false)
    }

    const onUpsellingConfirm = () => {
      handlers.upsellingOpen(false)
    }

    return (
      <UpSelling
        isActive={isUpsellingActive}
        isOpenOnMobile={isUpsellingOpenOnMobile}
        relations={(upsellingRelations && upsellingRelations.relations) || []}
        selectedIds={upsellingSelectedIds}
        onUpsellingServiceClick={onUpsellingServiceClick}
        onUpsellingCancel={onUpsellingCancel}
        onUpsellingConfirm={onUpsellingConfirm}
        duration={selectedItemDuration}
        price={selectedItemPrice}
        hideServiceDuration={hideServiceDuration}
      />
    )
  }
}

const maps = state => ({
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  isUpsellingActive: state.upselling.isActive || false,
  isUpsellingOpenOnMobile: state.upselling.isOpenMobile || false,
  upsellingCategories: state.upselling.categories || [],
  upsellingServices: state.upselling.services || [],
  upsellingSelectedIds: state.upselling.selectedIds || [],
  services: state.services.list || [],
  selectedServiceId: state.services.selectedId || null
})

export default connect(maps)(UpSellingWrapper)
