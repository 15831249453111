import React from 'react'
import { ResourceItem } from '../../Beauties'
import {
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  FontAwesome5,
  t
} from '../../Common'

import './ResourceFixed.css'

const ResourceFixed = props => {
  const { resources, hasTitle, isDatePickerHidden } = props
  const classNames = ['ta-resources-fixed']

  if (resources && resources.length > 6) classNames.push('ta-resources-fixed__many')
  if (!hasTitle) classNames.push('ta-resources-fixed__nochoose')
  if (isDatePickerHidden) classNames.push('nomargin')

  return (
    <div className={classNames.join(' ')}>
      {(hasTitle &&
        <div className='ta-resources-fixed__title'>
          {t('availability.list.section.fixedResources.main.title')}
        </div>
      )}
      <div className='ta-resources-fixed__container'>
        {resources && resources.map((resource, index) => {
          if (index > 6) return null
          return (
            <ResourceItem
              key={index}
              resource={resource}
              hasTitle={hasTitle}
            />
          )
        })}
        {(resources && resources.length > 6 &&
          <HoverPopup>
            <HoverPopupContent position='bottom' autoSize>
              {resources.map((resource, index) => {
                if (index < 7) return null
                return (
                  <div key={index} className='ta-resources-empty__popup'>
                    {resource.name}
                  </div>
                )
              })}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <div className='ta-resources-empty'>
                <div className='ta-resources-empty__cnt'>
                  <FontAwesome5 type='solid' icon='user-friends' />
                  +{`${resources.length - 7}`}
                </div>
              </div>
            </HoverPopupTrigger>
          </HoverPopup>
        )}
      </div>
    </div>
  )
}

export default ResourceFixed
