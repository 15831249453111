import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/en-au'
import 'moment/locale/de'
import 'moment/locale/bg'
import 'moment/locale/es'
import 'moment/locale/ca'
import 'moment/locale/da'
import 'moment/locale/et'
import 'moment/locale/fi'
import 'moment/locale/fr'
import 'moment/locale/fr-ca'
import 'moment/locale/fr-ch'
import 'moment/locale/hu'
import 'moment/locale/it'
import 'moment/locale/ja'
import 'moment/locale/mt'
import 'moment/locale/nl'
import 'moment/locale/nn'
import 'moment/locale/pl'
import 'moment/locale/ru'
import 'moment/locale/sv'
import 'moment/locale/cy'
import 'moment/locale/cs'
import 'moment/locale/sq'
import 'moment/locale/sr-cyrl'
import 'moment/locale/gl'
import 'moment/locale/ro'

moment.defineLocale('en-mt', { parentLocale: 'en-gb' })
moment.defineLocale('ca-es', { parentLocale: 'ca' })
moment.defineLocale('en-ie', { parentLocale: 'en-gb' })
moment.defineLocale('sr-rs', { parentLocale: 'sr-cyrl' })

export { default as countries } from './countries'
export { default as languages } from './languages'
export {
  APP_VERSION,
  APP_VERSION_SUFFIX,
  DEFAULT_LOCALE,
  SUPPORTED_LOCALES,
  AVAILABLE_ATTRIBUTES,
  TIMIFY_PLANS,
  PASSWORD_RESET_URL,
  // ENV
  COMPANIES_URL,
  BOOKERS_URL,
  ACCOUNTS_ENGINE_URL,
  SERVERS_MANAGEMENT_URL,
  EMAIL_VALIDATION_REGEX,
  PHONE_VALIDATION_REGEX,
  DEFAULT_LOCALE_COUNTRY_CODE,
  PATH_TO_CIRCLE_FLAGS,
  GOOGLE_MAPS_API_KEY,
  FACEBOOK_APP_ID,
  STRIPE_API_KEY,
  STRIPE_REDIRECT_URL,
  TWELVE_HOUR_CLOCK,
  UPSELLING_URL,
  UPSELLING_VERSION,
  AON_URL,
  AON_VERSION,
  MRM_URL,
  MRM_VERSION,
  MRSPEX_URL,
  EMOJIS_REGEX,
  PHRASE_PROJECT_ID,
  PATH_TO_S3_LOCALE,
  UPLOAD_FILES_LIMIT,
  UPLOAD_FILE_SIZE_LIMIT,
  FILE_UPLOAD_URL,
  SENTRY_KEY,
  SENTRY_PROJECT,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PAYPAL_CLIENT_ID,
  PAYPAL_SUPPORTED_LOCALES
} from './settings'
export {
  INTERSPORT_ENTERPRISE_ID,
  DEUTSCHES_FRANCHISE_INSTITUT_ID,
  MRSPEX_ENTERPRISE_ID,
  MRSPEX_PARTNER_ENTERPRISE_ID,
  UNIQUE_ENTERPRISE_ID
} from './enterprises'
