import { generateTimeFormat } from '../../Utils'
import { store } from '../../Store'

export const getCustomTimeFormat = () => {
  const state = store.getState()
  const { selectedId, list: companies } = state.companies || {}
  const company = companies.find(item => item.id === selectedId)
  const { settings } = company || {}
  let { timeFormat } = settings || {}
  timeFormat = timeFormat || {}
  const hasCustomTimeFormat = Object.values(timeFormat).length > 0 && !Object.values(timeFormat).every(item => item === null)
  const timeFormatValues = {
    type: timeFormat.timeFormatType,
    punctuation: timeFormat.timeFormatTwelve,
    separator: timeFormat.timeFormatTwentyFour
  }

  if (!hasCustomTimeFormat) return null
  return generateTimeFormat(timeFormatValues)
}
