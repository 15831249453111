import React from 'react'
import PropTypes from 'prop-types'

const SimpleListItemBody = props => {
  const { children, onClick, isClosed = false, className } = props
  const classNames = ['ta-simple-list-item__body']
  if (isClosed) classNames.push('closed')
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')} onClick={onClick}>
      {children}
    </div>
  )
}

SimpleListItemBody.propTypes = {
  onClick: PropTypes.func,
  isClosed: PropTypes.bool,
  className: PropTypes.string
}

export default SimpleListItemBody
