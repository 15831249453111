import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '../../Common'

import './Loader.css'

const Loader = props => {
  const {
    isActive,
    hideBackground,
    style
  } = props
  const classNames = ['ta-loader']
  if (isActive) classNames.push('active')
  if (hideBackground) classNames.push('no-background')

  return (
    <div className={classNames.join(' ')} style={style}>
      <Image src='/images/ta-loader.gif' alt='Loading...' />
    </div>
  )
}

Loader.propTypes = {
  isActive: PropTypes.bool,
  hideBackground: PropTypes.bool,
  style: PropTypes.object
}

export default Loader
