import React from 'react'
import PropTypes from 'prop-types'
import { t, Link, FontAwesome5, LanguageSelector } from '../../Common'
import { BookingInfoWrapper } from '../../Beasts'

import './Header.css'

const Header = props => {
  let {
    appMode,
    selectedLocale,
    hasLanguageSelector,
    showBackButton,
    onBackButtonClick,
    showCompanyNameInHeader,
    company
  } = props
  company = company || {}
  const { name: companyName, tradingName: companyTradingName } = company || {}
  const classNames = ['ta-header', 'active']
  const defaultTitleStyles = { display: 'block' }
  const companyTitleStyles = { display: 'none' }
  if (showBackButton) classNames.push('with-back-button')

  if (showCompanyNameInHeader && company && companyName) {
    defaultTitleStyles.display = 'none'
    companyTitleStyles.display = 'block'
  }

  return (
    <div className={classNames.join(' ')}>
      <>
        <div className='ta-header__container'>
          <Link className='ta-header__back_button' onClick={onBackButtonClick}>
            <FontAwesome5 icon='long-arrow-left' type='light' />
          </Link>
          <div className='ta-header__title'>
            <span className='ta-header__title-default' style={defaultTitleStyles}>
              {appMode !== 'RESCHEDULE' && t('header.title', [{ key: 'COMPANY_NAME', value: companyTradingName || companyName }])}
              {appMode === 'RESCHEDULE' && t('header.reschedule.title', [{ key: 'COMPANY_NAME', value: companyTradingName || companyName }])}
            </span>
            {(companyName &&
              <span className='ta-header__title-company' style={companyTitleStyles}>
                {companyTradingName || companyName}
              </span>
            )}
          </div>
          {(hasLanguageSelector &&
            <LanguageSelector
              locale={selectedLocale}
            />
          )}
        </div>
        <BookingInfoWrapper />
      </>
    </div>
  )
}

Header.propTypes = {
  selectedLocale: PropTypes.string,
  hasLanguageSelector: PropTypes.bool,
  showBackButton: PropTypes.bool,
  onBackClick: PropTypes.func
}

export default Header
