import React, { Component } from 'react'

import './Accordion.css'

class Accordion extends Component {
  constructor (props, context) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      expand: this.props.expand === undefined ? true : this.props.expand,
      toggle: this.toggle
    }
  }

  componentDidMount () {
    const { inactive } = this.props
    inactive && this.setState({ expand: true })
  }

  toggle (name) {
    const { inactive } = this.props
    !inactive && this.setState({ expand: !this.state.expand })
  }

  render () {
    const {
      children,
      inactive,
      hasFieldWithError,
      zIndex,
      className
    } = this.props
    const { expand } = this.state
    const classNames = ['ta-accordion']
    if (className) classNames.push(className)
    if (inactive) classNames.push('inactive')
    if (expand) classNames.push('expand')
    if (hasFieldWithError) classNames.push('has-field-with-error')
    const styles = { zIndex }

    return (
      <AccordionContext.Provider value={this.state}>
        <div className={classNames.join(' ')} style={styles}>
          {children}
        </div>
      </AccordionContext.Provider>
    )
  }
}

export const AccordionContext = React.createContext({
  toggle: () => {}
})

export default Accordion
