export const enterpriseTransform = enterprise => {
  if (!enterprise) return {}
  const result = {
    ...enterprise,
    settings: { ...enterprise.onlineSettings }
  }
  delete result.onlineSettings
  return result
}

export const companiesTransform = companies => {
  if (!companies) return []
  return companies.map(item => {
    const result = {
      ...item,
      settings: { ...item.onlineSettings }
    }
    return result
  })
}
