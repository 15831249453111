/**
 * Check if an object has property
 * @param obj - Object
 * @param searchKey - Search key
 * @param returnVal - Return current key value or boolean
 * @param checkForNull - Check for null values
 */
export default (obj, searchKey, returnVal, checkForNull) => {
  let name
  let arrKeys = searchKey.split('.')
  if (typeof returnVal === 'undefined') {
    returnVal = false
  }
  if (typeof checkForNull === 'undefined') {
    checkForNull = true
  }
  name = arrKeys.shift()
  while (name) {
    if (!obj || !obj.hasOwnProperty(name) || (checkForNull && obj[name] === null)) {
      if (returnVal) return ''
      return false
    }
    obj = obj[name]
    name = arrKeys.shift()
  }
  if (returnVal) {
    return obj
  }
  return true
}
