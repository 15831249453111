import React, { Component } from 'react'
import { connect, handlers, store } from '../../../Store'
import { AuthRegisterFacebook } from '../../../Beauties'
import { getRouteQuery } from '../../../Utils'

class RegisterFacebookWrapper extends Component {
  constructor (props) {
    super(props)

    this.onCloseClick = this.onCloseClick.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    handlers.registrationFormFacebookPopulate()
  }

  onCloseClick () {
    handlers.registrationFormFacebookPopulate()
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/auth${routeQuery}`)
  }

  onSubmit (cb) {
    const { form } = this.props
    handlers.registerFormFacebookSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    const {
      routeName,
      companies,
      selectedCompanyId,
      locale,
      enterpriseId
    } = this.props

    const company = companies.find(item => item.id === selectedCompanyId) || {}
    const { settings: companySettings = {} } = company
    const { customTermsAndConditionsUrl } = companySettings
    const isActive = routeName === 'authRegisterFacebook'

    return (
      <AuthRegisterFacebook
        isActive={isActive}
        onCloseClick={this.onCloseClick}
        onSubmit={this.onSubmit}
        company={company}
        customTermsAndConditionsUrl={customTermsAndConditionsUrl}
        enterpriseId={enterpriseId}
        locale={locale}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  form: state.forms.registerFacebook,
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  enterpriseId: state.enterprise.id || null,
  locale: state.app.locale
})

export default connect(maps)(RegisterFacebookWrapper)
