const reducer = {}

reducer.initialState = {
  settings: {}
}

reducer.handlers = (dispatch, actions) => ({
  partnerReset: () => dispatch(actions.PARTNER_RESET),
  partnerPopulate: data => dispatch(actions.PARTNER_POPULATE, data)
})

reducer.PARTNER_RESET = state => reducer.initialState

reducer.PARTNER_POPULATE = (state, data) => ({
  ...state,
  ...data
})

export default reducer
