import React from 'react'

const HoverPopupContent = props => {
  const { children, position, autoSize } = props
  const availablePositions = ['top', 'right', 'left', 'bottom']
  const selectedPosition = availablePositions.includes(position) ? position : availablePositions[0]
  const classNames = ['ta-hover-popup__content']
  classNames.push(selectedPosition)
  if (autoSize) classNames.push('auto-size')

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  )
}

export default HoverPopupContent
