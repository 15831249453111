import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '../../Common'

import './Message.css'

const Message = props => {
  const {
    type,
    big,
    label,
    children,
    show,
    hideIcon,
    className,
    onClick
  } = props
  const availableTypes = ['success', 'warning', 'error', 'neutral']
  const classNames = ['ta-message']
  classNames.push(availableTypes.indexOf(type) > -1 ? `type-${type}` : `type-${availableTypes[0]}`)
  if (className) classNames.push(className)
  if (big) classNames.push('big')
  if (show) classNames.push('active')
  let icon = 'check-square'
  if (type === 'warning') icon = 'lightbulb'
  if (type === 'error') icon = 'exclamation-triangle'

  return (
    <div className={classNames.join(' ')}>
      {type === 'warning' && onClick &&
        <div className='ta-message__close-btn' onClick={onClick}>
          <FontAwesome5 icon='times' />
        </div>
      }
      {label &&
        <div className='ta-message__title'>
          <FontAwesome5 icon={icon} type='solid' />
          {label}
        </div>
      }
      {!label && !hideIcon &&
        <div className='ta-message__icon'>
          <FontAwesome5 icon={icon} type='solid' />
        </div>
      }
      {children &&
        <div className={`ta-message__content ${label ? '' : 'no-label'} ${hideIcon ? 'no-icon' : ''}`}>{children}</div>
      }
    </div>
  )
}

Message.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  show: PropTypes.bool,
  hide: PropTypes.bool,
  hideIcon: PropTypes.bool
}

export default Message
