import React from 'react'
import { connect } from '../../Store'
import { EnterpriseLogo } from '../../Beauties'

const EnterpriseLogoWrapper = props => {
  const { hasOverwriteCss } = props
  const isActive = hasOverwriteCss

  return (
    <EnterpriseLogo
      isActive={isActive}
    />
  )
}

const maps = state => ({
  hasOverwriteCss: (state.app && state.app.customisationCSS) || false
})

export default connect(maps)(EnterpriseLogoWrapper)
