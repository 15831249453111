export default [
  // Active
  {
    code: 'GB',
    flag: 'GB.svg',
    name: 'English',
    locale: 'en-gb',
    availableLocales: ['en-gb', 'en-ie', 'en-mt', 'en-ca', 'en-ph', 'en-au', 'en-sg', 'en-in'],
    active: true
  },
  {
    code: 'US',
    flag: 'US.svg',
    name: 'US English',
    locale: 'en-us',
    availableLocales: ['en', 'en-us'],
    active: true
  },
  {
    code: 'DE',
    flag: 'DE.svg',
    name: 'Deutsch',
    locale: 'de-de',
    availableLocales: ['de-de', 'de-at', 'de-ch'],
    active: true
  },
  {
    code: 'ES',
    flag: 'ES.svg',
    name: 'Español',
    locale: 'es-es',
    availableLocales: ['es-es', 'es-co', 'es-cl', 'es-mx'],
    active: true
  },
  {
    code: 'FR',
    flag: 'FR.svg',
    name: 'Français',
    locale: 'fr-fr',
    availableLocales: ['fr-fr', 'fr-lu', 'fr-ch', 'fr-ca', 'fr-be'],
    active: true
  },
  {
    code: 'NL',
    flag: 'NL.svg',
    name: 'Nederlands',
    locale: 'nl-nl',
    availableLocales: ['nl-nl', 'nl-be'],
    active: true
  },
  {
    code: 'IT',
    flag: 'IT.svg',
    name: 'Italiano',
    locale: 'it-it',
    availableLocales: ['it-it', 'it-ch'],
    active: true
  },
  {
    code: 'CAT',
    flag: 'CAT.svg',
    name: 'Català',
    locale: 'ca-es',
    availableLocales: ['ca-es'],
    active: true
  },
  {
    code: 'GL',
    flag: 'GL.svg',
    name: 'Galego',
    locale: 'gl-es',
    availableLocales: ['gl-es'],
    active: true
  },
  {
    code: 'RO',
    flag: 'RO.svg',
    name: 'Romanian',
    locale: 'ro-ro',
    availableLocales: ['ro-ro'],
    active: true
  },
  {
    code: 'EE',
    flag: 'EE.svg',
    name: 'Eesti',
    locale: 'et-ee',
    availableLocales: ['et-ee'],
    active: true
  },
  {
    code: 'DK',
    flag: 'DK.svg',
    name: 'Dansk',
    locale: 'da-dk',
    availableLocales: ['da-dk'],
    active: true
  },
  {
    code: 'NO',
    flag: 'NO.svg',
    name: 'Norsk',
    locale: 'no-no',
    availableLocales: ['no-no'],
    active: true
  },
  {
    code: 'PL',
    flag: 'PL.svg',
    name: 'Polski',
    locale: 'pl-pl',
    availableLocales: ['pl-pl'],
    active: true
  },
  {
    code: 'FL',
    flag: 'FL.svg',
    name: 'Suomi',
    locale: 'fi-fi',
    availableLocales: ['fi-fi'],
    active: true
  },
  {
    code: 'SE',
    flag: 'SE.svg',
    name: 'Svenska',
    locale: 'sv-se',
    availableLocales: ['sv-se'],
    active: true
  },
  {
    code: 'CY',
    flag: 'WAL.svg',
    name: 'Cymraeg',
    locale: 'cy-gb',
    availableLocales: ['cy-gb'],
    active: true
  },
  {
    code: 'BG',
    flag: 'BG.svg',
    name: 'Български',
    locale: 'bg-bg',
    availableLocales: ['bg-bg'],
    active: true
  },
  {
    code: 'CZ',
    flag: 'CZ.svg',
    name: 'Czech',
    locale: 'cs-cz',
    availableLocales: ['cs-cz'],
    active: true
  },
  {
    code: 'PT',
    flag: 'PT.svg',
    name: 'Portuguese',
    locale: 'pt-pt',
    availableLocales: ['pt-pt'],
    active: true
  },
  {
    code: 'JP',
    flag: 'JP.svg',
    name: '日本',
    locale: 'ja-jp',
    availableLocales: ['ja-jp'],
    active: true
  },
  {
    code: 'KR',
    flag: 'KR.svg',
    name: '한국인',
    locale: 'ko-kr',
    availableLocales: ['ko-kr'],
    active: true
  },
  {
    code: 'AL',
    flag: 'AL.svg',
    name: 'Shqip',
    locale: 'sq-al',
    availableLocales: ['sq-al'],
    active: true
  },
  {
    code: 'RS',
    flag: 'RS.svg',
    name: 'Српски',
    locale: 'sr-rs',
    availableLocales: ['sr-rs'],
    active: true
  },
  {
    code: 'HU',
    flag: 'HU.svg',
    name: 'Magyar',
    locale: 'hu-hu',
    availableLocales: ['hu-hu'],
    active: true
  }, {
    code: 'CN',
    flag: 'CN.svg',
    name: '繁體中文',
    locale: 'zh-cn',
    availableLocales: ['zh-cn'],
    active: true
  }
]
