import { store } from '../../../Store'
import {
  coursesTransform,
  courseCategoriesTransform,
  customerFieldsFormTransform
} from './utils'
import { DEFAULT_LOCALE_COUNTRY_CODE } from '../../../Settings'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  selectedId: null,
  selectedCustomerFieldIds: [],
  extraParticipants: ''
}

reducer.handlers = (dispatch, actions, handlers) => ({
  coursesReset: () => dispatch(actions.COURSES_RESET),
  coursesPopulate: ({ courses, courseCategories, miscCourseCategory }) => dispatch(actions.COURSES_POPULATE, { courses: coursesTransform(courses), courseCategories: courseCategoriesTransform(courseCategories, miscCourseCategory, courses) }),
  courseSelect: ({ id, customerFieldIds, customerFields }) => {
    dispatch(actions.COURSE_SELECT, { id, customerFieldIds })
    const { companies, attributes, account } = store.getState()
    const { customerField = {}, externalCustomerField = {} } = attributes || {}
    const { booker } = account
    const { list: companiesList, selectedId: selectedCompanyId } = companies
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId)
    let { locale: countryPhoneCode = DEFAULT_LOCALE_COUNTRY_CODE } = selectedCompany || {}
    countryPhoneCode = countryPhoneCode.split('-')
    countryPhoneCode = (countryPhoneCode[1] || DEFAULT_LOCALE_COUNTRY_CODE).toUpperCase()
    if (id) handlers.formSet('customerFieldsOnService', customerFieldsFormTransform(customerFields, customerField, externalCustomerField, countryPhoneCode, booker))
    handlers.availabilityReset()
  },
  courseSetExtraParticipants: participants => dispatch(actions.COURSES_SET_EXTRA_PARTICIPANTS, { participants })
})

reducer.COURSES_RESET = state => reducer.initialState

reducer.COURSES_POPULATE = (state, { courses, courseCategories }) => ({
  ...state,
  list: courses,
  categoriesList: courseCategories
})

reducer.COURSE_SELECT = (state, { id, customerFieldIds }) => ({
  ...state,
  selectedId: id,
  selectedCustomerFieldIds: customerFieldIds
})

reducer.COURSES_SET_EXTRA_PARTICIPANTS = (state, { participants }) => ({
  ...state,
  extraParticipants: participants
})

export default reducer
