import React from 'react'
import { FontAwesome5, t } from '../../Common'

import './SlideSelector.css'

const SlideSelector = props => {
  const {
    categories,
    selectedCategoryIdx: index,
    onClickPrev,
    onClickNext
  } = props
  // const index = categories.findIndex(item => item.id === selectedCategoryId)
  const prevIndex = index > 0 ? index - 1 : null
  const nextIndex = index < categories.length - 1 ? index + 1 : null
  // const selectedResourcesCount = (categories[index].resources.filter(item => item.isSelected)).length
  // const prevSelectedResourcesCount = prevIndex === null ? 0 : (categories[prevIndex].resources.filter(item => item.isSelected)).length
  // const nextSelectedResourcesCount = nextIndex === null ? 0 : (categories[nextIndex].resources.filter(item => item.isSelected)).length

  return (
    <div className='ta-slide-selector'>
      {prevIndex !== null &&
        <>
          <div className='ta-cta-secondary btn-prev' onClick={onClickPrev}>
            <FontAwesome5 icon='angle-left' />
          </div>
          <div className='ta-slide-selector__item prev'>
            {categories[prevIndex].name === 'default' ? t('availability.filter.section.yourResources.title') : categories[prevIndex].name}
            {/* prevSelectedResourcesCount > 0 &&
              <span>{prevSelectedResourcesCount}</span>
            */ }
          </div>
        </>
      }
      <div className='ta-slide-selector__item active'>
        {categories[index].name === 'default' ? t('availability.filter.section.yourResources.title') : categories[index].name}
        {/* selectedResourcesCount > 0 &&
          <span>{selectedResourcesCount}</span>
        */ }
      </div>
      {nextIndex !== null &&
        <>
          <div className='ta-slide-selector__item next'>
            {categories[nextIndex].name === 'default' ? t('availability.filter.section.yourResources.title') : categories[nextIndex].name}
            {/* nextSelectedResourcesCount > 0 &&
              <span>{nextSelectedResourcesCount}</span>
            */ }
          </div>
          <div className='ta-cta-secondary btn-next' onClick={onClickNext}>
            <FontAwesome5 icon='angle-right' />
          </div>
        </>
      }
    </div>
  )
}

export default SlideSelector
