const reducer = {}

reducer.initialState = {
  isPending: false,
  paypalAuthorizationId: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  paypalReset: () => dispatch(actions.PAYPAL_RESET),
  paypalAuthorizationIdSave: authorisationId => dispatch(actions.PAYPAL_AUTHORISATION_ID_SAVE, authorisationId)
})

reducer.PAYPAL_RESET = state => reducer.initialState

reducer.PAYPAL_AUTHORISATION_ID_SAVE = (state, paypalAuthorizationId) => ({
  ...state,
  paypalAuthorizationId
})

export default reducer
