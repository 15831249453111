import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ServicesCategory } from '../../Beauties'
import { t, FontAwesome5, DashedTitle } from '../../Common'
import { globalActions } from '../../Store'

import './Services.css'

const Services = props => {
  const {
    isActive,
    isPrevious,
    company = {},
    hasSelectedItem = false,
    serviceCategories = [],
    services = [],
    courseCategories = [],
    courses = [],
    offers = [],
    hasSupportedBy = false,
    hasPoweredBy = false,
    courseIds = [],
    serviceIds = [],
    serviceExternalIds = [],
    courseExternalIds = [],
    fileLoading,
    setFileLoading
  } = props
  const ref = useRef()
  const classNames = ['ta-step', 'ta-services']
  const containerClassNames = ['ta-services__container']
  if (isActive) classNames.push('active')
  if (isPrevious) classNames.push('prev')
  if (hasSelectedItem) classNames.push('has-button')
  if (hasSupportedBy) classNames.push('has-supportedby')
  else if (hasPoweredBy) classNames.push('has-poweredby')
  const { showWidgetCategoriesClosed, showWidgetFirstCategoryOpen } = company.settings || {}
  const firstCategory =
    (offers.length > 0 && offers[0]) ||
    (serviceCategories.length > 0 && serviceCategories[0]) ||
    (courseCategories.length > 0 && courseCategories[0])
  const offerServiceIds = offers.reduce((acc, item) => {
    const ids = [
      ...services.filter(service => item.serviceIds.includes(service.id)),
      ...courses.filter(course => item.courseIds.includes(course.id))
    ].map(item => item.id)
    return (acc || []).concat(ids)
  }, [])
  if (offers && offers.length === 0 && services && services.length === 0 && courses && courses.length === 0) containerClassNames.push('flex')

  useEffect(() => {
    let interval
    let element
    interval = setInterval(() => {
      element = ref.current
      if (element && isActive) {
        globalActions.postMessage({
          action: 'timify:widget:services:container',
          height: element.clientHeight
        }, '*')
      }
    }, 500)

    return () => clearInterval(interval)
  }, [ref, isActive])

  return (
    <div className={classNames.join(' ')}>
      {((isActive || isPrevious) &&
        <div className={containerClassNames.join(' ')} ref={ref}>
          {(offers && offers.length === 0 && services && services.length === 0 && courses && courses.length === 0 &&
            <div className='ta-services__inner'>

              <FontAwesome5 icon='exclamation-triangle' type='solid' className='ta-services__icon' />

              <DashedTitle label={t('services.empty.info.title')} className='ta-popup__main_title' />
              <div className='ta-text-box center'>
                {t('services.empty.info.text')}
              </div>

            </div>
          )}

          {offers.map(item => {
            const serviceItems = [...services.filter(service => item.serviceIds.includes(service.id)), ...courses.filter(course => item.courseIds.includes(course.id))]
            let expand = ((showWidgetFirstCategoryOpen && firstCategory && firstCategory.id === item.id) || !showWidgetCategoriesClosed)
            serviceItems.forEach(service => {
              if (serviceIds.includes(service.id) || serviceExternalIds.includes(service.externalId)) expand = true
            })
            return (
              <ServicesCategory
                key={item.id}
                isOffer
                category={item}
                items={serviceItems}
                expand={expand}
                fileLoading={fileLoading}
                setFileLoading={setFileLoading}
              />
            )
          })}
          {serviceCategories.map(item => {
            const serviceItems = services.filter(service => service.categoryId === item.id && !service.offerId && !offerServiceIds.includes(service.id))
            let expand = (showWidgetFirstCategoryOpen && firstCategory && firstCategory.id === item.id) || !showWidgetCategoriesClosed
            serviceItems.forEach(service => {
              if (serviceIds.includes(service.id) || serviceExternalIds.includes(service.externalId)) expand = true
            })
            return (
              <ServicesCategory
                key={item.id}
                category={item}
                items={serviceItems}
                expand={expand}
                fileLoading={fileLoading}
                setFileLoading={setFileLoading}
              />
            )
          })}
          {courseCategories.map((item, i) => {
            const courseItems = courses.filter(course => course.categoryId === item.id && !course.offerId && !offerServiceIds.includes(course.id))
            let expand = ((showWidgetFirstCategoryOpen && firstCategory && firstCategory.id === item.id) || !showWidgetCategoriesClosed)
            courseItems.forEach(course => {
              if (courseIds.includes(course.id) || courseExternalIds.includes(course.externalId)) expand = true
            })
            return (
              <ServicesCategory
                key={item.id}
                category={item}
                items={courseItems}
                expand={expand}
                fileLoading={fileLoading}
                setFileLoading={setFileLoading}
              />
            )
          }
          )}
        </div>
      )}
    </div>
  )
}

Services.propTypes = {
  company: PropTypes.object,
  isActive: PropTypes.bool,
  isPrevious: PropTypes.bool,
  serviceCategories: PropTypes.array,
  services: PropTypes.array,
  courseCategories: PropTypes.array,
  courses: PropTypes.array,
  offers: PropTypes.array
}

export default Services
