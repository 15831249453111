import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { StripeElements } from '../../Beauties'

import './Stripe.css'

const Stripe = ({ locale, clientSecret, stripePromise, showFooterBackButton }) => {
  if (locale === 'nn') locale = 'nb' // Stripe has nb key for Norwegian
  const options = {
    clientSecret,
    locale,
    appearance: {
      theme: 'stripe',
      variables: {
        colorText: '#505a5e',
        colorDanger: '#cb3b43',
        fontFamily: '\'Helvetica Neue\', Ubuntu, sans-serif',
        fontLineHeight: '1',
        fontSizeBase: '14px',
        borderRadius: '3px',
        focusBoxShadow: 'none'
      },
      rules: {
        '.Input': {
          border: '1px solid rgba(80, 90, 94, 0.6)',
          boxShadow: 'none'
        },
        '.Input--invalid': {
          border: '1px solid #cb3b43',
          boxShadow: 'none'
        },
        '.Input:focus': {
          border: '1px solid #505a5e'
        },
        '.Input::placeholder': {
          color: 'rgba(80, 90, 94, 0.6)'
        }
      }
    }
  }

  return (
    <div className='ta-stripe'>
      <Elements stripe={stripePromise} options={options}>
        <StripeElements showFooterBackButton={showFooterBackButton} />
      </Elements>
    </div>
  )
}

export default Stripe
