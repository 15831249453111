import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { countries, DEFAULT_LOCALE_COUNTRY_CODE } from '../../../Settings'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error, CountrySelector } from '../../../Common'

import './PhoneInput.css'

class PhoneInput extends Component {
  constructor (props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChangeCountry = this.onChangeCountry.bind(this)
    this.onChangeNumber = this.onChangeNumber.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.state = { focused: false, open: false }
  }

  componentDidMount () {
    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('mouseup', this.handleOutsideClick)
    } else {
      document.addEventListener('mouseup', this.handleOutsideClick)
    }
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)

    if (!document.removeEventListener && document.detachEvent) {
      document.detachEvent('mouseup', this.handleOutsideClick)
    } else {
      document.removeEventListener('mouseup', this.handleOutsideClick)
    }
  }

  handleOutsideClick (e) {
    if (this.wrapper && !this.wrapper.contains(e.target)) {
      this.setState({ open: false })
    }
  }

  toggle () {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChangeCountry (phoneCountry) {
    this.setState({ open: false })
    const { name, form, formName } = this.props
    handlers.formFieldsUpdate(formName, { [name]: { ...form, phoneCountry } })
  }

  onChangeNumber () {
    const phone = this.ref.value || null
    const { name, form, formName } = this.props
    this.setState({ filled: !!phone })
    handlers.formFieldsUpdate(formName, { [name]: { ...form, phone } })
  }

  render () {
    const { focused, open } = this.state
    const {
      label,
      name,
      hintText,
      mandatory,
      disabled,
      hideError,
      top,
      form = {},
      companies,
      selectedCompanyId,
      booker
    } = this.props
    let { placeholder = form.placeholder } = this.props
    if (!focused && form.phone === '') placeholder = label || form.label
    if (mandatory || form.mandatory) placeholder = `${placeholder} *`
    const classNames = ['ta-phone-input']
    if (open) classNames.push('active')
    if (top) classNames.push('top')

    // If the phone country code is empty we need to get the country from the company locale
    if (!form || !form.phoneCountry) {
      const { phone: bookerPhone } = booker
      const { country: bookerCountry = null } = bookerPhone || {}
      const company = companies.find(c => c.id === selectedCompanyId)
      const countryPhoneCode = company.locale.split('-')
      form.phoneCountry = (bookerCountry || countryPhoneCode[1] || DEFAULT_LOCALE_COUNTRY_CODE).toUpperCase()
    }
    const country = countries.filter(country => country.code === form.phoneCountry.toUpperCase())[0] || {}
    if (country.code) classNames.push(`ta-code-${country.code}`)

    return (
      <div ref={wrapper => { this.wrapper = wrapper }}>
        <FormGroup
          focused={focused}
          filled={!!form.phone}
          disabled={disabled || form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory || form.mandatory}
          className='ta-phone-input-group'
        >
          <div className={classNames.join(' ')}>
            <CountrySelector
              countries={countries}
              selected={form.phoneCountry}
              onChangeCountry={this.onChangeCountry}
              toggle={this.toggle}
            />
            <input
              className='ta-form-control'
              ref={ref => { this.ref = ref }}
              // type='number' // not working for some browsers
              pattern='[0-9]*'
              name={name}
              value={form.phone || ''}
              placeholder={placeholder}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChangeNumber}
              onClick={() => this.setState({ open: false })}
              disabled={disabled || form.disabled}
              autoComplete='off'
            />
          </div>
          {hintText &&
            <div className='ta-form-control__hint'>{hintText}</div>
          }
          {!hideError &&
            <Error name={name} />
          }
        </FormGroup>
      </div>
    )
  }
}

PhoneInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  hintText: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' },
  companies: (state.companies && state.companies.list) || [],
  selectedCompanyId: (state.companies && state.companies.selectedId) || null,
  booker: (state.account && state.account.booker) || {}
})

export default feedContextInProps(connect(maps)(PhoneInput), FormContext)
