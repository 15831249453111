import React from 'react'
import PropTypes from 'prop-types'
import { LocationsListItem } from '../../Beauties'
import {
  t,
  NoResults,
  Accordion,
  AccordionTitle,
  AccordionBody,
  SectionTitle
} from '../../Common'

const LocationsList = props => {
  const { city, companies, notFoundLabel } = props
  const cityLabel = city && city !== 'z'
    ? t('locations.list.section.locations.accordion.title', [{
      key: 'CITY',
      value: city
    }])
    : t('locations.list.section.locations.defaultTitle')

  return (
    <div className='ta-locations__list'>
      <Accordion>
        <AccordionTitle>
          <SectionTitle
            noIcon
            label={cityLabel}
          />
        </AccordionTitle>
        <AccordionBody>
          {companies.length > 0 && companies.map((company, index) => (
            <LocationsListItem
              key={company.id}
              company={company}
              index={index}
            />
          ))}
          {(!companies.length &&
            <NoResults>{notFoundLabel}</NoResults>
          )}
        </AccordionBody>
      </Accordion>
    </div>
  )
}

LocationsList.propTypes = {
  city: PropTypes.string,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default LocationsList
