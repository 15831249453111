import { store, actions, handlers, actions$, store$ } from '.'
// Export some globals for development
if (['development', 'staging'].includes(process.env.REACT_APP_ENV)) {
  // Used for console logging the arrow function f => g ====> f => clog(g) without {}
  global.clog = f => {
    console.log(f)
    return f
  }
  global.printToken = () => {
    const token = store.getState().auth.tokens.accessToken
    console.warn(JSON.stringify({ authorization: `Bearer ${token}` }))
  }
  global.Store = store
  global.Actions = actions
  global.Handlers = handlers
  actions$().subscribe(action => console.log(`%cAction`, 'background: #eee; border-left: 3px solid #ebb65d; padding: 3px 25px 3px 5px; font-size: 8px; border-radius: 2px; color: #555;', action))
  store$.subscribe(state => console.info('%cState', 'background: #eee; border-left: 3px solid #769851; padding: 3px 30px 3px 5px; font-size: 8px; border-radius: 2px; color: #555;', state))
}
