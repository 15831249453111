import { tokensFields } from '../Bookers/fields'

// Queries

// Mutations

export const sendPasswordResetCode = `
  mutation sendPasswordResetCode($authScope: AuthScope, $email: String!) {    
    sendPasswordResetCode(authScope: $authScope, email: $email)
  }
`

export const refreshTokens = `
  query refreshTokens($refreshToken: ID!) {
    refreshTokens(refreshToken: $refreshToken) ${tokensFields}
  }
`

// Subscriptions
