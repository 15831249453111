export default ({ selectedItem, services, categories, relationsIds }) => {
  selectedItem.duration = selectedItem.duration || selectedItem.durationsPatternCalc
  let relations = services.find((item) => item.id === selectedItem.id)
  if (!relations) relations = categories.find((item) => item.id === selectedItem.id)
  if (relations && relations.relations) {
    relationsIds.map(selectedId => {
      const selectedService = relations.relations.find(service => service.id === selectedId) || null

      if (selectedService) {
        selectedItem.name = `${selectedItem.name}, ${selectedService.name}`

        if (selectedService.add_extra_duration) {
          selectedItem.duration = selectedItem.duration + selectedService.duration
        }

        selectedItem.price = selectedItem.price + (selectedService.price || 0)
      }

      return true
    })
  }
  return selectedItem
}
