import React from 'react'
import { connect } from '../../Store'
import { BookedOut } from '../../Beauties'

const BookedOutWrapper = props => {
  const { routeName } = props
  const isActive = routeName === 'bookedOut'

  return (
    <BookedOut isActive={isActive} />
  )
}

const maps = state => ({
  routeName: (state.router && state.router.name) || ''
})

export default connect(maps)(BookedOutWrapper)
