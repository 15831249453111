import React from 'react'
import PropTypes from 'prop-types'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { t, Row, Col, LinedTitle, DashedTitle } from '../../Common'
import { dangerousHTML } from '../../Utils'
import { FACEBOOK_APP_ID } from '../../Settings'

import './Auth.css'

const Auth = props => {
  const {
    isActive,
    isPrevious,
    hasTimifyLogin,
    hasFacebookLogin,
    hasGuestBooking,
    onTimifyLoginClick,
    onTimifyRegisterClick,
    onFacebookLoginClick,
    onFacebookLogin,
    onFacebookFail,
    onGuestBookingClick
  } = props

  const classNames = ['ta-step', 'ta-auth']

  if (isActive) classNames.push('active')
  if (isPrevious) classNames.push('prev')

  const facebookLogin = (
    <FacebookLogin
      appId={FACEBOOK_APP_ID}
      version='15.0'
      isMobile={false}
      autoLoad={false}
      fields='name,email'
      callback={response => onFacebookLogin(response)}
      onFailure={() => onFacebookFail()}
      render={renderProps => (
        <button
          onClick={() => {
            if (!renderProps.isSdkLoaded) return
            onFacebookLoginClick()
            renderProps.onClick()
          }}
          disabled={!renderProps.isSdkLoaded}
          className={`ta-cta-blue ta-cta-two-lines${!renderProps.isSdkLoaded ? ' disabled' : ''}`}
        >
          {dangerousHTML(t('auth.preview.section.buttons.twolines.facebook.login'))}
        </button>
      )}
    />
  )

  const timifyLogin = (
    <button onClick={onTimifyLoginClick} className='ta-cta-primary ta-cta-two-lines'>
      {dangerousHTML(t('auth.preview.section.buttons.twolines.timify.login'))}
    </button>
  )

  const timifyRegister = (
    <button onClick={onTimifyRegisterClick} className='ta-cta-third'>
      {t('auth.preview.section.buttons.timify.register')}
    </button>
  )

  const guestBooking = (
    <button onClick={onGuestBookingClick} className='ta-cta-secondary ta-cta-two-lines'>
      {dangerousHTML(t('auth.preview.section.buttons.twolines.guest.booking'))}
    </button>
  )

  return (
    <div className={classNames.join(' ')}>
      {(isActive || isPrevious) && (
        <div className='ta-auth__container'>
          <div className='ta-auth__inner'>

            <DashedTitle label={t('auth.preview.section.info.title')} className='ta-popup__main_title' />

            <div className='ta-text-box center'>
              {t('auth.preview.section.info.text')}
            </div>

            {(hasTimifyLogin && hasFacebookLogin && hasGuestBooking &&
              <>
                <Row>
                  <Col size={33}>{timifyLogin}</Col>
                  <Col size={33}>{facebookLogin}</Col>
                  <Col size={33}> {guestBooking}</Col>
                </Row>
                <LinedTitle label={t('auth.preview.section.buttons.or.label')} />
                {timifyRegister}
              </>
            )}

            {(hasTimifyLogin && hasFacebookLogin && !hasGuestBooking &&
              <>
                <Row>
                  <Col size={50}>{timifyLogin}</Col>
                  <Col size={50}>{facebookLogin}</Col>
                </Row>
                <LinedTitle label={t('auth.preview.section.buttons.or.label')} />
                {timifyRegister}
              </>
            )}

            {(hasTimifyLogin && !hasFacebookLogin && hasGuestBooking &&
              <>
                <Row>
                  <Col size={50}>{timifyLogin}</Col>
                  <Col size={50}>{guestBooking}</Col>
                </Row>
                <LinedTitle label={t('auth.preview.section.buttons.or.label')} />
                {timifyRegister}
              </>
            )}

            {(hasTimifyLogin && !hasFacebookLogin && !hasGuestBooking &&
              <Row>
                <Col size={50}>{timifyRegister}</Col>
                <Col size={50}>
                  <button onClick={onTimifyLoginClick} className='ta-cta-primary'>
                    {t('auth.preview.section.buttons.timify.login')}
                  </button>
                </Col>
              </Row>
            )}

            {(!hasTimifyLogin && hasFacebookLogin && hasGuestBooking &&
              <Row>
                <Col size={50}>{facebookLogin}</Col>
                <Col size={50}>{guestBooking}</Col>
              </Row>
            )}

            {(!hasTimifyLogin && hasFacebookLogin && !hasGuestBooking &&
              <Row>
                <Col size={100}>{facebookLogin}</Col>
              </Row>
            )}

            {(!hasTimifyLogin && !hasFacebookLogin && hasGuestBooking &&
              <Row>
                <Col size={100}>{guestBooking}</Col>
              </Row>
            )}

          </div>
        </div>
      )}
    </div>
  )
}

Auth.propTypes = {
  isActive: PropTypes.bool,
  isPrevious: PropTypes.bool,
  hasTimifyLogin: PropTypes.bool,
  hasFacebookLogin: PropTypes.bool,
  hasGuestBooking: PropTypes.bool,
  onTimifyLoginClick: PropTypes.func,
  onTimifyRegisterClick: PropTypes.func,
  onFacebookLoginClick: PropTypes.func,
  onGuestBookingClick: PropTypes.func
}

export default Auth
