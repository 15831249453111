import React from 'react'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { Loader, t } from '../../../Common'
import { PAYPAL_CLIENT_ID, PAYPAL_SUPPORTED_LOCALES } from '../../../Settings'
import { PaymentsPayPalButton, PaymentStripeButton } from '../../../Beauties'

import './PaymentOnlinePayment.css'

const PaymentOnlinePayment = props => {
  let {
    locale,
    eventId,
    isActive,
    isPending,
    routeQuery,
    isPrevious,
    companyId,
    region,
    companyCurrency,
    secret,
    paypalMerchantId,
    hasPayPalConnected,
    hasStripeConnected,
    voucherCode
  } = props
  locale = locale || ''
  const classNames = ['ta-step', 'ta-online-payment']
  if (isActive) classNames.push('active')
  if (isPrevious) classNames.push('prev')
  const formattedLocale = locale.replace('-', '_')
  const countryOfLocale = locale.split('-')[1]
  let localeToLoad = PAYPAL_SUPPORTED_LOCALES.find(paypalLocale => paypalLocale === formattedLocale)
  if (!localeToLoad && formattedLocale) localeToLoad = PAYPAL_SUPPORTED_LOCALES.find(l => l.includes(countryOfLocale))

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-payments__title'>{t('onlinePayments.title')}</div>
      {((isActive || isPrevious) &&
        <>
          <Loader isActive={isPending} />
          <div className='ta-online-payment-buttons__container'>
            {(hasStripeConnected &&
              <PaymentStripeButton routeQuery={routeQuery} />
            )}
            {(hasPayPalConnected &&
              <PayPalScriptProvider options={{
                'client-id': PAYPAL_CLIENT_ID,
                'merchant-id': paypalMerchantId,
                'disable-funding': 'card,venmo,paylater',
                currency: companyCurrency,
                locale: localeToLoad // Need to be parsed to https://developer.paypal.com/sdk/js/configuration/#locale
              }}
              >
                <PaymentsPayPalButton
                  eventId={eventId}
                  companyId={companyId}
                  region={region}
                  secret={secret}
                  voucherCode={voucherCode}
                />
              </PayPalScriptProvider>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default PaymentOnlinePayment
