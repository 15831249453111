import React from 'react'
import { connect } from '../../Store'
import { OtherDays } from '../../Beauties'

const OtherDaysWrapper = props => {
  const {
    availableDays,
    onClickOtherDay,
    isWidgetWeeklyView,
    attributes
  } = props

  return (
    <OtherDays
      availableDays={availableDays}
      onClickOtherDay={onClickOtherDay}
      isWidgetWeeklyView={isWidgetWeeklyView}
      attributes={attributes}
    />
  )
}

const maps = state => ({
  attributes: state.attributes || {}
})

export default connect(maps)(OtherDaysWrapper)
