import React from 'react'
// import PropTypes from 'prop-types'
// import moment from 'moment-timezone'
import {
  SimpleListItem,
  // SimpleListItemTitle,
  SimpleListItemContent,
  SimpleListItemButtons,
  Avatar,
  FontAwesome5,
  Price,
  // LinedTitle,
  DottedTimeline,
  DottedTimelineItem,
  format,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  getCustomDateFormat,
  getCustomTimeFormat,
  t
} from '../../Common'
import { convertHoursToMinutes, dangerousHTML } from '../../Utils'

import './Courses.css'

const CoursesListItem = props => {
  const {
    event,
    isActive,
    hideResourcesSection,
    onClickSlot,
    price,
    offerId,
    discountedPrice
  } = props
  const {
    id,
    day,
    time,
    participantsCount,
    maxParticipants,
    // extraPersonsPerParticipant,
    intervals = [],
    resources = [],
    currentBookerPresent = false
  } = event || {}
  const firstResource = resources[0] || {}
  const classNames = ['ta-courses__course']
  if (discountedPrice) classNames.push('discount')
  if (isActive) classNames.push('active')
  const bookedOut = participantsCount === maxParticipants
  if (bookedOut || currentBookerPresent) classNames.push('booked-out')
  const buttonClassNames = ['ta-cta-third', 'ta-courses__button']
  // if (description && price <= 0 && hideServiceDuration) buttonClassNames.push('short')

  return (
    <HoverPopup disabled={!bookedOut && !currentBookerPresent} className='course'>
      <HoverPopupContent position='top'>
        {bookedOut && dangerousHTML(t('groups.bookedOut.tooltip'))}
        {currentBookerPresent && dangerousHTML(t('groups.alreadyBooked.tooltip'))}
      </HoverPopupContent>
      <HoverPopupTrigger>
        <div className={classNames.join(' ')}>
          <SimpleListItem action={() => onClickSlot(id, day, convertHoursToMinutes(time), offerId)}>
            <Avatar name={firstResource.name} image={firstResource.avatarUrl} color={firstResource.color} abbreviation={firstResource.abbreviation} />
            <DottedTimeline>
              {intervals.map((item, index) => (
                <DottedTimelineItem key={index} type={index === 0 ? 'big' : 'small'}>
                  <strong>
                    {format(item.interval.begin, getCustomDateFormat() || 'longWithLongWeekDay', { format: 'YYYY-MM-DD HH:mm' })}
                    {' '}
                    {format(item.interval.begin, getCustomTimeFormat() || 'time', { format: 'YYYY-MM-DD HH:mm' })}
                  </strong>
                </DottedTimelineItem>
              ))}
            </DottedTimeline>
            <SimpleListItemContent>
              <ul className={price === 0 ? 'ta-simple-list__inline' : ''}>
                {(!hideResourcesSection &&
                  <li className='ta-course-info__item'>
                    <FontAwesome5 icon='user' type='solid' />
                    {dangerousHTML(t('groups.with', [{
                      key: 'RESOURCES',
                      value: resources.map(item => item.name).join(', ')
                    }]))}
                  </li>
                )}
                <li>
                  <FontAwesome5 icon='users' type='solid' />
                  <strong>{participantsCount}/{maxParticipants}</strong> {t('groups.participants')}
                </li>
                {(price > 0 &&
                  <li>
                    <FontAwesome5 icon='money-bill' type='solid' />
                    {!discountedPrice && <Price price={price} />}
                    {(discountedPrice &&
                      <>
                        <strike className='ta-services__price'><Price price={price} /></strike>
                        <FontAwesome5 icon='long-arrow-right' type='regular' />
                        <Price price={discountedPrice} />
                      </>
                    )}
                  </li>
                )}
              </ul>
            </SimpleListItemContent>
            <SimpleListItemButtons>
              <button className={buttonClassNames.join(' ')}>
                {(isActive &&
                  <FontAwesome5 icon='check' type='solid' />
                )}
                {(!isActive &&
                  <FontAwesome5 icon='arrow-right' type='solid' />
                )}
              </button>
            </SimpleListItemButtons>
          </SimpleListItem>
        </div>
      </HoverPopupTrigger>
    </HoverPopup>
  )
}

// CoursesListItem.propTypes = {
//   onServiceClick: PropTypes.func,
//   item: PropTypes.object.isRequired,
//   customerFields: PropTypes.array,
//   selected: PropTypes.object,
//   hideServiceDuration: PropTypes.bool
// }

export default CoursesListItem
