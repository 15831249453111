import { tokensFields, bookerCustomerFields } from './fields'

// Queries

export const getBookerCompanyCustomerValues = `
  query getBookerCompanyCustomerValues($companyId: ID!, $region: Region!) {
    getBookerCompanyCustomerValues(companyId: $companyId, region: $region) ${bookerCustomerFields}
  }
`

// Mutations

export const loginBookerAccountWithEmailAndPassword = `
  mutation loginBookerAccountWithEmailAndPassword($email: String!, $password: String!) {
    loginBookerAccountWithEmailAndPassword(email: $email, password: $password) ${tokensFields}
  }
`

export const loginBookerAccountWithFacebook = `
  mutation loginBookerAccountWithFacebook($facebookToken: ID!, $locale: Locale, $longSession: Boolean) {
    loginBookerAccountWithFacebook(facebookToken: $facebookToken, locale: $locale, longSession: $longSession) ${tokensFields}
  }
`

export const registerBookerAccountWithEmailAndPassword = `
  mutation registerBookerAccountWithEmailAndPassword($email: String!, $password: String!, $companyId: ID!, $registrationData: BookerAccountData!) {
    registerBookerAccountWithEmailAndPassword(email: $email, password: $password, companyId: $companyId, registrationData: $registrationData) ${tokensFields}
  }
`

export const registerBookerAccountWithFacebook = `
  mutation registerBookerAccountWithFacebook($facebookToken: ID!, $companyId: ID!, $registrationData: BookerAccountData!, $longSession: Boolean) {
    registerBookerAccountWithFacebook(facebookToken: $facebookToken, companyId: $companyId, registrationData: $registrationData, longSession: $longSession) ${tokensFields}
  }
`

export const saveUserData = `
  mutation saveUserData ($userData: UserData!) {
    saveUserData (userData: $userData) {
      id
    }
  }
`

// Subscriptions
