import React from 'react'
import PropTypes from 'prop-types'
import { t, Form, Input, FontAwesome5, DashedTitle, Message, Row, Col, Error } from '../../../Common'

import './Forgotten.css'

const Forgotten = props => {
  const { isActive, onCloseClick, onBackClick, onSubmit, formSuccess } = props
  const classNames = ['ta-popup', 'ta-auth-forgotten']
  if (isActive) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      {isActive && (
        <>
          <button onClick={onBackClick} className='ta-popup-back'>
            <FontAwesome5 icon='long-arrow-left' type='light' />
          </button>
          <button onClick={onCloseClick} className='ta-popup-close'>
            <FontAwesome5 icon='times' type='light' />
          </button>
          <div className='ta-auth-forgotten__container'>
            <div className='ta-auth-forgotten__inner'>

              <Form onSubmit={onSubmit} name='forgotten'>

                <DashedTitle label={t('auth.forgotten.section.form.title')} className='ta-popup__main_title' />
                <div className='ta-text-box center'>
                  {t('auth.forgotten.section.form.text')}
                </div>

                <div className='ta-auth-forgotten__field'>
                  <Input
                    mandatory
                    name='email'
                    label={t('global.email.label')}
                    placeholder={t('global.email.placeholder')}
                  />
                </div>

                {(!formSuccess &&
                  <Row>
                    <Col size={100}>
                      <Error name='globalErrors' />
                    </Col>
                  </Row>
                )}

                {(formSuccess &&
                  <Message
                    show
                    type='success'
                    label={t('auth.forgotten.section.form.success.message')}
                  />
                )}

                <button type='submit' className='ta-cta-primary ta-cta-fluid'>
                  {t('auth.forgotten.section.form.buttons.resetPassword')}
                </button>

              </Form>

            </div>
          </div>
        </>
      )}
    </div>
  )
}

Forgotten.propTypes = {
  isActive: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onBackClick: PropTypes.func,
  formSuccess: PropTypes.bool
}

export default Forgotten
