export const debugOptionsFormTransform = (options) => {
    options = options || {}
    let { phraseActive, defaultBranch, branchesList, isIntercomActive } = options
    branchesList = branchesList || []
  
    const result = {
      phraseActive: {
        value: phraseActive
      },
      phraseBranch: {
        value: defaultBranch,
        options: branchesList
      },
      intercomActive: {
        value: isIntercomActive
      }
    }
    return result
  }