import React from 'react'
import PropTypes from 'prop-types'
import { ResourceItem, ResourcesFixed } from '../../Beauties'
import { t, SlideSelector, FontAwesome5, Avatar } from '../../Common'

import './Resources.css'

const Resources = props => {
  const {
    isActive,
    resources = [],
    selectedResourceId = null,
    selectedResource = null,
    resourceCategories = [],
    selectedCategoryIdx,
    onClickCategoryPrev,
    onClickCategoryNext,
    onResourceClick,
    isResourcesClosed,
    onResourceSelectorClick,
    fixedResources,
    showSelectedResourcesOnly,
    isDatePickerHidden
  } = props
  const classNames = ['ta-resources']
  const titleClassNames = ['ta-resources__title']
  const containerClassNames = ['ta-resources__container']
  if (isResourcesClosed) classNames.push('closed')
  if (resourceCategories.length < 2) containerClassNames.push('no-slider')
  if (selectedResource) {
    classNames.push('selected')
    titleClassNames.push('selected employee')
  }

  return (
    <>
      {(isActive &&
        <>
          {(resources && resources.length > 0 &&
            <div className={classNames.join(' ')}>
              <div className={titleClassNames.join(' ')} onClick={onResourceSelectorClick}>
                <FontAwesome5 icon='users' type='solid' className='ta-resources__title__icon' />
                <span className='ta-resources__title__label'>{t('availability.list.section.resources.main.title')}</span>
                {(selectedResource &&
                  <>
                    <Avatar name={selectedResource.name} image={selectedResource.avatarUrl} color={selectedResource.color} abbreviation={selectedResource.abbreviation} />
                    <span className='ta-resources__title__employee'>{selectedResource.name}</span>
                    <span className='ta-resources__employee__selector'>
                      <FontAwesome5 icon={isResourcesClosed ? 'angle-down' : 'angle-up'} type='solid' />
                    </span>
                  </>
                )}
              </div>
              <div className={containerClassNames.join(' ')}>
                <div className='ta-resources__inner'>
                  {(resourceCategories.length > 1 &&
                    <SlideSelector
                      categories={resourceCategories}
                      selectedCategoryIdx={selectedCategoryIdx}
                      onClickPrev={onClickCategoryPrev}
                      onClickNext={onClickCategoryNext}
                    />
                  )}
                  {((!showSelectedResourcesOnly && resources.length > 0) &&
                    <div className='ta-resources__item__select-all' onClick={() => onResourceClick(null)}>
                      <div className='ta-resources__item__select-all__avatar'>
                        <FontAwesome5 icon='users' type='solid' />
                      </div>
                      <div className='ta-resources__item__select-all__name'>
                        {t('availability.list.section.resources.buttons.selectAll')}
                      </div>
                    </div>
                  )}
                  {resources.map(resource =>
                    <ResourceItem
                      key={resource.id}
                      resource={resource}
                      isSelected={resource.id === selectedResourceId}
                      onClickResource={() => onResourceClick(resource.id)}
                      hasTitle={resources && resources.length > 0}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {(fixedResources && fixedResources.length > 0 &&
            <ResourcesFixed
              resources={fixedResources}
              hasTitle={resources && resources.length === 0}
              isDatePickerHidden={isDatePickerHidden}
            />
          )}
        </>
      )}
    </>
  )
}

Resources.propTypes = {
  company: PropTypes.object,
  isActive: PropTypes.bool,
  resources: PropTypes.array,
  resourceCategories: PropTypes.array,
  onClickCategoryPrev: PropTypes.func,
  onClickCategoryNext: PropTypes.func,
  onResourceClick: PropTypes.func,
  isResourcesClosed: PropTypes.bool,
  onResourceSelectorClick: PropTypes.func
}

export default Resources
