import React from 'react'
import { CoursesListItem } from '../../Beauties'
import { t, format, getCustomDateFormat } from '../../Common'

import './Week.css'

const CoursesWeek = props => {
  const {
    day,
    slots,
    onClickSlot,
    isCollapsed,
    hideResourcesSection,
    onClickCollapse
  } = props
  const classNames = ['ta-slots__week']
  const slotsClassNames = ['ta-slots__week__slots']
  const moreSlotsClassNames = ['ta-slots__show-more']
  if (!slots.length) classNames.push('booked-out')
  if (slots.length > 6 && isCollapsed) slotsClassNames.push('collapsed')
  if (slots.length > 6 && !isCollapsed) moreSlotsClassNames.push('show-all')

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-slots__week__label'>
        {format(day, getCustomDateFormat() || 'longWithShortWeekDay', { isUTC: true })}
      </div>

      {slots.length > 0 && (
        <>
          <div className={slotsClassNames.join(' ')}>
            {slots.map((slot, index) => (
              <CoursesListItem
                key={`slot-${index}`}
                day={day}
                index={index}
                date={slot.date}
                price={slot.price}
                discountedPrice={slot.discountedPrice}
                offerId={slot.offerId}
                event={slot}
                hideResourcesSection={hideResourcesSection}
                onClickSlot={onClickSlot}
              />
            ))}
          </div>

          {(slots.length > 6 &&
            <span className={moreSlotsClassNames.join(' ')} onClick={onClickCollapse}>
              {isCollapsed && t('availability.list.section.slots.weekly.showMore')}
              {!isCollapsed && t('availability.list.section.slots.weekly.showLess')}
            </span>
          )}
        </>
      )}
      {(slots.length === 0 &&
        <div className='ta-slots__week__text'>{t('availability.list.section.slots.weekly.bookedOut')}</div>
      )}
    </div>
  )
}

export default CoursesWeek
