import React from 'react'
import PropTypes from 'prop-types'
import {
  t,
  Form,
  Row,
  Col,
  Input,
  FontAwesome5,
  DashedTitle,
  Error
} from '../../../Common'

import './EnforcePasswordChange.css'

const EnforcePasswordChange = props => {
  const {
    isActive,
    onSubmit,
    passwordFieldType,
    repeatPasswordFieldType,
    togglePasswordFieldType,
    toggleRepeatPasswordFieldType
  } = props
  const classNames = ['ta-popup', 'ta-auth-enforce-psw-change']
  if (isActive) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      {isActive && (
        <>
          <div className='ta-auth-enforce-psw-change__login'>
            <div className='ta-auth-enforce-psw-change__inner'>
              <Form onSubmit={onSubmit} name='enforcePasswordChange'>
                <DashedTitle label={t('auth.enfroceReset.title')} className='ta-popup__main_title' />
                <div className='ta-text-box center'>
                  {t('auth.enfroceReset.message')}
                </div>
                <Row>
                  <Col size={50} className='relative'>
                    <Input
                      type={passwordFieldType}
                      name='password'
                      label={t('global.password.label')}
                      placeholder={t('global.password.placeholder')}
                      mandatory
                    />
                    <div className='password-type-toggle-btn' onClick={togglePasswordFieldType}>
                      <FontAwesome5 icon={passwordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                    </div>
                  </Col>
                  <Col size={50} className='relative'>
                    <Input
                      type={repeatPasswordFieldType}
                      name='repeatPassword'
                      label={t('auth.enforceReset.repeatPassword.label')}
                      placeholder={t('auth.enforceReset.repeatPassword.placeholder')}
                      mandatory
                    />
                    <div className='password-type-toggle-btn' onClick={toggleRepeatPasswordFieldType}>
                      <FontAwesome5 icon={repeatPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                    </div>
                  </Col>
                </Row>
                <Error name='globalErrors' />
                <Row>
                  <Col>
                    <button type='submit' className='ta-cta-primary ta-cta-fluid'>
                      {t('auth.enforceReset.section.form.buttons.save')}
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

EnforcePasswordChange.propTypes = {
  isActive: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func
}

export default EnforcePasswordChange
