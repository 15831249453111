import React, { useRef, useEffect } from 'react'
import { OtherDaysWrapper } from '../../Beasts'
import {
  CoursesDailySlots,
  CoursesWeeklySlots,
  NoSlots
} from '../../Beauties'
import { t, format, SmallTitle, getCustomDateFormat } from '../../Common'
import { globalActions } from '../../Store'

import './Slots.css'

const Slots = props => {
  const {
    appMode,
    slotsByDays,
    selectedDate,
    selectedEventId,
    availableDays,
    onClickSlot,
    onClickOtherDay,
    isWidgetWeeklyView,
    hideResourcesSection,
    selectedTimezone,
    isAvailabilityActive
  } = props
  const ref = useRef()
  const availableSlots = (slotsByDays && slotsByDays[0] && slotsByDays[0].slots && slotsByDays[0].slots.filter(item => item.spotsLeft > 0)) || []
  const availableCourseSlots = (isWidgetWeeklyView && slotsByDays && slotsByDays.filter(item => item.slots && item.slots.length > 0 && item.slots.filter(item => item.spotsLeft > 0).length > 0)) || []
  const hasWeeklySlots = (isWidgetWeeklyView && slotsByDays &&
    slotsByDays.reduce((acc, item) => {
      if (item.slots && item.slots.length > 0 && item.slots.filter(item => item.spotsLeft > 0).length > 0) acc = true
      return acc
    }, false)
  ) || false
  const slotsDate = format(selectedDate, getCustomDateFormat() || 'longWithShortWeekDay', { isUTC: true })

  useEffect(() => {
    let interval
    let element
    interval = setInterval(() => {
      element = ref.current
      if (element && isAvailabilityActive) {
        globalActions.postMessage({
          action: 'timify:widget:slots:container',
          height: element.clientHeight
        }, '*')
      }
    }, 500)

    return () => clearInterval(interval)
  }, [ref, isAvailabilityActive])

  return (
    <div className='ta-slots' ref={ref}>
      {(!isWidgetWeeklyView &&
        <>
          {(availableSlots && availableSlots.length > 0 &&
            <>
              {(appMode !== 'RESCHEDULE' &&
                <SmallTitle
                  label={t('availability.list.section.slots.daily.title', [{
                    key: 'DATE',
                    value: slotsDate
                  }])}
                  className='ta-slots__title no-margin'
                />
              )}
              {(appMode === 'RESCHEDULE' &&
                <SmallTitle label={t('availability.list.section.slots.reschedule.title')} className='ta-slots__title no-margin' />
              )}
              {(selectedTimezone &&
                <SmallTitle label={`(${selectedTimezone.label})`} className='ta-slots__subtitle' />
              )}
              <CoursesDailySlots
                slotsByDays={slotsByDays}
                selectedEventId={selectedEventId}
                hideResourcesSection={hideResourcesSection}
                onClickSlot={onClickSlot}
              />
            </>
          )}
        </>
      )}
      {(isWidgetWeeklyView && availableCourseSlots.length > 0 &&
        <>
          {(appMode !== 'RESCHEDULE' &&
            <SmallTitle label={t('availability.list.section.slots.weekly.title')} className='ta-slots__title no-margin' />
          )}
          {(appMode === 'RESCHEDULE' &&
            <SmallTitle label={t('availability.list.section.slots.reschedule.title')} className='ta-slots__title no-margin' />
          )}
          {(selectedTimezone &&
            <SmallTitle label={`(${selectedTimezone.label})`} className='ta-slots__subtitle' />
          )}
          <CoursesWeeklySlots
            slotsByDays={slotsByDays}
            hideResourcesSection={hideResourcesSection}
            onClickSlot={onClickSlot}
          />
        </>
      )}
      {(((!isWidgetWeeklyView && (!availableSlots || availableSlots.length === 0)) ||
        (isWidgetWeeklyView && !hasWeeklySlots)) &&
        availableDays.length > 0 &&
          <>
            <SmallTitle label={t('availability.list.section.slots.nextDaySlots.text')} className='ta-slots__title' />
            <OtherDaysWrapper
              availableDays={availableDays}
              onClickOtherDay={onClickOtherDay}
              isWidgetWeeklyView={isWidgetWeeklyView}
            />
          </>
      )}
      {(((!isWidgetWeeklyView && (!availableSlots || availableSlots.length === 0) && availableDays.length === 0) || (isWidgetWeeklyView && availableCourseSlots.length === 0 && availableDays.length === 0)) &&
        <NoSlots />
      )}
    </div>
  )
}

export default Slots
