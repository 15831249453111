import React from 'react'
import { GoogleStaticMap } from '../../Common'

import './Address.css'

const Address = props => {
  const { address, details = '' } = props

  return (
    <div className='ta-address'>
      <div className='ta-address__map'>
        {/* <GoogleMaps {...props} /> */}
        <GoogleStaticMap address={address} size='450x200' />
      </div>
      <div className='ta-address__label'>
        {address}
        {details && `, ${details}`}
      </div>

    </div>
  )
}

export default Address
