export const INTERSPORT_ENTERPRISE_ID = ['5cadef8962b77f1032435315', '5cd19668c240601084694f42']
export const DEUTSCHES_FRANCHISE_INSTITUT_ID = ['5c9cc258aeaa3975c783d8bc']
export const MRSPEX_ENTERPRISE_ID = [
  '5cc16b42eacb20108645435c', // DE STORES
  '5ff4301520899110a5ee54a8', // AT STORES
  '5fbb7ddc98b3a111cb9bc2bd', // SWEDEN STORES
  '62f105901d60a7bd6fe96c53', // Switzerland STORES
  '5dcbe29a36438e110852775c' // STAGING
]
export const MRSPEX_PARTNER_ENTERPRISE_ID = [
  '5dcbdaed7f279010e7aab0a5', // DE PARTNERS
  '5ff446f85027cb10ccb4cc3d', // AT PARTNERS
  '5dfcc911ed5f1c1229964f6c', // WUTSCHER
  '5dcbe35d36438e110852777e', // STAGING,
  '6011722bb3258d326e3c6000', // WUTSCHER STAGING
  '60549002db6f0d1159632c24' // MATT OPTIC
]
export const UNIQUE_ENTERPRISE_ID = [
  '5e3af001122ca412197eece8',
  '5e43e4cc95578111fd8d8e36',
  '5e43e4cc95578111fd8d8e37',
  '5e43e4cc95578111fd8d8e38',
  '5e43e4cc95578111fd8d8e39',
  '5e43e4cc95578111fd8d8e40',
  '5e43e4cc95578111fd8d8e41',
  '5e3af082122ca412197eecfc',
  '5e3af09d122ca412197eed10'
]
