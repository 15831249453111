import { payloads$, actions, store, handlers } from '../../../Store'
import { UNIQUE_ENTERPRISE_ID } from '../../../Settings'
import { getRouteQuery, convertMinutesToHours } from '../../../Utils'
import { q } from '../../API'

// Locations
payloads$(actions.PAGE_LOCATIONS_LOADED)
  .subscribe(() => {
    handlers.companySelect(null)
  })

// Services
payloads$(actions.PAGE_SERVICES_LOADED)
  .subscribe(() => {
    const state = store.getState()
    const { attributes, router, app, services, courses, booking, companies } = state
    if (!app.isDataLoaded || !app.isInitDone) return
    const { selectedId: selectedCompanyId = null, list: companiesList } = companies || {}
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId) || {}
    const { mode: appMode = 'NORMAL', locale: appLocale = 'en-gb' } = app || {}
    const { rescheduledData } = booking || {}
    const { title: rescheduledTitle = '' } = rescheduledData || {}
    const { serviceId = [], courseId = [] } = attributes || {}
    const { serviceExternalIds = [], courseExternalIds = [] } = attributes || {}
    const { list: servicesList } = services
    const { list: coursesList } = courses || {}
    const { enterprise: companyEnterprise = {} } = selectedCompany || {}
    const { id: enterpriseId = null } = companyEnterprise || {}
    handlers.serviceSelect({ id: null, customerFieldIds: null, customerFields: null })
    handlers.courseSelect({ id: null, customerFieldIds: null, customerFields: null })
    handlers.resourcesReset()
    handlers.upsellingClear()
    // Preselect service by external id
    if (serviceExternalIds.length > 0 && servicesList && servicesList.length > 0) {
      const selectedServiceId = servicesList.find(item => item.externalId === serviceExternalIds[0] && item.companyId === selectedCompanyId)
      if (selectedServiceId) serviceId[0] = selectedServiceId.id
    }
    // Preselect course by external id
    if (courseExternalIds.length > 0 && coursesList && coursesList.length > 0) {
      const selectedCourseId = coursesList.find(item => item.externalId === courseExternalIds[0] && item.companyId === selectedCompanyId)
      if (selectedCourseId) courseId[0] = selectedCourseId.id
    }
    // Preselect service or course
    let id
    let customerFields
    let customerFieldIds
    let filteredCustomerFields
    const routeQuery = getRouteQuery(router)
    if (serviceId && serviceId.length > 0) {
      id = serviceId[0]
      customerFields = state.customerFields.list || []
      filteredCustomerFields = customerFields.filter(item =>
        item.companyId === selectedCompanyId &&
        !item.isAfterAuth &&
        (item.serviceIds.includes(id) || item.courseIds.includes(id))
      )
      customerFieldIds = filteredCustomerFields.map(item => item.id)
      handlers.serviceSelect({ id, customerFieldIds, customerFields: filteredCustomerFields })
      if (!filteredCustomerFields.length) handlers.navigateToPath(`/availability${routeQuery}`)
    } else if (courseId && courseId.length > 0) {
      id = courseId[0]
      customerFields = state.customerFields.list || []
      filteredCustomerFields = customerFields.filter(item =>
        item.companyId === selectedCompanyId &&
        !item.isAfterAuth &&
        (item.serviceIds.includes(id) || item.courseIds.includes(id))
      )
      customerFieldIds = filteredCustomerFields.map(item => item.id)
      handlers.courseSelect({ id, customerFieldIds, customerFields: filteredCustomerFields })
      if (!filteredCustomerFields.length) handlers.navigateToPath(`/availability${routeQuery}`)
    }

    // If we are in Unique widegt we must send message to Google Analytics
    if (enterpriseId && UNIQUE_ENTERPRISE_ID.includes(enterpriseId) && window && window.dataLayer) {
      window.dataLayer.push({
        event: 'VirtualPageview',
        step: 'Step1'
      })
    }

    // If the application mode is rescheduling and we have title booking
    if (appMode === 'RESCHEDULE' && rescheduledTitle !== '') {
      const routeQuery = getRouteQuery(router)
      handlers.navigateToPath(`/availability${routeQuery}`)
    }

    // Send message to the parent
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage('timify:widget:services', '*')

      window.parent.postMessage({
        action: 'timify:widget:services',
        language: appLocale.substring(0, 2).toUpperCase(),
        country: (selectedCompany && selectedCompany.locale ? selectedCompany.locale.substring(3, 5).toUpperCase() : ''),
        company: (selectedCompany && selectedCompany.name) || null,
        companyId: selectedCompanyId,
        companyExternalId: (selectedCompany && selectedCompany.externalId) || null
      }, '*')
    }
  })

// Availability
payloads$(actions.PAGE_AVAILABILITY_LOADED)
  .subscribe(async () => {
    const state = store.getState()
    const { app, router, booking, companies, attributes, services, courses } = state
    if (!app.isInitDone) return
    const { locale: appLocale = 'en-gb' } = app || {}
    const { list: companiesList = [], selectedId: selectedCompanyId } = companies
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId) || {}
    const { resourceId: preselectedResourceId = null } = attributes
    const { eventId = null, secret } = booking
    const { selectedId: selectedServiceId = null, list: servicesList = [] } = services
    const { selectedId: selectedCourseId = null, list: coursesList = [] } = courses
    const selectedService = servicesList.find(s => s.id === selectedServiceId)
    const selectedCourse = coursesList.find(c => c.id === selectedCourseId)
    const { enterprise: companyEnterprise = {} } = selectedCompany || {}
    const { id: enterpriseId } = companyEnterprise || {}
    handlers.slotSelect({})
    handlers.upsellingActive(false)
    if (preselectedResourceId) handlers.resourcePreSelect({ id: preselectedResourceId })
    if (eventId && !(router.oldPath || '').includes('/services')) {
      const event = {
        companyId: selectedCompany.id,
        region: selectedCompany.region,
        eventId,
        secret
      }
      await q('cancelOnlineEventReservation', { event })
    }
    handlers.availabilityGet()

    // Send message to the parent
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage('timify:widget:availability', '*')

      window.parent.postMessage({
        action: 'timify:widget:availability',
        language: appLocale.substring(0, 2).toUpperCase(),
        country: (selectedCompany && selectedCompany.locale ? selectedCompany.locale.substring(3, 5).toUpperCase() : ''),
        company: (selectedCompany && selectedCompany.name) || null,
        companyId: selectedCompanyId,
        companyExternalId: (selectedCompany && selectedCompany.externalId) || null,
        serviceId: selectedServiceId || selectedCourseId || null,
        serviceExternalId: (selectedService && selectedService.externalId) || (selectedCourse && selectedCourse.externalId) || null
      }, '*')
    }

    // Send message to Google Analytics if we are in Unique widget
    if (enterpriseId && UNIQUE_ENTERPRISE_ID.includes(enterpriseId) && window && window.dataLayer) {
      window.dataLayer.push({
        event: 'VirtualPageview',
        step: 'Step2',
        stepOption: (selectedService && selectedService.name) || (selectedCourse && selectedCourse.name) || ''
      })
    }
  })

// Auth
payloads$(actions.PAGE_AUTH_LOADED)
  .subscribe(() => {
    const state = store.getState()
    const { app, auth, companies, slots } = state
    if (!app.isInitDone) return
    const { locale: appLocale = 'en-gb' } = app || {}
    const { list: companiesList = [], selectedId: selectedCompanyId } = companies
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId)
    const isGuest = !auth.tokens
    const { enterprise: companyEnterprise = {} } = selectedCompany || {}
    const { id: enterpriseId } = companyEnterprise || {}
    const { selectedDay, selectedMinutes } = slots || {}

    // Send message to the parent
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage('timify:widget:authentication', '*')

      window.parent.postMessage({
        action: 'timify:widget:authentication',
        language: appLocale.substring(0, 2).toUpperCase(),
        country: (selectedCompany && selectedCompany.locale ? selectedCompany.locale.substring(3, 5).toUpperCase() : ''),
        company: (selectedCompany && selectedCompany.name) || null,
        companyId: selectedCompanyId,
        companyExternalId: (selectedCompany && selectedCompany.externalId) || null
      }, '*')
    }

    // Send message to Google Analytics if we are in Unique widget
    if (enterpriseId && UNIQUE_ENTERPRISE_ID.includes(enterpriseId) && window && window.dataLayer) {
      window.dataLayer.push({
        event: 'VirtualPageview',
        step: 'Step3',
        stepOption: `${selectedDay}T${convertMinutesToHours(selectedMinutes)}`
      })
    }

    if (!isGuest) handlers.authRedirect()
  })

// Customer fields
payloads$(actions.PAGE_CUSTOMER_FIELDS_LOADED)
  .subscribe(() => {
    const state = store.getState()
    const { app, account, companies, attributes } = state
    if (!app.isInitDone) return
    const { locale: appLocale = 'en-gb' } = app || {}
    const { email } = account || {}
    const { list: companiesList = [], selectedId: selectedCompanyId } = companies
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId)
    let { voucherCode: initVoucherCode } = attributes
    initVoucherCode = initVoucherCode || ''
    handlers.formFieldsUpdate('voucherInput', {
      voucherValue: { value: initVoucherCode || '' },
      voucherStatus: { value: '' },
      voucherFrom: { value: '' },
      voucherUntil: { value: '' },
      voucherDiscount: { value: 0 },
      globalErrors: {}
    })
    // Send message to the parent
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage('timify:widget:customerfields', '*')

      window.parent.postMessage({
        action: 'timify:widget:customerfields',
        language: appLocale.substring(0, 2).toUpperCase(),
        country: (selectedCompany && selectedCompany.locale ? selectedCompany.locale.substring(3, 5).toUpperCase() : ''),
        company: (selectedCompany && selectedCompany.name) || null,
        companyId: selectedCompanyId,
        companyExternalId: (selectedCompany && selectedCompany.externalId) || null
      }, '*')
    }
    handlers.pagesNextSet(null)
    // Check this page as visited
    // check as visited if user has not logged in
    !email && handlers.appOpenGuestBooking()
  })

// Conflict page
payloads$(actions.PAGE_CONFLICT_LOADED)
  .subscribe(() => {
    const state = store.getState()
    const { app, booking, companies, conflict } = state
    const { locale: appLocale = 'en-gb' } = app || {}
    const { eventId = null, secret = null } = booking || {}
    const { list: companiesList = [], selectedId: selectedCompanyId } = companies
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId)
    const { error } = conflict || {}

    // Send message to the parent
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage('timify:widget:conflict', '*')

      window.parent.postMessage({
        action: 'timify:widget:conflict',
        language: appLocale.substring(0, 2).toUpperCase(),
        country: (selectedCompany && selectedCompany.locale ? selectedCompany.locale.substring(3, 5).toUpperCase() : ''),
        company: (selectedCompany && selectedCompany.name) || null,
        companyId: selectedCompanyId,
        companyExternalId: (selectedCompany && selectedCompany.externalId) || null
      }, '*')
    }

    // If we have reservation we need to cancel it
    if (eventId && secret && error !== 'PaymentMustBeDone') handlers.bookingCancelReservation()
  })

// Confirm
payloads$(actions.PAGE_CONFIRM_LOADED)
  .subscribe(() => {
    const state = store.getState()
    const { app, companies } = state

    if (!app.isInitDone) return
    const { locale: appLocale = 'en-gb' } = app || {}
    const { list: companiesList = [], selectedId: selectedCompanyId } = companies
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId)
    const { enterprise: companyEnterprise = {} } = selectedCompany || {}
    const { id: enterpriseId } = companyEnterprise || {}
    // Send message to the parent
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage('timify:widget:confirmation', '*')

      window.parent.postMessage({
        action: 'timify:widget:confirmation',
        language: appLocale.substring(0, 2).toUpperCase(),
        country: (selectedCompany && selectedCompany.locale ? selectedCompany.locale.substring(3, 5).toUpperCase() : ''),
        company: (selectedCompany && selectedCompany.name) || null,
        companyId: selectedCompanyId,
        companyExternalId: (selectedCompany && selectedCompany.externalId) || null
      }, '*')
    }

    // Send message to Google Analytics if we are in Unique widget
    if (enterpriseId && UNIQUE_ENTERPRISE_ID.includes(enterpriseId) && window && window.dataLayer) {
      window.dataLayer.push({
        event: 'VirtualPageview',
        step: 'Step4'
      })
    }
  })
