import { isIE } from 'react-device-detect'
// import { EMOJIS_REGEX } from '../Settings'

export default (name, limit = 4) => {
  if (!name) return ''
  // const clearedFromEmojis = !isIE ? name.replace(new RegExp(EMOJIS_REGEX, 'ug'), '') : name
  const clearedFromEmojis = !isIE ? name.replace(/[^\w.,\s]/g, '') : name

  let initials = ''
  const nameParts = clearedFromEmojis.split(' ')
  const sliceAt = limit || nameParts.length

  nameParts
    .slice(0, sliceAt)
    .forEach(word => {
      if (word) {
        initials += word[0].toUpperCase()
      }
    })

  return initials
}
