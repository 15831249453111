import React from 'react'
import moment from 'moment-timezone'
import { connect, handlers } from '../../Store'
import { Availability, AvailabilityMrSpex } from '../../Beauties'

const AvailabilityWrapper = props => {
  const {
    routeName,
    isPending,
    isPendingSlots,
    companies,
    selectedCompanyId,
    selectedDays,
    enterpriseId,
    timezones,
    services,
    selectedServiceId,
    courses,
    selectedCourseId,
    attributes
  } = props
  const isActive = !isPending && routeName === 'availability'
  const isPrevious = ['customerFields', 'confirm', 'success', 'auth', 'authLogin', 'authRegister', 'authForgotten', 'authConfirmation', 'authChangePhone', 'authGuestBooking', 'authEnforcePasswordChange'].includes(routeName)
  const company = companies.find(item => item.id === selectedCompanyId) || {}
  let { plan, settings, addOns } = company
  addOns = addOns || []
  settings = settings || {}
  const selectedService = services.find(item => item.id === selectedServiceId)
  const selectedCourse = courses.find(item => item.id === selectedCourseId)
  const selectedItem = selectedService || selectedCourse
  const selectedDate = selectedDays[0] || moment().format('YYYY-MM-DD')
  let { isEnterprise } = attributes
  isEnterprise = !!isEnterprise
  const isMrSpex = isEnterprise && addOns && addOns.includes('MRSPEX')
  const formattedTimezones = timezones.map(item => ({
    label: item.label,
    value: item.code
  }))
  const onDateChanged = (selectedDays) => {
    handlers.availabilityPopulate({ selectedDays })
    handlers.slotsGet()
  }
  const onTimezoneChanged = (value) => {
    handlers.timezoneSelect(value)
    handlers.slotsGet()
  }

  return isMrSpex
    ? (
      <AvailabilityMrSpex
        isActive={isActive}
        isPrevious={isPrevious}
        isPendingSlots={isPendingSlots}
        selectedDate={selectedDate}
        onDateChanged={onDateChanged}
        isWidgetWeeklyView={settings.isWidgetWeeklyView}
        companyPlan={plan}
        enterpriseId={enterpriseId}
        showWidgetTimezoneSelector={settings.showWidgetTimezoneSelector || false}
        timezones={formattedTimezones}
        onTimezoneChanged={onTimezoneChanged}
        isTitleCourse={selectedCourse && selectedCourse.isTitleCourse}
        selectedCompany={company}
        attributes={attributes}
        selectedItem={selectedItem}
      />
      )
    : (
      <Availability
        isActive={isActive}
        isPrevious={isPrevious}
        isPendingSlots={isPendingSlots}
        selectedDate={selectedDate}
        onDateChanged={onDateChanged}
        isWidgetWeeklyView={settings.isWidgetWeeklyView}
        companyPlan={plan}
        enterpriseId={enterpriseId}
        showWidgetTimezoneSelector={settings.showWidgetTimezoneSelector || false}
        timezones={formattedTimezones}
        onTimezoneChanged={onTimezoneChanged}
        isTitleCourse={selectedCourse && selectedCourse.isTitleCourse}
        selectedCompany={company}
        attributes={attributes}
        selectedItem={selectedItem}
      />
      )
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  isPending: state.availability.isPending || false,
  isPendingSlots: state.slots.isPending || false,
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  selectedDays: state.availability.selectedDays || [],
  enterpriseId: state.enterprise.id || null,
  timezones: state.timezones.list || [],
  selectedTimezone: state.timezones.selectedCode || '',
  services: state.services.list || [],
  selectedServiceId: state.services.selectedId || null,
  courses: state.courses.list || [],
  selectedCourseId: state.courses.selectedId || null,
  attributes: state.attributes || {}
})

export default connect(maps)(AvailabilityWrapper)
