import React, { Component } from 'react'
import { connect, handlers, store } from '../../../Store'
import { AuthRegister } from '../../../Beauties'
import { getRouteQuery } from '../../../Utils'

class RegisterWrapper extends Component {
  constructor (props) {
    super(props)

    this.onCloseClick = this.onCloseClick.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.togglePasswordFieldType = this.togglePasswordFieldType.bind(this)
  }

  componentDidMount () {
    handlers.registrationFormPopulate()
  }

  onCloseClick () {
    handlers.registrationFormPopulate()
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/auth${routeQuery}`)
  }

  onSubmit (cb) {
    const { form } = this.props
    handlers.registerFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  togglePasswordFieldType () {
    const { form } = this.props
    let { password } = form || {}
    password = password || {}
    const { type: passwordFieldType } = password
    const passwordField = {
      ...password,
      type: passwordFieldType === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('register', { password: passwordField })
  }

  render () {
    const {
      routeName,
      companies,
      selectedCompanyId,
      enterpriseId,
      locale,
      form
    } = this.props
    const company = companies.find(item => item.id === selectedCompanyId) || {}
    const { settings: companySettings = {} } = company
    const { customTermsAndConditionsUrl, customPrivacyPolicyUrl } = companySettings
    const isActive = routeName === 'authRegister'
    const router = store.getState().router
    const { query = {} } = router
    const { fromLogin = null } = query
    const { password } = form || {}
    const { type: passwordFieldType } = password || {}

    return (
      <AuthRegister
        isActive={isActive}
        onCloseClick={this.onCloseClick}
        onSubmit={this.onSubmit}
        company={company}
        customTermsAndConditionsUrl={customTermsAndConditionsUrl}
        customPrivacyPolicyUrl={customPrivacyPolicyUrl}
        enterpriseId={enterpriseId}
        fromLogin={fromLogin}
        locale={locale}
        passwordFieldType={passwordFieldType}
        togglePasswordFieldType={this.togglePasswordFieldType}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  form: state.forms.register,
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  enterpriseId: state.enterprise.id || null,
  locale: state.app.locale
})

export default connect(maps)(RegisterWrapper)
