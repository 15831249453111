import React from 'react'
import {
  Address,
  format,
  getCustomDateFormat,
  t
} from '../../Common'
import { dangerousHTML, nl2br } from '../../Utils'
import { countries } from '../../Settings'

const CustomerFieldPreview = props => {
  const { item } = props
  let selectedOption = null
  if (item.type === 'SELECT') {
    selectedOption = item.options.find(option => option.value === item.value) || {}
  }
  let phoneNumber = null
  if (item.type === 'PHONE' && item.phone) {
    let phoneCountryCode = null
    if (item.phoneCountry) {
      phoneCountryCode = countries.find(country => country.code === item.phoneCountry)
    }
    phoneNumber = (phoneCountryCode && phoneCountryCode.phoneCountry ? `+${phoneCountryCode.phoneCountry}<span class='ta-confirm__country_code_null'>(0)</span> ` : '') + ((item.phone && item.phone.number) || item.phone)
  }

  return (
    <div className='ta-confirm__row'>
      <div className='ta-confirm__label'>
        {item.translationKey && t(item.translationKey)}
        {!item.translationKey && item.label && item.label}
      </div>
      {item.type === 'EMAIL' && item.value}
      {(item.type === 'ADDRESS' && item.data && Object.keys(item.data).length > 0 &&
        <Address
          address={item.data.formattedAddress}
          details={item.details || ''}
          lat={item.data.lat}
          lng={item.data.lng}
        />
      )}
      {item.type === 'FILE' && 'TODO'}
      {item.type === 'PHONE' && dangerousHTML(phoneNumber)}
      {item.type === 'DATE' && format(item.value, getCustomDateFormat() || 'long', { isUTC: true })}
      {item.type === 'TEXT' && item.value}
      {item.type === 'TEXTAREA' && nl2br(item.value)}
      {item.type === 'TIMEZONE' && item.value}
      {item.type === 'SELECT' && (selectedOption.label || t(selectedOption.translationKey))}
      {item.type === 'CHECKBOX' && (item.value + '' === 'true' ? t('global.yes') : t('global.no'))}
      {item.type === 'FILES' && ([...(item.valuesAdd || []), ...(item.selected || [])]).map((fileItem, index) => (
        <div className='ta-confirm__file-row' key={`${index}-${fileItem.url}`}>
          {fileItem.name}
        </div>
      ))}
    </div>
  )
}

export default CustomerFieldPreview
