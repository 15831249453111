import React from 'react'
import PropTypes from 'prop-types'
import { t, NextStep, Link, FontAwesome5, Image } from '../../Common'

import './Footer.css'

const Footer = props => {
  let {
    isActive,
    isFooterShadowActive,
    onClick,
    hasBackButton,
    showFooterBackButton,
    onBackClick,
    hasPoweredBy,
    hasSupportedBy,
    routeName,
    selectedCompany,
    attributes,
    fileLoading,
    nextPage,
    locale,
    widgetExternalUrlRedirect
  } = props
  nextPage = nextPage || ''
  let { hasNextButton } = props
  const classNames = ['ta-footer']
  if (isActive) classNames.push('active')
  const nextButtonClassNames = ['ta-cta-primary']
  const offlineClassNames = ['ta-footer__offline']
  const { addOns = [] } = selectedCompany
  const { isEnterprise = false } = attributes
  if (routeName === 'success' && widgetExternalUrlRedirect) {
    hasNextButton = false
  }
  const shadowClassNames = ['ta-footer__shadow']
  if (isFooterShadowActive) shadowClassNames.push('active')
  if (hasNextButton) shadowClassNames.push('with-button')
  /** DISABLE THIS FEATURE FOR NOW
  if (!online) {
    classNames.push('offline')
    offlineClassNames.push('active')
    nextButtonClassNames.push('disabled')
  }
  */
  if (routeName === 'success') {
    classNames.push('ta-footer__success__page')
    nextButtonClassNames.push('ta-footer__success')
  }
  if (hasPoweredBy || hasSupportedBy) shadowClassNames.push('with-supported-by')
  if (isEnterprise && addOns && addOns.includes('MRSPEX')) classNames.push('ta-footer__mrspex')

  const hasPayment = nextPage.includes('payments')

  let buttonText = ''
  if (routeName === 'services') buttonText = t('services.footer.section.button.label')
  if (routeName === 'customerFields') buttonText = t('customerdata.preview.section.form.button.label')
  if (routeName === 'confirm') buttonText = t(hasPayment ? 'confirmWithPayment.footer.section.button.label' : 'confirm.footer.section.button.label')
  if (routeName === 'conflict') buttonText = t('conflict.footer.section.button.label')
  if (routeName === 'success') buttonText = t('success.footer.section.button.label')

  return (
    <>
      <div className={classNames.join(' ')}>
        {(isActive &&
          <>
            <div className={shadowClassNames.join(' ')} />

            <NextStep
              isActive={hasNextButton && !fileLoading}
              className={showFooterBackButton ? 'has-back-button' : ''}
            >
              {((!!showFooterBackButton || (isEnterprise && addOns && addOns.includes('MRSPEX') && hasBackButton)) &&
                <button onClick={onBackClick} className='ta-cta ta-cta-secondary ta-footer__back-button'>
                  {t('footer.button.back.label')}
                </button>
              )}
              {(routeName !== 'availability' &&
                <Link>
                  <button onClick={onClick} className={nextButtonClassNames.join(' ')} tabIndex={-1}>
                    {buttonText}
                  </button>
                </Link>
              )}
            </NextStep>
            {(hasPoweredBy &&
              <div className='ta-footer__poweredby no-round'>
                {t('footer.poweredby.text')}
              </div>
            )}
            {(!hasPoweredBy && hasSupportedBy &&
              <div className='ta-footer__supportedby'>
                {t('footer.suportedby.text')}
                <a href={`https://www.timify.com/${locale}`} target='_blank' rel='noopener noreferrer' className='ta-footer__suportedby_timify'>
                  <Image src='/images/t-black.png' alt='TIMIFY' />
                </a>
              </div>
            )}
          </>
        )}
      </div>
      <div className={offlineClassNames.join(' ')}>
        <FontAwesome5 icon='wifi-slash' type='solid' />
        {t('footer.offlineMode.text')}
      </div>
    </>
  )
}

Footer.propTypes = {
  isActive: PropTypes.bool,
  hasScrollNotifier: PropTypes.bool,
  hasServiceNextButton: PropTypes.bool,
  onServiceNextClick: PropTypes.func,
  hasConfirmButton: PropTypes.bool,
  onConfirmClick: PropTypes.func,
  showScrollShadow: PropTypes.bool,
  routeName: PropTypes.string
}

export default Footer
