export const timezonesFields = `{
  code
  label
  translationKey
}`

export const countriesFields = `{
  code
  hasStripeActive
  hasStripeFeesNotAllowed
}`
