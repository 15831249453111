import React from 'react'
import {
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  FontAwesome5
} from '../../Common'
import { handlers } from '../../Store'

const LocationsListItem = props => {
  const { company = {} } = props
  const { info, name: companyName, tradingName: companyTradingName, id, address } = company
  const classNames = ['ta-locations__location']

  return (
    <div className={classNames.join(' ')}>
      <SimpleListItem action={() => handlers.companySelect(id, true)}>
        <SimpleListItemTitle title={companyTradingName || companyName} />
        <SimpleListItemContent>
          {(info &&
            <ul className='ta-simple-list__inline'>
              <li className='ta-simple-list__inline__info'>
                {info}
              </li>
            </ul>
          )}
          {(address && address.formatted &&
            <ul className='ta-simple-list__inline'>
              <li>
                <FontAwesome5 type='solid' icon='map' />
                {address.formatted}
              </li>
            </ul>
          )}
        </SimpleListItemContent>
      </SimpleListItem>
    </div>
  )
}

export default LocationsListItem
