import { enterpriseTransform, companiesTransform } from './utils'

const reducer = {}

reducer.initialState = {
  companies: [],
  settings: {}
}

reducer.handlers = (dispatch, actions) => ({
  enterpriseReset: () => dispatch(actions.ENTERPRISE_RESET),
  enterprisePopulate: enterprise => dispatch(actions.ENTERPRISE_POPULATE, enterpriseTransform(enterprise)),
  enterpriseCompaniesPopulate: companies => dispatch(actions.ENTERPRISE_COMPANIES_POPULATE, companiesTransform(companies))
})

reducer.ENTERPRISE_RESET = state => reducer.initialState

reducer.ENTERPRISE_POPULATE = (state, enterprise) => ({
  ...state,
  ...enterprise
})

reducer.ENTERPRISE_COMPANIES_POPULATE = (state, companies) => ({
  ...state,
  companies: companies
})

export default reducer
