import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import {
  Spinner,
  SelectTag,
  SelectTags,
  SelectArrows,
  SelectOptions,
  SelectClearButton,
  SelectTagsWithCategories,
  t
} from '../../Common'

import './Select.css'

const Select = (props) => {
  let {
    name,
    type,
    multi,
    value,
    filter,
    onBlur,
    hasAll,
    pending,
    options,
    onFocus,
    onReset,
    allLabel,
    position,
    onChange,
    onSearch,
    onDelete,
    disabled,
    hideArrow,
    className,
    clearable,
    renderItem,
    searchable,
    getOptions,
    inlineTags,
    placeholder,
    hideSelected,
    autoComplete,
    noResultsText,
    arrowRenderer,
    hasCategories,
    hideNoResults,
    customizedValue,
    renderSelectedItem,
    renderOptionsHeader,
    renderOptionsFooter,
    unresetableSearchValue,
    renderValueIcon
  } = props
  type = type || 'text'
  options = options || []
  allLabel = allLabel || t('global.all')
  position = position || 'bottom'
  autoComplete = autoComplete || 'off'
  filter = filter || ((item, value) => `${item.label}`.toLowerCase().indexOf(value.toLowerCase()) > -1)
  if (multi) value = value || []
  const [open, setOpened] = useState(false)
  const [focused, setFocused] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [propsOptions, setPropsOption] = useState(options)
  const [selectOptions, setSelectOptions] = useState(options)
  const [selectedOptionsIndex, setSelectedOptionsIndex] = useState(null)
  const wrapper = useRef()
  const input = useRef()
  const optionsRef = useRef()
  const header = useRef()
  const footer = useRef()
  const tagsRef = useRef()
  const tagsWithCategoriesRef = useRef()
  const stringifiedValue = JSON.stringify(value) // used as hook dependecy
  const stringifiedOptions = JSON.stringify(options) // used as hook dependecy
  const stringifiedPropsOptions = JSON.stringify(propsOptions) // used as hook dependecy
  const stringifiedSelectOptions = JSON.stringify(selectOptions) // used as hook dependecy
  const optionsIncludeAllOption = useMemo(
    () => hasAll && (!multi || (multi && !value.includes('all'))),
    [hasAll, multi, value])
  if (multi && value.length && inlineTags) placeholder = ''

  const onlyDigits = useCallback((value) => {
    if (!value && value !== 0) return null
    return parseInt(value.replace(/\D+/g, ''))
  }, [])

  const showOptions = useCallback(() => {
    setOpened(true)
  }, [])

  const hideOptions = useCallback(() => {
    setOpened(false)
  }, [])

  const filterOptions = useCallback((searchValue) => {
    let newOptions = [...propsOptions]
    // filter selected values
    if (multi && !hasCategories) newOptions = newOptions.filter(item => !value.includes(item.value))
    if (multi && hasCategories) {
      newOptions = newOptions.map(category => {
        let { name, items, ...rest } = category
        if (!name) return category // if option is 'all'
        items = items || []
        return {
          name,
          items: items.filter(item => !value.includes(item.value)),
          ...rest
        }
      })
    }

    if (!searchValue) {
      setSelectOptions(newOptions)
      return
    }
    if (!hasCategories) {
      setSelectOptions(newOptions.filter(item => filter(item, searchValue)))
      return
    }
    setSelectOptions(newOptions
      .map(category => {
        let { name, items, ...rest } = category
        if (!name) return category // if option is 'all'
        items = items || []
        return {
          name,
          items: items.filter(item => filter(item, searchValue)),
          ...rest
        }
      })
      .filter(item =>
        item.name || (!item.name && filter(item, searchValue)) // maybe filter the 'all' option
      )
    )
  }, [filter, hasCategories, propsOptions, multi, value])

  const getOffsets = useCallback((itemIndex) => {
    const optionsElement = (optionsRef && optionsRef.current) ? ReactDOM.findDOMNode(optionsRef.current) : null
    const headerElement = (header && header.current) ? ReactDOM.findDOMNode(header.current) : null
    const footerElement = (footer && footer.current) ? ReactDOM.findDOMNode(footer.current) : null
    const topOffset = optionsElement ? optionsElement.scrollTop : 0
    const optionElementList = [...((optionsElement || {}).childNodes || [])]
    if (headerElement) optionElementList.splice(0, 1) // remove first child (header)
    if (footerElement) optionElementList.splice(optionElementList.length - 1) // remove last child (footer)
    const maxOptionsHeight = optionsElement ? onlyDigits(window.getComputedStyle(optionsElement).getPropertyValue('max-height')) : 0
    const headerHeight = headerElement ? headerElement.getBoundingClientRect().height : 0
    const padding = optionsElement ? onlyDigits(window.getComputedStyle(optionsElement).getPropertyValue('padding')) : 0

    let upperCategoriesCount = 0
    const baseCount = (optionsIncludeAllOption && itemIndex !== 0) ? 1 : 0
    if (hasCategories && !(optionsIncludeAllOption && itemIndex === 0)) {
      let relativeIndex = itemIndex - baseCount
      for (var i = baseCount; i < selectOptions.length; i++) {
        const currentCategory = selectOptions[i]
        const currentItemsLength = (currentCategory.items || []).length
        upperCategoriesCount = upperCategoriesCount + 1
        if (relativeIndex >= 0 && relativeIndex < currentItemsLength) {
          break
        }
        relativeIndex = relativeIndex - currentItemsLength
      }
    }
    const nodeIndex = itemIndex + upperCategoriesCount
    const upperOffest = optionElementList.reduce((sum, item, i) =>
      i < nodeIndex
        ? sum + (item.getBoundingClientRect().height || 0)
        : sum,
    0)
    const optionHeight = (optionElementList.length > 0 && optionElementList[nodeIndex]) ? optionElementList[nodeIndex].getBoundingClientRect().height : 0
    const itemTopOffset = upperOffest + headerHeight + padding
    const itemBottomOffset = itemTopOffset + optionHeight
    const optionsTopOffset = topOffset
    const optionsBottomOffset = optionsTopOffset + maxOptionsHeight
    const isFullyVisible = itemTopOffset >= optionsTopOffset && itemBottomOffset <= optionsBottomOffset
    return { itemTopOffset, itemBottomOffset, optionsTopOffset, optionsBottomOffset, isFullyVisible }
  }, [onlyDigits, selectOptions, hasCategories, optionsIncludeAllOption])

  const getSelectedIndex = useCallback((values) => {
    const search = values.reduce((acc, item, i) => {
      let { isFound, index } = acc
      if (isFound) return acc
      if (item.items) {
        index = index || 0
        const selectedIndex = getSelectedIndex(item.items)
        isFound = selectedIndex || typeof selectedIndex === 'number'
        return {
          isFound,
          index: index + (isFound ? selectedIndex : item.items.length)
        }
      } else if (`${item.value}` === `${value}`) {
        return { isFound: true, index: i }
      }
      return acc
    }, { isFound: false, index: null })

    return search.index
  }, [value])

  const getSearchValueFromSelectedValue = useCallback(() => {
    const propsOptions = JSON.parse(stringifiedPropsOptions)
    if (multi) setSearchValue('')
    if ((value || typeof value === 'number') && !multi) {
      if (propsOptions && propsOptions.length > 0) {
        if (!hasCategories) {
          const selectedLabel = (propsOptions.find(item => `${item.value}` === `${value}`) || {}).label
          if (selectedLabel || typeof selectedLabel === 'number') {
            setSearchValue(`${selectedLabel}`)
          }
        } else {
          setSearchValue(value === 'all'
            ? allLabel
            : propsOptions
              .reduce((label, category) => {
                if (label || typeof label === 'number') return label
                let { items } = category
                items = items || []
                return (items.find(item => `${item.value}` === `${value}`) || {}).label
              }, ''))
        }
      } else {
        // if we have value but no options (case of customers)
        setSearchValue(value)
      }
    }
    // in case that the value is different from every option
    if (!(value || typeof value === 'number') && !multi && typeof customizedValue === 'string') {
      setSearchValue(customizedValue)
    }
    // in case that there is no value
    if (!(value || typeof value === 'number') && !multi && typeof customizedValue !== 'string') {
      setSearchValue('')
    }
  }, [allLabel, hasCategories, multi, value, stringifiedPropsOptions, customizedValue])

  const handleOutsideClick = useCallback((e) => {
    const wrapperClicked = wrapper && wrapper.current && wrapper.current.contains(e.target)
    const inputClicked = input && input.current && input.current.contains(e.target)
    const optionsClicked = optionsRef && optionsRef.current && optionsRef.current.contains(e.target)
    const tagsRefClicked = tagsRef && tagsRef.current && tagsRef.current.contains(e.target)
    const tagsWithCategoriesRefClicked =
      tagsWithCategoriesRef && tagsWithCategoriesRef.current && tagsWithCategoriesRef.current.contains(e.target)

    if (!wrapperClicked || tagsRefClicked || tagsWithCategoriesRefClicked) {
      hideOptions()
    }

    // reset searchValue if clicked outside
    if (!inputClicked && !optionsClicked && !unresetableSearchValue) {
      getSearchValueFromSelectedValue()
      filterOptions()
    }
  }, [hideOptions, filterOptions, unresetableSearchValue, getSearchValueFromSelectedValue])

  // attach/detach document click handlers
  useEffect(() => {
    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('mousedown', handleOutsideClick)
    } else {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      if (!document.removeEventListener && document.detachEvent) {
        document.detachEvent('mousedown', handleOutsideClick)
      } else {
        document.removeEventListener('mousedown', handleOutsideClick)
      }
    }
  }, [handleOutsideClick])

  // update input value with the selected option
  useEffect(() => {
    if (!(multi && value.length === 0)) getSearchValueFromSelectedValue()
  }, [value, getSearchValueFromSelectedValue, stringifiedPropsOptions, multi])

  // update select options if options from props are changed
  useEffect(() => {
    const options = JSON.parse(stringifiedOptions)
    setPropsOption(options)
    setSelectOptions(options)
  }, [stringifiedOptions])

  // add the 'all' option
  useEffect(() => {
    const propsOptions = JSON.parse(stringifiedPropsOptions)
    if (hasAll && propsOptions.length > 0 && (propsOptions[0] || {}).value !== 'all') {
      const newOptions = [{ value: 'all', label: allLabel }, ...propsOptions]
      setPropsOption(newOptions)
      setSelectOptions(newOptions)
    }
  }, [hasAll, allLabel, stringifiedPropsOptions])

  // update the options to not show selected values
  useEffect(() => {
    if (multi) {
      const propsOptions = JSON.parse(stringifiedPropsOptions)
      const value = JSON.parse(stringifiedValue)
      let selectOptions = propsOptions
      if (value.length) {
        if (hasCategories) {
          selectOptions = selectOptions
            .map(category => {
              let { name, items, ...rest } = category
              items = items || []
              if (!name) return { value: 'all', label: allLabel }
              return {
                name,
                items: items.filter(option => !value.includes(option.value)),
                ...rest
              }
            })
          if (value.includes('all')) {
            selectOptions = selectOptions.filter(option => option.value !== 'all')
          }
        } else {
          selectOptions = propsOptions.filter(option => !value.includes(option.value))
        }
      }
      setSelectOptions(selectOptions)
    }
  }, [stringifiedValue, multi, hasCategories, allLabel, stringifiedPropsOptions])

  // scroll to selected element when select is opened
  useEffect(() => {
    if (!multi && value && open) {
      const parsedSelectOptions = JSON.parse(stringifiedSelectOptions)
      const optionsElement = (optionsRef && optionsRef.current) ? ReactDOM.findDOMNode(optionsRef.current) : null
      if (optionsElement) {
        const topOffset = optionsElement ? optionsElement.scrollTop : 0
        const padding = optionsElement ? onlyDigits(window.getComputedStyle(optionsElement).getPropertyValue('padding')) : 0
        let selectedIndex = getSelectedIndex(parsedSelectOptions)
        if (optionsIncludeAllOption) selectedIndex++
        const { isFullyVisible, itemBottomOffset, optionsBottomOffset } = getOffsets(selectedIndex)
        if (!isFullyVisible && optionsElement) {
          optionsElement.scrollTop =
            topOffset +
            (itemBottomOffset - optionsBottomOffset) +
            (padding * 2)
        }
      }
    }
  }, [open, multi, value, getOffsets, onlyDigits, stringifiedSelectOptions, getSelectedIndex, optionsIncludeAllOption])

  const handleOnFocus = useCallback(() => {
    onFocus && onFocus()
    setFocused(true)
    showOptions()
  }, [onFocus, showOptions])

  const handleOnBlur = useCallback(() => {
    onBlur && onBlur()
    setFocused(false)
  }, [onBlur])

  const handleOnReset = useCallback(() => {
    onReset && onReset()
    !onReset && onChange && onChange(multi ? [] : {})
    if (multi) setSelectOptions(propsOptions)
    setSearchValue('')
    hideOptions()
  }, [hideOptions, multi, onChange, onReset, propsOptions])

  const handleOnSearch = useCallback(async (e) => {
    if (searchable) {
      const { value: searchValue } = e.target
      onSearch && onSearch(searchValue)
      setSearchValue(`${searchValue}`)
      if (getOptions) {
        const newOptions = await getOptions(searchValue)
        setPropsOption(newOptions)
        setSelectOptions(newOptions)
        return
      }
      filterOptions(searchValue)
    }
  }, [onSearch, searchable, getOptions, filterOptions])

  const handleOnSelect = useCallback((item, categoryName) => {
    hideOptions()
    if (onChange) {
      if (!multi) onChange(item)
      else {
        if (item.value === 'all') {
          onChange([item])
          return
        }
        if (hasCategories) {
          const values = propsOptions.reduce((acc, category) => {
            let { name, items } = category
            items = items || []
            items = items.filter(item => value.includes(item.value))
            if (categoryName === name) items.push(item)
            return acc.concat(items)
          }, [])
          onChange(values)
        } else {
          const currentValues = propsOptions
            .filter(option => (value.includes(option.value) && option.value !== 'all'))
          onChange([...currentValues, item])
        }
      }
    }
  }, [hasCategories, hideOptions, multi, onChange, propsOptions, value])

  const handleOnSelectAllCategory = useCallback((category) => {
    hideOptions()
    setSearchValue('')
    if (onChange) {
      const values = propsOptions
        .reduce((acc, option) => {
          let { name, items } = option
          items = items || []
          if (category.name === name) return acc.concat(items)
          return acc.concat(items.filter(item => value.includes(item.value)))
        }, [])
      onChange(values)
    }
  }, [hideOptions, onChange, propsOptions, value])

  const handleOnDelete = useCallback(val => {
    if (value.length === 1) setSelectOptions(propsOptions)
    if (onDelete) onDelete(val)
    else if (onChange) {
      if (val === 'all') {
        onChange([])
        return
      }
      if (hasCategories) {
        const newValues = propsOptions
          .map(category => {
            let { items, ...rest } = category
            items = items || []
            items = items.filter(item => value.includes(item.value) && item.value !== val)
            return { items, ...rest }
          })
          .filter(category => category.items.length > 0)
        onChange(newValues)
      } else {
        const newValues = propsOptions.filter(item => value.includes(item.value) && item.value !== val)
        onChange(newValues)
      }
    }
  }, [hasCategories, onChange, onDelete, propsOptions, value])

  const handleOnKeyDown = useCallback((e) => {
    if (open && (selectOptions.length !== 0 || (selectOptions.length === 0 && !hideNoResults))) {
      const optionsElement = (optionsRef && optionsRef.current) ? ReactDOM.findDOMNode(optionsRef.current) : null
      const inputElement = (input && input.current) ? ReactDOM.findDOMNode(input.current) : null
      const topOffset = optionsElement ? optionsElement.scrollTop : 0
      const padding = optionsElement ? onlyDigits(window.getComputedStyle(optionsElement).getPropertyValue('padding')) : 0
      let index = 0
      let items = selectOptions
      if (hasCategories) {
        items = selectOptions.reduce((acc, category) =>
          acc.concat((category.items || []).map(item => ({ ...item, categoryName: category.name })))
        , [])
        if (optionsIncludeAllOption) items = [selectOptions[0], ...items]
      }

      // Arrow Up
      if (e.key === 'ArrowUp' || e.keyCode === '38') {
        e.preventDefault()
        index = selectedOptionsIndex === null ? items.length - 1 : selectedOptionsIndex - 1
        if (index < 0) index = items.length - 1
        setSelectedOptionsIndex(index)
        const { isFullyVisible, itemTopOffset, optionsTopOffset } = getOffsets(index)
        if (!isFullyVisible && optionsElement) {
          optionsElement.scrollTop =
            topOffset +
            (itemTopOffset - optionsTopOffset) -
            (padding * 2) +
            2
        }
      }
      // Arrow Down
      if (e.key === 'ArrowDown' || e.keyCode === '40') {
        e.preventDefault()
        index = selectedOptionsIndex === null ? 0 : selectedOptionsIndex + 1
        if (index > items.length - 1) index = 0
        setSelectedOptionsIndex(index)
        const { isFullyVisible, itemBottomOffset, optionsBottomOffset } = getOffsets(index)
        if (!isFullyVisible && optionsElement) {
          optionsElement.scrollTop =
            topOffset +
            (itemBottomOffset - optionsBottomOffset) +
            (padding * 2)
        }
      }
      // Escape
      if (e.key === 'Escape' || e.keyCode === '27') {
        e.preventDefault()
        hideOptions()
        inputElement && inputElement.blur()
      }
      // Enter
      if (e.key === 'Enter' || e.keyCode === '13') {
        e.preventDefault()
        hideOptions()
        inputElement && inputElement.blur()
        let selected = selectedOptionsIndex !== null && items[selectedOptionsIndex]
        if (selected) {
          handleOnSelect(selected, selected.categoryName)
          return
        }
        if (searchValue) {
          selected = items.find(item => `${item.value}` === searchValue)
          if (selected) {
            handleOnSelect(selected, selected.categoryName)
          } else {
            getSearchValueFromSelectedValue()
            filterOptions()
          }
        }
      }
      // Tab
      if (e.key === 'Tab' || e.keyCode === '9') {
        hideOptions()
        inputElement && inputElement.blur()
      }
    }
  }, [handleOnSelect, hideOptions, onlyDigits, selectOptions, selectedOptionsIndex, hasCategories, optionsIncludeAllOption, hideNoResults, open, getOffsets, filterOptions, searchValue, getSearchValueFromSelectedValue])

  const classNames = ['ta-select']
  if (className) classNames.push(className)
  if (searchValue || (!multi && value)) classNames.push('hasValue')
  if (focused) classNames.push('focused')
  if (open && (pending || selectOptions.length !== 0 || (searchValue && renderOptionsHeader) || !hideNoResults)) classNames.push(`active ${position}`)
  if (inlineTags) classNames.push('inline')

  const inputWrapperClassNames = []
  if (multi) inputWrapperClassNames.push('ta-multi-select__values')
  if (multi && value.length > 0) inputWrapperClassNames.push('hasValue')
  if (inlineTags) inputWrapperClassNames.push('inline')

  const optionsClassNames = ['ta-select__options', position]
  if (pending) optionsClassNames.push('pending')
  return (
    <div ref={wrapper} className={classNames.join(' ')}>
      <div
        className={inputWrapperClassNames.join(' ')}
        onClick={() => !disabled && showOptions()}
      >
        {multi && value && !hasCategories && inlineTags &&
          value.map((val, index) => {
            const item = propsOptions.find(option => option.value === val)
            return (
              <SelectTag
                key={index}
                item={item}
                index={index}
                disabled={disabled}
                onDelete={handleOnDelete}
                renderSelectedItem={renderSelectedItem}
              />
            )
          })
        }
        {renderValueIcon && value &&
          renderValueIcon({ value })
        }
        <input
          ref={input}
          className='ta-select-input'
          type={type}
          name={name}
          disabled={disabled || !searchable}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          onChange={handleOnSearch}
          placeholder={placeholder}
          value={searchValue || ''}
          onKeyDown={handleOnKeyDown}
          autoComplete={autoComplete}
        />
      </div>
      {!hideArrow && <SelectArrows arrowRenderer={arrowRenderer} showOptions={showOptions} disabled={disabled} />}
      {clearable && (value || searchValue) && <SelectClearButton handleOnReset={handleOnReset} />}
      {open && (pending || selectOptions.length !== 0 || (searchValue && renderOptionsHeader) || !hideNoResults) &&
        <div
          className={optionsClassNames.join(' ')}
          ref={optionsRef}
        >
          {pending && <Spinner />}
          {!pending && renderOptionsHeader &&
            <div ref={header}>
              {renderOptionsHeader({ selectOptions, hideOptions })}
            </div>
          }
          <SelectOptions
            multi={multi}
            value={value}
            renderItem={renderItem}
            selectOptions={selectOptions}
            hasCategories={hasCategories}
            hideNoResults={hideNoResults}
            noResultsText={noResultsText}
            handleOnSelect={handleOnSelect}
            selectedOptionsIndex={selectedOptionsIndex}
            optionsIncludeAllOption={optionsIncludeAllOption}
            handleOnSelectAllCategory={handleOnSelectAllCategory}
          />
          {!pending && renderOptionsFooter &&
            <div ref={footer}>
              {renderOptionsFooter()}
            </div>
          }
        </div>
      }
      {multi && value && !hasCategories && !inlineTags && !hideSelected &&
        <div ref={tagsRef}>
          <SelectTags
            values={value}
            disabled={disabled}
            options={propsOptions}
            onDelete={handleOnDelete}
            renderSelectedItem={renderSelectedItem}
          />
        </div>
      }
      {multi && value && hasCategories && !hideSelected &&
        <div ref={tagsWithCategoriesRef}>
          <SelectTagsWithCategories
            values={value}
            options={propsOptions}
            allLabel={allLabel}
            disabled={disabled}
            onDelete={handleOnDelete}
            renderSelectedItem={renderSelectedItem}
          />
        </div>
      }
    </div>
  )
}

Select.propTypes = {
  value: PropTypes.any,
  multi: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  filter: PropTypes.func,
  hasAll: PropTypes.bool,
  onBlur: PropTypes.func,
  pending: PropTypes.bool,
  onFocus: PropTypes.func,
  onReset: PropTypes.func,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  hideArrow: PropTypes.bool,
  getOptions: PropTypes.func,
  inlineTags: PropTypes.bool,
  allLabel: PropTypes.string,
  position: PropTypes.string,
  renderItem: PropTypes.func,
  searchable: PropTypes.bool,
  className: PropTypes.string,
  hideSelected: PropTypes.bool,
  noResultsText: PropTypes.any,
  hasCategories: PropTypes.bool,
  placeholder: PropTypes.string,
  arrowRenderer: PropTypes.func,
  hideNoResults: PropTypes.bool,
  autoComplete: PropTypes.string,
  customizedValue: PropTypes.string,
  renderSelectedItem: PropTypes.func,
  renderOptionsHeader: PropTypes.func,
  renderOptionsFooter: PropTypes.func,
  unresetableSearchValue: PropTypes.bool,
  renderValueIcon: PropTypes.func
}

export default Select
