import React from 'react'
import PropTypes from 'prop-types'
import { CoursesExtraPersonsPerParticipantFormWrapper } from '../../../Beasts'
import { t, FontAwesome5 } from '../../../Common'

import './ExtraParticipants.css'

const ExtraParticipants = props => {
  const {
    isActive,
    onCloseClick,
    onSubmit
  } = props
  const classNames = ['ta-popup', 'ta-confirm-participants']
  if (isActive) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      {isActive && (
        <>
          <button onClick={onCloseClick} className='ta-popup-close'>
            <FontAwesome5 icon='times' type='light' />
          </button>
          <div className='ta-confirm-participants__container'>
            <div className='ta-confirm-participants__inner'>
              <CoursesExtraPersonsPerParticipantFormWrapper />
            </div>
          </div>
          <button onClick={onSubmit} className='ta-cta-primary'>
            {t('groups.extraParticipantsCount.confirm.button.label')}
          </button>
        </>
      )}
    </div>
  )
}

ExtraParticipants.propTypes = {
  isActive: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func
}

export default ExtraParticipants
