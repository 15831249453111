export const timezoneFormTransform = timezone => {
  return {
    timezone: {
      value: timezone
    }
  }
}

// TODO: move this to services and use globalActions
export const servicesTransform = (services) => {
  return services.map(item => ({
    id: item.id || null,
    externalId: item.externalId || null,
    internalId: item.internalId || null,
    name: item.name || null,
    orderIndex: item.orderIndex || null,
    companyId: item.companyId || null,
    categoryId: item.categoryId || null,
    description: item.description || null,
    duration: item.duration || null,
    durationBefore: item.durationBefore || null,
    durationAfter: item.durationAfter || null,
    durationsPattern: item.durationsPattern || null,
    price: item.price || null,
    hasOnlinePayment: item.hasOnlinePayment || null,
    isPaymentMandatory: item.isPaymentMandatory || null,
    isGlobal: item.isGlobal || null,
    shape: item.shape || null
  }))
}

// TODO: move this to courses and use globalActions
export const coursesTransform = (courses) => {
  return courses.map(item => ({
    id: item.id || null,
    externalId: item.externalId || null,
    name: item.name || null,
    orderIndex: item.orderIndex || null,
    companyId: item.companyId || null,
    categoryId: item.categoryId || null,
    description: item.description || null,
    duration: item.duration || null,
    durationBefore: item.durationBefore || null,
    durationAfter: item.durationAfter || null,
    durationsPattern: item.durationsPattern || null,
    price: item.price || null,
    hasOnlinePayment: item.hasOnlinePayment || null,
    isPaymentMandatory: item.isPaymentMandatory || null,
    isCourse: item.isCourse || null,
    extraPersonsPerParticipant: item.extraPersonsPerParticipant || null,
    maxParticipants: item.maxParticipants || null,
    participantsCount: item.participantsCount || null,
    shape: item.shape || null
  }))
}
