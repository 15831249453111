import React from 'react'
import PropTypes from 'prop-types'
import { PATH_TO_CIRCLE_FLAGS, DEFAULT_LOCALE_COUNTRY_CODE } from '../../../Settings'
import { Image, t } from '../../../Common'

const CountrySelector = props => {
  const {
    countries,
    selected = DEFAULT_LOCALE_COUNTRY_CODE,
    onChangeCountry,
    toggle
  } = props

  const country = countries.filter(country => country.code === selected.toUpperCase())[0]

  if (!country) return null

  return (
    <>
      <div className='ta-phone-input__country-selector'>
        <div className='ta-phone-input__selected-country' onClick={toggle}>
          <Image src={`${PATH_TO_CIRCLE_FLAGS}/${country.flag}`} alt={country.code} />
        </div>
        <ul className='ta-phone-input__countries'>
          {countries
            .filter(c => c.active)
            .sort((a, b) => t(a.name) > t(b.name) ? 1 : -1)
            .map(c => (
              <li
                key={c.code}
                onClick={() => onChangeCountry(c.code)}
                className={c.code === country.code ? 'active' : ''}
              >
                <Image src={`${PATH_TO_CIRCLE_FLAGS}/${c.flag}`} alt={c.code} />
                {t(c.name)}
                <span>(+{c.phoneCountry})</span>
              </li>
            ))
          }
        </ul>
      </div>
      <div className='ta-phone-input__country_code'>+{country.phoneCountry} <span className={`ta-phone-input__country_code_${country.code}`}>(0)</span></div>
    </>
  )
}

CountrySelector.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.string,
  onChangeCountry: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired
}

export default CountrySelector
