import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CustomerFieldPreview } from '../../Beauties'

import {
  t,
  format,
  FontAwesome5,
  DashedTitle,
  LinedTitle,
  Price,
  SmallTitle,
  getCustomDateFormat,
  getCustomTimeFormat,
  Input,
  Message,
  Error,
  Link
} from '../../Common'
import { convertMinutesToHours, dangerousHTML } from '../../Utils'
import { INTERSPORT_ENTERPRISE_ID } from '../../Settings'
import { globalActions, handlers } from '../../Store'

import './Confirm.css'

const Confirm = props => {
  const {
    info,
    appMode,
    isActive,
    enterpriseId,
    disclaimerText,
    hasStripeActive,
    hasNotification,
    selectedTimezone,
    rescheduledEvent,
    hasStripeConnected,
    onNotificationClose,
    resourceExternalIds,
    hasStripeFeesNotAllowed,
    shouldDisplayDisclaimer,
    customerFieldsWithValues,
    onCourseParticipantsUpdate,
    voucherCode,
    initVoucherCode,
    voucherFrom,
    voucherUntil,
    voucherError,
    routeName
  } = props
  let { hasVoucher, voucherDiscount, voucherStatus } = props
  const ref = useRef()
  const { service, resource, courseResources, slot } = info
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const customTimeFormat = getCustomTimeFormat()
  let slotMinutes = convertMinutesToHours(slot && slot.minutes)
  if (customTimeFormat) slotMinutes = format(slotMinutes, customTimeFormat, { isUTC: true, format: 'LT' })
  const classNames = ['ta-step', 'ta-confirm']
  if (isActive) classNames.push('active')
  const classNameInputVoucher = ['ta-cta-secondary', 'apply_voucher_button']
  if (voucherCode.length === 0 || buttonDisabled) classNameInputVoucher.push('disabled')
  let showError = false
  let showWarningMessage = false
  if ((voucherStatus === 'EXPIRED') || (voucherStatus === 'REDEEMED')) {
    showError = true
    voucherDiscount = 0
  } else if (voucherStatus === 'OTHER_DATES') {
    showWarningMessage = true
    hasVoucher = false
    voucherDiscount = 0
  }
  let discountPrice = service?.discountPrice || 0
  let discountedPrice = service?.discountedPrice || 0
  if (!service || !service.price || service.price < 0) {
    hasVoucher = false
    voucherDiscount = 0
  } else if (voucherDiscount > 0) {
    const voucherDiscountPrice = service.price * voucherDiscount / 100
    const offerDiscountPrice = discountedPrice !== 0 ? service.price - discountedPrice : 0
    if (voucherDiscountPrice > offerDiscountPrice) {
      discountPrice = voucherDiscountPrice
      discountedPrice = service.price - discountPrice
    } else {
      voucherDiscount = 0
      voucherStatus = 'NOT_APPLIED'
    }
  }
  const price = service?.price || null
  const participants = service?.extraPersonsPerParticipant || 0
  const totalRegularPrice = (participants + 1) * price || price
  let totalToPay = discountedPrice || price
  if ((!!voucherDiscount === false) && (price > 0)) {
    if ((discountedPrice === 0) && (participants > 0)) {
      totalToPay = (participants + 1) * price
    } else if ((discountedPrice > 0) && (participants === 0)) {
      totalToPay = discountedPrice
    } else if ((discountedPrice > 0) && (participants > 0)) {
      totalToPay = (participants + 1) * discountedPrice
    }
  }
  if ((voucherDiscount > 0) && (price > 0)) {
    if ((discountedPrice > 0) && (participants > 0)) {
      totalToPay = (participants + 1) * discountedPrice
    }
  }

  useEffect(() => {
    if (routeName === 'confirm') {
      handlers.bookingPricePopulate(
        {
          regularPrice: totalRegularPrice,
          finalPrice: totalToPay
        }
      )
    }
  }, [routeName, totalToPay, totalRegularPrice])

  useEffect(() => {
    if (initVoucherCode && routeName === 'confirm') {
      handlers.formFieldsUpdate('voucherInput', { voucherValue: { value: initVoucherCode } })
      handlers.checkVoucherCode({ code: initVoucherCode })
    }
  }, [initVoucherCode, routeName])

  useEffect(() => {
    let element
    const interval = setInterval(() => {
      element = ref.current
      if (element && isActive) {
        globalActions.postMessage({
          action: 'timify:widget:confirmation:container',
          height: element.clientHeight
        }, '*')
      }
    }, 500)

    return () => clearInterval(interval)
  }, [ref, isActive])

  useEffect(() => {
    if (voucherStatus || voucherError) setButtonDisabled(false)
  }, [voucherStatus, voucherError])

  const resetVoucherStatus = () => {
    handlers.formFieldsUpdate('voucherInput', {
      voucherStatus: { value: '' },
      voucherFrom: { value: '' },
      voucherUntil: { value: '' },
      voucherDiscount: { value: 0 },
      globalErrors: {}
    })
  }

  const resetVoucherInput = () => {
    handlers.formFieldsUpdate('voucherInput', {
      voucherValue: { value: initVoucherCode || '' },
      voucherStatus: { value: '' },
      voucherFrom: { value: '' },
      voucherUntil: { value: '' },
      voucherDiscount: { value: 0 },
      globalErrors: {}
    })
  }

  return (
    <div className={classNames.join(' ')}>
      {(isActive && (
        <div className='ta-confirm__container' ref={ref}>

          {(appMode === 'RESCHEDULE' && rescheduledEvent &&
            <Message
              show={hasNotification}
              type='warning'
              onClick={onNotificationClose}
              label={t('confirm.preview.section.reschedule.title')}
              className='ta-confirm__notification'
            >
              {(rescheduledEvent.resourcesNames
                ? t('confirm.preview.section.reschedule.message', [{
                  key: 'DATE',
                  value: rescheduledEvent.appointmentDate
                    ? format(rescheduledEvent.appointmentDate, getCustomDateFormat() || 'long')
                    : ''
                }, {
                  key: 'TIME',
                  value: rescheduledEvent.appointmentDate
                    ? format(rescheduledEvent.appointmentDate, getCustomTimeFormat() || 'time')
                    : ''
                }, {
                  key: 'RESOURCE_NAME',
                  value: (rescheduledEvent.resourcesNames && rescheduledEvent.resourcesNames.join(', ')) || ''
                }])
                : t('confirm.preview.section.reschedule.withoutResource.message', [{
                  key: 'DATE',
                  value: rescheduledEvent.appointmentDate
                    ? format(rescheduledEvent.appointmentDate, getCustomDateFormat() || 'long')
                    : ''
                }, {
                  key: 'TIME',
                  value: rescheduledEvent.appointmentDate
                    ? format(rescheduledEvent.appointmentDate, getCustomTimeFormat() || 'time')
                    : ''
                }])
              )}
            </Message>
          )}
          {(service && service.courseId && service.maxExtraPersonsPerParticipant > 0 &&
            <Message
              show
              type='warning'
              label={t('groups.extraParticipants.bookingInfo.message', [{ key: 'COUNT', value: service.extraPersonsPerParticipant + 1 }])}
              className='ta-confirm__notification'
            >
              <span
                className='ta-confirm__extra-persons-button'
                onClick={onCourseParticipantsUpdate}
              >
                {t('groups.extraParticipants.bookingInfo.link.label')}
              </span>
            </Message>
          )}

          {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
            <SmallTitle label={t('confirm.preview.header.title')} className='ta-enterprise__intersport-title' />
          )}

          {appMode !== 'RESCHEDULE' && <DashedTitle label={t('confirm.preview.section.info.title')} className='ta-popup__main_title' />}
          {appMode === 'RESCHEDULE' && <DashedTitle label={t('confirm.preview.section.info.title_reschedule')} className='ta-popup__main_title' />}

          {(enterpriseId && ['5c66f5673147e64eeead19c7', '5c813242d1b1b55743bdedc2'].includes(enterpriseId) &&
            <DashedTitle label={t('confirm.preview.section.bookingInfo.title')} className='ta-availability__booking__title' />
          )}

          <div className='ta-confirm__rows'>

            {(service &&
              <div className='ta-confirm__row service'>

                {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                  <div className='ta-confirm__label'>
                    {t('confirm.preview.section.service.label')}
                  </div>
                )}
                <div className='ta-confirm__row__service-data'>
                  <div className='ta-confirm__row__service-data__name'>
                    <FontAwesome5 icon='list' type='regular' className='ta-confirm__icon' />
                    <span>{service.name}</span>
                  </div>

                  {(service.duration &&
                    <div className='ta-confirm__value'>
                      <span>{service.duration} {t('services.list.section.category.service.min.label')}</span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {(slot &&
              <div>
                {(slot.day &&
                  <div className='ta-confirm__row'>

                    {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                      <div className='ta-confirm__label'>
                        {t('confirm.preview.section.day.label')}
                      </div>
                    )}

                    <FontAwesome5 icon='calendar-alt' type='solid' className='ta-confirm__icon' />
                    {format(slot.day, getCustomDateFormat() || 'longWithLongWeekDay', { isUTC: true, format: 'YYYY-MM-DD' })}
                  </div>
                )}

                {(slot.minutes &&
                  <div className='ta-confirm__row'>

                    {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                      <div className='ta-confirm__label'>
                        {t('confirm.preview.section.time.label')}
                      </div>
                    )}

                    <FontAwesome5 icon='clock' type='solid' className='ta-confirm__icon' />
                    {t('confirm.preview.section.info.minutes.label', [{
                      key: 'TIME',
                      value: slotMinutes
                    }])}

                    {(selectedTimezone &&
                      <span className='ta-confirm__timezone'>{`(${selectedTimezone.label})`}</span>
                    )}
                  </div>
                )}
              </div>
            )}

            {(resource &&
              <div className='ta-confirm__row'>

                {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                  <div className='ta-confirm__label'>
                    {t('confirm.preview.section.resource.label')}
                  </div>
                )}

                <FontAwesome5 icon='user' type='solid' className='ta-confirm__icon' />
                {t('confirm.preview.section.info.resources.by.label')} {resource.name}
              </div>
            )}

            {(courseResources &&
              <div className='ta-confirm__row'>

                {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                  <div className='ta-confirm__label'>
                    {t('confirm.preview.section.resource.label')}
                  </div>
                )}

                <FontAwesome5 icon='user' type='solid' className='ta-confirm__icon' />
                {dangerousHTML(t('groups.with', [{ key: 'RESOURCES', value: courseResources }]))}
              </div>
            )}

            {(service && service.courseId &&
              <div className='ta-confirm__row'>

                {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                  <div className='ta-confirm__label'>
                    {t('confirm.preview.section.participants.label')}
                  </div>
                )}

                <FontAwesome5 icon='users' type='solid' className='ta-confirm__icon' />
                {((!service.extraPersonsPerParticipant || service.extraPersonsPerParticipant < 1) &&
                  <>
                    {t('confirm.preview.section.participants.single.label')}
                    <span className='ta-services__discounted__price ta-confirm__icon__price-calculated'>({t('confirm.preview.section.participants.you.label')})</span>
                  </>
                )}
                {(!(!service.extraPersonsPerParticipant || service.extraPersonsPerParticipant < 1) &&
                  <>
                    {t('confirm.preview.section.participants.multiple.label', [{ key: 'PARTICIPANTS', value: service.extraPersonsPerParticipant + 1 }])}
                    <span className='ta-services__discounted__price ta-confirm__icon__price-calculated'>({t('confirm.preview.section.participants.you.label')} + {service.extraPersonsPerParticipant})</span>
                  </>
                )}
              </div>
            )}

            {enterpriseId && ['5c66f5673147e64eeead19c7', '5c813242d1b1b55743bdedc2'].includes(enterpriseId) && resourceExternalIds && resourceExternalIds.length > 0 &&
              resourceExternalIds.map((item, index) => (
                <div key={`external-id-${index}`} className='ta-confirm__row'>
                  {item}
                </div>
              ))}

            {(service && service.price && service.price > 0 &&
              <div className={(hasVoucher || showWarningMessage) ? 'ta-confirm__row price has-voucher' : 'ta-confirm__row price'}>
                {(!voucherDiscount &&
                  <div className='ta-confirm__row__price-data'>
                    {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                      <div className='ta-confirm__label'>
                        {t('confirm.preview.section.prices.price.label')}
                      </div>
                    )}
                    <div className='ta-confirm__label'>
                      {t('confirm.preview.section.prices.price.label')}:
                    </div>
                    {(!discountedPrice && !service.extraPersonsPerParticipant) && <Price price={service.price} />}
                    {((!discountedPrice && service.extraPersonsPerParticipant > 0) &&
                      <>
                        <Price price={(service.extraPersonsPerParticipant + 1) * service.price} />
                        &nbsp;
                        <span className='ta-confirm__icon__price-calculated flex'>
                          ({(service.extraPersonsPerParticipant + 1)}&nbsp;x&nbsp;<Price className='price' price={service.price} />)
                        </span>
                      </>
                    )}
                    {(!!discountedPrice && !service.extraPersonsPerParticipant &&
                      <>
                        <strike className='ta-services__price'><Price price={service.price} /></strike>
                        <FontAwesome5 icon='long-arrow-right' type='regular' />
                        <Price price={discountedPrice} className='ta-services__discounted__price' />
                      </>
                    )}
                    {((!!discountedPrice && service.extraPersonsPerParticipant > 0) &&
                      <>
                        <strike className='ta-services__price'><Price price={(service.extraPersonsPerParticipant + 1) * service.price} /></strike>
                        <FontAwesome5 icon='long-arrow-right' type='regular' />
                        <Price price={(service.extraPersonsPerParticipant + 1) * discountedPrice} />
                        &nbsp;
                        <span className='ta-services__discounted__price ta-confirm__icon__price-calculated flex'>
                          ({(service.extraPersonsPerParticipant + 1)}&nbsp;x&nbsp;<Price className='price' price={discountedPrice} />)
                        </span>
                      </>
                    )}
                  </div>
                )}

                {(!!voucherDiscount &&
                  <div className='ta-confirm__row__price-data voucher-applied'>
                    <div className='row-price-voucher'>
                      <div className='ta-confirm__label'>
                        {t('confirm.preview.section.voucher.originalPrice')}
                      </div>
                      {(!!discountedPrice && service.extraPersonsPerParticipant > 0)
                        ? <strike className='ta-services__price'><Price price={(service.extraPersonsPerParticipant + 1) * service.price} /></strike>
                        : <strike className='ta-services__price'> <Price price={service.price} /></strike>}
                    </div>
                    <div className='row-price-voucher'>
                      <div className='ta-confirm__label'>
                        {voucherDiscount}% {t('confirm.preview.section.voucher.percent.saving')}
                      </div>
                      <div>
                        {(!!discountedPrice && service.extraPersonsPerParticipant > 0)
                          ? <Price price={`-${(service.extraPersonsPerParticipant + 1) * discountPrice}`} />
                          : <Price price={`-${discountPrice}`} />}
                      </div>
                    </div>
                    <div className='row-price-voucher'>
                      <div className='ta-confirm__label' />
                      <span className='line-total' />
                    </div>
                    <div className='row-price-voucher'>
                      <div className='ta-confirm__label'>
                        {t('confirm.preview.section.voucher.newPrice')}
                      </div>
                      {(!!discountedPrice && service.extraPersonsPerParticipant > 0)
                        ? <Price price={(service.extraPersonsPerParticipant + 1) * discountedPrice} />
                        : <Price price={discountedPrice} />}
                    </div>
                  </div>
                )}
                {hasStripeActive && !hasStripeFeesNotAllowed && hasStripeConnected && !!service.transactionFeePrice && (
                  <div className='ta-confirm__row__price-data ta-confirm__row__price-data__no-flex'>
                    <div className='ta-confirm__row__transaction-fee__label'>
                      {t('confirm.preview.section.transaction.fee.label')}
                    </div>
                    <ul className='ta-confirm__row__transaction-fee__gateway-list'>
                      <li>Stripe (<Price price={service.transactionFeePrice} />)</li>
                    </ul>
                  </div>
                )}
                {hasVoucher && !showWarningMessage && (
                  <div className='ta-confirm__row voucher'>
                    <div>
                      <Input
                        formName='voucherInput'
                        name='voucherValue'
                        label={t('confirm.preview.section.voucher.input.label')}
                        value={voucherCode || ''}
                        onChangeAddon={() => resetVoucherStatus()}
                        placeholder={t('confirm.preview.section.voucher.input.placeholder')}
                        addon={
                          (voucherCode && voucherStatus && !showError)
                            ? (
                              <Link className='clear-voucher-input' onClick={() => resetVoucherInput()}>
                                <FontAwesome5 icon='times' />
                              </Link>)
                            : (
                                voucherCode
                                  ? (
                                    <button
                                      disabled={buttonDisabled}
                                      onClick={async () => {
                                        setButtonDisabled(true)
                                        resetVoucherStatus()
                                        await handlers.checkVoucherCode({ code: voucherCode })
                                      }}
                                      className={classNameInputVoucher.join(' ')}
                                      tabIndex={-1}
                                    >
                                      {t('confirm.preview.section.voucher.button.redeem.label')}
                                    </button>
                                    )
                                  : undefined
                              )
                            }
                      />
                    </div>
                  </div>
                )}
                {voucherStatus === 'NOT_APPLIED' && (
                  <div className='ta-confirm__row voucher message not_applied'>
                    <div>
                      <Message
                        type='warning'
                        show
                      >
                        {t('confirm.preview.section.voucher.not_applied.message')}
                      </Message>
                    </div>
                  </div>
                )}
                {showWarningMessage && (
                  <div className='ta-confirm__row voucher message'>
                    <div>
                      <Message
                        type='warning'
                        show
                      >
                        {voucherStatus === 'OTHER_DATES'
                          ? t('confirm.preview.section.voucher.choose.slot.message', [{
                            key: 'DATEFROM',
                            value: voucherFrom
                          }, {
                            key: 'DATETO',
                            value: voucherUntil
                          }])
                          : t('confirm.preview.section.voucher.no_offers.message')}
                      </Message>
                    </div>
                  </div>
                )}
                {showError && !showWarningMessage && (
                  <div className='ta-confirm__row voucher error'>
                    <div>
                      <Error
                        error={{ value: 'confirm.preview.section.voucher.error.message' }}
                      />
                    </div>
                  </div>
                )}
                {!!voucherError && !showWarningMessage && (
                  <div className='ta-confirm__row voucher error'>
                    <div>
                      <Error
                        error={{ value: voucherError }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {(customerFieldsWithValues && Object.keys(customerFieldsWithValues).length > 0 &&
              <div>
                <LinedTitle label={t('confirm.preview.section.customfields.title')} />

                <div className='ta-confirm__rows_container'>
                  {customerFieldsWithValues.map((item, index) => {
                    return (
                      <CustomerFieldPreview key={`customer-field-${index}`} item={item} />
                    )
                  })}
                </div>
              </div>
            )}
          </div>
          {(shouldDisplayDisclaimer &&
            <div className='ta-disclaimer'>
              {dangerousHTML(disclaimerText, false, true)}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

Confirm.propTypes = {
  isActive: PropTypes.bool
}

export default Confirm
