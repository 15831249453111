import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { SelectTag } from '../../Common'

const SelectTags = (props) => {
  let {
    inline,
    values,
    options,
    disabled,
    onDelete,
    renderSelectedItem
  } = props
  values = values || []
  const items = useMemo(() =>
    options.filter(item => values.includes(item.value)),
  [options, values])

  const classNames = ['ta-multi-select__values']
  if (inline) classNames.push('inline')

  return (
    <div className={classNames.join(' ')}>
      {items.map((item, index) => (
        <SelectTag
          key={index}
          item={item}
          index={index}
          disabled={disabled}
          onDelete={onDelete}
          renderSelectedItem={renderSelectedItem}
        />
      ))}
    </div>
  )
}

SelectTags.propTypes = {
  inline: PropTypes.bool,
  values: PropTypes.array,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onDelete: PropTypes.func,
  renderSelectedItem: PropTypes.func
}

export default SelectTags
