import { translations, store } from '../../Store'
import { encodeHtml } from '../../Utils'

export const t = (key, replace = [], locale = store.getState().app.locale, skipEncoding) => {
  if (!key) return 'Missing key!'

  locale = locale.toLowerCase()

  if (!locale) return 'Something is wrong'
  if (!translations[locale]) return 'Something is wrong!'

  const { router, phrase } = store.getState()

  const { query } = router || {}

  const { isEnabled } = phrase || {}
  const isStaging = ['development', 'staging'].includes(process.env.REACT_APP_ENV)

  let translation = isStaging && isEnabled
    ? `{{__phrase_${key}__}}`
    // REMOVE ONLY THE IF STATEMANT
    // ORIGINAL CODE let translation = translations[locale][key]
    : query.showTranslationKeys ? key : translations[locale][key]

  if (!translation) return `Missing translations: ${key}`
  if (replace.length) {
    replace.forEach(replaces => {
      if (replaces instanceof Array) {
        replaces.forEach(r => {
          if (r && r.key) {
            let replaceValue = r.value || ''
            if (r.translateReplace && r.translateReplace === true) {
              replaceValue = t(r.value)
            }
            replaceValue = (replaceValue || '').toString()
            translation = translation.replace(new RegExp(`%${replaces.key}%`, 'g'), skipEncoding ? replaceValue : encodeHtml(replaceValue))
          }
        })
      } else {
        if (replaces && replaces.key) {
          let replaceValue = replaces.value || ''
          if (replaces.translateReplace && replaces.translateReplace === true) {
            replaceValue = t(replaces.value)
          }
          replaceValue = (replaceValue || '').toString()
          translation = translation.replace(new RegExp(`%${replaces.key}%`, 'g'), skipEncoding ? replaceValue : encodeHtml(replaceValue))
        }
      }
    })
  }

  return translation
}
