import React, { PureComponent } from 'react'
import { connect, handlers } from '../../Store'
import { Resources } from '../../Beauties'

class ResourcesWrapper extends PureComponent {
  constructor (props) {
    super(props)

    this.state = { isResourcesClosed: false }

    this.onResourceClick = this.onResourceClick.bind(this)
    this.onResourcesSelectorClick = this.onResourcesSelectorClick.bind(this)
    this.onClickCategoryPrev = this.onClickCategoryPrev.bind(this)
    this.onClickCategoryNext = this.onClickCategoryNext.bind(this)
  }

  onResourceClick (id) {
    const { selectedResourceId } = this.props
    handlers.resourceSelect({ id: (id === selectedResourceId ? null : id) })
    this.setState({ isResourcesClosed: true })
  }

  onResourcesSelectorClick () {
    const { isResourcesClosed } = this.state
    this.setState({ isResourcesClosed: !isResourcesClosed })
  }

  onClickCategoryPrev () {
    const { selectedCategoryIdx } = this.props
    if (selectedCategoryIdx - 1 < 0) return
    handlers.resourceCategorySelect({ idx: selectedCategoryIdx - 1 })
  }

  onClickCategoryNext () {
    const { selectedCategoryIdx, resourceCategories = [] } = this.props
    if (selectedCategoryIdx + 1 > resourceCategories.length - 1) return
    handlers.resourceCategorySelect({ idx: selectedCategoryIdx + 1 })
  }

  render () {
    let {
      companies,
      selectedCompanyId,
      resources,
      selectedResourceId,
      resourceCategories,
      selectedCategoryIdx,
      showSelectedResourcesOnly,
      filterResources,
      filterResourcesbyExternalId,
      resourceId,
      isDatePickerHidden
    } = this.props
    const company = companies.find(item => item.id === selectedCompanyId) || {}
    const settings = company.settings || {}
    const { isResourcesClosed } = this.state
    const categories = (resourceCategories && resourceCategories.reduce((acc, item) => {
      if (item.resourceIds.length === 1) {
        if (!acc.fixedResources) acc.fixedResources = []
        acc.fixedResources.push(item.resourceIds[0])
      } else {
        if (!acc.categories) acc.categories = []
        acc.categories.push(item)
      }
      return acc
    }, {})) || {}
    resourceCategories = categories.categories || []
    const fixedResources = resources.filter(resource => categories.fixedResources && categories.fixedResources.includes(resource.id))
    const selectedResource = resources.find(resource => resource.id === selectedResourceId)
    const selectedCategory = resourceCategories[selectedCategoryIdx] || {}
    const { resourceIds = [] } = selectedCategory
    let filteredResources = resources.filter(resource => resourceIds.includes(resource.id))
    const isActive = !settings.hideResourcesSection

    if (filterResources.length > 0) filteredResources = filteredResources.filter(resource => filterResources.includes(resource.id))
    else if (filterResourcesbyExternalId.length > 0) {
      filteredResources = filteredResources.filter(resource => filterResourcesbyExternalId.includes(resource.externalId))
    }
    if (showSelectedResourcesOnly) {
      filteredResources = filteredResources.filter(resource => resourceId.includes(resource.id))
    }

    return (
      <Resources
        isActive={isActive}
        resources={filteredResources}
        selectedResourceId={selectedResourceId}
        selectedResource={selectedResource}
        resourceCategories={resourceCategories}
        selectedCategoryIdx={selectedCategoryIdx}
        onClickCategoryPrev={this.onClickCategoryPrev}
        onClickCategoryNext={this.onClickCategoryNext}
        onResourceClick={this.onResourceClick}
        isResourcesClosed={isResourcesClosed}
        onResourceSelectorClick={this.onResourcesSelectorClick}
        fixedResources={fixedResources}
        showSelectedResourcesOnly={showSelectedResourcesOnly}
        isDatePickerHidden={isDatePickerHidden}
      />
    )
  }
}

const maps = state => {
  const { attributes, courses, companies } = state
  const { disableOtherDates } = attributes || {}
  const { selectedId: selectedCourseId, list: coursesList } = courses || {}
  const { selectedCompanyId, list: companiesList } = companies || {}
  let selectedCourse = {}
  if (selectedCourseId) selectedCourse = coursesList.find(item => item.id === selectedCourseId) || {}
  const company = companiesList.find(item => item.id === selectedCompanyId) || {}
  const { settings } = company || {}
  const { showWidgetTimezoneSelector } = settings || {}
  const isDatePickerHidden = !showWidgetTimezoneSelector && (disableOtherDates || selectedCourse.isTitleCourse)

  return {
    companies: state.companies.list || [],
    selectedCompanyId: state.companies.selectedId || null,
    resources: state.resources.list || [],
    selectedResourceId: state.resources.selectedId || null,
    resourceCategories: state.resources.categoriesList || [],
    selectedCategoryIdx: state.resources.selectedCategoryIdx || 0,
    showSelectedResourcesOnly: state.attributes.showSelectedResourcesOnly || false,
    filterResources: state.attributes.filterResources || [],
    filterResourcesbyExternalId: state.attributes.filterResourcesbyExternalId || [],
    resourceId: state.attributes.resourceId || null,
    isDatePickerHidden
  }
}

export default connect(maps)(ResourcesWrapper)
