import React from 'react'
import { connect, handlers, store } from '../../Store'
import { ServicesListItem } from '../../Beauties'
import { t } from '../../Common'
import { getRouteQuery, getDiscountedPrice, prepareUpsellingInfo } from '../../Utils'

const ServicesListWrapper = props => {
  const {
    item,
    services,
    selectedServiceId,
    selectedServiceCustomerFieldIds,
    selectedCourseId,
    selectedCourseCustomerFieldIds,
    companies,
    selectedCompanyId,
    timezones,
    category,
    isOffer,
    isUpsellingActive,
    upsellingCategories,
    upsellingServices,
    upsellingSelectedIds,
    fileLoading,
    setFileLoading
  } = props
  const company = companies.find(item => item.id === selectedCompanyId) || {}
  const timezone = timezones.find(item => item.code === company.timezone) || {}
  const { hideServiceDuration = false } = company.settings || {}
  const selectedItemId = selectedServiceId || selectedCourseId
  let selectedItemCustomerFieldIds
  if (selectedServiceId) selectedItemCustomerFieldIds = selectedServiceCustomerFieldIds
  if (selectedCourseId) selectedItemCustomerFieldIds = selectedCourseCustomerFieldIds
  const { price } = item || {}
  const offer = isOffer ? category : null
  if (offer && price) item.discountedPrice = getDiscountedPrice(price, offer.discount)

  // If we have selected service we need to get information about the upselling services
  let upsellingDuration = 0
  let upsellingPrice = 0
  if (selectedServiceId) {
    const selectedService = services.find(item => item.id === selectedServiceId)
    // If we have selected upselling services we need to append them to the name and duration
    const { duration, price } = prepareUpsellingInfo({ selectedItem: { ...selectedService }, services: upsellingServices, categories: upsellingCategories, relationsIds: upsellingSelectedIds })

    upsellingDuration = duration
    upsellingPrice = price
  }

  const onServiceClick = (id, isCourse) => {
    const { selectedCompanyId, customerFields, upsellingCategories, upsellingServices } = props
    const state = store.getState()
    const { selectedId: selectedServiceId } = state.services || {}
    const { selectedId: selectedCourseId } = state.courses || {}
    const selectedItemId = selectedServiceId || selectedCourseId
    handlers.upsellingClear()
    if (selectedItemId === id) {
      handlers.serviceSelect({ id: null, customerFieldIds: null, customerFields: null })
      handlers.courseSelect({ id: null, customerFieldIds: null, customerFields: null })
      return
    }
    const filteredCustomerFields = customerFields
      .filter(item =>
        item.companyId === selectedCompanyId &&
        !item.isAfterAuth &&
        (item.serviceIds.includes(id) || item.courseIds.includes(id))
      )
      .map(item => {
        if (['salutation', 'title'].includes(item.defaultId) && item.selectOptions) {
          item.selectOptions = item.selectOptions.map(item => ({ ...item, label: t(item.translationKey) }))
        }
        return item
      })
    const customerFieldIds = filteredCustomerFields.map(item => item.id)

    if (!isCourse) {
      handlers.serviceSelect({ id, customerFieldIds, customerFields: filteredCustomerFields })
      handlers.courseSelect({ id: null, customerFieldIds: null, customerFields: null })

      let upsellingRelations = upsellingServices.find((item) => item.id === id)
      if (!upsellingRelations) upsellingRelations = upsellingCategories.find((item) => item.id === id)

      // If we have relations between services we need to show the sidebar with related services before
      // going to availability page
      if (upsellingRelations && upsellingRelations.id) {
        return handlers.upsellingActive(true)
      }
    }
    if (isCourse) {
      handlers.courseSelect({ id, customerFieldIds, customerFields: filteredCustomerFields })
      handlers.serviceSelect({ id: null, customerFieldIds: null, customerFields: null })
      handlers.upsellingClear()
    }
    const router = state.router || {}
    const routeQuery = getRouteQuery(router)
    if (!filteredCustomerFields.length) handlers.navigateToPath(`/availability${routeQuery}`)
  }

  const onUpsellingClick = () => {
    handlers.upsellingOpen(true)
  }

  return (
    <ServicesListItem
      item={item}
      offer={offer}
      selectedItemId={selectedItemId}
      selectedItemCustomerFieldIds={selectedItemCustomerFieldIds}
      timezone={timezone}
      hideServiceDuration={hideServiceDuration}
      onServiceClick={onServiceClick}
      isUpsellingActive={isUpsellingActive}
      onUpsellingClick={onUpsellingClick}
      hasSelectedUpsellingServices={upsellingSelectedIds.length > 0}
      upsellingDuration={upsellingDuration}
      upsellingPrice={upsellingPrice}
      fileLoading={fileLoading}
      setFileLoading={setFileLoading}
    />
  )
}

const maps = state => ({
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  services: state.services.list || [],
  selectedServiceId: state.services.selectedId || null,
  selectedServiceCustomerFieldIds: state.services.selectedCustomerFieldIds || null,
  selectedCourseId: state.courses.selectedId || null,
  selectedCourseCustomerFieldIds: state.courses.selectedCustomerFieldIds || null,
  customerFields: state.customerFields.list || [],
  timezones: state.timezones.list || [],
  isUpsellingActive: state.upselling.isActive || false,
  upsellingCategories: state.upselling.categories || [],
  upsellingServices: state.upselling.services || [],
  upsellingSelectedIds: state.upselling.selectedIds || []
})

export default connect(maps)(ServicesListWrapper)
