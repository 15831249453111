import React from 'react'
import PropTypes from 'prop-types'

import './IconList.css'

const IconList = props => {
  const { children, className } = props
  const classNames = ['ta-icon-list']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  )
}

IconList.propTypes = {
  className: PropTypes.string
}

export default IconList
