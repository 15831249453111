import React from 'react'

const SimpleListItemOffer = props => {
  const { children, onClick } = props

  return (
    <div className='ta-simple-list-item__offer'
      onClick={onClick}>

      {children}
    </div>
  )
}

export default SimpleListItemOffer
