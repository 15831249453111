import {
  loginFormInitialValues,
  loginSaveTransform,
  forgottenFormInitialValues,
  enforcePasswordChangeFormInitialValues,
  registerFormTransform,
  registerFormFacebookTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  isConnected: false,
  isPendingForm: false,
  showBlockedWarning: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  connectionChange: isConnected => dispatch(actions.CONNECTION_CHANGE, isConnected),
  logout: () => dispatch(actions.LOGOUT),
  // Login
  loginWithEmailAndPassword: (form, scrollToError = () => { }) => dispatch(actions.LOGIN_WITH_EMAIL_AND_PASSWORD, { fields: loginSaveTransform(form), scrollToError }),
  loginWithFacebook: data => dispatch(actions.LOGIN_WITH_FACEBOOK, { data }),
  loginFormReady: () => dispatch(actions.LOGIN_FORM_READY),
  loginFormPopulate: () => {
    handlers.formSet('login', loginFormInitialValues())
    handlers.loginFormReady()
  },
  loginBlockedWarningShow: () => dispatch(actions.LOGIN_BLOCKED_WARNING_SHOW),
  loginBlockedWarningHide: () => dispatch(actions.LOGIN_BLOCKED_WARNING_HIDE),
  // Forgotten
  forgottenFormReady: () => dispatch(actions.FORGOTTEN_FORM_READY),
  forgottenFormPopulate: () => {
    handlers.formSet('forgotten', forgottenFormInitialValues())
    handlers.forgottenFormReady()
  },
  forgottenFormSave: (booker, scrollToError = () => { }) => dispatch(actions.FORGOTTEN_FORM_SAVE, { booker, scrollToError }),
  forgottenFormSuccess: () => {
    handlers.formFieldsUpdate('forgotten', forgottenFormInitialValues())
    dispatch(actions.FORGOTTEN_FORM_SUCCESS)
  },
  // Enforce Password Change
  enforcePasswordChangeFormReady: () => dispatch(actions.ENFORCE_PASSWORD_CHANGE_FORM_READY),
  enforcePasswordChangeFormPopulate: () => {
    handlers.formSet('enforcePasswordChange', enforcePasswordChangeFormInitialValues())
    handlers.enforcePasswordChangeFormReady()
  },
  enforcePasswordChangeFormSave: (booker, scrollToError = () => { }) => dispatch(actions.ENFORCE_PASSWORD_CHANGE_FORM_SAVE, { booker, scrollToError }),
  enforcePasswordChangeFormSuccess: () => dispatch(actions.ENFORCE_PASSWORD_CHANGE_FORM_SUCCCESS),
  // Registration
  registrationFormReady: () => dispatch(actions.REGISTRATION_FORM_READY),
  registrationFormFacebookReady: () => dispatch(actions.REGISTRATION_FORM_FACEBOOK_READY),
  registrationFormPopulate: data => {
    handlers.formSet('register', registerFormTransform(data))
    handlers.registrationFormReady()
  },
  registrationFormFacebookPopulate: data => {
    handlers.formSet('registerFacebook', registerFormFacebookTransform(data))
    handlers.registrationFormFacebookReady()
  },
  registerFormSave: (booker, scrollToError = () => { }) => dispatch(actions.REGISTER_FORM_SAVE, { booker, scrollToError }),
  registerFormFacebookSave: (booker, scrollToError = () => { }) => dispatch(actions.REGISTER_FORM_FACEBOOK_SAVE, { booker, scrollToError }),
  // Tokens
  authTokensChange: tokens => dispatch(actions.AUTH_TOKENS_CHANGE, tokens),
  authTokensPopulate: ({ accessToken, refreshToken, expires }) => dispatch(actions.AUTH_TOKENS_POPULATE, { accessToken, refreshToken, expires }),
  authRedirect: () => dispatch(actions.AUTH_REDIRECT)
})

reducer.CONNECTION_CHANGE = (state, isConnected) => ({
  ...state,
  isConnected: !!isConnected
})

reducer.LOGOUT = (state) => ({
  ...state,
  isPendingForm: false,
  tokens: null
})

// Login

reducer.LOGIN_WITH_EMAIL_AND_PASSWORD = state => ({
  ...state,
  isPendingForm: true
})

reducer.LOGIN_WITH_FACEBOOK = state => ({
  ...state
})

reducer.LOGIN_FORM_READY = state => ({
  ...state,
  isPendingForm: false
})

reducer.LOGIN_BLOCKED_WARNING_SHOW = state => ({
  ...state,
  showBlockedWarning: true
})

reducer.LOGIN_BLOCKED_WARNING_HIDE = state => ({
  ...state,
  showBlockedWarning: false
})

// Forgotten

reducer.FORGOTTEN_FORM_READY = state => ({
  ...state,
  isPendingForm: false
})

reducer.FORGOTTEN_FORM_SAVE = state => ({
  ...state,
  forgotten: {
    ...state.forgotten,
    success: false
  }
})

// Enforce Password Change

reducer.ENFORCE_PASSWORD_CHANGE_FORM_READY = state => ({
  ...state,
  isPendingForm: false
})

reducer.ENFORCE_PASSWORD_CHANGE_FORM_SAVE = state => ({
  ...state
})

reducer.ENFORCE_PASSWORD_CHANGE_FORM_SUCCCESS = state => state

reducer.REGISTER_FORM_FACEBOOK_SAVE = state => ({
  ...state,
  forgotten: {
    ...state.forgotten,
    success: false
  }
})

reducer.FORGOTTEN_FORM_SUCCESS = state => ({
  ...state,
  forgotten: {
    ...state.forgotten,
    success: true
  }
})

// Registrations

reducer.REGISTRATION_FORM_READY = state => ({
  ...state,
  isPendingForm: false
})

reducer.REGISTRATION_FORM_FACEBOOK_READY = state => ({
  ...state,
  isPendingForm: false
})

reducer.REGISTER_FORM_SAVE = state => ({
  ...state
})

// Guest

reducer.GUEST_FORM_READY = state => ({
  ...state,
  isPendingForm: false
})

// Tokens

reducer.AUTH_TOKENS_CHANGE = state => state

reducer.AUTH_TOKENS_POPULATE = (state, tokens) => ({
  ...state,
  tokens
})

reducer.AUTH_REDIRECT = state => state

export default reducer
