import moment from 'moment'
import * as Sentry from '@sentry/react'
import {
  payloads$,
  actions,
  handlers,
  store
} from '../../../Store'
import { q } from '../../API'
import { getRouteQuery } from '../../../Utils'
import { SENTRY_KEY, SENTRY_PROJECT } from '../../../Settings'

// Get availability

payloads$(actions.AVAILABILITY_GET)
  .subscribe(async () => {
    handlers.appLoaderShow()
    const state = store.getState()
    const routeQuery = getRouteQuery(state.router || {})
    const { app, companies, services, courses, resources, availability, attributes, timezones } = state
    const { mode: appMode = 'NORMAL', sessionId } = app || {}
    const { list: companiesList, selectedId: selectedCompanyId } = companies || {}
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId) || {}
    const selectedResourceId = resources.selectedId
    const selectedServiceId = services.selectedId || courses.selectedId
    const { settings: selectedCompanySettings = {} } = selectedCompany
    const { selectedCode: selectedTimezone } = timezones
    const { filterResources = [], filterResourceCategories = [], filterResourceCategoriesbyExternalId = [], filterByResourceExternalIds = [] } = attributes || {}
    let {
      resourceExternalIds,
      date = '',
      eventId: rescheduledEventId = null,
      secret: rescheduledSecret = null,
      preReservationKey = null,
      dateRange = null,
      disableOtherDates = false
    } = attributes || {}
    let {
      selectedDays = [],
      offDays,
      onDays
    } = availability || {}
    offDays = offDays || {}
    onDays = onDays || {}
    const selectedResourceOffDays = offDays[selectedResourceId || 'ALL']
    const selectedResourceOnDays = onDays[selectedResourceId || 'ALL']
    const areDaysFetched = !!(selectedResourceOffDays || selectedResourceOnDays)

    // If there is no selected service or course we need to exit this method
    if (!selectedServiceId && appMode === 'NORMAL') {
      handlers.appLoaderHide()
      return
    }

    if (selectedDays.length === 0) {
      // Prepare dateRange dates
      if (dateRange && dateRange[0]) dateRange[0] = moment(dateRange[0])
      if (dateRange && dateRange[1]) dateRange[1] = moment(dateRange[1])

      const preselectedDate = moment(date)
      if (preselectedDate.isValid()) {
        if (!disableOtherDates && selectedCompanySettings.isWidgetWeeklyView) {
          let startWeekDate = preselectedDate.startOf('week')
          for (let index = 0; index < 7; index++) {
            selectedDays.push(startWeekDate.format('YYYY-MM-DD'))
            startWeekDate.add(1, 'days')
          }
        } else {
          selectedDays.push(preselectedDate.format('YYYY-MM-DD'))
        }
      } else if (selectedCompanySettings.isWidgetWeeklyView) {
        let startWeekDate = moment().startOf('week')
        if (dateRange && dateRange[0] && dateRange[0].isValid()) startWeekDate = dateRange[0].clone().startOf('week')
        for (let index = 0; index < 7; index++) {
          // If we have date range we must apply additional validation
          if (dateRange && dateRange[0] && dateRange[0].isValid() && dateRange[1] && dateRange[1].isValid()) {
            if (startWeekDate.isSameOrAfter(dateRange[0]) && startWeekDate.isSameOrBefore(dateRange[1])) selectedDays.push(startWeekDate.format('YYYY-MM-DD'))
          } else {
            selectedDays.push(startWeekDate.format('YYYY-MM-DD'))
          }
          startWeekDate.add(1, 'days')
        }
      } else {
        if (dateRange && dateRange[0] && dateRange[0].isValid()) {
          selectedDays.push(dateRange[0].format('YYYY-MM-DD'))
        } else {
          selectedDays.push(moment().format('YYYY-MM-DD'))
        }
      }
    }
    const params = {
      companyId: selectedCompany.id,
      region: selectedCompany.region,
      days: selectedDays
    }
    if (selectedResourceId) params.selectedResourceIds = [selectedResourceId]
    if (resourceExternalIds) params.selectedResourceExternalIds = resourceExternalIds
    // If we are in rescheduling mode we need to send also eventId & secret
    if (appMode === 'RESCHEDULE' && rescheduledEventId && rescheduledSecret) {
      params.rescheduledEventId = rescheduledEventId
      params.rescheduledSecret = rescheduledSecret
    }

    // If there have parameters to filter by resources or resource categories we must append them to the params
    if (filterResources && filterResources.length > 0) {
      params.filterByResourceIds = filterResources
    }
    if (filterResourceCategories && filterResourceCategories.length > 0) {
      params.filterByResourceCategoryIds = filterResourceCategories
    }
    if (filterResourceCategoriesbyExternalId && filterResourceCategoriesbyExternalId.length > 0) {
      params.filterByResourceExternalCategoryIds = filterResourceCategoriesbyExternalId
    }
    if (filterByResourceExternalIds && filterByResourceExternalIds.length > 0) {
      params.filterByResourceExternalIds = filterByResourceExternalIds
    }

    let result
    if (courses.selectedId) {
      // Course
      const selectedCourse = (courses.list || []).find(item => item.id === courses.selectedId) || {}
      if (selectedCourse.isTitleCourse) params.eventId = selectedCourse.id
      else params.courseId = selectedCourse.id
      result = (await q('getOnlineCourseAvailability', { params, timezone: selectedTimezone.replace(/ /g, ''), sessionId })) || {}
    } else {
      // Service
      params.skipAllDays = !!areDaysFetched
      if (services.selectedId) params.serviceId = services.selectedId
      const { resourceIds = null } = attributes
      if (resourceIds) params.filterByResourceIds = resourceIds.split(',')
      if (preReservationKey) params.privateEventSecret = preReservationKey

      result = (await q('getOnlineServiceAvailability', { params, timezone: selectedTimezone.replace(/ /g, ''), sessionId })) || {}
    }

    // If we do not have availability for selected days and there is no dateRange param we must load the availability for the first available period
    if ((!result.slots || result.slots.filter(s => s.minutes && s.minutes.length > 0).length === 0) && (!result.events || result.events.filter(e => e.spotsLeft > 0).length === 0) && !(dateRange && dateRange[1] && dateRange[1].isValid()) && result.onDays && result.onDays.length > 0) {
      if (result.onDays && result.onDays.length > 0) {
        result.onDays.sort((a, b) => {
          const aDate = moment(a)
          const bDate = moment(b)
          if (aDate.isBefore(bDate)) return -1
          if (aDate.isAfter(bDate)) return 1
          return 0
        })
      }
      selectedDays = []
      if (selectedCompanySettings.isWidgetWeeklyView) {
        let startWeekDate = moment(result.onDays[0]).startOf('week')
        for (let index = 0; index < 7; index++) {
          selectedDays.push(startWeekDate.format('YYYY-MM-DD'))
          startWeekDate.add(1, 'days')
        }
      } else {
        selectedDays.push(result.onDays[0])
      }
      params.days = selectedDays

      if (courses.selectedId) {
        result = (await q('getOnlineCourseAvailability', { params, timezone: selectedTimezone.replace(/ /g, ''), sessionId })) || {}
      } else {
        result = (await q('getOnlineServiceAvailability', { params, timezone: selectedTimezone.replace(/ /g, ''), sessionId })) || {}
      }
    }

    const {
      error,
      slots,
      events,
      resources: resourcesList,
      dependencies: resourceCategories
    } = result || { error: true }
    if (error) {
      handlers.appLoaderHide()
      if (SENTRY_KEY && SENTRY_PROJECT && ['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
        Sentry.captureMessage(`AVAIILABILITY_GET getOnlineServiceAvailability error: ${error.code}`)
      }
      handlers.conflictErrorPopulate(error.code || null)
      handlers.navigateToPath(`/conflict${routeQuery}`)
      handlers.appLoaderHide()
      return
    }
    result.selectedDays = selectedDays
    handlers.slotsPopulate({ slots, events })
    handlers.resourcesPopulate({ resources: resourcesList, resourceCategories })
    !areDaysFetched && handlers.availabilityPopulate(result, selectedResourceId)
    handlers.availabilityReady()

    // Preselect first resource from externalIds
    if (resourceExternalIds && resourceExternalIds[0] && !selectedResourceId) {
      const preselectedResource = resourcesList.find(res => res.externalId === resourceExternalIds[0])
      if (preselectedResource) handlers.resourcePreSelect({ id: preselectedResource.id })
    }

    handlers.appLoaderHide()
  })
