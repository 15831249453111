import moment from 'moment-timezone'
import * as Sentry from '@sentry/react'
import {
  payloads$,
  actions,
  handlers,
  store
} from '../../../Store'
import { q } from '../../API'
import { UNIQUE_ENTERPRISE_ID, SENTRY_KEY, SENTRY_PROJECT } from '../../../Settings'
import { getRouteQuery, convertMinutesToHours, mrspexSDK, aonSDK, mrmSDK, removeSpaces } from '../../../Utils'
import { registeredBookerCustomerFields } from './utils'

// Save booking

payloads$(actions.BOOKING_SAVE)
  .subscribe(async () => {
    handlers.appLoaderShow()
    const state = store.getState()
    const routeQuery = getRouteQuery(state.router || {})
    const {
      app,
      auth,
      companies,
      booking,
      forms,
      account,
      customerFields,
      attributes,
      courses,
      services,
      stripe,
      paypal,
      router,
      slots,
      upselling,
      customisations,
      timezones
    } = state
    const { payload: customisationsPayload } = customisations || {}
    const { settings: customisationsSettings } = customisationsPayload || {}
    const {
      hideWidgetExternalUrlRedirect,
      widgetExternalUrlRedirect: widgetExternalUrlRedirectCustomisation,
      parseDataFieldsAsPostMessageOnSuccess
    } = customisationsSettings || {}
    const { data } = router || {}
    const { locale: routerLocale } = data || {}
    const { paymentMethodId, paymentIntentId } = stripe || {}
    const { paypalAuthorizationId } = paypal || {}
    const { mode: appMode = 'NORMAL', isLocaleChanged, locale, sessionId } = app || {}
    const { list: companiesList, selectedId: selectedCompanyId } = companies || {}
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId) || {}
    const { settings: companySettings = {}, enterprise: companyEnterprise = {} } = selectedCompany
    const { id: enterpriseId, customersMiddlewareUrl } = companyEnterprise || {}
    const { eventId: rescheduleEventId = null, secret: rescheduleSecret = null, preReservationKey = null, isEnterprise = false } = attributes || {}
    const { eventId, secret } = booking
    const { customerFieldsOnService, customerFieldsBeforeConfirmation, voucherInput } = forms
    const { booker = {}, email: bookerEmail } = account
    const { selectedIds: upsellingSelectedIds = [], selectedOriginalServiceId: upsellingSelectedOriginalServiceId = null } = upselling || {}
    const { list: customerFieldsList } = customerFields
    const companyCustomerFields = customerFieldsList.filter(cf => cf.companyId === selectedCompanyId)
    const { selectedId: selectedServiceId = null, list: servicesList = [] } = services
    const selectedService = servicesList.find(service => service.id === selectedServiceId)
    const { selectedId: selectedCourseId = null, extraParticipants = '' } = courses
    const { coursesList = [], selectedEventId } = slots
    const selectedCourse = coursesList.find(course => course.id === selectedEventId)
    let additionalParticipantsCount = parseInt(extraParticipants, 10)
    if (isNaN(additionalParticipantsCount)) additionalParticipantsCount = 0

    // If the secret is missing, we should not call this method
    if (!secret) {
      handlers.appLoaderHide()
      return
    }

    // If a voucher for discount is applied we should get it from the STATE and to pass it later in the EVENT object to the BE
    const voucherCode = voucherInput.voucherFinalCode?.value || ''

    // If the user is not guest we need to fill firstName, lastName, phone, email and append them to the customer fields
    const bookerCustomerFields = (state.auth.tokens && booker && Object.keys(booker).length > 0)
      ? registeredBookerCustomerFields({ ...booker, email: bookerEmail }, companyCustomerFields)
      : {}

    const allCustomerFields = { ...bookerCustomerFields, ...customerFieldsOnService, ...customerFieldsBeforeConfirmation }
    const defaultCustomerFieldsObj = Object.values(allCustomerFields)
      .filter(({ defaultId }) => defaultId)
      .reduce((acc, curr) => ({
        ...acc,
        [curr.defaultId]: curr.type === 'PHONE' ? { phone: curr.phone, phoneCountry: curr.phoneCountry } : curr.value
      }), {})
    const customerFieldsWithValues = Object.entries(allCustomerFields).reduce((acc, item) => {
      if (item[0].indexOf('customerField') !== -1 && item[0].indexOf('Secondary') === -1 && item[0].indexOf('Zoom') === -1 && item[0].indexOf('CropRadius') === -1) {
        const id = item[0].split('customerField')[1]
        const field = item[1]
        let value = ''
        let values = ''

        // Send email of the booker if exist
        if (field.defaultId && field.defaultId === 'email' && bookerCustomerFields && typeof bookerCustomerFields[item[0]] !== 'undefined' && bookerCustomerFields[item[0]].value) {
          value = bookerCustomerFields[item[0]].value
        }
        // Send firstName & lastName of the booker if the value is not filled
        if (field.defaultId && ['firstName', 'lastName'].includes(field.defaultId) && !field.value && bookerCustomerFields && typeof bookerCustomerFields[item[0]] !== 'undefined' && bookerCustomerFields[item[0]].value) {
          value = bookerCustomerFields[item[0]].value
        }
        if (field.type === 'DATE' && field.value) {
          value = moment.utc(field.value, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
        if (field.type === 'PHONE' && field.phone) {
          value = JSON.stringify({
            number: removeSpaces(field.phone),
            country: field.phoneCountry
          })
        }
        if (field.type === 'CHECKBOX') {
          value = field.value + ''
        }
        if (field.type === 'FILE' && field.defaultId === 'avatar') {
          if (field.value === null) value = null
          if (field.avatarFile) value = field.avatarFile
        }
        if (field.type === 'ADDRESS' && field.value) {
          const data = field.data || null
          const newValue = (data && {
            placeId: data && data.placeId,
            latitude: data && data.lat,
            longitude: data && data.lng,
            street: data && data.streetName,
            streetNumber: data && data.streetNumber,
            city: data && data.city,
            country: data && data.country,
            zipCode: data && data.postalCode,
            formatted: data && data.formattedAddress,
            details: ''
          }) || null
          if (allCustomerFields[`${item[0]}Secondary`] && allCustomerFields[`${item[0]}Secondary`].value) newValue.details = allCustomerFields[`${item[0]}Secondary`].value
          value = JSON.stringify(newValue)
        }
        if (field.type === 'FILES') {
          values = {
            valuesAdd: (field.valuesAdd || []).map(file => JSON.stringify(file)),
            valuesRemove: field.valuesRemove || []
          }
        }
        if (!value && field.type !== 'FILE') value = field.value || null

        if (value || values) {
          acc.push({
            id,
            type: field.type,
            value,
            valuesAdd: values ? values.valuesAdd : undefined,
            valuesRemove: values ? values.valuesRemove : undefined
          })
        }
      }
      return acc
    }, [])

    const event = {
      companyId: selectedCompany.id,
      region: selectedCompany.region,
      eventId,
      voucherCode,
      secret,
      fields: customerFieldsWithValues,
      stripePaymentMethodId: paymentIntentId ? null : paymentMethodId
    }

    if (paymentIntentId) event.stripePaymentIntentId = paymentIntentId
    if (paypalAuthorizationId) event.paypalAuthorizationId = paypalAuthorizationId

    // If we are in reschedule mode we need to send old booking eventId & secret
    if (appMode === 'RESCHEDULE' && rescheduleEventId && rescheduleSecret) {
      event.rescheduledEventId = rescheduleEventId
      event.rescheduledSecret = rescheduleSecret
    }

    let result
    if (appMode !== 'RESCHEDULE' && selectedService && selectedService.isGlobal && selectedCompany && selectedCompany.addOns && selectedCompany.addOns.includes('MRSPEX')) {
      event.meta = {
        utm_source: attributes.utm_source || '',
        utm_medium: attributes.utm_medium || '',
        utm_name: attributes.utm_name || ''
      }

      // If exist customisationId or templatesCustomisationId parameter we need to add it in metadata object.
      // This will force sending customised template to the customer.
      if (attributes) {
        event.metadata = attributes.meta || {}
        if (attributes.customisationId) event.metadata.widgetCustomisationId = attributes.customisationId
        if (attributes.templatesCustomisationId) event.metadata.templatesCustomisationId = attributes.templatesCustomisationId
      }

      // If the locale code is changed and the user is signed in we must update his profile settings
      if ((companySettings.showWidgetLanguageSelector || isLocaleChanged) && locale) {
        event.metadata = event.metadata || {}
        event.metadata.locale = locale
        if (auth && auth.tokens && auth.tokens.accessToken) await q('saveUserData', { userData: { locale } })
      }
      // If locale selector is hidden, use locale from url
      if (!companySettings.showWidgetLanguageSelector && routerLocale) {
        event.metadata = event.metadata || {}
        event.metadata.locale = routerLocale
        if (auth && auth.tokens && auth.tokens.accessToken) await q('saveUserData', { userData: { locale: routerLocale } })
      }

      result = await mrspexSDK.finaliseBooking(event, isEnterprise, selectedCompany.region)
    } else {
      let queryName = 'finaliseOnlineEventReservation'
      if (courses.selectedId) {
        if (selectedCourse.extraPersonsPerParticipant && selectedCourse.extraPersonsPerParticipant !== 0) {
          event.extraPersons = additionalParticipantsCount
        }
        queryName = 'finaliseOnlineCourseEventReservation'
      }
      if (preReservationKey && queryName === 'finaliseOnlineEventReservation') event.privateEventSecret = preReservationKey
      if (customersMiddlewareUrl) queryName = `${queryName}CustomersMiddleware`

      // If exist templatesCustomisationId parameter we need to add it in metadata object. This will force sending
      // customised template to the customer.
      let metadata = {}
      if (attributes) {
        metadata = attributes.meta || {}
        if (attributes.customisationId) metadata.widgetCustomisationId = attributes.customisationId
        if (attributes.templatesCustomisationId) metadata.templatesCustomisationId = attributes.templatesCustomisationId
      }

      let locationData = {}

      // if AON app is installed and preReservationKey exists, send request to AON backend to get location data
      if (selectedCompany && selectedCompany.addOns && selectedCompany.addOns.includes('AON') && preReservationKey) {
        locationData = await aonSDK.getLocationData('location', {
          preReservationKey,
          sessionStart: `${slots.selectedDay}T${convertMinutesToHours(slots.selectedMinutes)}:00`
        },
        selectedCompany.region)
      } else if (selectedCompany && selectedCompany.addOns && selectedCompany.addOns.includes('MRM') && preReservationKey) {
        // if MRM app is installed and preReservationKey exists, send request to MRM backend to get location data
        locationData = await mrmSDK.getLocationData('location', {
          preReservationKey,
          sessionStart: `${slots.selectedDay}T${convertMinutesToHours(slots.selectedMinutes)}:00`
        },
        selectedCompany.region)
      }

      // If have selected UpSelling services we must send their IDs in metadata
      if (upsellingSelectedIds && upsellingSelectedIds.length > 0) {
        metadata.upsellingServiceIds = upsellingSelectedIds
        if (upsellingSelectedOriginalServiceId) metadata.upsellingServiceIds.push(upsellingSelectedOriginalServiceId)
      }

      if (locationData && Object.keys(locationData).length > 0) {
        const {
          companyId = '',
          startsAt = '',
          extraInfo = {},
          notes = '',
          companyName = '',
          locationName = '',
          locationStreet = '',
          locationStreetNr = '',
          locationRoom = ''
        } = locationData || {}

        if (companyId) metadata.companyId = companyId
        if (startsAt) metadata.startsAt = startsAt
        if (extraInfo.notes) metadata.extraInfoNotes = extraInfo.notes
        if (extraInfo.room) metadata.extraInfoRoom = extraInfo.room
        if (notes) metadata.notes = notes
        if (companyName) metadata.companyName = companyName
        if (locationName) metadata.locationName = locationName
        if (locationStreet) metadata.locationStreet = locationStreet
        if (locationStreetNr) metadata.locationStreetNr = locationStreetNr
        if (locationRoom) metadata.locationRoom = locationRoom
      }

      // If externalCustomerId parameter is set we must send it in finalise mutation
      let externalCustomerId = null
      if (attributes && attributes.externalCustomerId) {
        externalCustomerId = attributes.externalCustomerId
      }

      // If locale was changed manually we must send it in metadata for guests or execute save user profile for bookers
      if ((companySettings.showWidgetLanguageSelector || isLocaleChanged) && locale) {
        metadata.locale = locale
        if (auth && auth.tokens && auth.tokens.accessToken) await q('saveUserData', { userData: { locale } })
      }
      // If locale selector is hidden, use locale from url
      if (!companySettings.showWidgetLanguageSelector && routerLocale) {
        metadata.locale = routerLocale
        if (auth && auth.tokens && auth.tokens.accessToken) await q('saveUserData', { userData: { locale: routerLocale } })
      }
      // If the customer selects a custom timezone
      if (timezones?.selectedCode) {
        metadata.customerTimezone = timezones.selectedCode
      }
      result = await q(queryName, { event, sessionId, metadata, externalCustomerId }, null, customersMiddlewareUrl)
    }

    const { error } = result || { error: {} }
    if (error) {
      // If no spots left
      if (error.code === 'NoSpotsLeft') {
        handlers.appLoaderHide()
        if (SENTRY_KEY && SENTRY_PROJECT && ['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
          Sentry.captureMessage(`BOOKING_SAVE no spots left error: ${error.code}`)
        }
        handlers.conflictErrorPopulate(error.code || null)
        handlers.pagesNextSet(`/customer-fields${routeQuery}`)
        handlers.navigateToPath(`/conflict${routeQuery}`)
        return
      }

      // Other errors
      handlers.appLoaderHide()
      if (SENTRY_KEY && SENTRY_PROJECT && ['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
        Sentry.captureMessage(`BOOKING_SAVE other errors, error: ${error.code}`)
      }
      handlers.conflictErrorPopulate(error.code || null)
      handlers.navigateToPath(`/conflict${routeQuery}`)
      return
    }

    handlers.appTrackEvent(result.id)

    // Track complete booking
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage('timify:widget:page:success', '*')
      window.parent.postMessage({
        action: 'timify:widget:page:success',
        language: locale.substring(0, 2).toUpperCase(),
        country: (selectedCompany && selectedCompany.locale ? selectedCompany.locale.substring(3, 5).toUpperCase() : ''),
        company: selectedCompany.name,
        companyId: selectedCompanyId,
        companyExternalId: selectedCompany.externalId || null,
        serviceId: selectedServiceId || selectedCourseId || null,
        serviceExternalId: (selectedService && selectedService.externalId) || (selectedCourse && selectedCourse.externalId) || null,
        selectedDate: slots.selectedDay,
        selectedTime: convertMinutesToHours(slots.selectedMinutes)
      }, '*')
      if (attributes.meta?.postmessage) {
        window.parent.postMessage({
          action: 'timify:widget:metadata:postmessage',
          data: attributes.meta.postmessage
        }, '*')
      }
      if (parseDataFieldsAsPostMessageOnSuccess && !Object.keys(booker).length) {
        window.parent.postMessage({
          action: 'timify:widget:page:success:customerFields',
          firstName: defaultCustomerFieldsObj.firstName,
          lastName: defaultCustomerFieldsObj.lastName,
          phone: defaultCustomerFieldsObj.mobilePhone,
          email: defaultCustomerFieldsObj.email
        }, '*')
      }

      if (appMode === 'RESCHEDULE') window.parent.postMessage('timifyRescheduleSuccess', '*')
    }

    // Send message to Google Analytics if we are in Unique widget
    if (enterpriseId && UNIQUE_ENTERPRISE_ID.includes(enterpriseId) && window && window.dataLayer) {
      window.dataLayer.push({
        event: 'VirtualPageview',
        step: 'Step5',
        stepOption1: (selectedService && selectedService.externalId) || (selectedCourse && selectedCourse.externalId) || '',
        stepOption2: `${slots.selectedDay}T${convertMinutesToHours(slots.selectedMinutes)}`
      })
    }

    // Redirect user to specific URL address
    let { widgetExternalUrlRedirect } = companySettings || {}
    if (widgetExternalUrlRedirectCustomisation) widgetExternalUrlRedirect = widgetExternalUrlRedirectCustomisation
    if (hideWidgetExternalUrlRedirect) widgetExternalUrlRedirect = null
    if (widgetExternalUrlRedirect) {
      const redirectTimer = setInterval(() => {
        const state = store.getState()
        const { booking } = state
        const { redirectSeconds } = booking
        handlers.bookingRedirectCountDown()

        if (redirectSeconds === 1) {
          clearInterval(redirectTimer)
          if (window && window.parent) {
            window.parent.location = widgetExternalUrlRedirect
          } else {
            window.location = widgetExternalUrlRedirect
          }
        }
      }, 1000)
    }

    handlers.appLoaderHide()
    handlers.stripeReset()
    handlers.courseSetExtraParticipants('')
    handlers.bookingPopulate({
      icsText: result.icsText,
      icsData: result.icsData
    })
    handlers.pagesNextSet(null)
    handlers.navigateToPath(`/success${routeQuery}`)
  })

payloads$(actions.BOOKING_CANCEL_RESERVATION)
  .subscribe(async () => {
    const state = store.getState()
    const { booking, companies, conflict } = state
    let { list: companiesList, selectedId: selectedCompanyId } = companies
    companiesList = companiesList || []
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId)
    let { eventId, secret } = booking || {}
    eventId = eventId || null
    secret = secret || null
    const { error } = conflict || {}

    if (eventId && secret) {
      const event = {
        companyId: selectedCompany.id,
        region: selectedCompany.region,
        eventId,
        secret
      }
      await q('cancelOnlineEventReservation', { event })
      if (!['NoSpotsLeft', 'PaymentMustBeDone'].includes(error)) handlers.bookingReset()
    }
  })
