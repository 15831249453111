import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesome5 } from '../../Common'

const SelectClearButton = (props) => {
  const { handleOnReset } = props

  return (
    <div className='ta-form-control__btn-clear' onClick={handleOnReset}>
      <FontAwesome5 icon='times' type='solid' />
    </div>
  )
}

SelectClearButton.propTypes = {
  handleOnReset: PropTypes.func
}

export default SelectClearButton
