import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './Utils/polyfill'
import React from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { render } from 'react-dom'
import { store, Provider } from './Store'
import { SENTRY_KEY, SENTRY_PROJECT } from './Settings'
import './Store/Config/services'
// import registerServiceWorker from './registerServiceWorker'

import AppWrapper from './Beasts/App/AppWrapper'

import './index.css'
import '@fortawesome/fontawesome-pro/css/all.min.css'

import { unregister } from './registerServiceWorker'

if (SENTRY_KEY && SENTRY_PROJECT && ['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
  Sentry.init({
    dsn: `https://${SENTRY_KEY}@sentry.io/${SENTRY_PROJECT}`,
    environment: process.env.REACT_APP_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: process.env.REACT_APP_ENV === 'staging' ? 0.1 : 0.2
  })
}

unregister()

render(
  <Provider store={store}>
    <AppWrapper />
  </Provider>,
  document.getElementById('app')
)

// registerServiceWorker()
