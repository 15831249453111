import React from 'react'

import './LoadingPlaceholder.css'

const LoadingPlaceholder = props => {
  return (
    <div className='ta-loading-placeholder'>
      <div className='ta-loading-placeholder__header' />
      <div className='ta-loading-placeholder__boxes'>
        <div className='ta-loading-placeholder__box medium' />
        <div className='ta-loading-placeholder__box' />
        <div className='ta-loading-placeholder__box' />
        <div className='ta-loading-placeholder__space' />
        <div className='ta-loading-placeholder__box small opacity' />
        <div className='ta-loading-placeholder__box opacity' />
        <div className='ta-loading-placeholder__box opacity' />
        <div className='ta-loading-placeholder__space' />
        <div className='ta-loading-placeholder__box large opacity-more' />
        <div className='ta-loading-placeholder__box opacity-more' />
      </div>
    </div>
  )
}

export default LoadingPlaceholder
