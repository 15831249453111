import React from 'react'

import './EnterpriseLogo.css'

const EnterpriseLogo = props => {
  const { isActive } = props

  return (
    <>
      {(isActive &&
        <div className='ta-enterprise-logo' />
      )}
    </>
  )
}

export default EnterpriseLogo
