import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '../../../Common'

import './SectionTitle.css'

const SectionTitle = props => {
  const {
    label,
    fullLabel,
    extraText,
    icon,
    iconType = 's',
    noIcon,
    children,
    ignoreTopOffset,
    className
  } = props
  const classNames = ['ta-section-title']
  if (className) classNames.push(className)
  if (ignoreTopOffset) classNames.push('ignore-top-offset')
  if (noIcon) classNames.push('no-icon')
  const onClick = e => e.stopPropagation()

  return (
    <div className={classNames.join(' ')}>
      {!noIcon && icon && <FontAwesome5 className='ta-section-title__icon' icon={icon} type={iconType} />}
      <span className='ta-section-title__label' title={fullLabel || label}>{label}</span>
      {extraText && <span className='ta-section-title__extra-text'>{extraText}</span>}
      <div className='ta-section-title__content' onClick={onClick}>{children}</div>
    </div>
  )
}

SectionTitle.propTypes = {
  label: PropTypes.string.isRequired,
  extraText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  ignoreTopOffset: PropTypes.bool,
  className: PropTypes.string
}

export default SectionTitle
