import React from 'react'
import { Avatar } from '../../Common'

import './ResourceItem.css'

const ResourceItem = props => {
  const { resource, onClickResource, isSelected, hasTitle } = props
  const classNames = ['ta-resource-item']
  const nameClassNames = ['ta-resource-item__name']
  if (isSelected) classNames.push('active')
  const isSingleResource = !hasTitle && resource.name.length && resource.name.length > 15
  if (isSingleResource) {
    nameClassNames.push('single-resource')
  }

  return (
    <div
      className={classNames.join(' ')}
      onClick={() => onClickResource && onClickResource(resource.id, resource.categoryId)}
    >
      <Avatar name={resource.name} image={resource.avatarUrl} color={resource.color} abbreviation={resource.abbreviation} />
      <div className={nameClassNames.join(' ')}>{resource.name}</div>
    </div>
  )
}

export default ResourceItem
