const reducer = {}

reducer.initialState = []

reducer.handlers = (dispatch, actions) => ({
  countriesPopulate: ({ countries }) => { dispatch(actions.COUNTRIES_POPULATE, { countries }) }
})

reducer.COUNTRIES_POPULATE = (_, { countries }) => (countries)

export default reducer
