import { bookingRescheduledDataTransform } from './utils'

const reducer = {}

reducer.initialState = {
  isPending: false,
  eventId: null,
  secret: null,
  rescheduledData: null,
  icsText: null,
  redirectSeconds: 5,
  regularPrice: null,
  finalPrice: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  bookingReset: () => dispatch(actions.BOOKING_RESET),
  bookingPopulate: (data) => dispatch(actions.BOOKING_POPULATE, { data }),
  bookingSave: () => dispatch(actions.BOOKING_SAVE),
  bookingCancelReservation: () => dispatch(actions.BOOKING_CANCEL_RESERVATION),
  bookingRescheduledDataPopulate: (data) => dispatch(actions.BOOKING_RESCHEDULED_DATA_POPULATE, bookingRescheduledDataTransform(data)),
  bookingPricePopulate: (data) => dispatch(actions.BOOKING_PRICE_POPULATE, { data }),
  bookingRedirectCountDown: () => dispatch(actions.BOOKING_REDIRECT_COUNT_DOWN)
})

reducer.BOOKING_RESET = state => reducer.initialState

reducer.BOOKING_POPULATE = (state, { data }) => ({
  ...state,
  isPending: false,
  eventId: data.eventId || null,
  secret: data.secret || null,
  icsText: data.icsText || null,
  icsData: (data.icsData || [])[0] || {}
})

reducer.BOOKING_SAVE = state => ({
  ...state,
  isPending: true
})

reducer.BOOKING_CANCEL_RESERVATION = state => ({
  ...state
})

reducer.BOOKING_RESCHEDULED_DATA_POPULATE = (state, data) => ({
  ...state,
  rescheduledData: data
})

reducer.BOOKING_PRICE_POPULATE = (state, { data }) => ({
  ...state,
  ...data
})

reducer.BOOKING_REDIRECT_COUNT_DOWN = state => ({
  ...state,
  redirectSeconds: state.redirectSeconds - 1
})

export default reducer
