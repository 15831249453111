export const bookingSaveTransform = event => ({
  companyId: event.companyId,
  region: event.region,
  eventId: event.eventId,
  secret: event.secret,
  customerFields: event.customerfields
})

export const registeredBookerCustomerFields = (booker, customerFields) => {
  const bookerFields = ['firstName', 'lastName', 'email', 'mobilePhone']
  return Object.entries(customerFields).reduce((acc, item) => {
    if (item[1] && item[1].defaultId && bookerFields.includes(item[1].defaultId)) {
      let bookerValue = null
      switch (item[1].defaultId) {
        case 'mobilePhone':
          item[1].phone = (booker.phone && booker.phone.number && booker.phone.number) || null
          item[1].phoneCountry = (booker.phone && booker.phone.country && booker.phone.country) || null
          break
        default:
          bookerValue = (booker[item[1].defaultId] && booker[item[1].defaultId]) || null
          break
      }

      acc[`customerField${item[1].id}`] = {
        ...item[1],
        value: bookerValue
      }
    }
    return acc
  }, {})
}

export const bookingRescheduledDataTransform = data => {
  return {
    ...data,
    durationsPatternCalc: (data.durationsPattern && data.durationsPattern.reduce((a, b) => a + b, 0)) || 0
  }
}
