import axios from 'axios'
import { http_build_query } from 'qhttp'

import { AON_URL, AON_VERSION } from '../Settings'

const instance = axios.create({
  baseURL: AON_URL,
  headers: { 'X-APP-VERSION': AON_VERSION }
})

export default {
  getLocationData: (url, params, region) => {
    return instance.get(`${url}?region=${region}&${http_build_query(params)}`)
      .then(response => {
        if (response && response.data && response.data.data) {
          return {
            companyId: response.data.data.companyId || null,
            startsAt: response.data.data.startsAt || null,
            extraInfo: response.data.data.extraInfo || null,
            notes: response.data.data.notes || null,
            companyName: response.data.data.companyName || null,
            locationName: response.data.data.locationName || null,
            locationStreet: response.data.data.locationStreet || null,
            locationStreetNr: response.data.data.locationStreetNr || null,
            locationRoom: response.data.data.locationRoom || null
          }
        }
        return null
      })
      .catch(() => null)
  }
}
