import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error } from '../../../Common'

import './Textarea.css'

class Textarea extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange () {
    const { form, formName, name, limit } = this.props
    let value = this.ref.value || ''
    if (limit && value.length > limit) value = value.substring(0, limit)
    this.setState({ filled: !!value })
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
  }

  render () {
    const { focused } = this.state
    const {
      className,
      label,
      name,
      hintText,
      mandatory,
      disabled,
      hideError,
      limit,
      form = {}
    } = this.props
    let { placeholder = form.placeholder } = this.props

    if (!focused && form.value === '') placeholder = label || form.label

    if (mandatory || form.mandatory) placeholder = `${placeholder} *`

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={className}>
        <FormGroup
          focused={focused}
          filled={!!form.value}
          disabled={disabled || form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory || form.mandatory}
        >
          <div style={{ position: 'relative' }}>
            <textarea
              className='ta-form-control ta-textarea'
              ref={ref => { this.ref = ref }}
              name={name}
              value={form.value}
              placeholder={placeholder}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              maxLength={limit && limit}
              disabled={disabled || form.disabled}
            />
            {limit &&
              <div className='ta-textarea__limit'>
                {(form.value && form.value.length) || 0} / {limit}
              </div>
            }
          </div>
          {hintText &&
            <div className={`ta-form-control__hint ${limit && 'short'}`}>{hintText}</div>
          }
          {!hideError &&
            <Error name={name} className='ta-clear' />
          }
        </FormGroup>
      </div>
    )
  }
}

Textarea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  hintText: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  limit: PropTypes.number,
  hideError: PropTypes.bool,
  form: PropTypes.object,
  className: PropTypes.string
}

const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' }
})

export default feedContextInProps(connect(maps)(Textarea), FormContext)
