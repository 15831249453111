import moment from 'moment-timezone'
import * as Sentry from '@sentry/react'
import {
  payloads$,
  actions,
  handlers,
  store
} from '../../../Store'
import { q } from '../../API'
// import { fromEvent } from 'rxjs'
// import { startWith, debounceTime, filter } from 'rxjs/operators'
import { getRouteQuery, convertMinutesToHours, aonSDK, mrmSDK } from '../../../Utils'
import { SENTRY_KEY, SENTRY_PROJECT } from '../../../Settings'

// Get slots

// TODO: this method and AVAILABILITY_GET are doing 99% the same
// can be removed and AVAILABILITY_GET with little modifications can handle it to avoid duplicate code
payloads$(actions.SLOTS_GET)
  .subscribe(async () => {
    handlers.appLoaderShow()
    const state = store.getState()
    const routeQuery = getRouteQuery(state.router || {})
    const { app, companies, services, courses, resources, availability, attributes, timezones } = state
    const { mode: appMode = 'NORMAL', sessionId } = app || {}
    const { list: companiesList, selectedId: selectedCompanyId } = companies || {}
    const { filterResources = [], filterResourceCategories = [], filterResourceCategoriesbyExternalId = [], filterByResourceExternalIds = [] } = attributes || {}
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId) || {}
    let { resourceExternalIds, date = '', eventId: rescheduledEventId = null, secret: rescheduledSecret = null, preReservationKey = null, resourceIds = null } = attributes || {}
    const { selectedId: selectedResourceId } = resources || {}
    let {
      selectedDays = [],
      offDays,
      onDays
    } = availability || {}
    offDays = offDays || {}
    onDays = onDays || {}
    const selectedResourceOffDays = offDays[selectedResourceId || 'ALL']
    const selectedResourceOnDays = onDays[selectedResourceId || 'ALL']
    const areDaysFetched = !!(selectedResourceOffDays || selectedResourceOnDays)
    if (selectedDays.length === 0) {
      const preselectedDate = moment(date)
      if (preselectedDate.isValid()) {
        selectedDays.push(preselectedDate.format('YYYY-MM-DD'))
      } else {
        selectedDays.push(moment().format('YYYY-MM-DD'))
      }
    }
    const { selectedCode: selectedTimezone } = timezones
    const params = {
      companyId: selectedCompany.id,
      region: selectedCompany.region,
      days: selectedDays
    }
    if (selectedResourceId) params.selectedResourceIds = [selectedResourceId]
    if (resourceExternalIds) params.selectedResourceExternalIds = resourceExternalIds

    // If we are in rescheduling mode we need to send also eventId & secret
    if (appMode === 'RESCHEDULE' && rescheduledEventId && rescheduledSecret) {
      params.rescheduledEventId = rescheduledEventId
      params.rescheduledSecret = rescheduledSecret
    }

    // If there have parameters to filter by resources or resource categories we must append them to the params
    if (filterResources && filterResources.length > 0) {
      params.filterByResourceIds = filterResources
    }
    if (filterResourceCategories && filterResourceCategories.length > 0) {
      params.filterByResourceCategoryIds = filterResourceCategories
    }
    if (filterResourceCategoriesbyExternalId && filterResourceCategoriesbyExternalId.length > 0) {
      params.filterByResourceExternalCategoryIds = filterResourceCategoriesbyExternalId
    }
    if (filterByResourceExternalIds && filterByResourceExternalIds.length > 0) {
      params.filterByResourceExternalIds = filterByResourceExternalIds
    }

    let result
    if (courses.selectedId) {
      // Course
      const selectedCourse = (courses.list || []).find(item => item.id === courses.selectedId) || {}
      if (selectedCourse.isTitleCourse) params.eventId = selectedCourse.id
      else params.courseId = selectedCourse.id
      result = (await q('getOnlineCourseAvailability', { params, timezone: selectedTimezone.replace(/ /g, ''), sessionId })) || {}
    } else {
      // Service
      params.skipAllDays = !!areDaysFetched
      if (services.selectedId) params.serviceId = services.selectedId
      if (preReservationKey) params.privateEventSecret = preReservationKey
      if (resourceIds) params.filterByResourceIds = resourceIds.split(',')
      result = (await q('getOnlineServiceAvailability', { params, timezone: selectedTimezone.replace(/ /g, ''), sessionId })) || {}
    }

    let {
      error,
      slots,
      events,
      resources: resourcesList,
      dependencies: resourceCategories,
      allocationOffDays
    } = result || { error: true }
    if (error) {
      handlers.appLoaderHide()
      if (SENTRY_KEY && SENTRY_PROJECT && ['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
        Sentry.captureMessage(`SLOTS_GET getOnlineServiceAvailability error: ${error.code}`)
      }
      handlers.conflictErrorPopulate(error.code || null)
      handlers.navigateToPath(`/conflict${routeQuery}`)
      return
    }
    allocationOffDays = allocationOffDays || []
    result.selectedDays = selectedDays
    handlers.slotsPopulate({ slots, events })
    handlers.resourcesPopulate({ resources: resourcesList, resourceCategories })
    if (!areDaysFetched || allocationOffDays.length > 0) handlers.availabilityPopulate(result, selectedResourceId)
    handlers.availabilityReady()
    handlers.appLoaderHide()
  })

// Select slot

payloads$(actions.SLOT_SELECT)
  .subscribe(async () => {
    handlers.appLoaderShow()
    const state = store.getState()
    const routeQuery = getRouteQuery(state.router || {})
    const { app, companies, services, courses, resources, slots, attributes, timezones } = state
    const { mode: appMode, isGuestBookingOpened = false, sessionId } = app
    const { list: companiesList, selectedId: selectedCompanyId } = companies || {}
    const { list: servicesList = [] } = services
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId) || {}
    const selectedServiceId = services.selectedId || courses.selectedId
    const selectedResourceId = resources.selectedId
    const { selectedCode: selectedTimezone } = timezones
    const { selectedDay: selectedSlotDay, selectedMinutes: selectedSlotMinutes, selectedEventId, coursesList = [] } = slots
    const selectedService = servicesList.find(s => s.id === selectedServiceId)
    const selectedEvent = coursesList.find(c => c.id === selectedEventId)
    if (!selectedSlotDay || (!selectedSlotMinutes && selectedSlotMinutes !== 0)) {
      handlers.appLoaderHide()
      return
    }
    const params = {
      companyId: selectedCompany.id,
      region: selectedCompany.region,
      serviceId: selectedServiceId,
      slot: {
        day: selectedSlotDay,
        minute: selectedSlotMinutes
      }
    }

    if (selectedResourceId) params.selectedResourceIds = [selectedResourceId]
    let {
      resourceExternalIds,
      eventId: rescheduledEventId = null,
      secret: rescheduledSecret = null,
      showGuestBookingForm = false,
      preReservationKey = null,
      resourceIds = null,
      filterResources = null
    } = attributes || {}
    if (resourceExternalIds) params.selectedResourceExternalIds = resourceExternalIds

    // If we are in rescheduling mode we need to send also eventId & secret
    if (appMode === 'RESCHEDULE' && rescheduledEventId && rescheduledSecret) {
      params.rescheduledEventId = rescheduledEventId
      params.rescheduledSecret = rescheduledSecret
    }

    if (preReservationKey) params.privateEventSecret = preReservationKey
    if (filterResources && filterResources.length) params.filterByResourceIds = filterResources
    if (resourceIds) params.filterByResourceIds = resourceIds.split(',')

    // If exist templatesCustomisationId parameter we need to add it in metadata object. This will force sending
    // customised template to the customer.
    let metadata = {}
    if (attributes) {
      metadata = attributes.meta || {}
      if (attributes.customisationId) metadata.widgetCustomisationId = attributes.customisationId
      if (attributes.templatesCustomisationId) metadata.templatesCustomisationId = attributes.templatesCustomisationId
    }

    let locationData = {}

    // if AON app is installed and preReservationKey exists, send request to AON backend to get location data
    if (selectedCompany && selectedCompany.addOns && selectedCompany.addOns.includes('AON') && preReservationKey) {
      locationData = await aonSDK.getLocationData('location', {
        preReservationKey,
        sessionStart: `${selectedSlotDay}T${convertMinutesToHours(selectedSlotMinutes)}:00`
      },
      selectedCompany.region)
    } else if (selectedCompany && selectedCompany.addOns && selectedCompany.addOns.includes('MRM') && preReservationKey) {
      // if MRM app is installed and preReservationKey exists, send request to MRM backend to get location data
      locationData = await mrmSDK.getLocationData('location', {
        preReservationKey,
        sessionStart: `${selectedSlotDay}T${convertMinutesToHours(selectedSlotMinutes)}:00`
      },
      selectedCompany.region)
    }

    if (locationData && Object.keys(locationData).length > 0) {
      const {
        companyId = '',
        startsAt = '',
        extraInfo = {},
        notes = '',
        companyName = '',
        locationName = '',
        locationStreet = '',
        locationStreetNr = '',
        locationRoom = ''
      } = locationData || {}

      if (companyId) metadata.companyId = companyId
      if (startsAt) metadata.startsAt = startsAt
      if (extraInfo.notes) metadata.extraInfoNotes = extraInfo.notes
      if (extraInfo.room) metadata.extraInfoRoom = extraInfo.room
      if (notes) metadata.notes = notes
      if (companyName) metadata.companyName = companyName
      if (locationName) metadata.locationName = locationName
      if (locationStreet) metadata.locationStreet = locationStreet
      if (locationStreetNr) metadata.locationStreetNr = locationStreetNr
      if (locationRoom) metadata.locationRoom = locationRoom
    }
    const result = (await q('reserveOnlineService', { params, timezone: selectedTimezone.replace(/ /g, ''), sessionId, metadata })) || {}

    const {
      error,
      eventId,
      secret
    } = result || { error: true }
    if (error) {
      handlers.appLoaderHide()
      if (SENTRY_KEY && SENTRY_PROJECT && ['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
        Sentry.captureMessage(`SLOT_SELECT reserveOnlineService error: ${error.code}`)
      }
      handlers.conflictErrorPopulate(error.code || null)
      handlers.navigateToPath(`/conflict${routeQuery}`)
      return
    }

    handlers.bookingPopulate({ eventId, secret })
    handlers.appLoaderHide()

    // If the user trying to reschedule the event we need to go directly to the confirmation page
    if (appMode === 'RESCHEDULE') {
      handlers.navigateToPath(`/confirm${routeQuery}`)
      return
    }

    // We need to check if externalCustomerId parameter is set we must go directly to confirmation page
    if (attributes && attributes.externalCustomerId) {
      if ((selectedService && selectedService.hasOnlinePayment) || (selectedEvent && selectedEvent.hasOnlinePayment)) handlers.navigateToPath(`/payments${routeQuery}`)
      else handlers.navigateToPath(`/confirm${routeQuery}`)
      return
    }

    // We need to check where to redirect the user. If only guest booking is active or we have parameter to redirect user to
    // guest booking form we need to redirect the user to `customer-fields` otherwise we need to redirect the user to
    // authentication page
    const { settings } = selectedCompany
    const { hasFacebookLogin = true, hasGuestBooking = true, hasTimifyLogin = true } = settings
    if (hasGuestBooking && ((!hasFacebookLogin && !hasTimifyLogin) || (showGuestBookingForm && !isGuestBookingOpened))) {
      handlers.navigateToPath(`/customer-fields${routeQuery}`)
      return
    }
    handlers.navigateToPath(`/auth${routeQuery}`)
  })

payloads$(actions.SLOT_FOR_COURSE_SELECT)
  .subscribe(async () => {
    handlers.appLoaderShow()
    const state = store.getState()
    const routeQuery = getRouteQuery(state.router || {})
    const { companies, courses, slots, attributes, app } = state
    const { sessionId } = app || {}
    const { list: companiesList, selectedId: selectedCompanyId } = companies || {}
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId) || {}
    const { selectedEventId } = slots
    if (courses.selectedId && !selectedEventId) {
      handlers.appLoaderHide()
      return
    }
    const params = {
      companyId: selectedCompany.id,
      region: selectedCompany.region,
      eventId: selectedEventId
    }
    let { resourceExternalIds, preReservationKey } = attributes || {}
    if (resourceExternalIds) params.selectedResourceExternalIds = resourceExternalIds

    // If exist templatesCustomisationId parameter we need to add it in metadata object. This will force sending
    // customised template to the customer.
    let metadata = {}
    if (attributes) {
      metadata = attributes.meta || {}
      if (attributes.customisationId) metadata.widgetCustomisationId = attributes.customisationId
      if (attributes.templatesCustomisationId) metadata.templatesCustomisationId = attributes.templatesCustomisationId
    }

    let locationData = {}

    // if AON app is installed and preReservationKey exists, send request to AON backend to get location data
    if (selectedCompany && selectedCompany.addOns && selectedCompany.addOns.includes('AON') && preReservationKey) {
      locationData = await aonSDK.getLocationData('location', {
        preReservationKey,
        sessionStart: `${slots.selectedDay}T${convertMinutesToHours(slots.selectedMinutes)}:00`
      },
      selectedCompany.region)
    } else if (selectedCompany && selectedCompany.addOns && selectedCompany.addOns.includes('MRM') && preReservationKey) {
      // if MRM app is installed and preReservationKey exists, send request to MRM backend to get location data
      locationData = await mrmSDK.getLocationData('location', {
        preReservationKey,
        sessionStart: `${slots.selectedDay}T${convertMinutesToHours(slots.selectedMinutes)}:00`
      },
      selectedCompany.region)
    }

    if (locationData && Object.keys(locationData).length > 0) {
      const {
        companyId = '',
        startsAt = '',
        extraInfo = {},
        notes = '',
        companyName = '',
        locationName = '',
        locationStreet = '',
        locationStreetNr = '',
        locationRoom = ''
      } = locationData || {}

      if (companyId) metadata.companyId = companyId
      if (startsAt) metadata.startsAt = startsAt
      if (extraInfo.notes) metadata.extraInfoNotes = extraInfo.notes
      if (extraInfo.room) metadata.extraInfoRoom = extraInfo.room
      if (notes) metadata.notes = notes
      if (companyName) metadata.companyName = companyName
      if (locationName) metadata.locationName = locationName
      if (locationStreet) metadata.locationStreet = locationStreet
      if (locationStreetNr) metadata.locationStreetNr = locationStreetNr
      if (locationRoom) metadata.locationRoom = locationRoom
    }
    const result = (await q('reserveOnlineCourse', { params, sessionId, metadata }))
    const {
      error,
      eventId,
      secret
    } = result || { error: true }
    if (error) {
      handlers.appLoaderHide()
      // TODO: handle errors
      return
    }

    handlers.bookingPopulate({ eventId, secret })
    handlers.appLoaderHide()

    // We need to check where to redirect the user. If only guest booking is active we need to redirect the user to `customer-fields`
    // otherwise we need to redirect the user to authentication page
    const { settings } = selectedCompany
    const { hasFacebookLogin = true, hasGuestBooking = true, hasTimifyLogin = true } = settings
    if (hasGuestBooking && !hasFacebookLogin && !hasTimifyLogin) {
      handlers.navigateToPath(`/customer-fields${routeQuery}`)
      return
    }
    handlers.navigateToPath(`/auth${routeQuery}`)
  })
