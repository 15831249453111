// Add here initial state for forms if required
export default {
  customerFieldsOnService: {},
  customerFieldsBeforeConfirmation: {},
  dates: {},
  marketingCookies: {
    googleCookieActive: { value: true },
    facebookCookieActive: { value: true }
  },
  // TODO check forms
  app: {},
  availability: {},
  booking: {},
  login: {},
  forgotten: {},
  register: {},
  registerFacebook: {},
  stripeElements: {},
  debugOptions: {},
  voucherInput: {}
}
