import { DEFAULT_LOCALE_COUNTRY_CODE } from '../../../Settings'

export const coursesTransform = courses => {
  if (!courses) return []
  return courses.map(item => ({
    ...item,
    durationsPatternCalc: item.durationsPattern.reduce((a, b) => a + b, 0),
    isTitleCourse: !item.categoryId,
    categoryId: item.categoryId || 'miscCourseCategory'
  }))
}

export const courseCategoriesTransform = (categories, miscCourseCategory, courses) => {
  if (!categories) return []
  const titleCourses = courses.filter(item => !item.categoryId) || []
  if (titleCourses.length > 0) categories.push(miscCourseCategory)
  return categories
}

export const customerFieldsFormTransform = (customerFields, attributeFields, externalAttributeFields, countryPhoneCode, booker) => {
  if (!customerFields) return {}
  customerFields = (customerFields || []).filter(item => !item.isAfterAuth)
  const result = {}

  customerFields
    .forEach(item => {
      let bookerField = null
      if (booker && booker.customerFields && booker.customerFields.length > 0) bookerField = booker.customerFields.find(customerField => customerField.id === item.id)
      result[`customerField${item.id}`] = {
        value: item.value || (bookerField && bookerField.value) || attributeFields[item.id] || externalAttributeFields[item.externalId] || ''
      }
      if (item.type === 'CHECKBOX') {
        if ((!item.value && attributeFields[item.id]) || (!item.value && externalAttributeFields[item.externalId])) {
          result[`customerField${item.id}`].value = true
        } else {
          result[`customerField${item.id}`].value = item.value + '' === 'true'
        }
      }
      if (item.type === 'SELECT') {
        result[`customerField${item.id}`].options = item.selectOptions || attributeFields[item.id] || externalAttributeFields[item.externalId]
      }
      if (item.type === 'PHONE') {
        result[`customerField${item.id}`] = {
          phone: (item.value && item.value.number) || (bookerField && bookerField.value && bookerField.value.number) || (booker && booker.phone && booker.phone.number) || '',
          phoneCountry: (item.value && item.value.country) || (bookerField && bookerField.value && bookerField.value.country) || (booker && booker.phone && booker.phone.country) || countryPhoneCode || DEFAULT_LOCALE_COUNTRY_CODE
        }
      }
      if (item.type === 'FILE' && item.defaultId === 'avatar') {
        result[`customerField${item.id}`] = {
          avatarUrl: { value: item.value || '' },
          value: ''
        }
      }
      if (item.type === 'ADDRESS') {
        const address = item.value || {}
        result[`customerField${item.id}`].value = address.formatted
        result[`customerField${item.id}`].data = ((address.formatted && address.formatted !== '') && {
          placeId: address.placeId || '',
          city: address.city || '',
          streetName: address.street || '',
          streetNumber: address.streetNumber || '',
          postalCode: address.zipCode || '',
          country: address.country || '',
          lat: address.latitude || '',
          lng: address.longitude || '',
          formattedAddress: address.formatted || '',
          details: address.details || ''
        }) || null
        result[`customerField${item.id}Secondary`] = {
          value: address.details || ''
        }
      }
      if (item.type === 'FILES') {
        result[`customerField${item.id}`] = {
          selected: (item.values || []).map(file => JSON.parse(file)),
          valuesAdd: [],
          valuesRemove: [],
          value: ''
        }
      }
      result[`customerField${item.id}`].type = item.type
      result[`customerField${item.id}`].defaultId = item.defaultId
      result[`customerField${item.id}`].isMandatory = item.isMandatoryOnline
      result[`customerField${item.id}`].translationKey = item.translationKey
      result[`customerField${item.id}`].label = item.label
    })

  return result
}
