const reducer = {}

reducer.initialState = {
  isPending: true,
  nextPage: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  pagesReset: () => dispatch(actions.PAGES_RESET),
  pageLocationsLoaded: () => dispatch(actions.PAGE_LOCATIONS_LOADED),
  pageServicesLoaded: () => dispatch(actions.PAGE_SERVICES_LOADED),
  pageAvailabilityLoaded: () => dispatch(actions.PAGE_AVAILABILITY_LOADED),
  pageAuthLoaded: () => dispatch(actions.PAGE_AUTH_LOADED),
  pageCustomerFieldsLoaded: () => dispatch(actions.PAGE_CUSTOMER_FIELDS_LOADED),
  pageConflictLoaded: () => dispatch(actions.PAGE_CONFLICT_LOADED),
  pageConfirmLoaded: () => dispatch(actions.PAGE_CONFIRM_LOADED),
  pagesNextSet: page => dispatch(actions.PAGE_NEXT_SET, page)
})

reducer.PAGES_RESET = state => reducer.initialState

reducer.PAGE_LOCATIONS_LOADED = state => state

reducer.PAGE_SERVICES_LOADED = state => state

reducer.PAGE_AVAILABILITY_LOADED = state => state

reducer.PAGE_AUTH_LOADED = state => state

reducer.PAGE_CUSTOMER_FIELDS_LOADED = state => state

reducer.PAGE_CONFLICT_LOADED = state => state

reducer.PAGE_CONFIRM_LOADED = state => state

reducer.PAGE_NEXT_SET = (state, page) => ({
  ...state,
  nextPage: page || null
})

export default reducer
