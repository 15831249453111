export default node => {
  let children = node.parentNode.childNodes
  let num = 0
  let i
  for (i = 0; i < children.length; i++) {
    if (children[i] === node) return num
    if (children[i].nodeType === 1) num++
  }
  return -1
}
