import React from 'react'
import {
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  SimpleListItemButtons,
  FontAwesome5
} from '../../Common'
import { dangerousHTML } from '../../Utils'

const PaymentItem = props => {
  const {
    title,
    text,
    icon,
    link,
    onClickOnlinePayment,
    onClickCash
  } = props

  return (
    <div className='ta-payments__item'>
      <SimpleListItem to={link} action={onClickOnlinePayment || onClickCash}>
        <FontAwesome5 icon={icon} type='solid' className='ta-payments__item__icon' />
        <SimpleListItemTitle title={title} />
        <SimpleListItemContent>
          {dangerousHTML(text)}
        </SimpleListItemContent>
        <SimpleListItemButtons>
          <button className='ta-cta-third'>
            <FontAwesome5 icon='arrow-right' type='solid' />
          </button>
        </SimpleListItemButtons>
      </SimpleListItem>
    </div>
  )
}

export default PaymentItem
