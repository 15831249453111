import React from 'react'

import './FormText.css'

const FormText = props => {
  const { children, className } = props
  const classNames = ['ta-form-text']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  )
}

export default FormText
