import { validator } from '../../../Utils'
import { DEFAULT_LOCALE_COUNTRY_CODE } from '../../../Settings'

export const servicesTransform = services => {
  if (!services) return []
  return services.map(item => ({
    ...item,
    durationsPatternCalc: (item.durationsPattern && item.durationsPattern.reduce((a, b) => a + b, 0)) || 0
  }))
}

export const serviceCategoriesTransform = categories => {
  if (!categories) return []
  return categories
}

export const customerFieldsFormTransform = (customerFields, attributeFields, externalAttributeFields, countryPhoneCode, booker) => {
  if (!customerFields) return {}
  customerFields = (customerFields || []).filter(item => !item.isAfterAuth)
  const result = {}

  customerFields
    .forEach(item => {
      let bookerField = null
      if (booker && booker.customerFields && booker.customerFields.length > 0) bookerField = booker.customerFields.find(customerField => customerField.id === item.id)
      result[`customerField${item.id}`] = {
        value: item.value || (bookerField && bookerField.value) || attributeFields[item.id] || externalAttributeFields[item.externalId] || ''
      }
      if (item.type === 'CHECKBOX') {
        if (!item.value && !(bookerField && bookerField.value) && (attributeFields[item.id] || externalAttributeFields[item.externalId])) {
          result[`customerField${item.id}`].value = true
        } else {
          result[`customerField${item.id}`].value = (item.value || (bookerField && bookerField.value) || null) + '' === 'true'
        }
      }
      if (item.type === 'SELECT') {
        result[`customerField${item.id}`].options = item.selectOptions || attributeFields[item.id] || externalAttributeFields[item.externalId]
      }
      if (item.type === 'PHONE') {
        result[`customerField${item.id}`] = {
          phone: (item.value && item.value.number) || (bookerField && bookerField.value && bookerField.value.number) || (booker && booker.phone && booker.phone.number) || '',
          phoneCountry: (item.value && item.value.country) || (bookerField && bookerField.value && bookerField.value.country) || (booker && booker.phone && booker.phone.country) || countryPhoneCode || DEFAULT_LOCALE_COUNTRY_CODE
        }
      }
      if (item.type === 'FILE' && item.defaultId === 'avatar') {
        result[`customerField${item.id}`] = {
          avatarUrl: { value: item.value || '' },
          value: ''
        }
      }
      if (item.type === 'ADDRESS') {
        const address = item.value || (bookerField && bookerField.value) || {}
        result[`customerField${item.id}`].value = address.formatted
        result[`customerField${item.id}`].data = ((address.formatted && address.formatted !== '') && {
          placeId: address.placeId || '',
          city: address.city || '',
          streetName: address.street || '',
          streetNumber: address.streetNumber || '',
          postalCode: address.zipCode || '',
          country: address.country || '',
          lat: address.latitude || '',
          lng: address.longitude || '',
          formattedAddress: address.formatted || '',
          details: address.details || ''
        }) || null
        result[`customerField${item.id}Secondary`] = {
          value: address.details || ''
        }
      }
      if (item.type === 'FILES') {
        result[`customerField${item.id}`] = {
          selected: (item.values || []).map(file => JSON.parse(file)),
          valuesAdd: [],
          valuesRemove: [],
          value: ''
        }
      }
      result[`customerField${item.id}`].type = item.type
      result[`customerField${item.id}`].defaultId = item.defaultId
      result[`customerField${item.id}`].isMandatory = item.isMandatoryOnline
      result[`customerField${item.id}`].translationKey = item.translationKey
      result[`customerField${item.id}`].label = item.label
    })

  return result
}

// VALIDATION

export const serviceCustomerFieldFormValidate = (customer) => {
  if (!customer) return

  const combinedFields = Object.keys(customer).reduce((acc, key) => {
    if (key.indexOf('customerField') !== -1) {
      acc.push({
        ...customer[key],
        name: key
      })
    }
    return acc
  }, [])

  let fieldErrors = []
  const rules = []
  const messages = {}
  const replaces = {}
  const atLeastOneIsRequired = []
  const atLeastOneIsRequiredValues = []
  const atLeastOneIsRequiredFields = []

  combinedFields.forEach((field, index) => {
    const name = field.name
    const defaultId = (field.defaultId && field.defaultId) || ''
    const type = field.type && field.type
    const value = field.value && field.value
    const isMandatory = !!field.isMandatory
    const translationKey = field.translationKey || field.label || 'global.theField'
    switch (type) {
      case 'FILE':
        if (defaultId.toLowerCase() === 'avatar') {
          if (isMandatory) {
            const isAvatarSet = !!(field.avatarUrl && field.avatarUrl.value && field.avatarUrl.value !== '')
            rules.push({ [`${name}.avatarFile`]: [`requiredIf:${!isAvatarSet}`] })
          }
          messages[name] = {
            'requiredIf': 'errors.required'
          }
          replaces[name] = {
            'requiredIf': { key: 'FIELD_NAME', value: translationKey, translateReplace: field.translationKey || !field.label }
          }
        }
        break

      case 'TEXT':
        if (atLeastOneIsRequired.includes(defaultId) && !atLeastOneIsRequiredValues.includes(value)) {
          if (value && value.trim() !== '') atLeastOneIsRequiredValues.push(value)
          atLeastOneIsRequiredFields.push(name)
        } else if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })

        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          'max': 'errors.invalidMaxLength',
          'required': 'errors.required'
        }
        replaces[name] = {
          'max': { key: 'MAX', value: 255 },
          'required': { key: 'FIELD_NAME', value: translationKey, translateReplace: field.translationKey || !field.label }
        }
        break

      case 'TEXTAREA':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:400'] })
        messages[name] = {
          'max': 'errors.invalidMaxLength',
          'required': 'errors.required'
        }
        replaces[name] = {
          'max': { key: 'MAX', value: 400 },
          'required': { key: 'FIELD_NAME', value: translationKey, translateReplace: field.translationKey || !field.label }
        }
        break

      case 'SELECT':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          'max': 'errors.invalidMaxLength',
          'required': 'errors.required'
        }
        replaces[name] = {
          'max': { key: 'MAX', value: 255 },
          'required': { key: 'FIELD_NAME', value: translationKey, translateReplace: field.translationKey || !field.label }
        }
        break

      case 'DATE':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          'max': 'errors.invalidMaxLength',
          'required': 'errors.required'
        }
        replaces[name] = {
          'max': { key: 'MAX', value: 255 },
          'required': { key: 'FIELD_NAME', value: translationKey, translateReplace: field.translationKey || !field.label }
        }
        break

      case 'CHECKBOX':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:64'] })
        messages[name] = {
          'max': 'errors.invalidMaxLength',
          'required': 'errors.required'
        }
        replaces[name] = {
          'max': { key: 'MAX', value: 64 },
          'required': { key: 'FIELD_NAME', value: translationKey, translateReplace: field.translationKey || !field.label }
        }
        break

      case 'EMAIL':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['email', 'max:255'] })
        messages[name] = {
          'email': 'errors.email.invalid',
          'max': 'errors.invalidMaxLength',
          'required': 'errors.required'
        }
        replaces[name] = {
          'max': { key: 'MAX', value: 255 },
          'required': { key: 'FIELD_NAME', value: translationKey, translateReplace: field.translationKey || !field.label }
        }
        break

      case 'PHONE':
        if (isMandatory) rules.push({ [`${name}.phone`]: ['required'] })
        rules.push({ [`${name}.phone`]: ['phone', 'max:64'] })
        messages[name] = {
          'phone': 'errors.phone.invalid',
          'max': 'errors.invalidMaxLength',
          'required': 'errors.required'
        }
        replaces[name] = {
          'max': { key: 'MAX', value: 64 },
          'required': { key: 'FIELD_NAME', value: translationKey, translateReplace: field.translationKey || !field.label }
        }
        break

      case 'ADDRESS':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required', 'max:800'] })
        const isAddressSet = (customer[name] && customer[name].value && customer[name].value !== '')
        if (isAddressSet) {
          rules.push({ [`${name}.data.city`]: [`requiredIf:${isAddressSet}`] })
          rules.push({ [`${name}.data.streetName`]: [`requiredIf:${isAddressSet}`] })
          // rules.push({ [`${name}.data.streetNumber`]: [`requiredIf:${isAddressSet}`] })
          // rules.push({ [`${name}.data.postalCode`]: [`requiredIf:${isAddressSet}`] })
        }

        let addressErrorMessage = 'errors.address.required'

        if (isAddressSet && customer[name] && customer[name].data) {
          if (!customer[name].data.city || customer[name].data.city === '') {
            addressErrorMessage = 'errors.address.fillCity'
          } else if (!customer[name].data.streetName || customer[name].data.streetName === '') {
            addressErrorMessage = 'errors.address.fillStreetName'
          }
          // else if (!customer[name].data.postalCode || customer[name].data.postalCode === '') {
          //   addressErrorMessage = 'errors.address.fillZipCode'
          // }
          // else if (!customer[name].data.streetNumber || customer[name].data.streetNumber === '') {
          //   addressErrorMessage = 'errors.address.fillStreetNumber'
          // }
        }

        messages[name] = {
          'requiredIf': addressErrorMessage,
          'max': 'errors.invalidMaxLength'
        }
        replaces[name] = {
          'max': { key: 'MAX', value: 800 },
          'required': { key: 'FIELD_NAME', value: translationKey, translateReplace: field.translationKey || !field.label }
        }
        break

      case 'FILES':
        if (isMandatory) rules.push({ [`${name}.values`]: [`requiredIf:${(field.selected || []).length === 0}`] })
        messages[name] = {
          'requiredIf': 'errors.required'
        }
        replaces[name] = {
          'requiredIf': { key: 'FIELD_NAME', value: translationKey, translateReplace: field.translationKey || !field.label }
        }
        fieldErrors = [...fieldErrors, ...(field.errors || [])]
        break

      default:
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })

        messages[name] = {
          'max': 'invalidMaxLength',
          'required': 'errors.required'
        }
        break
    }
  })

  let errors = validator(customer, rules, messages, replaces)
  if (fieldErrors.length > 0) {
    fieldErrors = fieldErrors.filter(item => !(errors || []).find(error => error.key === item.key)) // Remove duplicated errors
    errors = [...errors, ...fieldErrors]
  }

  if (atLeastOneIsRequiredValues.length === 0) {
    atLeastOneIsRequiredFields.forEach(item => {
      errors.push({
        key: item,
        value: 'errors.requiredOne'
      })
    })
  }

  return errors.length && errors
}
