import React from 'react'
import { store } from '../../Store'

const Price = props => {
  const state = store.getState()
  const { companies } = state
  const { list: companiesList = [], selectedId: selectedCompanyId = null } = companies
  const selectedCompany = companiesList.find(company => company.id === selectedCompanyId)
  const DEFAULT_CURRENCY = 'EUR'
  const DEFAULT_LOCALE = 'en-gb'
  const {
    currency = (selectedCompany && selectedCompany.currency) || DEFAULT_CURRENCY,
    locale = (selectedCompany && selectedCompany.locale) || DEFAULT_LOCALE,
    price = 0,
    cleanHtml,
    className
  } = props
  const options = {
    style: 'currency',
    currency: currency && currency.toUpperCase(),
    currencyDisplay: 'symbol'
  }
  const formatedPrice = new Intl.NumberFormat(locale, options).format(price)
  const classNames = []
  if (className) classNames.push(className)

  if (cleanHtml) return <>{formatedPrice}</>

  return <span className={classNames.join(' ')}>{formatedPrice}</span>
}

export default Price
