import React, { Component } from 'react'
import { connect } from '../../Store'
import { ProgressBar } from '../../Beauties'

class LocationsWrapper extends Component {
  render () {
    const { routeName } = this.props
    let step = 0

    if (routeName === 'services') step = 1
    else if (routeName === 'availability') step = 2
    else if (['auth', 'authLogin', 'authRegister', 'authForgotten', 'authConfirmation', 'authChangePhone', 'authGuestBooking', 'authEnforcePasswordChange', 'customerFields'].includes(routeName)) step = 3
    else if (routeName === 'confirm') step = 4
    else if (routeName === 'success') step = 5

    return (
      <ProgressBar
        step={step}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || ''
})

export default connect(maps)(LocationsWrapper)
