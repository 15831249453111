import md5 from 'md5'
import { isIE } from 'react-device-detect'
import { getInitialsByName } from '../Utils'
// import { EMOJIS_REGEX } from '../Settings'

export default (name = '') => {
  const initials = getInitialsByName(name)
  // const clearedFromEmojis = !isIE ? initials.replace(new RegExp(EMOJIS_REGEX, 'ug'), '') : initials
  const clearedFromEmojis = !isIE ? initials.replace(/[^\w.,\s]/g, '') : initials

  return '#' + md5(clearedFromEmojis).substring(0, 6)
}
