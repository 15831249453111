import React from 'react'

import './NextStep.css'

const NextStep = props => {
  const { children, isActive, className } = props
  const classNames = ['ta-next-step']
  if (isActive) classNames.push('active')
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  )
}

export default NextStep
