import moment from 'moment-timezone'

// checks if the vouchers are available for the selected slot in the selected timezone
export default ({ vouchers, info, selectedTimezone, serviceId, courseId } = {}) => {
  if (!serviceId && !courseId) return false
  if (!info?.slot) return false
  const { slot } = info
  let { day, minutes } = slot || {}
  if (!day) return false
  minutes = minutes || 0
  vouchers = (vouchers || []).filter((voucher) => (voucher.serviceIds || []).includes(serviceId) || (voucher.courseIds || []).includes(courseId))
  if (!vouchers.length) return false
  selectedTimezone = selectedTimezone || {}
  const timezone = selectedTimezone.label || 'UTC'
  return vouchers.some(({ publishFrom, publishUntil }) => {
    const slotAt = moment.tz(day, 'YYYY-MM-DD', timezone).add(minutes, 'minute').toDate()
    const fromAt = moment.utc(publishFrom, 'YYYY-MM-DD HH:mm').toDate()
    const untilAt = moment.utc(publishUntil, 'YYYY-MM-DD HH:mm').toDate()
    return slotAt >= fromAt && slotAt <= untilAt
  })
}
