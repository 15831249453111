import React, { useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { handlers, store } from '../../Store'
import { t, Form, Error, Row, Col, NextStep, Link } from '../../Common'
import { STRIPE_REDIRECT_URL } from '../../Settings'

const StripeElements = props => {
  const { showFooterBackButton } = props
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState('')
  const classNames = ['ta-cta', 'ta-cta-primary']
  if (!stripe) classNames.push('disabled')

  const getErrorTranslationKey = (code) => {
    let result = 'errors.somethingWentWrong'
    if (code === 'incomplete_number') result = ''
    else if (code === 'incomplete_expiry') result = ''
    else if (code === 'incomplete_cvc') result = ''
    else if (['invalid_expiry_month_past', 'invalid_expiry_year', 'invalid_expiry_year_past'].includes(code)) result = 'errors.invalidExpiry'
    else if (code === 'invalid_number') result = 'errors.invalidNumber'
    else if (code === 'incomplete_zip') result = 'errors.incompleteZip'
    else if (code === 'card_declined') result = 'errors.cardDeclined'
    else if (code === 'expired_card') result = 'errors.expiredCard'
    else if (code === 'processing_error') result = 'errors.processingError'
    else if (code === 'payment_intent_authentication_failure') result = 'errors.paymentIntentAuthenticationFailure'

    return result
  }

  const handleSubmit = async () => {
    handlers.appLoaderShow()
    setError('')
    if (!stripe || !elements) return
    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: STRIPE_REDIRECT_URL
      }
    })
    if (result.error) {
      setError(getErrorTranslationKey(result.error.code))
      handlers.appLoaderHide()
      return
    }
    const state = store.getState()
    if (state?.booking?.isPending) return
    handlers.bookingSave()
  }
  const onBackClick = () => {
    document.getElementsByClassName('ta-header__back_button')[0].click()
  }

  return (
    <Form name='stripeElements'>
      <PaymentElement onReady={() => handlers.appLoaderHide()} />
      {error && (
        <Row>
          <Col>
            <Error name='globalErrors' error={{ value: error }} />
          </Col>
        </Row>
      )}
      <div className='ta-footer active'>
        <NextStep
          isActive
          className={showFooterBackButton ? 'has-back-button' : ''}
        >
          {!!showFooterBackButton &&
            <button onClick={onBackClick} className='ta-cta ta-cta-secondary ta-footer__back-button'>
              {t('footer.button.back.label')}
            </button>
          }
          <Link>
            <button onClick={() => handleSubmit()} className={classNames.join(' ')}>
              {t('payments.checkout.buttonLabel')}
            </button>
          </Link>
        </NextStep>
      </div>
    </Form>
  )
}

export default StripeElements
