import React from 'react'

import './BorderedBox.css'

const BorderedBox = props => {
  const { children, topOffset, style } = props
  const classNames = ['ta-bordered-box']
  if (topOffset) classNames.push('top-offset')

  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  )
}

export default BorderedBox
