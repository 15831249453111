/* global google */
import React, { Component } from 'react'
import { connect, handlers } from '../../Store'
import { Locations } from '../../Beauties'
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete'
import { sortBy } from '../../Utils'
class LocationsWrapper extends Component {
  constructor (props) {
    super(props)
    this.state = {
      position: {
        lat: 48.1174978,
        lng: 11.5997428
      },
      zoom: 15,
      hasInitialPosition: false,
      hasSelectedPlace: false,
      bounds: null,
      searchValue: ''
    }
    this.onMapUpdate = this.onMapUpdate.bind(this)
    this.onPlaceSelect = this.onPlaceSelect.bind(this)
    this.onPlaceChange = this.onPlaceChange.bind(this)
    this.onPlaceClear = this.onPlaceClear.bind(this)
  }

  componentDidMount () {
  }

  onMapUpdate (map) {
    if (!map) return
    this._map = map
    const { hasInitialPosition } = this.state
    const { companies = [] } = this.props
    const bounds = map.getBounds()
    let zoom = map.getZoom()

    if (!hasInitialPosition && companies.length > 0) {
      const initialBounds = new google.maps.LatLngBounds()
      companies.map(company => {
        const { address } = company
        const { latitude = null, longitude = null } = address || {}

        if (latitude && longitude) initialBounds.extend(new google.maps.LatLng(latitude, longitude))
        return null
      })

      map.fitBounds(initialBounds)
      zoom = map.getZoom()
      this.setState({
        position: {
          lat: initialBounds.getCenter().lat(),
          lng: initialBounds.getCenter().lng()
        },
        zoom,
        hasInitialPosition: true
      })
    } else {
      this.setState({
        position: {
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng()
        },
        zoom,
        bounds
      })
    }
  }

  async onPlaceSelect (address, placeId) {
    const results = await geocodeByPlaceId(placeId)
    if (results && results[0]) {
      const latLng = await getLatLng(results[0])
      this.setState({
        position: latLng,
        zoom: 10,
        hasSelectedPlace: true
      })
      this.onMapUpdate(this._map)
    }
  }

  async onPlaceClear () {
    const { companies = [] } = this.props

    if (companies.length > 0) {
      const initialBounds = new google.maps.LatLngBounds()
      companies.map(company => {
        const { address } = company
        const { latitude = null, longitude = null } = address || {}

        if (latitude && longitude) initialBounds.extend(new google.maps.LatLng(latitude, longitude))
        return null
      })

      this._map.fitBounds(initialBounds)
      const zoom = this._map.getZoom()
      await this.setState({
        position: {
          lat: initialBounds.getCenter().lat(),
          lng: initialBounds.getCenter().lng()
        },
        zoom,
        searchValue: '',
        hasSelectedPlace: false
      })
    } else {
      await this.setState({
        searchValue: '',
        hasSelectedPlace: false
      })
    }

    this.onMapUpdate(this._map)
  }

  onPlaceChange (value) {
    this.setState({
      searchValue: value
    })
  }

  render () {
    const {
      routeName,
      companies,
      showOnlyLocations,
      hideLocations
    } = this.props
    const { position, bounds, searchValue, zoom, hasSelectedPlace } = this.state
    const isActive = routeName === 'locations'
    const isPrevious = ['services', 'availability', 'auth', 'authLogin', 'authRegister', 'authForgotten', 'authConfirmation', 'authChangePhone', 'authGuestBooking', 'authEnforcePasswordChange', 'customerFields', 'confirm', 'success'].includes(routeName)
    const filteredWithParamsCompanies = companies
      .filter(item => (showOnlyLocations.length === 0 || showOnlyLocations.includes(item.id)) && !hideLocations.includes(item.id))
    const sortedCompanies = filteredWithParamsCompanies.sort(sortBy('name'))
    const companiesCountries = []
    const companiesList = sortedCompanies.reduce((acc, company) => {
      const { address } = company
      const { latitude = null, longitude = null } = address || {}
      const city = (address && address.city) || 'z'

      if (!acc[city]) acc[city] = []

      if (!bounds || (latitude && longitude && bounds.contains({ lat: latitude, lng: longitude }))) {
        acc[city].push(company)
      }

      const country = company.locale.substring(3, 5)
      if (!companiesCountries.includes(country)) companiesCountries.push(country)

      return acc
    }, {})
    const filteredCompanies = Object.entries(companiesList).reduce((acc, item) => {
      if (item[1].length > 0) {
        acc[item[0]] = item[1]
      }
      return acc
    }, {})
    const orderedFilteredCompanies = {}
    Object.keys(filteredCompanies).sort().forEach(key => {
      orderedFilteredCompanies[key] = filteredCompanies[key]
    })

    const mapMarkers = companies
      .filter(item => item.address && item.address.latitude && item.address.longitude && item.address.longitude)
      .map(item => {
        return {
          lat: item.address.latitude,
          lng: item.address.longitude,
          onClick: () => {
            handlers.companySelect(item.id, true)
          }
        }
      })

    return (
      <Locations
        isActive={isActive}
        isPrevious={isPrevious}
        showMap={filteredWithParamsCompanies.length > 10}
        mapPosition={position}
        onMapUpdate={this.onMapUpdate}
        onPlaceSelect={this.onPlaceSelect}
        hasSelectedPlace={hasSelectedPlace}
        onPlaceChange={this.onPlaceChange}
        onPlaceClear={this.onPlaceClear}
        searchValue={searchValue}
        companiesList={orderedFilteredCompanies}
        companiesCountries={companiesCountries}
        mapMarkers={mapMarkers}
        mapZoom={zoom}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  companies: state.enterprise.companies || [],
  showOnlyLocations: state.attributes.showLocations || [],
  hideLocations: state.attributes.hideLocations || []
})

export default connect(maps)(LocationsWrapper)
