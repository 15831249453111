import React from 'react'
import PropTypes from 'prop-types'

import './LinedTitle.css'

const LinedTitle = props => {
  const { label } = props

  return (
    <div className='ta-lined-title'>
      <div className='ta-lined-title__label'>
        {label}
      </div>
    </div>
  )
}

LinedTitle.propTypes = {
  label: PropTypes.string.isRequired
}

export default LinedTitle
