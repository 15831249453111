import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FormGroup,
  Error,
  FontAwesome5,
  getCustomDateFormat,
  getCustomTimeFormat
} from '../../../Common'

import 'react-datepicker/dist/react-datepicker.css'
import './DateInput.css'

class DateInput extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange (momentDate) {
    const { formName, name, form } = this.props
    const value = (momentDate && momentDate.format('YYYY-MM-DD HH:mm')) || null
    this.setState({ filled: !!value })
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
  }

  render () {
    const { focused } = this.state
    const {
      className,
      label,
      name,
      hintText,
      dateFormat,
      timeFormat,
      timeInterval,
      showTimes,
      showMonth,
      showYear,
      mandatory,
      disabled,
      hideError,
      icon,
      iconType = 'regular',
      timezone,
      form = {},
      maxDate = null
    } = this.props
    let { placeholder = form.placeholder } = this.props
    if (!focused && form.value === '') placeholder = label || form.label
    if (mandatory || form.mandatory) placeholder = `${placeholder} *`
    const value = form.value ? moment.tz(form.value, 'YYYY-MM-DD HH:mm', timezone) : null
    const classNames = ['ta-date-input']
    if (showMonth || showYear) classNames.push('has-dropdown')
    if (showTimes) classNames.push('has-times')

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={className}>
        <FormGroup
          focused={focused}
          filled={!!form.value}
          disabled={disabled || form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory || form.mandatory}
        >
          <div className={classNames.join(' ')}>
            <DatePicker
              ref={ref => { this.ref = ref }}
              className='ta-form-control'
              name={name}
              selected={value}
              placeholderText={placeholder}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              dateFormat={getCustomDateFormat() || dateFormat || (showTimes ? 'L LT' : 'L')}
              showTimeSelect={showTimes || false}
              shouldCloseOnSelect={!showTimes}
              timeFormat={getCustomTimeFormat() || timeFormat || 'LT'}
              timeIntervals={timeInterval || 15}
              showMonthDropdown={showMonth || false}
              showYearDropdown={showYear || false}
              scrollableYearDropdown={showYear || false}
              dropdownMode='select'
              autoComplete='off'
              // yearDropdownItemNumber={100}
              disabled={disabled || form.disabled}
              minDate={moment.tz(timezone).startOf('year').subtract(100, 'years')}
              maxDate={maxDate || moment.tz(timezone).add(10, 'years')}
            />
          </div>
          {icon &&
            <div className='ta-form-control__addon'>
              <FontAwesome5 icon={icon} type={iconType} />
            </div>
          }
          {hintText &&
            <div className='ta-form-control__hint'>{hintText}</div>
          }
          {!hideError &&
            <Error name={name} />
          }
        </FormGroup>
      </div>
    )
  }
}

DateInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  hintText: PropTypes.string,
  format: PropTypes.string,
  showMonth: PropTypes.bool,
  showYear: PropTypes.bool,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object,
  className: PropTypes.string
}

const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' }
})

export default feedContextInProps(connect(maps)(DateInput), FormContext)
