export default (fileType) => {
  if (!fileType) return ''
  fileType = fileType.toLowerCase()
  if (fileType === 'jpg') return `image/jpeg`
  if (['jpeg', 'png', 'gif', 'heic', 'heif'].includes(fileType)) return `image/${fileType}`
  if (['zip', 'pdf'].includes(fileType)) return `application/${fileType}`
  if (fileType === 'xlsx') return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  if (fileType === 'docx') return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  if (fileType === 'pptx') return 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
}
