import React from 'react'
import PropTypes from 'prop-types'
import { t, Row, Col, FontAwesome5, DashedTitle, AddToCalendar } from '../../Common'
import { MRSPEX_ENTERPRISE_ID } from '../../Settings'
import { dangerousHTML } from '../../Utils'

import './Success.css'

const Success = props => {
  const {
    isActive,
    appMode,
    attributes,
    company,
    event,
    booking,
    companyEnterpriseId,
    widgetExternalUrlRedirect,
    redirectSeconds,
    onMrSpexVoucherClick,
    successMessage
  } = props
  const classNames = ['ta-step', 'ta-success']
  let { icsText = null, icsData } = booking
  icsData = icsData || {}
  const { addOns = [], tradingName: companyTradingName, name: companyName } = company || {}
  const { isEnterprise = false } = attributes
  if (isActive) classNames.push('active')
  const containerClassNames = ['ta-success__container']
  if (!isEnterprise || !addOns || !addOns.includes('MRSPEX') || !companyEnterpriseId || MRSPEX_ENTERPRISE_ID.includes(companyEnterpriseId)) containerClassNames.push('clean-container')
  const listItems = [
    { apple: t('success.preview.addToCalendar.apple') },
    { google: t('success.preview.addToCalendar.google') },
    { outlook: t('success.preview.addToCalendar.outlook') },
    { outlookcom: t('success.preview.addToCalendar.outlookCom') },
    { yahoo: t('success.preview.addToCalendar.yahoo') }
  ]

  return (
    <div className={classNames.join(' ')}>
      {isActive && (
        <>
          <div className={containerClassNames.join(' ')}>
            <div className='ta-success__inner'>
              <FontAwesome5 icon='check' type='solid' className='ta-success__icon' />
              {widgetExternalUrlRedirect && (
                <>
                  <DashedTitle label={t('success.preview.redirect.info.title')} className='ta-success_title' />
                  <div className='ta-text-box center'>
                    {t('success.preview.redirect.info.text', [{ key: 'SECONDS', value: redirectSeconds || '0' }])}
                  </div>
                </>
              )}
              {!widgetExternalUrlRedirect && (
                <>
                  {appMode !== 'RESCHEDULE' && (
                    <>
                      <DashedTitle label={t('success.preview.section.info.title')} className='ta-success_title' />
                      <div className='ta-text-box center'>
                        {dangerousHTML(t(successMessage, [{ key: 'COMPANY_NAME', value: companyTradingName || companyName || '' }]))}
                      </div>
                    </>
                  )}
                  {appMode === 'RESCHEDULE' && (
                    <>
                      <DashedTitle label={t('success.preview.section.info.reschedule.title')} className='ta-success_title' />
                      <div className='ta-text-box center'>
                        {dangerousHTML(t('success.preview.section.info.reschedule.text', [{ key: 'COMPANY_NAME', value: companyTradingName || companyName || '' }]))}
                      </div>
                    </>
                  )}
                  <AddToCalendar
                    event={event}
                    displayItemIcons={false}
                    buttonLabel={t('success.preview.section.button.addToCalendar')}
                    buttonTemplate={{ 'calendar-plus-o': 'left' }}
                    listItems={listItems}
                    icsText={icsText || ''}
                    icsData={icsData}
                  />
                  <div style={{ display: 'none' }}>
                    <Row>
                      <Col size={50}>
                        <button className='ta-cta-third'>
                          <FontAwesome5 icon='calendar-alt' type='solid' />
                          Add booking to...
                        </button>
                      </Col>
                      <Col size={50}>
                        <button className='ta-cta-primary'>
                          Make another booking
                        </button>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='ta-success__footer' style={{ display: 'none' }}>
            <DashedTitle label='Want to make yourself or your business online bookable?' />
            <div className='ta-text-box center'>
              Then open a TIMIFY business account! It’s free, easy to use and your customers will thank you for it!
            </div>
            <button className='ta-cta-primary ta-cta-fluid'>
              Open an account now
            </button>
          </div>

          {(isEnterprise && addOns && addOns.includes('MRSPEX') && companyEnterpriseId && !MRSPEX_ENTERPRISE_ID.includes(companyEnterpriseId) &&
            <div className='ta-success__footer'>
              <img src='images/mrspex_voucher.png' alt='' className='ta-success__footer-icon' />
              <DashedTitle label={t('misterspex.voucher.title')} />
              <div className='ta-text-box center'>
                {t('misterspex.voucher.text')}
              </div>
              <button onClick={onMrSpexVoucherClick} className='ta-cta-primary ta-cta-fluid'>
                {t('misterspex.voucher.button.label')}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

Success.propTypes = {
  isActive: PropTypes.bool,
  onMrSpexVoucherClick: PropTypes.func
}

export default Success
