import React from 'react'

import { dangerousHTML } from '../../../Utils'

const SimpleListItemDescription = props => {
  const { children, onClick } = props

  return (
    <div className='ta-simple-list-item__description' onClick={onClick}>
      {dangerousHTML(children, false, true)}
    </div>
  )
}

export default SimpleListItemDescription
