import { DEFAULT_LOCALE } from '../../../Settings'

const reducer = {}

reducer.initialState = {
  isLoaded: false,
  isDataLoaded: false,
  isPending: true,
  isPendingLanguage: true,
  isLanguageOpen: false,
  isLocaleChanged: false,
  isConsentBoxCookieHidden: true,
  isInitDone: false,
  mode: 'NORMAL',
  IP: null,
  userAgent: null,
  isGuestBookingOpened: false,
  isCookieConsentMessageActive: true,
  isCookieConsentMessageMaximised: false,
  customisationCSS: false
}

reducer.handlers = (dispatch, actions) => ({
  // App
  appLoaded: () => dispatch(actions.APP_LOADED),
  setSessionId: sessionId => dispatch(actions.SET_SESSION_ID, sessionId),
  appDataLoaded: () => dispatch(actions.APP_DATA_LOADED),
  appInit: () => dispatch(actions.APP_INIT),
  appInitReady: () => dispatch(actions.APP_INIT_READY),
  appRun: () => dispatch(actions.APP_RUN),
  appReset: () => dispatch(actions.APP_RESET),
  appRegisterClient: (payload) => dispatch(actions.APP_REGISTER_CLIENT, payload),
  appLoaderShow: () => dispatch(actions.APP_LOADER_SHOW),
  appLoaderHide: () => dispatch(actions.APP_LOADER_HIDE),
  appUpdateMode: mode => dispatch(actions.APP_UPDATE_MODE, mode),
  appOpenGuestBooking: () => dispatch(actions.APP_OPEN_GUEST_BOOKING),
  appConsentMessageToggle: () => dispatch(actions.APP_CONSENT_MESSAGE_TOGGLE),
  appConsentMessageShow: () => dispatch(actions.APP_CONSENT_MESSAGE_SHOW),
  appConsentMessageHide: () => dispatch(actions.APP_CONSENT_MESSAGE_HIDE),
  appCloseGuestBooking: () => dispatch(actions.APP_CLOSE_GUEST_BOOKING),
  appCustomisationCSS: (customisationCSS) => dispatch(actions.APP_CUSTOMISATION_CSS, customisationCSS),
  appTrackingInit: () => dispatch(actions.APP_TRACKING_INIT),
  appTrackingCheck: () => dispatch(actions.APP_TRACKING_CHECK),
  appTrackEvent: (trackingId) => dispatch(actions.APP_TRACK_EVENT, trackingId),

  // Language
  languageChange: (locale, initial) => dispatch(actions.LANGUAGE_CHANGE, { locale, initial }),
  languageChangedManually: () => dispatch(actions.LANGUAGE_CHANGED_MANUALLY),
  languageLoaded: locale => dispatch(actions.LANGUAGE_LOADED, locale),
  languageOptionsOpen: isOpen => dispatch(actions.LANGUAGE_OPTIONS_OPEN, isOpen),

  // Window
  windowResize: (w) => dispatch(actions.WINDOW_RESIZE, w && {
    windowHeight: w.innerHeight,
    windowWidth: w.innerWidth
  }),

  // Consent cookies
  toggleCookieConsentMessageVisibility: () => dispatch(actions.TOGGLE_COOKIE_CONSENT_MESSAGE_VISIBILITY),
  toggleCookieConsentMessageCover: () => dispatch(actions.TOGGLE_COOKIE_CONSENT_MESSAGE_COVER)
})

// App

reducer.APP_LOADED = state => ({
  ...state,
  isLoaded: true
})
reducer.SET_SESSION_ID = (state, sessionId) => ({
  ...state,
  sessionId
})

reducer.APP_DATA_LOADED = state => ({
  ...state,
  isDataLoaded: true
})

reducer.APP_INIT = state => state

reducer.APP_INIT_READY = state => ({
  ...state,
  isInitDone: true
})

reducer.APP_RUN = state => state

reducer.APP_RESET = state => state

reducer.APP_LOADER_SHOW = state => ({
  ...state,
  isPending: true
})

reducer.APP_LOADER_HIDE = state => ({
  ...state,
  isPending: false
})

reducer.APP_UPDATE_MODE = (state, mode) => ({
  ...state,
  mode: mode
})

reducer.APP_OPEN_GUEST_BOOKING = (state) => ({
  ...state,
  isGuestBookingOpened: true
})

reducer.APP_CLOSE_GUEST_BOOKING = (state) => ({
  ...state,
  isGuestBookingOpened: false
})

reducer.APP_CUSTOMISATION_CSS = (state, customisationCSS) => ({
  ...state,
  customisationCSS: customisationCSS
})

reducer.APP_CONSENT_MESSAGE_TOGGLE = (state) => state

reducer.APP_CONSENT_MESSAGE_SHOW = (state) => ({
  ...state,
  isConsentBoxCookieHidden: false
})

reducer.APP_CONSENT_MESSAGE_HIDE = (state) => ({
  ...state,
  isConsentBoxCookieHidden: true
})

// Language

reducer.LANGUAGE_CHANGE = state => ({
  ...state,
  isPendingLanguage: true
})

reducer.LANGUAGE_CHANGED_MANUALLY = state => ({
  ...state,
  isLocaleChanged: true
})

reducer.LANGUAGE_LOADED = (state, locale = DEFAULT_LOCALE) => ({
  ...state,
  locale,
  isPendingLanguage: false,
  isLanguageOpen: false
})

reducer.LANGUAGE_OPTIONS_OPEN = (state, isOpen = false) => ({
  ...state,
  isLanguageOpen: isOpen
})

// Window

reducer.WINDOW_RESIZE = (state, payload) => ({
  ...state,
  windowHeight: payload && payload.windowHeight,
  windowWidth: payload && payload.windowWidth
})

// Consent cookies
reducer.TOGGLE_COOKIE_CONSENT_MESSAGE_VISIBILITY = (state) => ({
  ...state,
  isCookieConsentMessageActive: !state.isCookieConsentMessageActive
})

reducer.TOGGLE_COOKIE_CONSENT_MESSAGE_COVER = (state) => ({
  ...state,
  isCookieConsentMessageMaximised: !state.isCookieConsentMessageMaximised
})

reducer.APP_TRACKING_INIT = state => state
reducer.APP_TRACKING_CHECK = state => state
reducer.APP_TRACK_EVENT = (state, trackingId) => ({ ...state, trackingId })
reducer.APP_REGISTER_CLIENT = (state, payload) => ({ ...state, ...payload })

export default reducer
