const reducer = {}

reducer.initialState = {
  isPending: false,
  clientSecret: null,
  expireAt: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  stripeReset: () => dispatch(actions.STRIPE_RESET),
  stripePaymentIntentGet: () => dispatch(actions.STRIPE_PAYMENT_INTENT_GET),
  stripePaymentIntentReset: () => dispatch(actions.STRIPE_PAYMENT_INTENT_RESET),
  stripePaymentIntentPopulate: (clientSecret, expireAt) => dispatch(actions.STRIPE_PAYMENT_INTENT_POPULATE, { clientSecret, expireAt })
})

reducer.STRIPE_RESET = state => reducer.initialState

reducer.STRIPE_PAYMENT_INTENT_GET = state => ({
  ...state,
  isPending: true
})

reducer.STRIPE_PAYMENT_INTENT_RESET = state => state

reducer.STRIPE_PAYMENT_INTENT_POPULATE = (state, { clientSecret, expireAt }) => ({
  ...state,
  clientSecret,
  expireAt,
  isPending: false
})

export default reducer
