const reducer = {}

reducer.initialState = {
  isActive: false,
  isOpenMobile: false,
  categories: [],
  services: [],
  selectedIds: [],
  selectedOriginalServiceId: null
}

reducer.handlers = (dispatch, actions) => ({
  upsellingPopulate: ({ categories, services }) => { dispatch(actions.UPSELLING_POPULATE, { categories, services }) },
  upsellingActive: isActive => { dispatch(actions.UPSELLING_ACTIVE, isActive) },
  upsellingOpen: isOpen => { dispatch(actions.UPSELLING_OPEN, isOpen) },
  upsellingClear: () => { dispatch(actions.UPSELLING_CLEAR) },
  upsellingSelectedIds: (selectedIds) => { dispatch(actions.UPSELLING_SELECTED_IDS, selectedIds) },
  upsellingSelectedOriginalServiceId: (selectedId) => { dispatch(actions.UPSELLING_SELECTED_ORIGINAL_SERVICE_ID, selectedId) }
})

reducer.UPSELLING_POPULATE = (state, { categories, services }) => ({
  ...state,
  categories: categories || [],
  services: services || []
})

reducer.UPSELLING_ACTIVE = (state, isActive) => ({
  ...state,
  isActive: isActive
})

reducer.UPSELLING_OPEN = (state, isOpen) => ({
  ...state,
  isOpenMobile: isOpen
})

reducer.UPSELLING_CLEAR = state => ({
  ...state,
  isActive: false,
  isOpenMobile: false,
  selectedIds: []
})

reducer.UPSELLING_SELECTED_IDS = (state, selectedIds) => ({
  ...state,
  selectedIds: selectedIds || []
})

reducer.UPSELLING_SELECTED_ORIGINAL_SERVICE_ID = (state, selectedId) => ({
  ...state,
  selectedOriginalServiceId: selectedId || null
})

export default reducer
