import React from 'react'
import PropTypes from 'prop-types'
import { t, FontAwesome5, DashedTitle } from '../../Common'

import './Conflict.css'

const Conflict = props => {
  const { isActive, conflictError, isBooker, selectedItem, onSignOut } = props
  const classNames = ['ta-step', 'ta-conflict']
  if (isActive) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      {isActive && (
        <div className='ta-conflict__container'>
          <div className='ta-conflict__inner'>
            {(conflictError && conflictError === 'AlreadyBooked' &&
              <div className='ta-conflict__already-booked'>
                <FontAwesome5 icon='lightbulb' type='solid' className='ta-conflict__icon' />
                <DashedTitle label={t('conflict.preview.section.already_booked.title')} className='ta-popup__main_title' />
                {t('conflict.preview.section.already_booked.text')}
                {(isBooker &&
                  <div className='ta-conflict__signed'>
                    <div className='ta-conflict__text'>
                      {t('conflict.preview.section.already_booked.booker.text')}
                    </div>

                    <button
                      onClick={onSignOut}
                      className='ta-cta ta-cta-third ta-cta-fluid'
                    >
                      {t('conflict.preview.section.already_booked.booker.button.label')}
                    </button>
                  </div>
                )}
              </div>
            )}
            {((!conflictError || conflictError !== 'AlreadyBooked') &&
              <>
                <FontAwesome5 icon='exclamation-triangle' type='solid' className='ta-conflict__icon' />
                <DashedTitle label={t('conflict.preview.section.info.title')} className='ta-popup__main_title' />
                <div className='ta-text-box center'>
                  {conflictError && t(`errors.api.${conflictError.toLowerCase()}`)}
                  {!conflictError && t('conflict.preview.section.info.text')}
                  {(conflictError && conflictError === 'PaymentMustBeDone' && selectedItem && selectedItem.hasOnlinePayment && !selectedItem.isPaymentMandatory &&
                    <div>
                      {t('errors.paymentCreditCardProcessFailureOptional')}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

Conflict.propTypes = {
  isActive: PropTypes.bool
}

export default Conflict
