import React from 'react'
import PropTypes from 'prop-types'
import {
  t,
  FontAwesome5,
  DashedTitle,
  Row,
  Col,
  Form,
  Input,
  PhoneInput,
  Checkbox,
  Error
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

import './Register.css'

const Register = props => {
  const {
    locale,
    isActive,
    onCloseClick,
    onSubmit,
    fromLogin,
    company,
    customTermsAndConditionsUrl,
    customPrivacyPolicyUrl,
    passwordFieldType,
    togglePasswordFieldType
  } = props
  const classNames = ['ta-popup', 'ta-auth-register']
  if (isActive) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      {isActive && (
        <>
          <button onClick={onCloseClick} className='ta-popup-close'>
            <FontAwesome5 icon='times' type='light' />
          </button>
          <div className='ta-auth-register__form'>
            <div className='ta-auth-register__form__container'>

              <DashedTitle label={t('auth.register.section.heading.title')} className='ta-popup__main_title' />
              <div className='ta-text-box center'>
                {fromLogin && dangerousHTML(t('auth.register.section.heading.redirectedFromLoginNote.text'))}
                {!fromLogin && dangerousHTML(t('auth.register.section.heading.text'))}
              </div>

              <Form onSubmit={onSubmit} name='register'>
                <Row>
                  <Col size={50}>
                    <Input
                      mandatory
                      name='name'
                      label={t('global.name.label')}
                      placeholder={t('global.name.placeholder')}
                    />
                  </Col>
                  <Col size={50}>
                    <Input
                      mandatory
                      name='surname'
                      label={t('global.surname.label')}
                      placeholder={t('global.surname.placeholder')}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col size={100}>
                    <PhoneInput
                      mandatory
                      name='phone'
                      label={t('global.phone.label')}
                      placeholder={t('global.phone.placeholder')}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col size={50}>
                    <Input
                      mandatory
                      name='email'
                      label={t('global.email.label')}
                      placeholder={t('global.email.placeholder')}
                    />
                  </Col>
                  <Col className='relative' size={50}>
                    <Input
                      mandatory
                      name='password'
                      label={t('global.password.label')}
                      placeholder={t('global.password.placeholder')}
                      className='ta-auth-register__password-field'
                    />
                    <div
                      className='ta-auth-register__password-type-toggle-btn' onClick={togglePasswordFieldType}
                    >
                      <FontAwesome5 icon={passwordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                    </div>
                  </Col>
                </Row>

                <div className='ta-auth-register__checkbox'>
                  <Checkbox
                    name='terms'
                    label={dangerousHTML(t('checkbox.terms.label', [{
                      key: 'TERMS_CONDITIONS',
                      value: `<a href="https://www.timify.com/${locale}/pages/terms-of-use-smb/" target="_blank">${t('global.termsAndConditions')}</a>`
                    }, {
                      key: 'PRIVACY_POLICY',
                      value: `<a href="https://www.timify.com/${locale}/legal/" target="_blank">${t('global.privacyPolicy')}</a>`
                    }], undefined, true), false, true)}
                  />
                </div>

                <div className='ta-auth-register__checkbox'>
                  {(customTermsAndConditionsUrl && customPrivacyPolicyUrl &&
                    <Checkbox
                      name='companyTerms'
                      label={dangerousHTML(t('checkbox.company_terms_and_privacy.label', [{
                        key: 'COMPANY_NAME',
                        value: (company && company.name) || ''
                      }, {
                        key: 'TERMS_CONDITIONS',
                        value: `<a href="${customTermsAndConditionsUrl}" target="_blank">${t('global.termsAndConditions')}</a>`
                      }, {
                        key: 'PRIVACY_POLICY',
                        value: `<a href="${customPrivacyPolicyUrl}" target="_blank">${t('global.privacyPolicy')}</a>`
                      }], undefined, true), false, true)}
                    />
                  )}

                  {(customTermsAndConditionsUrl && !customPrivacyPolicyUrl &&
                    <Checkbox
                      name='companyTerms'
                      label={dangerousHTML(t('checkbox.company_terms.label', [{
                        key: 'COMPANY_NAME',
                        value: (company && company.name) || ''
                      }, {
                        key: 'TERMS_CONDITIONS',
                        value: `<a href="${customTermsAndConditionsUrl}" target="_blank">${t('global.termsAndConditions')}</a>`
                      }], undefined, true), false, true)}
                    />
                  )}

                  {(!customTermsAndConditionsUrl && customPrivacyPolicyUrl &&
                    <Checkbox
                      name='companyTerms'
                      label={dangerousHTML(t('checkbox.company_privacy.label', [{
                        key: 'COMPANY_NAME',
                        value: (company && company.name) || ''
                      }, {
                        key: 'PRIVACY_POLICY',
                        value: `<a href="${customPrivacyPolicyUrl}" target="_blank">${t('global.privacyPolicy')}</a>`
                      }], undefined, true), false, true)}
                    />
                  )}
                </div>

                <Row>
                  <Col size={100}>
                    <Error name='globalErrors' />
                  </Col>
                </Row>

                <button type='submit' className='ta-cta-primary ta-cta-middle ta-cta-fluid'>
                  {t('auth.register.section.buttons.registerNow')}
                </button>
              </Form>

            </div>
          </div>
        </>
      )}
    </div>
  )
}

Register.propTypes = {
  isActive: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func,
  formHeight: PropTypes.number,
  onNoteResize: PropTypes.func
}

export default Register
