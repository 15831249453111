import { eventReservation } from '../Companies/fields'

// Mutations

export const finaliseOnlineEventReservationCustomersMiddleware = `
  mutation finaliseOnlineEventReservationCustomersMiddleware ($event:EventReservationPayload!, $sessionId: ID, $metadata: Dynamic, $externalCustomerId: ID) {
    finaliseOnlineEventReservationCustomersMiddleware (event:$event, sessionId: $sessionId, metadata: $metadata, externalCustomerId: $externalCustomerId) ${eventReservation}
  }
`

export const finaliseOnlineCourseEventReservationCustomersMiddleware = `
  mutation finaliseOnlineCourseEventReservationCustomersMiddleware ($event:EventCourseReservationPayload!, $sessionId: ID, $metadata: Dynamic, $externalCustomerId: ID) {
    finaliseOnlineCourseEventReservationCustomersMiddleware (event:$event, sessionId: $sessionId, metadata: $metadata, externalCustomerId: $externalCustomerId) ${eventReservation}
  }
`

export const checkOnlineCourseEventReservationCustomersMiddleware = `
  mutation checkOnlineCourseEventReservationCustomersMiddleware ($event:EventCourseReservationPayload!) {
    checkOnlineCourseEventReservationCustomersMiddleware (event:$event)
  }
`
