import React, { Component } from 'react'
import { connect } from '../../../Store'
import { AuthConfirmation } from '../../../Beauties'

class ConfirmationWrapper extends Component {
  componentDidMount () {
  }

  render () {
    const { routeName } = this.props
    const isActive = routeName === 'authConfirmation'

    return (
      <AuthConfirmation
        isActive={isActive}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || ''
})

export default connect(maps)(ConfirmationWrapper)
