import React from 'react'
import { connect, handlers } from '../../Store'
import { Payments } from '../../Beauties'
import { getRouteQuery } from '../../Utils'

const PaymentsWrapper = props => {
  const {
    router,
    services,
    courses,
    slots,
    companies,
    selectedCompanyId
  } = props
  const isActive = router.name === 'payments'
  const isPrevious = ['paymentOnlinePayment', 'success', 'auth', 'authLogin', 'authRegister', 'authForgotten', 'authConfirmation', 'authChangePhone', 'authGuestBooking', 'authEnforcePasswordChange'].includes(router.name)
  const routeQuery = getRouteQuery(router)
  const { selectedId: selectedServiceId, list: servicesList } = services || {}
  const { selectedId: selectedCourseId, list: coursesList } = courses || {}
  const { selectedEventId, coursesList: coursesSlots = [] } = slots || {}
  let selectedService
  if (selectedServiceId) selectedService = servicesList.find(item => item.id === selectedServiceId)
  if (selectedCourseId) {
    const selectedSlot = coursesSlots.find(item => item.id === selectedEventId) || {}
    const { isPaymentMandatory, hasOnlinePayment } = selectedSlot || {}
    selectedService = {
      ...(coursesList.find(item => item.id === selectedCourseId) || {}),
      isPaymentMandatory,
      hasOnlinePayment
    }
  }
  const company = companies.find(item => item.id === selectedCompanyId) || {}
  // const { hasStripeConnected, paypalConnectionStatus } = company || {}
  const { paypalConnectionStatus, hasStripeConnected } = company || {}
  const hasPayPalConnected = paypalConnectionStatus === 'ON'
  const hasOnlinePaymentActive = hasStripeConnected || hasPayPalConnected

  const onClickOnlinePayment = () => {
    if (hasOnlinePaymentActive) handlers.pagesNextSet(`/payments/online-payment${routeQuery}`)
    handlers.navigateToPath(`/confirm${routeQuery}`)
  }

  const onClickCash = () => {
    handlers.pagesNextSet(null)
    handlers.navigateToPath(`/confirm${routeQuery}`)
  }

  return (
    <Payments
      isActive={isActive}
      isPrevious={isPrevious}
      selectedService={selectedService}
      onClickOnlinePayment={onClickOnlinePayment}
      onClickCash={onClickCash}
      hasOnlinePaymentActive={hasOnlinePaymentActive}
    />
  )
}

const maps = state => ({
  router: state.router || {},
  services: state.services || {},
  courses: state.courses || {},
  slots: state.slots || [],
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null
})

export default connect(maps)(PaymentsWrapper)
