import React from 'react'
import { connect, handlers, store } from '../../Store'
import { Footer } from '../../Beauties'
import { getRouteQuery, upsellingSDK } from '../../Utils'
import { DEUTSCHES_FRANCHISE_INSTITUT_ID } from '../../Settings'

const FooterWrapper = props => {
  const {
    routeName,
    appMode,
    selectedServiceCustomerFieldIds,
    selectedCourseCustomerFieldIds,
    companies,
    selectedCompanyId,
    enterpriseId,
    isUpsellingActive,
    upsellingSelectedIds,
    services,
    serviceCategories,
    selectedServiceId,
    attributes,
    customerFields,
    customerFieldCategories,
    fileLoading,
    nextPage,
    locale,
    customisations,
    onSubmit
  } = props
  const { payload: customisationsPayload } = customisations || {}
  const { settings: customisationsSettings } = customisationsPayload || {}
  const {
    hideWidgetExternalUrlRedirect,
    hideNewBookingButton,
    widgetExternalUrlRedirect: widgetExternalUrlRedirectCustomisation
  } = customisationsSettings || {}
  let isActive = ['availability', 'services', 'resources', 'customerFields', 'conflict', 'confirm', 'success'].includes(routeName)
  const isServicesRoute = routeName === 'services'
  const isAvailabilityRoute = routeName === 'availability'
  const isCustomerFieldsRoute = routeName === 'customerFields'
  const isConfirmRoute = routeName === 'confirm'
  const isConflictRoute = routeName === 'conflict'
  const isSuccessRoute = routeName === 'success'
  let isFooterShadowActive = true
  const hasServiceCustomerFieldIds = (selectedServiceCustomerFieldIds || selectedCourseCustomerFieldIds || []).length > 0
  const selectedCompany = companies.find(company => company.id === selectedCompanyId) || {}
  const { plan: selectedCompanyPlan = '', addOns = [], settings: companySettings } = selectedCompany
  let { widgetExternalUrlRedirect } = companySettings || {}
  if (widgetExternalUrlRedirectCustomisation) widgetExternalUrlRedirect = widgetExternalUrlRedirectCustomisation
  if (hideWidgetExternalUrlRedirect) widgetExternalUrlRedirect = null
  let {
    isEnterprise,
    serviceId,
    courseId,
    serviceExternalIds,
    courseExternalIds,
    showFooterBackButton
  } = attributes || {}
  isEnterprise = !!isEnterprise
  serviceId = serviceId || null
  serviceExternalIds = !!serviceExternalIds
  showFooterBackButton = !!showFooterBackButton
  let hasNextButton =
    (isServicesRoute && (hasServiceCustomerFieldIds || isUpsellingActive)) ||
    isConfirmRoute ||
    isCustomerFieldsRoute ||
    isAvailabilityRoute ||
    (isConflictRoute && appMode === 'NORMAL') ||
    (isSuccessRoute && appMode === 'NORMAL')
  const router = store.getState().router
  const routeQuery = getRouteQuery(router)
  const onServiceNextClick = async () => {
    if (document.querySelector('form[name="customerFieldsOnService"] button[type="submit"]')) {
      document.querySelector('form[name="customerFieldsOnService"] button[type="submit"]').click()
    } else {
      if (selectedServiceId) {
        if (upsellingSelectedIds && upsellingSelectedIds.length > 0) {
          handlers.appLoaderShow()
          const upsellingService = await upsellingSDK.createGhostService(selectedCompanyId, selectedCompany.region, {
            mainServiceId: selectedServiceId,
            additionalServicesIds: upsellingSelectedIds
          })

          if (upsellingService && upsellingService.id) {
            // We must add the service to the data fields based on data fields of all selected services
            const upsellingServices = [selectedServiceId, ...upsellingSelectedIds]
            const upsellingDataFields = [...customerFields]
            upsellingDataFields.map(customerField => {
              if (customerField.serviceIds) {
                const intersection = upsellingServices.filter(serviceId => customerField.serviceIds.includes(serviceId))
                if (intersection && intersection.length > 0) {
                  customerField.serviceIds.push(upsellingService.id)
                }
              }
              return null
            })
            handlers.customerFieldsPopulate({ customerFields: upsellingDataFields, customerFieldCategories })

            // We must add the ghost service to the store & select it before go to the next screen to get availability
            services.push({ ...upsellingService, companyId: selectedCompanyId })
            await handlers.servicesPopulate({ services, serviceCategories })
            await handlers.serviceSelectedChange(upsellingService.id)
          } else {
            // TODO: We must show error !!
            handlers.appLoaderHide()
            return
          }

          handlers.appLoaderHide()
        }
      }

      const form = store.getState().forms.customerFieldsOnService
      handlers.serviceFormSave(form, (errors) => { })
    }
  }

  const onConfirmClick = () => {
    handlers.bookingSave()
  }
  const onConflictClick = () => {
    handlers.appReset()
    handlers.navigateToPath(`/services${routeQuery}`)
  }
  const onSuccessClick = () => {
    handlers.appReset()
  }
  const onClick = (e) => {
    if (nextPage) {
      handlers.navigateToPath(nextPage)
      return
    }
    isServicesRoute && onServiceNextClick()
    isCustomerFieldsRoute && onSubmit(e)
    isConfirmRoute && onConfirmClick()
    isConflictRoute && onConflictClick()
    isSuccessRoute && onSuccessClick()
  }
  const hasBackButton = (!serviceId && !serviceExternalIds) || ((serviceId || (serviceExternalIds && serviceExternalIds.length > 0)) && !isAvailabilityRoute) ||
    (!courseId && !courseExternalIds) || ((courseId || (courseExternalIds && courseExternalIds.length > 0)) && !isAvailabilityRoute)
  const onBackClick = () => {
    document.getElementsByClassName('ta-header__back_button')[0].click()
  }
  const hasSupportedBy = enterpriseId && DEUTSCHES_FRANCHISE_INSTITUT_ID.includes(enterpriseId)
  if (isSuccessRoute && isEnterprise && addOns && addOns.includes('MRSPEX')) isActive = false
  if (isAvailabilityRoute && isEnterprise && addOns && addOns.includes('MRSPEX')) {
    isActive = true
    hasNextButton = true
    isFooterShadowActive = false
  }
  if (isAvailabilityRoute && hasNextButton && !showFooterBackButton) {
    isFooterShadowActive = false
  }
  if (['services', 'success', 'conflict'].includes(routeName)) showFooterBackButton = false
  // Customisations
  if (routeName === 'success' && hideNewBookingButton) isActive = false

  return (
    <Footer
      isActive={isActive}
      isFooterShadowActive={isFooterShadowActive}
      hasNextButton={hasNextButton}
      showFooterBackButton={showFooterBackButton}
      onClick={onClick}
      hasBackButton={hasBackButton}
      onBackClick={onBackClick}
      routeName={routeName}
      hasSupportedBy={hasSupportedBy}
      hasPoweredBy={selectedCompanyPlan === 'CLASSIC'}
      selectedCompany={selectedCompany}
      attributes={attributes}
      fileLoading={fileLoading}
      nextPage={nextPage}
      locale={locale}
      widgetExternalUrlRedirect={widgetExternalUrlRedirect}
    />
  )
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  appMode: state.app.mode || 'NORMAL',
  selectedServiceCustomerFieldIds: state.services.selectedCustomerFieldIds || null,
  selectedCourseCustomerFieldIds: state.courses.selectedCustomerFieldIds || null,
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  enterpriseId: state.enterprise.id || null,
  isUpsellingActive: state.upselling.isActive || false,
  upsellingSelectedIds: state.upselling.selectedIds || [],
  services: state.services.list || [],
  serviceCategories: state.services.categoriesList || [],
  selectedServiceId: state.services.selectedId || null,
  attributes: state.attributes || {},
  customerFields: state.customerFields.list || [],
  customerFieldCategories: state.customerFields.categoriesList || [],
  nextPage: state.pages.nextPage || null,
  locale: state.app.locale,
  customisations: state.customisations || {}
})

export default connect(maps)(FooterWrapper)
