export const bookerFields = `{
  id
  firstName
  lastName
  fullName
  phone {
    country
    number
  }
  timezone
}`
export const tokensFields = `{
  accessToken
  refreshToken
  sessionDuration
  account {
    email
    booker ${bookerFields}
    enforcePasswordChange
  }
}`
export const bookerCustomerFields = `{
  id
  type
  value
  values
}`
