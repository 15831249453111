import React, { Component } from 'react'
import { connect, handlers, store } from '../../Store'
import { Conflict } from '../../Beauties'
import { getRouteQuery } from '../../Utils'

class ConflictWrapper extends Component {
  render () {
    const { routeName, conflictError, booker, services, selectedServiceId, courses, selectedCourseId } = this.props
    const isActive = routeName === 'conflict'
    const isBooker = booker && booker.id
    const selectedService = services.find(item => item.id === selectedServiceId)
    const selectedCourse = courses.find(item => item.id === selectedCourseId)
    const selectedItem = selectedService || selectedCourse
    const onSignOut = () => {
      handlers.accountReset()
      handlers.logout()
      handlers.customerFieldsFormPopulate()
      const router = store.getState().router
      const routeQuery = getRouteQuery(router)
      handlers.navigateToPath(`/availability${routeQuery}`)
    }

    return (
      <Conflict
        isActive={isActive}
        conflictError={conflictError}
        isBooker={isBooker}
        selectedItem={selectedItem}
        onSignOut={onSignOut}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  conflictError: state.conflict.error || null,
  booker: state.account.booker || null,
  services: state.services.list || [],
  selectedServiceId: state.services.selectedId || null,
  courses: state.courses.list || [],
  selectedCourseId: state.courses.selectedId || null
})

export default connect(maps)(ConflictWrapper)
