import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '../../Common'

import './NoResults.css'

const NoResults = props => {
  const { linkLabel, linkTo, children } = props

  return (
    <div className='ta-no-results'>
      <div className='ta-no-results__message'>{children}</div>
      {linkLabel && linkTo &&
        <Link to={linkTo} className='ta-btn ta-btn-primary'>{linkLabel}</Link>
      }
    </div>
  )
}

NoResults.propTypes = {
  linkLabel: PropTypes.string,
  linkTo: PropTypes.string
}

export default NoResults
