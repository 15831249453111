import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5, Link, Form, FormSection, Row, Col, BorderedBox, Checkbox, t } from '../../Common'
import './CookieConsentMessage.css'
import { dangerousHTML } from '../../Utils'

const CookieConsentMessage = ({
  maximised,
  toggleMaximise,
  googleCookieActive,
  companyName,
  startTracking,
  denyTracking,
  trackEvent,
  customMessage,
  privacyPolicyLink,
  googleAnalyticsId,
  facebookPixelId,
  facebookCookieActive
}) => {
  const [siteCookiesVisible, toggleSiteCookies] = useState(false)
  const [marketingCookiesVisible, toggleMarketingCookies] = useState(false)
  const containerClassNames = ['ta-cookie-consent__container']

  if (maximised) {
    containerClassNames.push('full')
  }

  return (
    <div className={containerClassNames.join(' ')}>
      {maximised && (
        <div className='ta-cookie-consent__header'>
          <Link className='ta-cookie-consent__header__back_button' onClick={() => { toggleMaximise(); startTracking() }}>
            <FontAwesome5 icon='long-arrow-left' type='light' />
          </Link>
          <div className='ta-cookie-consent__header_title'>
            {t('consentMessage.heading.label')}
          </div>
        </div>
      )}
      <div className='ta-cookie-consent__content'>
        {maximised && (
          <Form name='marketingCookies'>
            <div className='ta-cookie-consent__content_intro'>
              {t('consentMessage.content.intro')}
            </div>
            <div className='ta-cookie-consent__content_title'>
              {t('consentMessage.strictlyNecessaryCookies.title')}
            </div>
            <div className='ta-cookie-consent__content_description'>
              {t('consentMessage.strictlyNecessaryCookies.description')}
            </div>
            <span className='ta-cookie-consent__content_link_container'>
              <span className='ta-cookie-consent__content_link_addon'>{siteCookiesVisible ? '-' : '+'}</span>
              <Link className='ta-cookie-consent__content_link' external onClick={() => toggleSiteCookies(!siteCookiesVisible)}>{siteCookiesVisible ? t('consentMessage.hideCookies.label') : t('consentMessage.viewCookies.label')}</Link>
            </span>
            {siteCookiesVisible && (
              <>
                <BorderedBox>
                  <Row>
                    <Col>
                      <div className='ta-cookie-consent__cookie_title'>cookie_consent_box</div>
                    </Col>
                    <Col>
                      <div className='ta-cookie-consent__cookie_timestamp'>{t('consentMessage.expiryDate.label')}: 1 {t('consentMessage.expiryDate.period.year')}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='ta-cookie-consent__cookie_description'>{t('consentMessage.consentBoxCookie.description')}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='ta-cookie-consent__cookie_title'>cookie_agreement</div>
                    </Col>
                    <Col>
                      <div className='ta-cookie-consent__cookie_timestamp'>{t('consentMessage.expiryDate.label')}: 1 {t('consentMessage.expiryDate.period.year')}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='ta-cookie-consent__cookie_description'>{t('consentMessage.agreementCookie.description')}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='ta-cookie-consent__cookie_title'>__stripe_mid</div>
                    </Col>
                    <Col>
                      <div className='ta-cookie-consent__cookie_timestamp'>{t('consentMessage.expiryDate.label')}: 1 {t('consentMessage.expiryDate.period.year')}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='ta-cookie-consent__cookie_description'>{t('consentMessage.stripeMid.description')}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='ta-cookie-consent__cookie_title'>__stripe_sid</div>
                    </Col>
                    <Col>
                      <div className='ta-cookie-consent__cookie_timestamp'>{t('consentMessage.expiryDate.label')}: 30 {t('consentMessage.expiryDate.period.minutes')}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='ta-cookie-consent__cookie_description'>{t('consentMessage.stripeSid.description')}</div>
                    </Col>
                  </Row>
                </BorderedBox>
              </>
            )}
            <div className='ta-divider' />
            <div className='ta-cookie-consent__content_title'>
              Marketing Cookies
            </div>
            <div className='ta-cookie-consent__content_description'>
              {t('consentMessage.marketingCookies.description', [{ key: 'COMPANY_NAME', value: companyName }])}
            </div>
            <span className='ta-cookie-consent__content_link_container'>
              <span className='ta-cookie-consent__content_link_addon'>{marketingCookiesVisible ? '-' : '+'}</span>
              <Link className='ta-cookie-consent__content_link' external onClick={() => toggleMarketingCookies(!marketingCookiesVisible)}>{marketingCookiesVisible ? t('consentMessage.hideCookies.label') : t('consentMessage.viewCookies.label')}</Link>
            </span>
            {marketingCookiesVisible && (
              <>
                {
                  facebookPixelId && (
                    <>
                      <FormSection>
                        <Row noOffset>
                          <Col>
                            <div className='ta-cookie-consent__cookie_switch_label'>{t('consentMessage.marketingCookies.facebook.title')}</div>
                          </Col>
                          <Col>
                            <Checkbox label={facebookCookieActive ? t('global.enabled') : t('global.disabled')} name='facebookCookieActive' theme='switch' />
                          </Col>
                        </Row>
                      </FormSection>
                      <BorderedBox>
                        <Row>
                          <Col>
                            <div className='ta-cookie-consent__cookie_title'>_fbp</div>
                          </Col>
                          <Col>
                            <div className='ta-cookie-consent__cookie_timestamp'>{t('consentMessage.expiryDate.label')}: 3 {t('consentMessage.expiryDate.period.month')}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className='ta-cookie-consent__cookie_description'>{t('consentMessage.marketingCookies.facebook.description', [{ key: 'COMPANY_NAME', value: companyName }])}</div>
                          </Col>
                        </Row>
                      </BorderedBox>
                    </>
                  )
                }
                {(googleAnalyticsId && (
                  <>
                    <FormSection>
                      <Row noOffset>
                        <Col>
                          <div className='ta-cookie-consent__cookie_switch_label'>{t('consentMessage.marketingCookies.google.title')}</div>
                        </Col>
                        <Col>
                          <Checkbox label={googleCookieActive ? t('global.enabled') : t('global.disabled')} name='googleCookieActive' theme='switch' />
                        </Col>
                      </Row>
                    </FormSection>
                    <BorderedBox>
                      <Row>
                        <Col>
                          <div className='ta-cookie-consent__cookie_title'>_gid, _ga, _gat,_UA</div>
                        </Col>
                        <Col>
                          <div className='ta-cookie-consent__cookie_timestamp'>{t('consentMessage.expiryDate.label')}: {t('consentMessage.expiryDate.period.differ')}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='ta-cookie-consent__cookie_description'>{t('consentMessage.marketingCookies.google.description', [{ key: 'COMPANY_NAME', value: companyName }])}</div>
                        </Col>
                      </Row>
                    </BorderedBox>
                  </>
                )
                )}
              </>
            )}
          </Form>
        )}
        {!maximised && (
          <>
            <div className='ta-cookie-consent__message'>
              {dangerousHTML(customMessage, false, true) || t('consentMessage.text')}
            </div>
            <div className='ta-cookie-conset__links_container'>
              <div className='ta-cookie-consent__buttons_container'>
                <button className='ta-btn ta-btn-ghost' onClick={denyTracking}>{t('consentMessage.button.disableAll.label')}</button>
                <button className='ta-btn ta-btn-primary' onClick={startTracking}>{t('consentMessage.button.allowAll.label')}</button>
              </div>
              <div className='ta-cookie-consent__buttons_container links'>
                <Link onClick={toggleMaximise} className='ta-cookie-conset__link bold' external>{t('consentMessage.link.customiseSettings.label')}</Link>
                {privacyPolicyLink && <Link to={privacyPolicyLink} target='_blank' className='ta-cookie-conset__link' external>{t('consentMessage.link.privacyPolicy.label')}</Link>}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

CookieConsentMessage.propTypes = {
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  maximised: PropTypes.bool,
  companyName: PropTypes.string,
  denyTracking: PropTypes.func,
  startTracking: PropTypes.func,
  toggleMaximise: PropTypes.func,
  trackEvent: PropTypes.func,
  customMessage: PropTypes.string,
  privacyPolicyLink: PropTypes.string,
  googleCookieActive: PropTypes.bool,
  facebookCookieActive: PropTypes.bool
}

CookieConsentMessage.defaultProps = {
  onAccept: () => {},
  onDecline: () => {},
  denyTracking: () => {},
  startTracking: () => {},
  trackEvent: () => {},
  toggleMaximise: () => {},
  maximised: false,
  companyName: '',
  customMessage: '',
  privacyPolicyLink: '',
  facebookCookieActive: true,
  googleCookieActive: true
}

export default CookieConsentMessage
