import { availabilityTransform } from './utils'

const reducer = {
  isPending: false
}

reducer.initialState = {}

reducer.handlers = (dispatch, actions) => ({
  availabilityReset: () => dispatch(actions.AVAILABILITY_RESET),
  availabilityGet: () => dispatch(actions.AVAILABILITY_GET),
  availabilityPopulate: (data, selectedResourceId) => dispatch(actions.AVAILABILITY_POPULATE, { data: availabilityTransform(data), selectedResourceId }),
  availabilityReady: () => dispatch(actions.AVAILABILITY_READY)
})

reducer.AVAILABILITY_RESET = state => reducer.initialState

reducer.AVAILABILITY_GET = state => ({
  ...state,
  isPending: true
})

reducer.AVAILABILITY_POPULATE = (state, { data, selectedResourceId }) => {
  const { offDays: dataOffDays, onDays: dataOnDays, allocationOffDays } = data || {}
  const { offDays: stateOffDays, onDays: stateOnDays } = state || {}
  const offDays = { ...stateOffDays }
  const onDays = { ...stateOnDays }
  // SA rules
  let combinedOffDays = [...(offDays[selectedResourceId || 'ALL'] || []), ...(dataOffDays || []), ...(allocationOffDays || [])]
    .filter((value, index, combinedOffDays) => combinedOffDays.indexOf(value) === index) // unique
    .sort()
  const filteredOnDays = [...(onDays[selectedResourceId || 'ALL'] || []), ...(dataOnDays || [])]
    .filter((value, index, combinedOffDays) => combinedOffDays.indexOf(value) === index) // unique
    .filter(day => !combinedOffDays.includes(day)) // not in combinedOffDays
    .sort()
  if (dataOffDays || allocationOffDays) offDays[selectedResourceId || 'ALL'] = combinedOffDays
  if (dataOnDays || allocationOffDays) onDays[selectedResourceId || 'ALL'] = filteredOnDays
  delete data.allocationOffDays // not needed

  return {
    ...state,
    ...data,
    offDays,
    onDays,
    isPending: false
  }
}

reducer.AVAILABILITY_READY = state => ({
  ...state,
  isPending: false
})

export default reducer
