import React from 'react'
import PropTypes from 'prop-types'
import { PaymentItem } from '../../Beauties'
import { t } from '../../Common'

import './Payments.css'

const Payments = props => {
  const {
    isActive,
    isPrevious,
    selectedService,
    onClickOnlinePayment,
    onClickCash,
    hasOnlinePaymentActive
  } = props
  const { isPaymentMandatory } = selectedService || {}
  const classNames = ['ta-step', 'ta-payments']
  if (isActive) classNames.push('active')
  if (isPrevious) classNames.push('prev')

  return (
    <div className={classNames.join(' ')}>
      {((isActive || isPrevious) &&
        <>
          <div className='ta-payments__title'>{t('payments.title')}</div>
          {hasOnlinePaymentActive && (
            <PaymentItem
              title={t('payments.stripe.creditcard.title')}
              text={t('payments.stripe.creditcard.text')}
              icon='credit-card'
              onClickOnlinePayment={onClickOnlinePayment}
            />
          )}
          {(!isPaymentMandatory &&
            <PaymentItem
              title={t('payments.cash.title')}
              text={t('payments.cash.text')}
              icon='money-bill'
              onClickCash={onClickCash}
            />
          )}
        </>
      )}
    </div>
  )
}

Payments.propTypes = {
  isActive: PropTypes.bool,
  isPrevious: PropTypes.bool
}

export default Payments
