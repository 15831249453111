import React from 'react'
import moment from 'moment'
import {
  t,
  Row,
  Col,
  SingleSelect,
  Input,
  Textarea,
  Checkbox,
  PhoneInput,
  DateInput,
  ImageInput,
  AddressInput,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  FilesInput,
  FormText
} from '../../Common'
import { dangerousHTML, linkify } from '../../Utils'
import { UPLOAD_FILE_SIZE_LIMIT } from '../../Settings'

const CustomerFormField = props => {
  const {
    field = {},
    availableFields = [],
    plan,
    timezones = [],
    setFileLoading,
    fileLoading,
    locale
  } = props
  const classNames = ['ta-customers__form-row']
  if (field.isDefault) {
    if (field.defaultId === 'avatar') classNames.push('avatar')
    if (availableFields.includes('avatar') && field.defaultId !== 'avatar') classNames.push('with-avatar')
    if (
      availableFields.includes('salutation') &&
      availableFields.includes('title') &&
      (field.defaultId === 'salutation' || field.defaultId === 'title')
    ) {
      classNames.push('half')
      if (field.defaultId === 'title') classNames.push('left')
    }
  }
  const name = `customerField${field.id}`
  const placeholder = `${field.placeholder || field.label || t(field.translationKey)}...`
  const description = field.description || null
  const mandatory = field.isMandatoryOnline
  const label = field.label || t(field.translationKey)
  const imageMaxSize = 5
  const TYPE_INPUT = ['TEXT', 'EMAIL']
  const basicDataFieldIds = ['firstName', 'lastName', 'email']
  let type = field.type
  if (field.defaultId === 'avatar') type = 'AVATAR'
  if (field.defaultId) classNames.push(`ta-field-default-${field.defaultId}`)
  classNames.push(name)

  if (type === 'FILES' && plan === 'ENTERPRISE') {
    return (
      <>
        <FilesInput
          name={name}
          maxFileSize={UPLOAD_FILE_SIZE_LIMIT}
          maxFiles={field.fileUploadLimit}
          allowMultiple={field.fileUploadLimit > 1}
          label={field.label}
          acceptedFileTypes={field.fileUploadTypes}
          setLoading={setFileLoading}
          fileLoading={fileLoading}
          mandatory={mandatory}
        />
        {field.description && (
          <FormText>{field.description}</FormText>
        )}
      </>
    )
  }

  return (
    <Row className={classNames.join(' ')}>
      <Col size={100}>
        {(TYPE_INPUT.includes(type) &&
          <>
            <Input
              name={name}
              label={label}
              placeholder={placeholder}
              hintText={description}
              mandatory={mandatory}
              limit={!basicDataFieldIds.includes(field.defaultId) ? 255 : undefined}
            />
          </>
        )}
        {(type === 'SELECT' &&
          <SingleSelect
            name={name}
            label={label}
            placeholder={placeholder}
            hintText={description}
            mandatory={mandatory}
            clearable
          />
        )}
        {(type === 'TIMEZONE' &&
          <SingleSelect
            name={name}
            label={label}
            placeholder={placeholder}
            hintText={description}
            mandatory={mandatory}
            options={timezones.map(item => ({ value: item.code, label: item.label }))}
            clearable
            searchable
          />
        )}
        {(type === 'TEXTAREA' &&
          <Textarea
            name={name}
            label={label}
            placeholder={placeholder}
            hintText={description}
            mandatory={mandatory}
            limit={3000}
          />
        )}
        {(type === 'CHECKBOX' &&
          <div className='ta-checkbox-container'>
            <Checkbox
              name={name}
              label={dangerousHTML(linkify(field.label), false, true)}
            />
            {(description &&
              <HoverPopup>
                <HoverPopupContent position='left'>
                  {description}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <FontAwesome5 icon='question-circle' type='s' />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
          </div>
        )}
        {(type === 'PHONE' &&
          <PhoneInput
            name={name}
            label={label}
            placeholder={placeholder}
            hintText={description}
            mandatory={mandatory}
            top
          />
        )}
        {(type === 'DATE' &&
          <DateInput
            name={name}
            label={label}
            placeholder={placeholder}
            hintText={description}
            icon='calendar'
            timezone='UTC'
            showMonth
            showYear
            mandatory={mandatory}
            maxDate={field.defaultId === 'birthday' ? moment() : null}
          />
        )}
        {(type === 'ADDRESS' &&
          <AddressInput
            name={name}
            label={label}
            defaultZoom={1.5}
            defaultBorderRadius={50}
            controls={{ radius: false }}
            validation={{ maxSize: 7 }}
            mandatory={mandatory}
            placeholder={placeholder}
            hintText={description}
            withMap
            locale={locale}
          />
        )}
        {/* {type === 'FILE' &&
          <ImageInput
            name={name}
            defaultZoom={1.5}
            defaultBorderRadius={100}
            controls={{ radius: false }}
            validation={{ maxSize: 5 }}
            mandatory={mandatory}
            hintText={description}
            title={t('customerFields.avatarUpload.title')}
            text={t('customerFields.avatarUpload.text', [{ key: 'FILE SIZE', value: `${imageMaxSize}MB` }])}
          />
        } */}
        {(type === 'AVATAR' && plan !== 'CLASSIC' &&
          <ImageInput
            name={name}
            defaultZoom={1.5}
            defaultBorderRadius={100}
            controls={{ radius: false }}
            validation={{ maxSize: 5 }}
            mandatory={mandatory}
            hintText={description}
            title={t('customerFields.avatarUpload.title')}
            text={t('customerFields.avatarUpload.text', [{ key: 'FILE SIZE', value: `${imageMaxSize}MB` }])}
          />
        )}
      </Col>
    </Row>
  )
}

export default CustomerFormField
