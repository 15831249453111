import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import Slider from 'react-slick'
import { connect } from '../../Store'
import { getDatesBetweenTwoDates, indexInParent } from '../../Utils'

import './WeekPicker.css'
import './slick.css'
import FontAwesome5 from '../FontAwesome/FontAwesome5'

class WeekPicker extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      sliderCurrentDate: null
    }

    this.changeSelectedDate = this.changeSelectedDate.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.selectedDate !== prevProps.selectedDate) {
      if (typeof this.slider !== 'undefined') {
        const slideElement = document.querySelector(`[data-weekpicker-date="${this.props.selectedDate}"]`)
        if (slideElement) {
          const slideIndex = indexInParent(slideElement.parentNode.parentNode)
          if (slideIndex > -1) this.slider.slickGoTo(slideIndex)
        }
      }
    }
  }

  changeSelectedDate (date) {
    const { onDateChanged, weekCalendar } = this.props
    let currentDate = moment()
    currentDate.hour(0)
    currentDate.minute(0)
    currentDate.second(0)

    const selectedDays = []
    if (weekCalendar) {
      let startWeekDate = date.clone()
      let endWeekDate = date.clone()

      startWeekDate.startOf('week')
      endWeekDate.endOf('week')

      for (let index = 0; index < 7; index++) {
        selectedDays.push(startWeekDate.format('YYYY-MM-DD'))
        startWeekDate.add(1, 'days')
      }
    } else {
      selectedDays.push(date.format('YYYY-MM-DD'))
    }

    if (onDateChanged) onDateChanged(selectedDays)
  }

  render () {
    const {
      availability,
      selectedDate,
      withMonthSelector,
      resources,
      className
    } = this.props
    const { sliderCurrentDate } = this.state
    const { selectedId: selectedResourceId } = resources || {}
    let {
      calendarBegin: minDate = moment(),
      calendarEnd: maxDate = moment(),
      offDays
    } = availability || {}
    offDays = offDays || {}
    const selectedResourceOffDays = offDays[selectedResourceId || 'ALL'] || []
    const changeSelectedMonth = (direction) => {
      let currentMonth = sliderCurrentDate ? sliderCurrentDate.clone() : moment()
      if (direction === '-') {
        currentMonth.subtract('1', 'month')
      } else {
        currentMonth.add('1', 'month')
      }
      const dateToLoad = datesRange.find(d => d.isSame(currentMonth, 'month'))
      if (dateToLoad) {
        const slideElement = document.querySelector(`[data-weekpicker-date="${dateToLoad.format('YYYY-MM-DD')}"]`)
        if (slideElement) {
          const slideIndex = indexInParent(slideElement.parentNode.parentNode)
          if (slideIndex > -1) this.slider.slickGoTo(slideIndex)
        }
      }
    }
    const currentDate = moment().subtract(1, 'day')
    const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
      <span {...props}>{children}</span>
    )
    if (moment(minDate).day() !== 0) minDate = moment(minDate).startOf('week')
    const datesRange = getDatesBetweenTwoDates(minDate, maxDate)
    const currentMonth = sliderCurrentDate ? sliderCurrentDate.clone() : moment()
    const prevMonth = currentMonth.clone().subtract('1', 'month')
    const nextMonth = currentMonth.clone().add('1', 'month')
    const hasPrevMonth = datesRange.find(d => d.isSame(prevMonth, 'month'))
    const hasNextMonth = datesRange.find(d => d.isSame(nextMonth, 'month'))
    const settings = {
      dots: false,
      infinite: false,
      draggable: false,
      speed: 1000,
      slidesToShow: 7,
      slidesToScroll: 7,
      prevArrow: (<SlickButtonFix className='slick-prev'><FontAwesome5 icon='chevron-left' /></SlickButtonFix>),
      nextArrow: (<SlickButtonFix className='slick-next'><FontAwesome5 icon='chevron-right' /></SlickButtonFix>),
      beforeChange: (oldIndex, newIndex) => {
        if (datesRange[newIndex]) {
          this.setState({
            sliderCurrentDate: datesRange[newIndex]
          })
        }
      }
    }
    const mainClasses = ['ta-weekpicker']

    if (className) mainClasses.push(className)

    return (
      <div className={mainClasses.join(' ')}>
        {withMonthSelector &&
          <div className='ta-weekpicker__monthselector'>
            <button
              onClick={() => changeSelectedMonth('-')}
              className={`ta-weekpicker__monthselector-prev ${!hasPrevMonth ? ' disabled' : ''}`}
            >
              <FontAwesome5 icon='chevron-left' />
            </button>
            <span className='ta-weekpicker__monthselector-label'>
              {sliderCurrentDate ? sliderCurrentDate.format('MMMM YYYY') : moment(selectedDate).format('MMMM YYYY')}
            </span>
            <button
              onClick={() => changeSelectedMonth('+')}
              className={`ta-weekpicker__monthselector-next ${!hasNextMonth ? ' disabled' : ''}`}
            >
              <FontAwesome5 icon='chevron-right' />
            </button>
          </div>
        }
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {datesRange && datesRange.length > 0 &&
            datesRange.map((date, index) => {
              const itemClasses = ['ta-weekpicker__slide']
              if (selectedResourceOffDays.includes(date.format('YYYY-MM-DD')) || date.isBefore(currentDate)) itemClasses.push('unavailable')
              if (selectedDate === date.format('YYYY-MM-DD')) itemClasses.push('active')
              return (
                <div
                  key={index}
                  data-weekpicker-date={date.format('YYYY-MM-DD')}
                  className={itemClasses.join(' ')}
                  onClick={() => { this.changeSelectedDate(date) }}
                >
                  {date.format('DD')}
                  <div className='ta-weekpicker__slide-day'>{date.format('ddd')}</div>
                </div>
              )
            })
          }
        </Slider>
      </div>
    )
  }
}

WeekPicker.propTypes = {
  onDateChanged: PropTypes.func,
  withMonthSelector: PropTypes.bool,
  className: PropTypes.string
}

const maps = state => ({
  availability: state.availability || {},
  resources: state.resources || {}
})

export default connect(maps)(WeekPicker)
