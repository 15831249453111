import gql from 'graphql-tag'
import { ApolloLink, concat, execute, makePromise } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { store, handlers } from '../../Store'
import {
  COMPANIES_URL,
  ACCOUNTS_ENGINE_URL,
  BOOKERS_URL,
  SERVERS_MANAGEMENT_URL,
  APP_VERSION,
  APP_VERSION_SUFFIX
} from '../../Settings'
import { isObject, decodeHtml } from '../../Utils'
import * as companies from './Companies'
import * as bookers from './Bookers'
import * as accounts from './AccountsEngine'
import * as server from './ServersManagement'
import * as customersMiddleware from './CustomersMiddleware'

const endpoints = {
  Companies: {
    url: COMPANIES_URL,
    definitions: companies
  },
  Bookers: {
    url: BOOKERS_URL,
    definitions: bookers
  },
  AccountsEngine: {
    url: ACCOUNTS_ENGINE_URL,
    definitions: accounts
  },
  ServersManagement: {
    url: SERVERS_MANAGEMENT_URL,
    definitions: server
  },
  CustomersMiddleware: {
    url: null, // Dynamic value. Must be set in q function
    definitions: customersMiddleware
  }
}

// Debuging only on development and staging
const VERBOSE_QUERIES = !!['development', 'staging'].includes(process.env.REACT_APP_ENV)

const getDefinition = name => {
  const endpoint = Object
    .keys(endpoints)
    .map(name => endpoints[name])
    .find(endpoint => !!endpoint.definitions[name])

  const definition = endpoint && endpoint.definitions[name]
  if (!definition) throw new Error(`Please define the ${name} query definition`)
  return definition
}

const getEndpointUrl = name => {
  const endpoint = Object
    .keys(endpoints)
    .map(name => endpoints[name])
    .find(endpoint => !!endpoint.definitions[name])

  const endpointUrl = endpoint && endpoint.url
  if (!endpointUrl) throw new Error(`Please define the ${name} query definition`)
  return endpointUrl
}

const wsLink = name => {
  const state = store.getState()
  const accessToken = (state.auth && state.auth.tokens && state.auth.tokens.accessToken) || ''
  return new WebSocketLink(new SubscriptionClient(getEndpointUrl(name).replace('http', 'ws') + '/graphql', {
    reconnect: true,
    connectionParams: accessToken ? {
      authorization: `Bearer ${accessToken}`
    } : {}
  }))
}

const link = (name, headersArray, url) => {
  const httpLink = new HttpLink({ uri: url || getEndpointUrl(name) })
  const authTokenLink = new ApolloLink((operation, forward) => {
    const headers = {}
    const state = store.getState()
    const accessToken = (state.auth && state.auth.tokens && state.auth.tokens.accessToken) || ''
    if (headersArray) {
      headersArray.forEach(header => { headers[header.key] = header.value })
      operation.setContext({ headers })
    }
    if (!accessToken) return forward(operation)
    operation.setContext({
      headers: {
        authorization: 'Bearer ' + accessToken
      }
    })
    return forward(operation)
  })

  return concat(authTokenLink, httpLink)
}

// checks if has to refresh tokens
const checkRefresh = async queryName => {
  const state = store.getState()
  const tokens = state.auth.tokens || {}
  let { refreshToken, accessToken, expires } = tokens
  // see if current access token expired then refresh the tokens
  const expired = expires && expires < new Date().getTime()
  if (queryName !== 'refreshTokens' && accessToken && expired) {
    // with access token expired and no refresh token we log out
    if (!refreshToken) return handlers.logout()
    // get new access token if error here logout
    let tokens = {}
    try {
      tokens = await q('refreshTokens', { refreshToken })
    } catch (err) {
      // in case of error here then logout
      handlers.logout()
    }
    // not access token or duration then logout
    if (!tokens.accessToken || !tokens.sessionDuration) return handlers.logout()
    expires = (new Date()).getTime() + parseInt(tokens.sessionDuration, 10)
    handlers.authTokensPopulate({ ...tokens, expires })
  }
}
// keeps the subscriptions in the hash table
const subscriptions = {}

export const qUnsubscribeAll = async (exceptions = []) => {
  Object.keys(subscriptions).forEach(subscriptionName => !exceptions.includes(subscriptionName) && qUnsubscribe(subscriptionName))
  // console.w1arn('Empty subscription', subscriptions)
}
// TODO just improve how subscibe and unsubscribe works, maybe you need to subscribe same subscription with diffrent variables
export const qUnsubscribe = async subscriptionName => {
  // just try to unsubscribe
  subscriptions[subscriptionName] && subscriptions[subscriptionName].unsubscribe()
  delete subscriptions[subscriptionName]
}

// subscribe to subsctiption
export const qSubscribe = async (subscriptionName, variables, onData, onError, onComplete) => {
  const subscription = getDefinition(subscriptionName)
  // if query is not defined then throw
  if (!subscription) return console.error(`Define subscription with name: ${subscriptionName}`)
  await checkRefresh(subscriptionName)
  const operation = {
    query: gql(subscription),
    variables
  }

  // unsubscribe if was already subscribed first
  qUnsubscribe(subscriptionName)

  onData = onData || (data => VERBOSE_QUERIES && console.warn(`no data handler for ${subscriptionName}`, data))
  onError = onError || (error => console.error(`Error on subscription handler for ${subscriptionName}`, error))
  onComplete = onComplete || (() => VERBOSE_QUERIES && console.warn(`no complete handler for ${subscriptionName}`))
  if (VERBOSE_QUERIES) console.warn('Subscribed to', subscriptionName)
  subscriptions[subscriptionName] = execute(wsLink(subscriptionName), operation)
    .subscribe({
      next: ({ data, errors } = {}) => {
        console.warn(data)
        if (data) return onData(data[subscriptionName])
        if (errors) return onError(errors.length === 1 ? errors[0] : errors)
      },
      error: onError,
      complete: onComplete
    })
}

// Decode all encoded chars to prevent double decoding from JSX
const decodeResponse = response => {
  if (!isObject(response)) return response
  if (Array.isArray(response)) {
    return Object
      .keys(response)
      .map(key => {
        const item = response[key]
        if (isObject(item)) return decodeResponse(item)
        return typeof item !== 'string' ? item : decodeHtml(item)
      })
  }
  return Object
    .keys(response)
    .reduce((acc, key) => {
      const item = response[key]
      if (typeof item === 'string') return { ...acc, [key]: decodeHtml(item) }
      return isObject(item) ? { ...acc, [key]: decodeResponse(item) } : { ...acc, [key]: item }
    }, {})
}

// runs a query or mutation
export const q = async (queryName, variables, headers, url) => {
  headers = headers || []
  const query = getDefinition(queryName)
  // if query is not defined then throw
  if (!query) return console.error(`Define query or mutation with name: ${queryName}`)
  await checkRefresh(queryName)
  const operation = {
    query: gql(query),
    variables
  }
  let result = {}
  try {
    headers.push({ key: 'p_i', value: window.btoa(JSON.stringify({ p_n: APP_VERSION_SUFFIX, p_v: APP_VERSION })) })
    result = await makePromise(execute(link(queryName, headers, url), operation))
    // if success then connection is still online
    const state = store.getState()
    if (!state.auth.isConnected) handlers.connectionChange(true)
  } catch (e) {
    if (VERBOSE_QUERIES) console.error('Executed', queryName, 'with try error', e)
    // connection become offline
    handlers.connectionChange(false)
    return {
      error: {
        code: 'ServerDown',
        message: 'Server down'
      }
    }
  }
  // deal with errors
  if (result.errors) {
    const error = result.errors[0]
    const { extensions: { code, exception = {} }, message } = error
    delete exception.stacktrace
    const err = {
      error: { code, message, data: { ...exception } },
      errors: result.errors.map(e => {
        const { extensions: { code, exception = {} }, message } = e
        delete exception.stacktrace
        return { code, message, data: { ...exception } }
      })
    }
    if (VERBOSE_QUERIES) console.error('Executed', queryName, 'with err ', err)
    return err
  }

  // one query at a time usually
  const queryNames = Object.keys(result.data)
  const specific = queryNames.length > 1
  const finalResult = specific ? result.data : result.data[queryNames[0]]
  if (VERBOSE_QUERIES) console.warn('Executed', queryName, 'with', finalResult)
  return decodeResponse(finalResult)
}

// keep here until final refactor
export const runMutation = null
export const runQuery = null
export const runCustomQuery = null
