import React, { PureComponent } from 'react'
import { connect } from '../../Store'
import {
  AuthLoginWrapper,
  AuthForgottenWrapper,
  AuthRegisterWrapper,
  AuthRegisterFacebookWrapper,
  AuthEnforcePasswordChangeWrapper,
  CoursersExtraParticipantsWrapper
} from '../../Beasts'

class PopupWrapper extends PureComponent {
  componentDidMount () {
  }

  render () {
    const { route } = this.props
    const isActive = route === 'auth'
    const isConfirmActive = route === 'confirm'

    return (
      <>
        <AuthLoginWrapper isActive={isActive} />
        <AuthForgottenWrapper isActive={isActive} />
        <AuthRegisterWrapper isActive={isActive} />
        <AuthRegisterFacebookWrapper isActive={isActive} />
        <AuthEnforcePasswordChangeWrapper isActive={isActive} />
        <CoursersExtraParticipantsWrapper isActive={isConfirmActive} />
      </>
    )
  }
}

const maps = state => ({
  route: (state.router && state.router.name) || ''
})

export default connect(maps)(PopupWrapper)
