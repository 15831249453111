import React from 'react'
import PropTypes from 'prop-types'
import { SlotsWrapper, ResourcesWrapper } from '../../Beasts'
import { t, Datepicker, Form, SingleSelect, WeekPicker } from '../../Common'
import { TIMIFY_PLANS } from '../../Settings'

import './Availability.css'

const AvailabilityMrSpex = props => {
  const {
    isActive,
    isPrevious,
    isPendingSlots,
    selectedDate,
    onDateChanged,
    isWidgetWeeklyView,
    showWidgetTimezoneSelector,
    companyPlan,
    timezones,
    enterpriseId,
    onTimezoneChanged,
    isTitleCourse,
    attributes,
    selectedItem,
    availabilityValue
  } = props
  const classNames = ['ta-step', 'ta-availability']
  const { disableOtherDates = false, horizontalCalendarLayout = false, horizontalMonthSelector = false } = attributes
  if (isActive) classNames.push('active')
  if (isPrevious) classNames.push('prev')

  let previousWeekLabel = t('datepicker.previousWeek.label')
  let nextWeekLabel = t('datepicker.nextWeek.label')

  if (enterpriseId === '5fbb7ddc98b3a111cb9bc2bd') {
    previousWeekLabel = 'Föregående vecka' // t('datepicker.previousWeek.sw.label')
    nextWeekLabel = 'Nästa vecka' // t('datepicker.nextWeek.sw.label')
  }

  return (
    <div className={classNames.join(' ')}>
      {(isActive || isPrevious) && (
        <>
          {(selectedItem && selectedItem.name &&
            <div className='ta-availability-header-space'>
              {selectedItem.name}
            </div>
          )}
          {((showWidgetTimezoneSelector && companyPlan && companyPlan === TIMIFY_PLANS.ENTERPRISE) &&
            <Form name='availability' onSubmit={() => { }}>
              <div className='ta-availability__timezones'>
                <SingleSelect
                  name='timezone'
                  label={t('availability.list.section.timezones.label')}
                  placeholder={t('availability.list.section.timezones.placeholder')}
                  options={timezones}
                  searchable
                  onChangeAddon={onTimezoneChanged}
                  value={availabilityValue}
                  hideArrow={false}
                />
              </div>
            </Form>
          )}
          {(!isTitleCourse &&
            <>
              {(!horizontalCalendarLayout &&
                <>
                  {(!disableOtherDates &&
                    <Datepicker
                      formatDay='dddd'
                      formatDate='DD.MM.YYYY'
                      selectedDate={selectedDate}
                      onDateChanged={onDateChanged}
                      weekCalendar={isWidgetWeeklyView}
                    />
                  )}
                  <ResourcesWrapper />
                </>
              )}
              {horizontalCalendarLayout && (
                <WeekPicker
                  selectedDate={selectedDate}
                  onDateChanged={onDateChanged}
                  weekCalendar={isWidgetWeeklyView}
                  withMonthSelector={horizontalMonthSelector}
                  className={horizontalCalendarLayout ? 'default' : ''}
                />
              )}
            </>
          )}
          {!isPendingSlots && <SlotsWrapper isAvailabilityActive={isActive} />}
          {isWidgetWeeklyView && (
            <Datepicker
              formatDay='dddd'
              formatDate='DD.MM.YYYY'
              weekCalendar
              calendarLabelWithText
              previousWeekLabel={previousWeekLabel}
              nextWeekLabel={nextWeekLabel}
              selectedDate={selectedDate}
              onDateChanged={onDateChanged}
            />
          )}
        </>
      )}
    </div>
  )
}

AvailabilityMrSpex.propTypes = {
  isActive: PropTypes.bool,
  isPrevious: PropTypes.bool,
  isPendingSlots: PropTypes.bool,
  selectedDate: PropTypes.string
}

export default AvailabilityMrSpex
