import React from 'react'
import { CustomerFormField } from '../../Beauties'
import { Form, t } from '../../Common'

const CustomerFieldsOnService = props => {
  const {
    filteredCustomerFields,
    timezones,
    onSubmit,
    plan,
    setFileLoading,
    fileLoading,
    isBooker,
    locale
  } = props

  return (
    <div className='ta-customer-fields__on-service'>
      <Form name='customerFieldsOnService' onSubmit={onSubmit}>
        {filteredCustomerFields.map(item => (
          <CustomerFormField
            key={item.id}
            field={item}
            timezones={timezones}
            plan={plan}
            setFileLoading={setFileLoading}
            fileLoading={fileLoading}
            isBooker={isBooker}
            locale={locale}
          />
        ))}

        <button type='submit' className='ta-cta ta-cta-primary' style={{ display: 'none' }} disabled={fileLoading}>
          {t('customerdata.preview.section.form.button.label')}
        </button>
      </Form>
    </div>
  )
}

export default CustomerFieldsOnService
