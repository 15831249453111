import React from 'react'
import { connect } from '../../Store'
import { NotFound } from '../../Beauties'

const NotFoundWrapper = props => {
  let { routeName, customUnavailableMessage } = props
  customUnavailableMessage = customUnavailableMessage || ''
  const isActive = routeName === 'notFound'

  return (
    <NotFound isActive={isActive} customUnavailableMessage={customUnavailableMessage} />
  )
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  customUnavailableMessage: state.companies && state.companies.customUnavailableMessage
})

export default connect(maps)(NotFoundWrapper)
