import axios from 'axios'
import { http_build_query } from 'qhttp'

import { UPSELLING_URL, UPSELLING_VERSION } from '../Settings'

const instance = axios.create({
  baseURL: UPSELLING_URL,
  headers: { 'X-APP-VERSION': UPSELLING_VERSION }
})

export default {
  // Get all public relations between services for the spefici company
  getRelations: (companyId, region) => {
    return instance.get(`relations/public/${companyId}?region=${region}`)
      .then(response => {
        let relations = []
        if (response && response.data && response.data.data) {
          relations = response.data.data
        }
        return relations
      })
  },

  // Create ghost service
  createGhostService: (companyId, region, data) => {
    return instance.post(`services/public/${companyId}/?region=${region}`, data)
      .then(response => {
        if (response && response.data && response.data.data) {
          return response.data.data
        }
        return null
      })
      .catch(() => null)
  },

  // Get availability based on duration of the services
  getAvailability: (companyId, params) => {
    return instance.get(`availability/${companyId}/?${http_build_query(params)}`)
      .then(response => {
        return (response && response.data && response.data.data) || null
      })
      .catch(() => null)
  },

  // Create reservation based on duration of the services
  createReservation: (companyId, data) => {
    return instance.post(`appointments/reservation/${companyId}/`, data)
      .then(response => {
        if (response && response.data && response.data.data) {
          return {
            eventId: response.data.data.eventId || null,
            secret: response.data.data.secret || null
          }
        }
        return null
      })
      .catch(() => null)
  }
}
