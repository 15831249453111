const reducer = {}

reducer.initialState = {
  list: [],
  selectedCode: null
}

reducer.handlers = (dispatch, actions) => ({
  timezonesPopulate: ({ timezones }) => { dispatch(actions.TIMEZONES_POPULATE, { timezones }) },
  timezoneSelect: code => dispatch(actions.TIMEZONE_SELECT, code)
})

reducer.TIMEZONES_POPULATE = (state, { timezones }) => ({
  ...state,
  list: timezones || []
})

reducer.TIMEZONE_SELECT = (state, code) => ({
  ...state,
  selectedCode: code
})

export default reducer
