import momenttz from 'moment-timezone'
import { payloads$, actions, handlers, store } from '../../../Store'
import { getCustomerFieldsWithValues, getRouteQuery } from '../../../Utils'
import { q } from '../../API'

let timer

payloads$(actions.STRIPE_PAYMENT_INTENT_GET)
  .subscribe(async () => {
    handlers.appLoaderShow()
    const state = store.getState()
    let {
      booking,
      companies,
      forms,
      router
    } = state || {}
    const customerFieldsWithValues = getCustomerFieldsWithValues(state)
    router = router || {}
    const routeQuery = getRouteQuery(router)
    const { eventId, secret } = booking || {}

    const { selectedId: selectedCompanyId = null, list: companiesList } = companies || {}
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId) || {}
    const { id: companyId, region } = selectedCompany || {}

    const firstName = customerFieldsWithValues.find(item => (item.defaultId === 'firstName'))?.value || ''
    const lastName = customerFieldsWithValues.find(item => (item.defaultId === 'lastName'))?.value || ''
    let fullName = `${firstName} ${lastName}`.trim().replace(/\s\s+/g, ' ')
    const customerCompanyName = customerFieldsWithValues.find(item => (item.defaultId === 'company'))?.value
    const email = customerFieldsWithValues.find(item => (item.defaultId === 'email'))?.value
    if (!fullName) fullName = customerCompanyName
    const { voucherInput } = forms
    const voucherCode = voucherInput?.voucherFinalCode?.value || ''
    const event = {
      eventId,
      secret,
      companyId,
      region,
      customerName: fullName,
      customerEmail: email
    }
    if (voucherCode) event.voucherCode = voucherCode
    const clientSecret = await q('saveStripeIntent', event)
    const { error } = clientSecret || { error: {} }
    if (error) {
      handlers.pagesNextSet(null)
      handlers.conflictErrorPopulate('StripeIntentError')
      handlers.appLoaderHide()
      handlers.navigateToPath(`/conflict${routeQuery}`)
      return
    }
    const duration = 10
    const exporeAt = momenttz().add(duration, 'minutes').toDate()
    timer = setTimeout(async () => {
      const result = await q('cancelOnlineEventReservation', { event })
      const { error } = result || { error: {} }
      if (error?.code === 'InvalidCancelRequest') {
        const state = store.getState()
        if (state?.booking?.isPending) return
        handlers.bookingSave()
        return
      }
      handlers.bookingReset()
      handlers.pagesNextSet(null)
      handlers.conflictErrorPopulate('StripeSessionExpired')
      handlers.appLoaderHide()
      handlers.navigateToPath(`/conflict${routeQuery}`)
    }, (duration * 60 * 1000))
    handlers.stripePaymentIntentPopulate(clientSecret, exporeAt)
  })

payloads$(actions.STRIPE_PAYMENT_INTENT_RESET)
  .subscribe(async () => {
    handlers.appLoaderShow()
    clearTimeout(timer)
    const state = store.getState()
    let { booking, companies, stripe, router } = state || {}
    router = router || {}
    const routeQuery = getRouteQuery(router)
    const { eventId } = booking || {}
    const { clientSecret } = stripe || {}
    const { selectedId: selectedCompanyId = null, list: companiesList } = companies || {}
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId) || {}
    const { id: companyId, region } = selectedCompany || {}
    const data = {
      eventId,
      clientSecret,
      companyId,
      region
    }
    const result = await q('deleteStripeIntent', data)
    const { error } = result || { error: {} }
    if (error) {
      handlers.pagesNextSet(null)
      handlers.conflictErrorPopulate('StripeIntentError')
      handlers.appLoaderHide()
      handlers.navigateToPath(`/conflict${routeQuery}`)
      return
    }
    handlers.stripePaymentIntentPopulate(null, null)
    handlers.appLoaderHide()
  })

payloads$(actions.STRIPE_RESET)
  .subscribe(async () => {
    clearTimeout(timer)
  })
