import { payloads$, actions, handlers, store } from '../../../Store'
import { q } from '../../API'
import { getRouteQuery } from '../../../Utils'
import {
  forgottenFormValidate,
  forgottenFormServerErrorsTransform,
  forgottenFormSaveTransform,
  enforcePasswordChangeFormValidate,
  enforcePasswordChangeFormSaveTransform,
  enforcePasswordChangeFormServerErrorsTransform,
  registerFormFacebookSaveTransform,
  registerFormTransform,
  registerFormFacebookTransform,
  registerFormValidate,
  registerFormFacebookValidate,
  registerFormServerErrorsTransform,
  registerFormSaveTransform,
  loginFormValidate,
  loginServerErrorsTransform,
  customerFieldsTransform
} from './utils'
import { DEFAULT_LOCALE } from '../../../Settings'

const setLoginErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('login', errors)
  handlers.loginFormReady()
  scrollToError && scrollToError(errors)
}

// Login with email and password
payloads$(actions.LOGIN_WITH_EMAIL_AND_PASSWORD)
  .subscribe(async ({ fields, scrollToError }) => {
    const { app } = store.getState()
    const { isLocaleChanged, locale } = app || {}

    const errors = loginFormValidate(fields)

    if (errors.length) return setLoginErrors(errors, scrollToError)

    const loginData = {
      email: fields.email,
      password: fields.password
      // longSession: fields.remember
    }

    // If the user changed his locale we must send the new locale on the login endpoint
    if (isLocaleChanged && locale) loginData.locale = locale

    const tokens = await q('loginBookerAccountWithEmailAndPassword', loginData)

    const { error } = tokens
    if (error) {
      // If the account is valid but it's not a booker we must redirect the user to registratoin form
      if (error && error.code && error.code.toLowerCase() === 'bookernotexists') {
        const router = store.getState().router
        let routeQuery = getRouteQuery(router)
        if (routeQuery.indexOf('fromLogin') === -1) routeQuery = `${routeQuery}&fromLogin=1`
        handlers.registrationFormPopulate({ email: fields.email, password: fields.password })
        handlers.navigateToPath(`/auth/register${routeQuery}`)
        handlers.appLoaderHide()
        return
      }

      error.code === 'UserTemporaryBlockedWithResetLink' && handlers.loginBlockedWarningShow()

      if (error.code === 'UserPermanentlyBlocked') handlers.loginBlockedWarningHide()

      return setLoginErrors(loginServerErrorsTransform(error), scrollToError)
    }

    handlers.authTokensChange(tokens)
  })

// Login with Facebook
payloads$(actions.LOGIN_WITH_FACEBOOK)
  .subscribe(async ({ data }) => {
    if (!data || !data.accessToken) {
      handlers.appLoaderHide()
      return
    }
    const state = store.getState()
    const locale = state.app.locale || ''

    const tokens = await q('loginBookerAccountWithFacebook', {
      facebookToken: data.accessToken,
      locale: locale
      // longSession: data.remember
    })

    const { error } = tokens

    if (error) {
      handlers.registrationFormFacebookPopulate(data)
      const router = store.getState().router
      const routeQuery = getRouteQuery(router)
      handlers.navigateToPath(`/auth/register/facebook${routeQuery}`)
      handlers.appLoaderHide()
      return
    }

    handlers.authTokensChange(tokens)
    handlers.appLoaderHide()
  })

// Auth tokens change
payloads$(actions.AUTH_TOKENS_CHANGE)
  .subscribe(async tokens => {
    const { accessToken, refreshToken, sessionDuration, account } = tokens

    handlers.authTokensPopulate({
      accessToken,
      refreshToken,
      expires: (new Date()).getTime() + parseInt(sessionDuration, 10)
    })

    // Get customer fields of the booker for the current company
    const state = store.getState()
    const { companies } = state
    const { list: companiesList, selectedId: selectedCompanyId } = companies
    const selectedCompany = companiesList.find(company => company.id === selectedCompanyId)

    const bookerCustomerFields = await q('getBookerCompanyCustomerValues', {
      companyId: selectedCompany.id || '',
      region: selectedCompany.region || ''
    })

    // Append fields to the booker's object
    if (account && account.booker && bookerCustomerFields) account.booker.customerFields = customerFieldsTransform(bookerCustomerFields)

    handlers.accountPopulate(account)

    handlers.authRedirect()
  })

payloads$(actions.AUTH_REDIRECT)
  .subscribe(async () => {
    const { router, companies, services, courses, slots, customerFields, upselling, account } = store.getState()
    const routeQuery = getRouteQuery(router)
    const { selectedId: selectedCompanyId } = companies
    const { selectedId: selectedServiceId = null, list: servicesList } = services
    const { selectedId: selectedCourseId = null } = courses
    const { selectedEventId = null, coursesList = [] } = slots || {}
    const { list: customerFieldsList = [] } = customerFields
    const { selectedIds: upsellingSelectedIds = [] } = upselling
    // const filterByDefaultId = ['firstName', 'lastName', 'email', 'mobilePhone']
    const selectedCompany = companies.list.find(item => item.id === selectedCompanyId)
    const selectedItemId = selectedServiceId || selectedCourseId
    let selectedService
    if (selectedServiceId) selectedService = servicesList.find(item => item.id === selectedServiceId)
    if (selectedCourseId) selectedService = coursesList.find(item => item.id === selectedEventId)
    const filteredCustomerFields = customerFieldsList.filter(item =>
      item.companyId === selectedCompanyId &&
      item.isAfterAuth &&
      ((selectedServiceId && item.areAllServicesSelected) || item.serviceIds.includes(selectedItemId) || (selectedCourseId && item.areAllCoursesSelected) || item.courseIds.includes(selectedItemId))
      // && !filterByDefaultId.includes(item.defaultId)
    )
    handlers.loginFormPopulate()
    if (account && account.enforcePasswordChange) {
      handlers.navigateToPath(`/auth/enforcePasswordChange${routeQuery}`)
      return
    }
    if (filteredCustomerFields.length > 0) {
      handlers.navigateToPath(`/customer-fields${routeQuery}`)
      return
    }

    if (upsellingSelectedIds.length === 0 && selectedService && selectedService.hasOnlinePayment && selectedService.price && selectedCompany && (selectedCompany.hasStripeConnected || selectedCompany.paypalConnectionStatus === 'ON')) {
      handlers.navigateToPath(`/payments${routeQuery}`)
      return
    }
    handlers.navigateToPath(`/confirm${routeQuery}`)
  })

// Logout
payloads$(actions.LOGOUT)
  .subscribe(() => {
    // Reset to initial state
    handlers.accountReset()
  })

// Forgotten
const setForgottenFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('forgotten', errors)
  scrollToError && scrollToError(errors)
}

payloads$(actions.FORGOTTEN_FORM_SAVE)
  .subscribe(async ({ booker, scrollToError }) => {
    const errors = forgottenFormValidate(booker)
    if (errors.length) {
      setForgottenFormSaveErrors(errors, scrollToError)
      return
    }

    handlers.appLoaderShow()

    const resetSent = await q('sendPasswordResetCode', forgottenFormSaveTransform(booker))
    const { error } = resetSent

    handlers.appLoaderHide()
    if (error) return setForgottenFormSaveErrors(forgottenFormServerErrorsTransform(error), scrollToError)

    handlers.forgottenFormSuccess()
  })

// Enforce Password Change
const setEnforcePasswordChangeFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('enforcePasswordChange', errors)
  scrollToError && scrollToError(errors)
}

payloads$(actions.ENFORCE_PASSWORD_CHANGE_FORM_SAVE)
  .subscribe(async ({ booker, scrollToError }) => {
    const errors = enforcePasswordChangeFormValidate(booker)
    if (errors.length) {
      setEnforcePasswordChangeFormSaveErrors(errors, scrollToError)
      return
    }

    handlers.appLoaderShow()
    const result = await q('saveUserData', enforcePasswordChangeFormSaveTransform(booker))
    const { error } = result
    handlers.appLoaderHide()
    if (error) return setEnforcePasswordChangeFormSaveErrors(enforcePasswordChangeFormServerErrorsTransform(error), scrollToError)

    handlers.enforcePasswordChangeFormSuccess()
  })

payloads$(actions.ENFORCE_PASSWORD_CHANGE_FORM_SUCCCESS)
  .subscribe(() => {
    const state = store.getState()
    const { account, forms } = state || {}
    const { email } = account || {}
    const { enforcePasswordChange } = forms || {}
    const { password } = enforcePasswordChange || {}
    const { value: passwordValue } = password || {}
    handlers.loginWithEmailAndPassword({ email: { value: email }, password: { value: passwordValue } })
  })

// Password Reset Email
payloads$(actions.SEND_PASSWORD_RESET_EMAIL)
  .subscribe(async ({ fields, scrollToError }) => {
    const errors = loginFormValidate(fields)

    if (errors.length) return setLoginErrors(errors, scrollToError)

    handlers.appLoaderShow()
    const resetSent = await q('sendPasswordResetCode', { email: fields.email })
    const { error } = resetSent

    handlers.appLoaderHide()
    if (error) return setLoginErrors(loginServerErrorsTransform(error), scrollToError)
  })

// Register
const setRegisterFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('register', errors)
  scrollToError && scrollToError(errors)
}
// Register Facebook
const setRegisterFacebookFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('registerFacebook', errors)
  scrollToError && scrollToError(errors)
}

payloads$(actions.REGISTER_FORM_SAVE)
  .subscribe(async ({ booker, scrollToError }) => {
    const { companies, app } = store.getState()
    const { selectedId: selectedCompanyId, list: companiesList } = companies
    const selectedCompany = companiesList.find(company => company.id === selectedCompanyId)
    const { locale } = app || {}
    const errors = registerFormValidate(booker, selectedCompany)
    if (errors.length) {
      setRegisterFormSaveErrors(errors, scrollToError)
      return
    }

    // Set booker locale based on selected locale of the customer or url locale or company locale
    booker.locale = locale || selectedCompany.locale || DEFAULT_LOCALE

    handlers.appLoaderShow()

    const tokens = await q('registerBookerAccountWithEmailAndPassword', registerFormSaveTransform(booker, selectedCompanyId))
    const { error } = tokens
    if (error) {
      if (error.code && error.code === 'BookerExists') {
        handlers.appLoaderHide()
        setRegisterFormSaveErrors(registerFormServerErrorsTransform(error), scrollToError)
        return
      }
      if (error.code && error.code === 'PasswordWeak') error.replace = [{ key: 'MIN', value: '8' }]

      handlers.appLoaderHide()
      setRegisterFormSaveErrors(registerFormServerErrorsTransform(error), scrollToError)
      return
    }

    handlers.formFieldsUpdate('register', registerFormTransform())
    handlers.authTokensChange(tokens)
    handlers.appLoaderHide()
  })

payloads$(actions.REGISTER_FORM_FACEBOOK_SAVE)
  .subscribe(async ({ booker, scrollToError }) => {
    const errors = registerFormFacebookValidate(booker)
    const state = store.getState()
    const locale = state.app.locale
    const selectedCompany = (
      state.companies &&
      state.companies.selectedId &&
      state.companies.list &&
      state.companies.list.find(item => item.id === state.companies.selectedId)
    )
    const timezone = selectedCompany && selectedCompany.timezone
    const { companies } = store.getState()
    const { selectedId: selectedCompanyId } = companies

    if (errors.length) {
      setRegisterFacebookFormSaveErrors(errors, scrollToError)
      return
    }

    handlers.appLoaderShow()
    const tokens = await q('registerBookerAccountWithFacebook', registerFormFacebookSaveTransform({ ...booker, locale, timezone }, selectedCompanyId))
    const { error } = tokens
    if (error) {
      if (error.code && error.code === 'PasswordWeak') error.replace = [{ key: 'MIN', value: '8' }]
      handlers.appLoaderHide()
      setRegisterFacebookFormSaveErrors(registerFormServerErrorsTransform(error), scrollToError)
      return
    }

    handlers.formFieldsUpdate('registerFacebook', registerFormFacebookTransform())
    handlers.authTokensChange(tokens)
    handlers.appLoaderHide()
  })
