import React, { Component } from 'react'
import PropTypes from 'prop-types'

class OutsideClick extends Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount () {
    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('click', this.handleClick)
      document.attachEvent('touchend', this.handleClick)
    } else {
      document.addEventListener('click', this.handleClick)
      document.addEventListener('touchend', this.handleClick)
    }
  }

  componentWillUnmount () {
    if (!document.removeEventListener && document.detachEvent) {
      document.detachEvent('click', this.handleClick)
      document.detachEvent('touchend', this.handleClick)
    } else {
      document.removeEventListener('click', this.handleClick)
      document.removeEventListener('touchend', this.handleClick)
    }
  }

  handleClick (e) {
    const { target } = e
    const { onClickOutside } = this.props
    if (this.wrapper && this.wrapper.contains(target)) return
    onClickOutside && onClickOutside(e)
  }

  render () {
    const { onClickOutside, children, ...rest } = this.props

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} {...rest}>
        {children}
      </div>
    )
  }
}

OutsideClick.propTypes = {
  onClickOutside: PropTypes.func.isRequired
}

export default OutsideClick
