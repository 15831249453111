import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../../Store'
import { feedContextInProps, dangerousHTML } from '../../../Utils'
import { FormContext, t } from '../../../Common'

class Error extends Component {
  constructor (props, context) {
    super(props)
  }

  render () {
    const { multiFields, errors, error } = this.props
    const classNames = ['ta-form-error']
    const allErrors = (error && [error]) || errors

    if (allErrors.length > 0) classNames.push('active')
    if (multiFields) classNames.push('multi-fields')

    return (
      <div className={classNames.join(' ')}>
        {allErrors.map((item, index) => <div key={index}>{dangerousHTML(t(item.value, item.replace || []))}</div>)}
      </div>
    )
  }
}

Error.propTypes = {
  multiFields: PropTypes.bool,
  message: PropTypes.string,
  error: PropTypes.object
}

const maps = (state, props) => ({
  errors: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].errors) || []
})

export default feedContextInProps(connect(maps)(Error), FormContext)
