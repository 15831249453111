import React from 'react'
import PropTypes from 'prop-types'
import {
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Price,
  format,
  getCustomTimeFormat
} from '../../Common'
import { convertMinutesToHours } from '../../Utils'

const Slot = props => {
  const {
    day,
    slot,
    price,
    discountedPrice,
    showTooltip,
    offerId,
    isActive,
    onClickSlot
  } = props
  const customTimeFormat = getCustomTimeFormat()
  let slotMinutes = convertMinutesToHours(slot.minutes)
  if (customTimeFormat) slotMinutes = format(slotMinutes, customTimeFormat, { isUTC: true, format: 'LT' })
  const classNames = ['ta-slots__slot', 'ta-cta-secondary']
  if (discountedPrice) classNames.push('discount')
  if (isActive) classNames.push('active')

  return (
    <HoverPopup disabled={!showTooltip}>
      <HoverPopupContent position='top' autoSize>
        <Price price={discountedPrice || price} />
      </HoverPopupContent>
      <HoverPopupTrigger>
        <div
          className={classNames.join(' ')}
          onClick={() => onClickSlot(day, slot.minutes, offerId)}
        >
          {slotMinutes}
        </div>
      </HoverPopupTrigger>
    </HoverPopup>
  )
}

Slot.propTypes = {
  slot: PropTypes.object.isRequired
}

export default Slot
