import { payloads$, actions, store, handlers } from '../../../Store'

payloads$(actions.COURSE_SELECT)
  .subscribe(async ({ id }) => {
    if (!id) return
    const { app: storeApp = {}, courses: storeCourses = {}, companies: storeCompanies = {} } = store.getState()
    const { locale: appLocale = 'en-gb' } = storeApp
    const { list: companies = [], selectedId: selectedCompanyId = null } = storeCompanies
    const { list: courses = [] } = storeCourses
    const selectedCourse = courses.find(s => s.id === id) || {}
    const selectedCompany = companies.find(c => c.id === selectedCompanyId)
    handlers.upsellingSelectedOriginalServiceId(id)

    // Send message to the parent
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage('timify:widget:servicesSelect', '*')
      window.parent.postMessage({
        action: 'timify:widget:page:servicesSelect',
        language: appLocale.substring(0, 2).toUpperCase(),
        country: (selectedCompany && selectedCompany.locale ? selectedCompany.locale.substring(3, 5).toUpperCase() : ''),
        companyId: selectedCompanyId,
        companyExternalId: (selectedCompany && selectedCompany.externalId) || null,
        id: id,
        name: (selectedCourse && selectedCourse.name) || '',
        serviceExternalId: (selectedCourse && selectedCourse.externalId) || null,
        type: 'GROUP_SERVICE'
      }, '*')
    }
  })
