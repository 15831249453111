import React from 'react'
import { CoursesListItem } from '../../Beauties'

const CoursesDailySlots = props => {
  const {
    slotsByDays,
    selectedEventId,
    hideResourcesSection,
    onClickSlot
  } = props

  return (
    <div className='ta-slots__daily'>
      {slotsByDays.map(item => (
        <div key={item.day}>
          {item.slots.map(slot => (
            <CoursesListItem
              key={slot.id}
              event={slot}
              price={slot.price}
              offerId={slot.offerId}
              discountedPrice={slot.discountedPrice}
              showTooltip={item.hasOffer}
              isActive={slot.id === selectedEventId}
              hideResourcesSection={hideResourcesSection}
              onClickSlot={onClickSlot}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

export default CoursesDailySlots
