import React from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../Store'

import './SimpleList.css'

const SimpleListItem = props => {
  const { to, status, children, className, draggable, action } = props
  const classNames = ['ta-simple-list-item']
  if (className) classNames.push(className)
  if (status) classNames.push(status)
  if (!to && !draggable) classNames.push('no-pointer')
  if (draggable) classNames.push('draggable')

  const onClick = e => {
    // dont call action if a link inside the component is clicked
    // dont call action even if an element nested inside a link is clicked
    if (action && e.target.tagName !== 'A' && !e.target.closest('a')) action()
    if (!to) return null
    e.preventDefault()
    handlers.navigateToPath(to)
  }

  return (
    <div onClick={onClick} className={classNames.join(' ')}>
      {children}
    </div>
  )
}

SimpleListItem.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
  action: PropTypes.func
}

export default SimpleListItem
