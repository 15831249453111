import React from 'react'

const SimpleListItemTitle = props => {
  const { title, children } = props
  const textClassNames = ['ta-simple-list-item__title-text']
  if (children) textClassNames.push('ta-simple-list-item__with-children')

  return (
    <div className='ta-simple-list-item__title'>
      <div className={textClassNames.join(' ')} title={title}>{title}</div>
      {children &&
        <div className='ta-simple-list-item__title-icon'>{children}</div>
      }
    </div>
  )
}

export default SimpleListItemTitle
