export const companiesTransform = companies => {
  if (!companies) return []
  return companies.map(item => {
    const result = {
      ...item,
      settings: { ...item.onlineSettings }
    }
    delete result.services
    delete result.serviceCategories
    delete result.courses
    delete result.courseCategories
    delete result.customerFields
    delete result.customerFieldCategories
    delete result.offers
    delete result.onlineSettings
    return result
  })
}
