import { store } from '../../../Store'
import { servicesTransform, serviceCategoriesTransform, customerFieldsFormTransform } from './utils'
import { DEFAULT_LOCALE_COUNTRY_CODE } from '../../../Settings'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  selectedId: null,
  selectedCustomerFieldIds: [],
  isPending: true
}

reducer.handlers = (dispatch, actions, handlers) => ({
  servicesReset: () => dispatch(actions.SERVICES_RESET),
  servicesPopulate: ({ services, serviceCategories }) => dispatch(actions.SERVICES_POPULATE, { services: servicesTransform(services), serviceCategories: serviceCategoriesTransform(serviceCategories) }),
  serviceSelect: ({ id, customerFieldIds, customerFields }) => {
    dispatch(actions.SERVICE_SELECT, { id, customerFieldIds })
    const { companies, attributes, account } = store.getState()
    const { customerField = {}, externalCustomerField = {} } = attributes
    const { booker } = account
    const { list: companiesList, selectedId: selectedCompanyId } = companies
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId)
    let { locale: countryPhoneCode = DEFAULT_LOCALE_COUNTRY_CODE } = selectedCompany || {}
    countryPhoneCode = countryPhoneCode.split('-')
    countryPhoneCode = (countryPhoneCode[1] || DEFAULT_LOCALE_COUNTRY_CODE).toUpperCase()
    if (id) handlers.formSet('customerFieldsOnService', customerFieldsFormTransform(customerFields, customerField, externalCustomerField, countryPhoneCode, booker))
    handlers.availabilityReset()
  },
  serviceSelectedChange: id => dispatch(actions.SERVICES_SELECTED_CHANGE, { id }),
  serviceFormSave: (fields, scrollToError = () => { }) => dispatch(actions.SERVICE_FORM_SAVE, { fields, scrollToError })
})

reducer.SERVICES_RESET = state => reducer.initialState

reducer.SERVICES_POPULATE = (state, { services, serviceCategories }) => ({
  ...state,
  list: services,
  categoriesList: serviceCategories,
  isPending: false
})

reducer.SERVICE_SELECT = (state, { id, customerFieldIds }) => ({
  ...state,
  selectedId: id,
  selectedCustomerFieldIds: customerFieldIds
})

reducer.SERVICES_SELECTED_CHANGE = (state, { id }) => ({
  ...state,
  selectedId: id
})

reducer.SERVICE_FORM_SAVE = state => ({
  ...state
})

export default reducer
