import { generateDateFormat } from '../../Utils'
import { store } from '../../Store'

export const getCustomDateFormat = (options) => {
  const { isShort } = options || {}
  const state = store.getState()
  const { selectedId, list: companies } = state.companies || {}
  const company = companies.find(item => item.id === selectedId)
  const { settings } = company || {}
  let { dateFormat } = settings || {}
  dateFormat = dateFormat || {}
  const hasCustomDateFormat = Object.values(dateFormat).length > 0 && !Object.values(dateFormat).every(item => item === null)
  const dateFormatValues = {
    position: dateFormat.dateFormatPosition,
    year: dateFormat.dateFormatYear,
    month: dateFormat.dateFormatMonth,
    day: dateFormat.dateFormatDay,
    separator: dateFormat.dateFormatSeparator,
    isShort
  }

  if (!hasCustomDateFormat) return null
  return generateDateFormat(dateFormatValues)
}
