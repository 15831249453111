import router from '../Router'
import forms from '../Forms'
import account from '../Components/Account'
import app from '../Components/App'
import attributes from '../Components/Attributes'
import auth from '../Components/Auth'
import availability from '../Components/Availability'
import booking from '../Components/Booking'
import companies from '../Components/Companies'
import courses from '../Components/Courses'
import customerFields from '../Components/CustomerFields'
import enterprise from '../Components/Enterprise'
import offers from '../Components/Offers'
import partner from '../Components/Partner'
import services from '../Components/Services'
import slots from '../Components/Slots'
import resources from '../Components/Resources'
import pages from '../Components/Pages'
import timezones from '../Components/Timezones'
import conflict from '../Components/Conflict'
import stripe from '../Components/Stripe'
import paypal from '../Components/PayPal'
import upselling from '../Components/Upselling'
import customisations from '../Components/Customisations'
import phrase from '../Components/Phrase'
import debugOptions from '../Components/DebugOptions'
import persist from '../Components/Persist'
import countries from '../Components/Countries'
// This name will be available in store object
export default {
  router,
  forms,
  account,
  app,
  attributes,
  auth,
  availability,
  booking,
  companies,
  conflict,
  countries,
  courses,
  customerFields,
  customisations,
  enterprise,
  offers,
  partner,
  services,
  slots,
  resources,
  pages,
  timezones,
  stripe,
  paypal,
  upselling,
  phrase,
  debugOptions,
  persist
}
