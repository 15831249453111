import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t, FontAwesome5, OutsideClick } from '../../Common'
import { PATH_TO_CIRCLE_FLAGS, languages } from '../../Settings'
import { handlers } from '../../Store'

import './LanguageSelector.css'

class LanguageSelector extends Component {
  constructor (props, context) {
    super(props)

    this.state = { isOpen: false }

    this.changeOpenState = this.changeOpenState.bind(this)
    this.onChangeLanguage = this.onChangeLanguage.bind(this)
  }

  changeOpenState (newState) {
    let { isOpen } = this.state
    isOpen = typeof newState === 'boolean' ? newState : !isOpen
    this.setState({ isOpen: isOpen })
    handlers.languageOptionsOpen(isOpen)
  }

  onChangeLanguage (language) {
    handlers.languageChange(language.locale)
    handlers.languageChangedManually()
    this.setState({ isOpen: false })
  }

  render () {
    const { locale } = this.props
    const { isOpen } = this.state
    const classNames = ['ta-language-selector']
    const availableLanguages = languages.filter(lang => lang.active === true)
    const selectedLocale = languages.find(lang => lang.availableLocales.includes(locale))
    if (isOpen) { classNames.push('ta-language-selector__open') }

    return (
      <OutsideClick onClickOutside={() => this.changeOpenState(false)}>
        <div className={classNames.join(' ')}>
          <div className='ta-language-selector__selector' onClick={this.changeOpenState}>
            {selectedLocale && selectedLocale.flag &&
              <img src={`${PATH_TO_CIRCLE_FLAGS}/${selectedLocale.flag}`} alt='' />
            }
            <FontAwesome5 icon='sort' type='solid' className='ta-language-selector__arrow' />
          </div>
          <div className='ta-language-selector__options'>
            <div className='ta-language-selector__title'>
              {t('header.languageSelect.section.options.title')}
              <div className='ta-language-selector__close' onClick={() => this.changeOpenState(false)}>
                <FontAwesome5 icon='times' type='regular' />
              </div>
            </div>
            {availableLanguages.map((language, idx) => (
              <button key={`language-selector-button-${idx}`} onClick={() => this.onChangeLanguage(language)}>
                {language.flag &&
                  <img src={`${PATH_TO_CIRCLE_FLAGS}/${language.flag}`} alt='' />
                }
                {language.name}
              </button>
            ))}
          </div>
        </div>
      </OutsideClick>
    )
  }
}

LanguageSelector.propTypes = {
  locale: PropTypes.string.isRequired,
  onSelect: PropTypes.func
}

export default LanguageSelector
