const reducer = {}

reducer.initialState = {}

reducer.handlers = (dispatch, actions) => ({
  accountReset: () => dispatch(actions.ACCOUNT_RESET),
  accountPopulate: data => dispatch(actions.ACCOUNT_POPULATE, data)
})

reducer.ACCOUNT_RESET = state => reducer.initialState

reducer.ACCOUNT_POPULATE = (state, data) => ({
  ...state,
  ...data
})

export default reducer
