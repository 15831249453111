import _ from 'lodash'

export default (...shapes) => {
  // find the min length
  if (!shapes || !shapes.length) return [[]]
  // see which is the minimum number of dependencies in all the shapes
  const minShapeDependencies = Math.min(...shapes.map(shape => shape.length))
  if (!minShapeDependencies) return [[]]
  // now reduce for each shape, dependency with dependency by intersecting the resourceIds in each corresponding dependency index
  return shapes.reduce((accumulatedShape, shape) => {
    return shape.slice(0, minShapeDependencies).reduce((temporaryShape, resourceIds, index) => {
      temporaryShape[index] = accumulatedShape[index] ? _.intersection(accumulatedShape[index], resourceIds) : resourceIds
      return temporaryShape
    }, [])
  }, [])
}
