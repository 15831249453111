import { registerRoute, registerAfterEach, getRoute } from '../Router'
import { store } from '../../Store'
import { t } from '../../Common'
import { getRouteQuery, sortByOrderIndex, sortBy, sortByCategoryOrderIndex } from '../../Utils'
import { DEFAULT_LOCALE_COUNTRY_CODE } from '../../Settings'

registerAfterEach((route, state, handlers) => {
  const debug = route.query && route.query.debug
  const persistPayload = {}
  if (debug === 'true') persistPayload.isDebuggingActive = true
  if (debug === 'false') {
    persistPayload.isDebuggingActive = false
    persistPayload.isPhraseActive = false
  }
  handlers.persistSet(persistPayload)
})

// LOCATION SELECTOR

registerRoute('locations', {
  pattern: '/locations',
  onAfter: (route, state, handlers) => {
    handlers.pageLocationsLoaded()
  }
})

// INDEX

registerRoute('home', {
  pattern: '/'
})

// SERVICES

registerRoute('services', {
  pattern: '/services',
  onAfter: (route, state, handlers) => {
    handlers.pageServicesLoaded()
  }
})

// AVAILABILITY

registerRoute('availability', {
  pattern: '/availability',
  onAfter: (route, state, handlers) => {
    handlers.pageAvailabilityLoaded()
  }
})

// AUTH

registerRoute('auth', {
  pattern: '/auth',
  onAfter: (route, state, handlers) => {
    handlers.pageAuthLoaded()
  }
})
registerRoute('authLogin', {
  pattern: '/auth/login'
})
registerRoute('authForgotten', {
  pattern: '/auth/forgotten'
})
registerRoute('authRegister', {
  pattern: '/auth/register'
})
registerRoute('authRegisterFacebook', {
  pattern: '/auth/register/facebook'
})
registerRoute('authConfirmation', {
  pattern: '/auth/confirmation'
})
registerRoute('authChangePhone', {
  pattern: '/auth/changephone'
})
registerRoute('authGuestBooking', {
  pattern: '/auth/guestbooking'
})
registerRoute('authEnforcePasswordChange', {
  pattern: '/auth/enforcePasswordChange'
})

// CUSTOM FIELDS

registerRoute('customerFields', {
  pattern: '/customer-fields',
  onBefore: (route, state, handlers) => {
    const booker = (state.account && state.account.booker) || {}
    const { companies, customerFields, forms, services, courses } = state
    const { oldPath } = route || {}
    const oldRoute = getRoute(oldPath || '')
    const isRoutingBack = ['confirm', 'payments', 'conflict'].includes(oldRoute.name)
    const { list: companiesList, selectedId: selectedCompanyId } = companies
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId)
    let { locale: countryPhoneCode = DEFAULT_LOCALE_COUNTRY_CODE } = selectedCompany || {}
    const { list } = customerFields
    const selectedItemId = services.selectedId || courses.selectedId
    const { customerFieldsBeforeConfirmation = {} } = forms
    if (
      (Object.keys(customerFieldsBeforeConfirmation).length === 0 || !!booker) && // add booker, bc if you open guest booking form first, data fields are not prefilled after Timify login
      !isRoutingBack // dont repopulate fields if its routing back, since we need to preserve the current field values
    ) {
      let customerFieldsList = list
        .filter(item => selectedCompany && item.companyId === selectedCompany.id && ((services.selectedId && item.areAllServicesSelected) || item.serviceIds.includes(selectedItemId) || (courses.selectedId && item.areAllCoursesSelected) || item.courseIds.includes(selectedItemId)))
        .map(item => {
          if (item.type === 'SELECT') {
            item.selectOptions = item?.selectOptions?.map?.(item => {
              return {
                ...item,
                label: item.translationKey ? t(item.translationKey) : (item.label || item.value)
              }
            })
          }
          return item
        })
      const nameCustomerFields = customerFieldsList.filter(item => ['firstName', 'lastName', 'company'].includes(item.defaultId))
      if (nameCustomerFields.length === 0) {
        customerFieldsList = list
          .filter(item => ['firstName', 'lastName', 'company'].includes(item.defaultId))
          .sort(sortByOrderIndex) || []
      } else {
        customerFieldsList = customerFieldsList
          .sort(sortByOrderIndex)
          .sort(sortBy('internalId', false, true)) // put globals last, but ignore default data fields, bc they will not get ordered by orderIndex if you apply this sort without last param
          .sort(sortByCategoryOrderIndex) || []
      }
      countryPhoneCode = countryPhoneCode.split('-')
      countryPhoneCode = (countryPhoneCode[1] || DEFAULT_LOCALE_COUNTRY_CODE).toUpperCase()
      handlers.customerFieldsFormPopulate(customerFieldsList, countryPhoneCode, booker)
    }
    handlers.formErrorsSet('customerFieldsBeforeConfirmation', [])
  },
  onAfter: (route, state, handlers) => {
    const stateChanged = store.getState()
    const { router, companies, forms, attributes, services, courses, customerFields, slots } = stateChanged
    const routeQuery = getRouteQuery(router)
    const { skipCustomerFieldsSection, customerField: attributeFields = {}, externalCustomerField: externalAttributeFields = {} } = attributes || {}
    const { customerFieldsBeforeConfirmation = {} } = forms

    if (skipCustomerFieldsSection && Object.keys(customerFieldsBeforeConfirmation).length > 0) {
      const { selectedId: selectedCompanyId } = companies
      const { list: customerFieldsList } = customerFields
      const { selectedId: selectedServiceId, list: servicesList = [] } = services
      const { selectedId: selectedCourseId } = courses
      const { selectedEventId, coursesList = [] } = slots
      const selectedItemId = selectedServiceId || selectedCourseId
      const selectedService = servicesList.find(s => s.id === selectedServiceId)
      const selectedEvent = coursesList.find(c => c.id === selectedEventId)
      const filteredCustomerFields = customerFieldsList.filter(item =>
        !item.value && !attributeFields[item.id] && !externalAttributeFields[item.externalId] &&
        item.companyId === selectedCompanyId &&
        item.isAfterAuth &&
        ((selectedServiceId && item.areAllServicesSelected) || item.serviceIds.includes(selectedItemId) || (selectedCourseId && item.areAllCoursesSelected) || item.courseIds.includes(selectedItemId))
      )
      if (filteredCustomerFields.length === 0) {
        if ((selectedService && selectedService.hasOnlinePayment) || (selectedEvent && selectedEvent.hasOnlinePayment)) handlers.navigateToPath(`/payments${routeQuery}`)
        else handlers.navigateToPath(`/confirm${routeQuery}`)
      }
    }

    handlers.pageCustomerFieldsLoaded()
  }
})

// PAYMENT

registerRoute('payments', {
  pattern: '/payments',
})

registerRoute('paymentCreditCard', {
  pattern: '/payments/credit-card',
})

registerRoute('paymentOnlinePayment', {
  pattern: '/payments/online-payment',
})

registerRoute('paymentStripe', {
  pattern: '/payments/stripe',
})

// CONFIRM

registerRoute('confirm', {
  pattern: '/confirm',
  onAfter: (route, state, handlers) => {
    handlers.pageConfirmLoaded()
  }
})
registerRoute('confirmParticipants', {
  pattern: '/confirm/participants'
})

// CONFLICT

registerRoute('conflict', {
  pattern: '/conflict',
  onAfter: async (route, state, handlers) => {
    handlers.pageConflictLoaded()
  }
})

// SUCCESS

registerRoute('success', {
  pattern: '/success'
})

// NOT FOUND

registerRoute('notFound', {
  pattern: '/not-found'
})

// BOOKED OUT

registerRoute('bookedOut', {
  pattern: '/booked-out'
})
