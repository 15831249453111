const flattenMessages = (o, key = '', res = {}) => {
  Object
    .keys(o)
    .forEach(k => {
      let v = o[k]
      typeof v === 'object'
        ? flattenMessages(v, key + k + '.', res)
        : res[key + k] = v
    })
  return res
}

export default flattenMessages
