import React from 'react'

const SimpleListItemButtons = props => {
  const { children } = props

  return (
    <div className='ta-simple-list-item__buttons'>{children}</div>
  )
}

export default SimpleListItemButtons
