import initialState from '../Config/formsInitialState'

const reducer = { initialState }

reducer.handlers = (dispatch, actions) => ({
  formFieldsUpdate: (form, fields) => dispatch(actions.FORM_FIELDS_UPDATE, { form, fields }),
  formSet: (form, fields = reducer.initialState[form]) => dispatch(actions.FORM_SET, { form, fields }),
  formErrorsUpdate: (form, errors) => dispatch(actions.FORM_ERRORS_UPDATE, { form, errors }),
  formErrorsSet: (form, errors) => dispatch(actions.FORM_ERRORS_SET, { form, errors })
})

reducer.FORM_ERRORS_UPDATE = (state, { form, errors = [] }) => {
  if (!form) return state
  const obj = { ...state[form] }
  errors.forEach(err => {
    if (!err || !err.key) return
    obj[err.key] = {
      ...obj[err.key],
      error: err.value,
      errors: [...(obj[err.key].errors || []), err]
    }
  })
  return { ...state, [form]: obj }
}

reducer.FORM_ERRORS_SET = (state, { form, errors = [] }) => {
  if (!form) return state
  const obj = { ...state[form] }
  Object
    .keys(obj)
    .filter(k => obj[k] !== null && typeof obj[k] === 'object')
    .forEach(key => {
      obj[key].error = ''
      obj[key].errors = []
    })
  errors.forEach(err => {
    if (!err || !err.key) return
    obj[err.key] = {
      ...obj[err.key],
      error: err.value,
      errors: obj[err.key]
        ? [...(obj[err.key].errors || []), { ...err }]
        : [{ ...err }]
    }
  })
  return { ...state, [form]: obj }
}

reducer.FORM_FIELDS_UPDATE = (state, { form, fields }) => {
  if (!form) return state
  const fieldsWithoutErrors = {}
  Object
    .keys(fields)
    .forEach(field => {
      fieldsWithoutErrors[field] = { ...fields[field], error: null, errors: [] }
    })
  return { ...state, [form]: { ...state[form], ...fieldsWithoutErrors, isEditMode: true } }
}

reducer.FORM_SET = (state, { form, fields }) => form
  ? { ...state, [form]: { ...fields } }
  : state

export default reducer
