import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '../../Common'

import './EnterpriseLoader.css'

const EnterpriseLoader = props => {
  const {
    isActive,
    hideBackground,
    style
  } = props
  const classNames = ['ta-enterprise-loader']
  if (isActive) classNames.push('active')
  if (hideBackground) classNames.push('no-background')

  return (
    <div className={classNames.join(' ')} style={style}>
      <div className='ta-enterprise-loader__box'>
        <FontAwesome5 type='solid' icon='spin' className='fa-spinner' />
      </div>
    </div>
  )
}

EnterpriseLoader.propTypes = {
  isActive: PropTypes.bool,
  hideBackground: PropTypes.bool,
  style: PropTypes.object
}

export default EnterpriseLoader
