const reducer = {}

reducer.initialState = {
  error: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  conflictErrorReset: () => dispatch(actions.CONFLICT_ERROR_RESET),
  conflictErrorPopulate: (error) => dispatch(actions.CONFLICT_ERROR_POPULATE, { error })
})

reducer.CONFLICT_ERROR_RESET = state => reducer.initialState

reducer.CONFLICT_ERROR_POPULATE = (state, { error }) => ({
  ...state,
  error: error || null
})

export default reducer
