import { companiesTransform } from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  selectedId: null,
  customUnavailableMessage: ''
}

reducer.handlers = (dispatch, actions) => ({
  companiesReset: () => dispatch(actions.COMPANIES_RESET),
  companiesPopulate: companies => dispatch(actions.COMPANIES_POPULATE, companiesTransform(companies)),
  companySelect: (id, goToNext) => dispatch(actions.COMPANY_SELECT, { id, goToNext }),
  companySelectId: (id) => dispatch(actions.COMPANY_SELECT_ID, { id }),
  companyCustomUnavailableMessageSet: (message) => dispatch(actions.COMPANY_CUSTOM_UNAVAILABLE_MESSAGE_SET, { message })
})

reducer.COMPANIES_RESET = state => reducer.initialState

reducer.COMPANIES_POPULATE = (state, companies) => ({
  ...state,
  list: companies
})

reducer.COMPANY_SELECT = (state, company) => ({
  ...state,
  selectedId: company.id
})

reducer.COMPANY_SELECT_ID = (state, company) => ({
  ...state,
  selectedId: company.id
})

reducer.COMPANY_CUSTOM_UNAVAILABLE_MESSAGE_SET = (state, { message }) => ({
  ...state,
  customUnavailableMessage: message
})

export default reducer
