import { store } from '../../../Store'
import {
  customerFieldsTransform,
  customerFieldCategoriesTransform,
  customerFieldsFormTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  isFormPending: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  customerFieldsReset: () => dispatch(actions.CUSTOMER_FIELDS_RESET),
  customerFieldsPopulate: ({ customerFields, customerFieldCategories }) => dispatch(actions.CUSTOMER_FIELDS_POPULATE, { customerFields: customerFieldsTransform(customerFields), customerFieldCategories: customerFieldCategoriesTransform(customerFieldCategories) }),
  customerFieldsFormPopulate: (customerFields, countryPhoneCode, booker) => {
    const attributes = store.getState().attributes
    const { customerField = {}, externalCustomerField = {} } = attributes
    handlers.formSet('customerFieldsBeforeConfirmation', customerFieldsFormTransform(customerFields, countryPhoneCode, booker, customerField, externalCustomerField))
  },
  customerFieldsFormReady: () => dispatch(actions.CUSTOMER_FIELDS_FORM_READY),
  customerFieldsFormSave: (fields, scrollToError = () => { }) => dispatch(actions.CUSTOMER_FIELDS_FORM_SAVE, { fields, scrollToError })
})

reducer.CUSTOMER_FIELDS_RESET = state => reducer.initialState

reducer.CUSTOMER_FIELDS_POPULATE = (state, { customerFields, customerFieldCategories }) => ({
  ...state,
  list: customerFields,
  categoriesList: customerFieldCategories
})

reducer.CUSTOMER_FIELDS_FORM_READY = state => ({
  ...state,
  isFormPending: false
})

reducer.CUSTOMER_FIELDS_FORM_SAVE = state => ({
  ...state,
  isFormPending: true
})

export default reducer
