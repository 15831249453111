import React, { PureComponent } from 'react'
import moment from 'moment-timezone'
import { connect, handlers } from '../../Store'
import { Week } from '../../Beauties'

class WeekWrapper extends PureComponent {
  constructor (props) {
    super(props)

    this.state = { isCollapsed: true }

    this.onClickCollapse = this.onClickCollapse.bind(this)
  }

  componentDidMount () {
    const { collapseSlots } = this.props
    if (collapseSlots) this.setState({ isCollapsed: !collapseSlots })
  }

  onClickCollapse () {
    const { isCollapsed } = this.state
    this.setState({ isCollapsed: !isCollapsed })
  }

  render () {
    const {
      item,
      selectedMinutes,
      locale
    } = this.props
    const { isCollapsed } = this.state
    const { day = moment().format('YYYY-MM-DD'), slots = [] } = item

    const onClickSlot = (day, minutes, offerId) => {
      handlers.slotSelect({ day, minutes, offerId })
    }

    return (
      <Week
        day={day}
        slots={slots}
        selectedMinutes={selectedMinutes}
        onClickSlot={onClickSlot}
        isCollapsed={isCollapsed}
        onClickCollapse={this.onClickCollapse}
        locale={locale}
      />
    )
  }
}

const maps = state => ({
  selectedMinutes: state.slots.selectedMinutes || null,
  locale: state.app.locale || 'en-gb',
  collapseSlots: (state.attributes && state.attributes.collapseSlots) || null
})

export default connect(maps)(WeekWrapper)
