import React from 'react'
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps'

let mapInited = false
let _map
const Map = withGoogleMap((props) => {
  const {
    lat = 48.1926301,
    lng = 11.56950489999997,
    isDraggable,
    zoom = 15,
    defaultOptions = {},
    onDragEnd = null
  } = props

  const mapOptions = {
    draggable: isDraggable,
    defaultZoom: zoom,
    defaultCenter: { lat, lng },
    center: { lat, lng },
    zoom: zoom,
    defaultOptions: defaultOptions
  }

  if (onDragEnd) {
    mapOptions.onIdle = () => {
      if (!mapInited) {
        mapInited = true
        onDragEnd(_map)
      }
    }
    mapOptions.onDragEnd = () => {
      onDragEnd(_map)
    }
    mapOptions.onClick = () => {
      onDragEnd(_map)
    }
  }

  const markerOptions = {
    position: { lat, lng },
    icon: {
      url: 'data:image/svg+xml;charset=UTF-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDE3IDIyIj4KICA8cGF0aCBmaWxsPSIjQ0IzQjQzIiBkPSJNMjE4NC4zMDMwNCw0NDUuMDI5OTQ1IEMyMTg5Ljk0MDgzLDQzNi4yNTYxNzMgMjE5MSw0MzUuMzA1MTk1IDIxOTEsNDMyLjI1MDAwNiBDMjE5MSw0MjcuNjkxMTYxIDIxODcuMTkyMSw0MjQgMjE4Mi41LDQyNCBDMjE3Ny44MDMwMSw0MjQgMjE3NCw0MjcuNjk1OTAzIDIxNzQsNDMyLjI1MDAwNiBDMjE3NCw0MzUuMzA1ODI1IDIxNzUuMDYyMTEsNDM2LjI2MDc0NyAyMTgwLjY5NjkxLDQ0NS4wMjk5NDUgQzIxODEuNTI4NTEsNDQ2LjMyNDEyOCAyMTgzLjQ3MjQsNDQ2LjMyMjU3NSAyMTg0LjMwMzA0LDQ0NS4wMjk5NDUgWiBNMjE4Mi41LDQ0NCBDMjE3Ni43NDgyMyw0MzUuMTU5NDcxIDIxNzYsNDM0LjYwMTQwOCAyMTc2LDQzMi4yMzA3NjkgQzIxNzYsNDI4Ljc4OTU5OCAyMTc4LjkxMDE0LDQyNiAyMTgyLjUsNDI2IEMyMTg2LjA4OTg2LDQyNiAyMTg5LDQyOC43ODk1OTggMjE4OSw0MzIuMjMwNzY5IEMyMTg5LDQzNC41OTEyMjQgMjE4OC4zMTU0Miw0MzUuMDYxNjUgMjE4Mi41LDQ0NCBaIE0yMTgyLjUsNDM2IEMyMTg0LjQzMjk5LDQzNiAyMTg2LDQzNC40MzI5OSAyMTg2LDQzMi41IEMyMTg2LDQzMC41NjcwMSAyMTg0LjQzMjk5LDQyOSAyMTgyLjUsNDI5IEMyMTgwLjU2NzAxLDQyOSAyMTc5LDQzMC41NjcwMSAyMTc5LDQzMi41IEMyMTc5LDQzNC40MzI5OSAyMTgwLjU2NzAxLDQzNiAyMTgyLjUsNDM2IFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMTc0IC00MjQpIi8+Cjwvc3ZnPgo='
    }
  }

  return (
    <GoogleMap
      ref={map => { _map = map }}
      getDraggable={(x, y) => {}}
      {...mapOptions}>

      {props.isMarkerShown &&
        <Marker {...markerOptions} />
      }
      {props.markers && props.markers.length > 0 &&
        props.markers.map((location, index) => {
          const markerOptions = {
            position: { lat: location.lat, lng: location.lng },
            icon: {
              url: 'data:image/svg+xml;charset=UTF-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDE3IDIyIj4KICA8cGF0aCBmaWxsPSIjQ0IzQjQzIiBkPSJNMjE4NC4zMDMwNCw0NDUuMDI5OTQ1IEMyMTg5Ljk0MDgzLDQzNi4yNTYxNzMgMjE5MSw0MzUuMzA1MTk1IDIxOTEsNDMyLjI1MDAwNiBDMjE5MSw0MjcuNjkxMTYxIDIxODcuMTkyMSw0MjQgMjE4Mi41LDQyNCBDMjE3Ny44MDMwMSw0MjQgMjE3NCw0MjcuNjk1OTAzIDIxNzQsNDMyLjI1MDAwNiBDMjE3NCw0MzUuMzA1ODI1IDIxNzUuMDYyMTEsNDM2LjI2MDc0NyAyMTgwLjY5NjkxLDQ0NS4wMjk5NDUgQzIxODEuNTI4NTEsNDQ2LjMyNDEyOCAyMTgzLjQ3MjQsNDQ2LjMyMjU3NSAyMTg0LjMwMzA0LDQ0NS4wMjk5NDUgWiBNMjE4Mi41LDQ0NCBDMjE3Ni43NDgyMyw0MzUuMTU5NDcxIDIxNzYsNDM0LjYwMTQwOCAyMTc2LDQzMi4yMzA3NjkgQzIxNzYsNDI4Ljc4OTU5OCAyMTc4LjkxMDE0LDQyNiAyMTgyLjUsNDI2IEMyMTg2LjA4OTg2LDQyNiAyMTg5LDQyOC43ODk1OTggMjE4OSw0MzIuMjMwNzY5IEMyMTg5LDQzNC41OTEyMjQgMjE4OC4zMTU0Miw0MzUuMDYxNjUgMjE4Mi41LDQ0NCBaIE0yMTgyLjUsNDM2IEMyMTg0LjQzMjk5LDQzNiAyMTg2LDQzNC40MzI5OSAyMTg2LDQzMi41IEMyMTg2LDQzMC41NjcwMSAyMTg0LjQzMjk5LDQyOSAyMTgyLjUsNDI5IEMyMTgwLjU2NzAxLDQyOSAyMTc5LDQzMC41NjcwMSAyMTc5LDQzMi41IEMyMTc5LDQzNC40MzI5OSAyMTgwLjU2NzAxLDQzNiAyMTgyLjUsNDM2IFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMTc0IC00MjQpIi8+Cjwvc3ZnPgo=',
              scaledSize: new window.google.maps.Size(30, 30)
            }
          }

          if (location.onClick) markerOptions.onClick = location.onClick

          return (
            <Marker key={index} {...markerOptions} />
          )
        })
      }
    </GoogleMap>
  )
})

const GoogleMaps = props => {
  const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places`
  const styles = { height: '100%' }

  return (
    <Map
      isMarkerShown
      googleMapURL={googleMapURL}
      loadingElement={<div style={styles} />}
      containerElement={<div style={styles} />}
      mapElement={<div style={styles} />}
      {...props}
    />
  )
}

export default GoogleMaps
