import * as phoneParser from 'libphonenumber-js/max'
import { EMAIL_VALIDATION_REGEX, PHONE_VALIDATION_REGEX } from '../Settings'

const getFromObject = (defaultReturn, params, object) => params.reduce((xs, x) => (xs && xs[x]) ? xs[x] : defaultReturn, object)

function isArray (data) {
  return Object.prototype.toString.call(data) === '[object Array]'
}

function isObject (data) {
  return data !== null && typeof data === 'object'
}

function empty (value) {
  if (isObject(value) || isArray(value)) {
    return value.length === 0
  } else {
    return value === null || typeof value === 'undefined' || value === ''
  }
}

export default function (form, rules, messages = {}, replaces = {}) {
  const errors = []
  const defaultMessages = {
    email: 'errors.email.required',
    phone: 'errors.phone.required',
    required: 'errors.required',
    requiredIf: 'errors.required',
    min: 'errors.invalidMinLength',
    max: 'errors.invalidMaxLength',
    array: '',
    object: ''
  }

  const defaultReplaces = {
    required: { key: 'FIELD_NAME', value: 'global.theField', translateReplace: true }
  }

  rules && rules.forEach((rule, index) => {
    const key = Object.keys(rule)[0]
    const fieldName = key.split('.')[0]
    const reversedRules = rule[key]

    reversedRules && reversedRules.reverse().forEach(option => {
      const value = getFromObject(null, key.split('.'), form)
      const ruleName = option.split(':')[0]
      const ruleParams = option.split(':')[1] || null
      const errorMessage = (messages[fieldName] && messages[fieldName][ruleName]) || defaultMessages[ruleName]
      const replace = (replaces[fieldName] && replaces[fieldName][ruleName]) || defaultReplaces[ruleName]

      switch (ruleName) {
        case 'required':
          if (isArray(value) || isObject(value)) {
            if (value.length === 0) {
              errors.push({
                key: fieldName,
                value: errorMessage,
                replace: [replace]
              })
            }
          } else if (
            value === null ||
            value === false ||
            typeof value === 'undefined' ||
            (typeof value === 'string' && value.trim()) === '' ||
            (typeof value === 'object' && Object.keys(value).length === 0) ||
            (typeof value === 'object' && Object.values(value).length === 0)
          ) {
            errors.push({
              key: fieldName,
              value: errorMessage,
              replace: [replace]
            })
          }
          break

        case 'requiredIf':
          if (ruleParams === 'true') {
            if (isArray(value) || isObject(value)) {
              if (value.length === 0) {
                errors.push({
                  key: fieldName,
                  value: errorMessage,
                  replace: [replace]
                })
              }
            } else if (
              value === null ||
              !value ||
              typeof value === 'undefined' ||
              value.trim() === '' ||
              Object.keys(value).length === 0 ||
              Object.values(value).length === 0
            ) {
              errors.push({
                key: fieldName,
                value: errorMessage,
                replace: [replace]
              })
            }
          }
          break

        case 'email':
          if (!empty(value) && !EMAIL_VALIDATION_REGEX.test(value)) {
            errors.push({
              key: fieldName,
              value: errorMessage,
              replace: [replace]
            })
          }
          break

        case 'min':
          if (isArray(value) || isObject(value)) {
            if (value.length < ruleParams) {
              errors.push({
                key: fieldName,
                value: errorMessage,
                replace: [replace]
              })
            }
          } else if (value && value.toString().trim().length < Number(ruleParams)) {
            errors.push({
              key: fieldName,
              value: errorMessage,
              replace: [replace]
            })
          }
          break

        case 'max':
          if (isArray(value) || isObject(value)) {
            if (value.length > ruleParams) {
              errors.push({
                key: fieldName,
                value: errorMessage,
                replace: [replace]
              })
            }
          } else if (value && value.toString().trim().length > Number(ruleParams)) {
            errors.push({
              key: fieldName,
              value: errorMessage,
              replace: [replace]
            })
          }
          break

        case 'phone':
          if (!empty(value)) {
            if (!PHONE_VALIDATION_REGEX.test(value)) {
              errors.push({
                key: fieldName,
                value: errorMessage,
                replace: [replace]
              })
            } else {
              const phoneCountryCodeFields = key.split('.')
              phoneCountryCodeFields[phoneCountryCodeFields.length - 1] = 'phoneCountry'
              const phoneCountryCode = getFromObject(null, phoneCountryCodeFields, form)

              if (!phoneCountryCode || !value || !phoneParser.parsePhoneNumberFromString(value, phoneCountryCode.toUpperCase()).isValid()) {
                errors.push({
                  key: fieldName,
                  value: errorMessage,
                  replace: [replace]
                })
              }
            }
          }

          break

        case 'array':
          if (!isArray(value)) {
            errors.push({
              key: fieldName,
              value: errorMessage,
              replace: [replace]
            })
          }
          break

        case 'object':
          if (!isObject(value)) {
            errors.push({
              key: fieldName,
              value: errorMessage,
              replace: [replace]
            })
          }
          break

        default:
          break
      }
    })
  })

  const errorsObj = errors.reduce((acc, item) => {
    if (!acc[item.key]) acc[item.key] = item
    return acc
  }, {})

  return Object.values(errorsObj)
}
