import React from 'react'
import DOMPurify from 'dompurify'
import { encodeHtml } from '../Utils'

export default (text, doHtmlEncoding) => {
  if (!text) return ''
  text = DOMPurify.sanitize(text)
  if (doHtmlEncoding) text = encodeHtml(text)
  return <span dangerouslySetInnerHTML={{ __html: text.replace(/\r?\n/g, '<br />') }} />
}
