import React from 'react'
import { Input, LinedTitle, DashedTitle, Message, Price, Error, t } from '../../Common'

import './CoursesExtraPersonsPerParticipantForm.css'

const CoursesExtraPersonsPerParticipantForm = props => {
  const { selectedEvent, extraParticipants, onConfirmPage, onChange } = props
  const eventPrice = (selectedEvent && selectedEvent.price) || 0
  const additionalParticipantsCount = parseInt(extraParticipants, 10)
  const totalPrice = eventPrice * (1 + (!isNaN(additionalParticipantsCount) ? additionalParticipantsCount : 0))

  return (
    <div className='ta-courses-extra-persons__container'>
      {!onConfirmPage && <LinedTitle label={t('groups.extraParticipantsCount.title')} />}
      {onConfirmPage && <DashedTitle label={t('groups.extraParticipantsCount.confirm.title')} />}

      <Input
        value={extraParticipants}
        mandatory={false}
        addon={t('groups.extraParticipantsCount.participants')}
        hintText={t('groups.extraParticipantsCount.input.note', [{ key: 'COUNT', value: (selectedEvent && selectedEvent.extraPersonsPerParticipant) || 0 }])}
        onChange={onChange}
      />
      <Error name='extraParticipantsErrors' />

      {(selectedEvent && selectedEvent.extraPersonsPerParticipant < additionalParticipantsCount &&
        <div className='ta-form-error active'>
          {t('groups.extraParticipantsCount.message.reach_the_limit', [{ key: 'COUNT', value: (selectedEvent && selectedEvent.extraPersonsPerParticipant) || 0 }])}
        </div>
      )}

      <Message
        type='warning'
        className='ta-courses-extra-persons__note'
        show={totalPrice > 0}
      >
        {t('groups.extraParticipantsCount.note')}
        <Price price={totalPrice} />
      </Message>
    </div>
  )
}

export default CoursesExtraPersonsPerParticipantForm
