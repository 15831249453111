import { payloads$, actions, store, handlers } from '../../../Store'
import { getRouteQuery } from '../../../Utils'
import { serviceCustomerFieldFormValidate } from './utils'

// Save
const setServiceCustomerFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('customerFieldsOnService', errors)
  scrollToError && scrollToError(errors)
}

payloads$(actions.SERVICE_SELECT)
  .subscribe(async ({ id }) => {
    if (!id) return
    const { app: storeApp = {}, services: storeServices = {}, companies: storeCompanies = {} } = store.getState()
    const { locale: appLocale = 'en-gb' } = storeApp
    const { list: companies = [], selectedId: selectedCompanyId = null } = storeCompanies
    const { list: services = [] } = storeServices
    const selectedService = services.find(s => s.id === id)
    const selectedCompany = companies.find(c => c.id === selectedCompanyId)
    handlers.upsellingSelectedOriginalServiceId(id)

    // Send message to the parent
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage('timify:widget:servicesSelect', '*')
      window.parent.postMessage({
        action: 'timify:widget:page:servicesSelect',
        language: appLocale.substring(0, 2).toUpperCase(),
        country: (selectedCompany && selectedCompany.locale ? selectedCompany.locale.substring(3, 5).toUpperCase() : ''),
        companyId: selectedCompanyId,
        companyExternalId: (selectedCompany && selectedCompany.externalId) || null,
        id: id,
        name: (selectedService && selectedService.name) || '',
        serviceExternalId: (selectedService && selectedService.externalId) || null,
        type: 'SERVICE'
      }, '*')
    }
  })

payloads$(actions.SERVICE_FORM_SAVE)
  .subscribe(async ({ fields, scrollToError }) => {
    const errors = serviceCustomerFieldFormValidate(fields)
    if (errors.length) return setServiceCustomerFormSaveErrors(errors, scrollToError)

    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/availability${routeQuery}`)
  })
