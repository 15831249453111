import React, { Component } from 'react'
import { connect, handlers, store } from '../../../Store'
import { CoursesExtraParticipants } from '../../../Beauties'
import { getRouteQuery } from '../../../Utils'

class ExtraParticipantsWrapper extends Component {
  constructor (props) {
    super(props)

    this.state = {
      participants: 0
    }

    this.onCloseClick = this.onCloseClick.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevProps.isActive && this.props.isActive) {
      const { extraParticipants } = this.props
      let additionalParticipantsCount = parseInt(extraParticipants, 10)
      if (isNaN(additionalParticipantsCount)) additionalParticipantsCount = 0
      this.setState({ participants: additionalParticipantsCount })
    }
  }

  onSubmit (cb) {
    const { coursesList, selectedEventId, extraParticipants } = this.props
    const selectedEvent = coursesList.find(event => event.id === selectedEventId)
    let additionalParticipantsCount = parseInt(extraParticipants, 10)
    if (isNaN(additionalParticipantsCount)) additionalParticipantsCount = 0

    if (selectedEvent && selectedEvent.extraPersonsPerParticipant < additionalParticipantsCount) return

    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/confirm${routeQuery}`)
  }

  onCloseClick () {
    const { participants } = this.state
    handlers.courseSetExtraParticipants(participants)
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/confirm${routeQuery}`)
  }

  render () {
    const { routeName } = this.props
    const isActive = routeName === 'confirmParticipants'

    return (
      <CoursesExtraParticipants
        isActive={isActive}
        onCloseClick={this.onCloseClick}
        onSubmit={this.onSubmit}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  coursesList: state.slots.coursesList || [],
  selectedEventId: state.slots.selectedEventId || null,
  extraParticipants: state.courses.extraParticipants || ''
})

export default connect(maps)(ExtraParticipantsWrapper)
