import {
  onlineDataFields,
  onlineDataEnterpriseFields,
  serviceAvailabilityFields,
  courseAvailabilityFields,
  onlineEventReservation,
  eventReservation,
  onlineEventStatusFields
} from './fields'

// Queries

export const getOnlineDataCompany = `
  query getOnlineDataCompany($params: OnlineParamsCompany!, $sessionId: ID, $metadata: Dynamic) {
    getOnlineDataCompany(params: $params, sessionId: $sessionId, metadata: $metadata) ${onlineDataFields}
  }
`

export const getOnlineDataEnterprise = `
  query getOnlineDataEnterprise($params: OnlineParamsEnterprise!, $sessionId: ID, $metadata: Dynamic) {
    getOnlineDataEnterprise(params: $params, sessionId: $sessionId, metadata: $metadata) ${onlineDataEnterpriseFields}
  }
`

export const getOnlineServiceAvailability = `
  query getOnlineServiceAvailability($params: OnlineServiceAvailabilityParams!, $timezone: Timezone, $sessionId: ID, $metadata: Dynamic) {
    getOnlineServiceAvailability(params: $params, timezone: $timezone, sessionId: $sessionId, metadata: $metadata) ${serviceAvailabilityFields}
  }
`

export const getOnlineCourseAvailability = `
  query getOnlineCourseAvailability($params: OnlineCourseAvailabilityParams!, $timezone: Timezone, $sessionId: ID, $metadata: Dynamic) {
    getOnlineCourseAvailability(params: $params, timezone: $timezone, sessionId: $sessionId, metadata: $metadata) ${courseAvailabilityFields}
  }
`

export const getOnlineEventStatus = `
  query getOnlineEventStatus($companyId: ID!, $region: Region!, $eventId: ID!, $secret: ID!) {
    getOnlineEventStatus (companyId: $companyId, region: $region, eventId: $eventId, secret: $secret) ${onlineEventStatusFields}
  }
`

// Mutations

export const reserveOnlineService = `
  mutation reserveOnlineService($params: OnlineServiceReservationParams!, $timezone: Timezone, $sessionId: ID, $metadata: Dynamic) {
    reserveOnlineService (params:$params, timezone:$timezone, sessionId: $sessionId, metadata: $metadata) ${onlineEventReservation}
  }
`

export const checkVoucherCode = `
  query checkVoucherCode($companyId: ID!, $region: Region!, $eventId: ID!, $code: ID!) {
    checkVoucherCode (companyId: $companyId, region: $region, eventId: $eventId, code: $code) {
      status
      from
      until
      discount
    }
  }
`

export const reserveOnlineCourse = `
  mutation reserveOnlineCourse($params: OnlineCourseReservationParams!, $sessionId: ID, $metadata: Dynamic) {
    reserveOnlineCourse (params:$params, sessionId: $sessionId, metadata: $metadata) ${onlineEventReservation}
  }
`

export const cancelOnlineEventReservation = `
  mutation cancelOnlineEventReservation($event: EventReservationCancellationPayload!) {
    cancelOnlineEventReservation (event:$event)
  }
`

export const finaliseOnlineEventReservation = `
  mutation finaliseOnlineEventReservation ($event:EventReservationPayload!, $sessionId: ID, $metadata: Dynamic, $externalCustomerId: ID) {
    finaliseOnlineEventReservation (event:$event, sessionId: $sessionId, metadata: $metadata, externalCustomerId: $externalCustomerId) ${eventReservation}
  }
`

export const finaliseOnlineCourseEventReservation = `
  mutation finaliseOnlineCourseEventReservation ($event:EventCourseReservationPayload!, $sessionId: ID, $metadata: Dynamic, $externalCustomerId: ID) {
    finaliseOnlineCourseEventReservation (event:$event, sessionId: $sessionId, metadata: $metadata, externalCustomerId: $externalCustomerId) ${eventReservation}
  }
`

export const checkOnlineCourseEventReservation = `
  mutation checkOnlineCourseEventReservation ($event:EventCourseReservationPayload!) {
    checkOnlineCourseEventReservation (event:$event)
  }
`

export const saveStripeIntent = `
  mutation saveStripeIntent ($companyId: ID!, $region: Region!, $eventId: ID!, $secret: ID!, $customerName: String, $customerEmail: String $voucherCode: ID) {
    saveStripeIntent(companyId:$companyId, region:$region, eventId:$eventId, secret:$secret, customerName: $customerName, customerEmail: $customerEmail voucherCode: $voucherCode)
  }
`

export const deleteStripeIntent = `
  mutation deleteStripeIntent ($companyId: ID!, $region: Region!, $eventId: ID!, $clientSecret: ID!) {
    deleteStripeIntent(companyId:$companyId, region:$region, eventId:$eventId, clientSecret:$clientSecret)
  }
`

export const createOnlineReservationPaypalOrder = `
  mutation createOnlineReservationPaypalOrder ($companyId: ID!, $region: Region!, $eventId: ID!, $secret: ID! $voucherCode: ID) {
    createOnlineReservationPaypalOrder(companyId:$companyId, region:$region, eventId:$eventId, secret:$secret voucherCode: $voucherCode)
  }
`

// Subscriptions
