const compareTable = {
  EmailRequired: '',
  EmailNotValid: '',
  EmailNotConfirmed: '',
  EmailNotVerified: 'errors.email.notVerified',
  EmailExists: 'errors.email.exists',

  PasswordMandatory: '',
  PasswordWeak: 'errors.password.weak',
  PasswordInvalid: 'errors.password.invalid',

  NoScopeForUser: '', // Booker or Enterprise account. No compnay yet.

  // AccountIdNotFound: '',
  AccountNotApproved: '',
  AccountDisabled: '',

  UserNotFound: 'errors.user.notFound',

  DependencyNotAvailable: 'errors.dependencyNotAvailable'.includes,

  // Registration
  BookerExists: 'errors.api.registration.bookerExists',
  UserExists: 'errors.api.registration.userExists',
  EmailAlreadyLinked: 'errors.api.registration.emailAlreadyLinked',

  // Login
  UserTemporaryBlocked: 'errors.api.authuserTemporaryBlocked',
  UserPermanentlyBlocked: 'errors.api.authuserPermanentlyBlocked',
  LoginError: 'errors.loginError'
}

export default (serverCode) => {
  return compareTable[serverCode] || 'errors.somethingWentWrong'
}
