import React from 'react'
import PropTypes from 'prop-types'
import { t, format, DashedTitle, Message } from '../../Common'
import { convertMinutesToHours, dangerousHTML } from '../../Utils'
import { CustomerFieldPreview } from '../../Beauties'

import './ConfirmMrSpex.css'

const ConfirmMrSpex = props => {
  const {
    isActive,
    appMode,
    info,
    customerFieldsWithValues,
    rescheduledEvent,
    hasNotification,
    onNotificationClose,
    onRemoveCourseExtraPersons,
    company,
    shouldDisplayDisclaimer,
    disclaimerText
  } = props
  const { service, slot } = info
  const classNames = ['ta-step', 'ta-confirm-mrspex']
  if (isActive) classNames.push('active')

  const customerNames = customerFieldsWithValues
    .reduce((acc, item) => {
      if (item.defaultId) {
        switch (item.defaultId) {
          case 'firstName':
            acc.push({
              value: item.value,
              index: 1
            })
            break
          case 'lastName':
            acc.push({
              value: item.value,
              index: 2
            })
            break
          case 'salutation':
            acc.push({
              value: item.value,
              index: 0
            })
            break
          default:
        }
      }
      return acc
    }, [])
    .sort((a, b) => a.index > b.index)
    .map(e => e.value)
    .join(' ')
  const customerEmail = customerFieldsWithValues.find(item => item.defaultId === 'email')
  const preselectedCustomerFieldDefaultIds = ['email', 'firstName', 'lastName', 'salutation']
  const otherCustomerFields = customerFieldsWithValues.filter(customerField =>
    !preselectedCustomerFieldDefaultIds.includes(customerField.defaultId)
  )

  return (
    <div className={classNames.join(' ')}>
      {(isActive && (
        <div className='ta-confirm-mrspex__container'>

          {(appMode === 'RESCHEDULE' && rescheduledEvent &&
            <Message
              show={hasNotification}
              type='warning'
              onClick={onNotificationClose}
              label={t('confirm.preview.section.reschedule.title')}
              className='ta-confirm-mrspex__notification'
            >
              {t('confirm.preview.section.reschedule.message', [{
                key: 'DATE',
                value: (rescheduledEvent.appointmentDate && format(rescheduledEvent.appointmentDate, 'long')) || ''
              }, {
                key: 'TIME',
                value: (rescheduledEvent.appointmentDate && format(rescheduledEvent.appointmentDate, 'time')) || ''
              }, {
                key: 'RESOURCE_NAME',
                value: (rescheduledEvent.resourcesNames && rescheduledEvent.resourcesNames.join(', ')) || ''
              }])}
            </Message>
          )}
          {((service.courseId && service.extraPersonsPerParticipant > 0) &&
            <Message
              show={hasNotification}
              type='warning'
              onClick={onNotificationClose}
              label={t('groups.extraParticipantsMessage.heading', [{
                key: 'COUNT',
                value: service.extraPersonsPerParticipant + 1
              }])}
              className='ta-confirm__notification'
            >
              <span className='ta-confirm__extra-persons-button' onClick={() => onRemoveCourseExtraPersons(service.courseId)}>{t('groups.extraParticipantsMessage.text')}</span>
            </Message>
          )}

          {appMode !== 'RESCHEDULE' && <DashedTitle label={t('confirm.preview.section.info.title')} className='ta-popup__main_title' />}
          {appMode === 'RESCHEDULE' && <DashedTitle label={t('confirm.preview.section.info.title_reschedule')} className='ta-popup__main_title' />}

          <DashedTitle label={t('misterspex.confirm.booking_data.title')} className='ta-popup__small_title' />

          {(service &&
            <div className='ta-confirm-mrspex__row'>
              <div className='ta-confirm-mrspex__title'>{t('misterspex.confirm.service.label')}</div>
              {service.name}
              {(service.duration &&
                <span className='ta-confirm-mrspex__note'>
                  (ca {service.duration} {t('services.list.section.category.service.min.label')})
                </span>
              )}
            </div>
          )}

          {(slot && slot.day && slot.minutes &&
            <div className='ta-confirm-mrspex__row'>
              <div className='ta-confirm-mrspex__title'>Datum</div>
              {t('misterspex.confirm.date.label', [{
                key: 'DAY_DATE',
                value: format(slot.day, 'longWithLongWeekDay', { isUTC: true, format: 'YYYY-MM-DD' })
              }, {
                key: 'TIME',
                value: convertMinutesToHours(slot.minutes)
              }])}
            </div>
          )}

          {(company &&
            <>
              <DashedTitle label='Optiker' className='ta-popup__small_title' />

              <div className='ta-confirm-mrspex__row'>
                <div className='ta-confirm-mrspex__title'>{t('misterspex.confirm.name.label')}</div>
                {company.name}
              </div>

              {(company.address && company.address.formatted &&
                <div className='ta-confirm-mrspex__row'>
                  <div className='ta-confirm-mrspex__title'>{t('misterspex.confirm.address.label')}</div>
                  {company.address.formatted}
                </div>
              )}
            </>
          )}

          {(customerNames && customerEmail &&
            <>
              <DashedTitle label={t('misterspex.confirm.contact_data.title')} className='ta-popup__small_title' />

              {(customerNames &&
                <div className='ta-confirm-mrspex__row'>
                  <div className='ta-confirm-mrspex__title'>{t('misterspex.confirm.name.label')}</div>
                  {customerNames}
                </div>
              )}

              {(customerEmail && customerEmail.value &&
                <div className='ta-confirm-mrspex__row'>
                  <div className='ta-confirm-mrspex__title'>{t('misterspex.confirm.email.label')}</div>
                  {customerEmail.value}
                </div>
              )}
            </>
          )}
          {otherCustomerFields.length > 0 && (
            <>
              <DashedTitle label={t('customerFields.otherFields')} className='ta-popup__small_title' />
              {otherCustomerFields.map(customerField => (
                <CustomerFieldPreview
                  key={`customer-field-${customerField.id}`}
                  item={customerField}
                />
              ))}
            </>
          )}
        </div>
      )
      )}
      {(shouldDisplayDisclaimer &&
        <div className='ta-disclaimer'>
          {dangerousHTML(disclaimerText, false, true)}
        </div>
      )}
    </div>
  )
}

ConfirmMrSpex.propTypes = {
  isActive: PropTypes.bool
}

export default ConfirmMrSpex
