export const slotsTransform = slots => {
  if (!slots) return []
  return slots.map(item => ({
    day: item.day,
    slots: item.minutes
      ? item.minutes.map(minutes => ({
        minutes,
        isActive: true
      }))
      : []
  }))
}
