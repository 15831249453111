import React, { Component } from 'react'
import { connect, handlers } from '../../../Store'
import { AuthEnforcePasswordChange } from '../../../Beauties'

class EnforcePasswordChangeWrapper extends Component {
  constructor (props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.togglePasswordFieldType = this.togglePasswordFieldType.bind(this)
    this.toggleRepeatPasswordFieldType = this.toggleRepeatPasswordFieldType.bind(this)
  }

  componentDidMount () {
    handlers.enforcePasswordChangeFormPopulate()
  }

  onSubmit (cb) {
    const { form } = this.props
    handlers.enforcePasswordChangeFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  togglePasswordFieldType () {
    const { form } = this.props
    let { password } = form || {}
    password = password || {}
    const { type: passwordFieldType } = password
    const passwordField = {
      ...password,
      type: passwordFieldType === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('enforcePasswordChange', { password: passwordField })
  }

  toggleRepeatPasswordFieldType () {
    const { form } = this.props
    let { repeatPassword } = form || {}
    repeatPassword = repeatPassword || {}
    const { type: passwordFieldType } = repeatPassword
    const passwordField = {
      ...repeatPassword,
      type: passwordFieldType === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('enforcePasswordChange', { repeatPassword: passwordField })
  }

  render () {
    const { routeName, form } = this.props
    const isActive = routeName === 'authEnforcePasswordChange'
    const { password, repeatPassword } = form || {}
    const { type: passwordFieldType } = password || {}
    const { type: repeatPasswordFieldType } = repeatPassword || {}

    return (
      <AuthEnforcePasswordChange
        isActive={isActive}
        onSubmit={this.onSubmit}
        passwordFieldType={passwordFieldType}
        repeatPasswordFieldType={repeatPasswordFieldType}
        togglePasswordFieldType={this.togglePasswordFieldType}
        toggleRepeatPasswordFieldType={this.toggleRepeatPasswordFieldType}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  form: state.forms.enforcePasswordChange,
  showBlockedWarning: state.auth.showBlockedWarning,
  locale: state.app.locale || 'en-gb'
})

export default connect(maps)(EnforcePasswordChangeWrapper)
