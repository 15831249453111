import React from 'react'
import PropTypes from 'prop-types'

import './StripedTitle.css'

const StripedTitle = props => {
  const { label, customStyle } = props

  return (
    <div className='ta-striped-title' style={customStyle}>
      <div className='ta-striped-title__label'>
        {label}
      </div>
    </div>
  )
}

StripedTitle.propTypes = {
  label: PropTypes.string.isRequired
}

export default StripedTitle
