export const offersTransform = offers => {
  if (!offers) return []
  return offers
    .filter(offer => !offer.type || offer.type === 'STANDARD')
    .map(item => {
      const { interval, publishInterval } = item
      const { begin, end } = interval || {}
      const { begin: publishBegin, end: publishEnd } = publishInterval || {}
      const result = {
        ...item,
        from: begin || '2000-01-01 00:00',
        until: end || '2100-01-01 00:00',
        publishFrom: publishBegin || '2000-01-01 00:00',
        publishUntil: publishEnd || '2100-01-01 00:00'
      }
      delete result.interval
      return result
    })
}

export const vouchersTransform = offers => {
  if (!offers) return []
  return offers
    .filter(offer => offer.type === 'VOUCHER')
    .map(item => {
      const { interval, publishInterval } = item
      const { begin, end } = interval || {}
      const { begin: publishBegin, end: publishEnd } = publishInterval || {}
      const result = {
        ...item,
        from: begin || '2000-01-01 00:00',
        until: end || '2100-01-01 00:00',
        publishFrom: publishBegin || '2000-01-01 00:00',
        publishUntil: publishEnd || '2100-01-01 00:00'
      }
      if (result.publishUntil > result.until) {
        result.publishUntil = result.until
      }
      delete result.interval
      return result
    })
}
