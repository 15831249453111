import React from 'react'
import PropTypes from 'prop-types'

import './ProgressBar.css'

const ProgressBar = props => {
  const { step } = props
  const progressClassNames = ['ta-progressbar__line', `step${step}`]
  return (
    <div className='ta-progressbar'>
      <div className={progressClassNames.join(' ')} />
    </div>
  )
}

ProgressBar.propTypes = {
  step: PropTypes.number
}

export default ProgressBar
