import { store } from '../Store'

export default (cname, cvalue, exdays) => {
  const state = store.getState()
  const { app } = state || {}
  const { userAgent } = app || {}

  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  let expires = 'expires=' + d.toUTCString()
  let cookie = cname + '=' + cvalue + ';' + expires + ';path=/;SameSite=None'
  if (userAgent !== 'safari') cookie += ';Secure'
  document.cookie = cookie
}
