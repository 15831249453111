import moment from 'moment'
import qs from 'qs'
import {
  payloads$,
  actions,
  handlers,
  languageChange,
  store$,
  store,
  globalActions
} from '../../../Store'
import { q } from '../../API'
import { fromEvent } from 'rxjs'
import { startWith, debounceTime, filter } from 'rxjs/operators'
import { getRouteQuery, getCssFileLink, getCookie } from '../../../Utils'
import { v4 } from 'uuid'
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '../../../Settings'
import { t } from '../../../Common'

// Post Message
globalActions.postMessage = (message, targetOrigin) => {
  if (window && window.parent && window.parent.postMessage) window.parent.postMessage(message, targetOrigin)
}

// Check IP
globalActions.fetchUserIP = async () => {
  try {
    const response = await fetch('https://ipapi.co/json/')
    const data = response.json()
    return data
  } catch (error) {
    console.warn('Error getting the user ip: ', error)
  }
}

// Favicon
const favicon = document.getElementById('favicon')
let faviconUrl = null
if (['development', 'staging'].includes(process.env.REACT_APP_ENV)) faviconUrl = '/favicon-stg.png'
if (favicon && faviconUrl) {
  favicon.href = faviconUrl
}

// App first load
const sessionId = v4()
handlers.setSessionId(sessionId)
store$
  .pipe(filter(state => state.app && !state.app.isLoaded))
  .subscribe(() => {
    handlers.appLoaded()
    handlers.phraseLoad()
  })

// App init

payloads$(actions.APP_INIT)
  .subscribe(async () => {
    const state = store.getState()
    const router = state.router || {}
    const { query = {} } = router || {}
    handlers.attributesPopulate(qs.parse(`${window.location.search.substring(1)}${window.location.hash}`))
    const params = {}

    if (query.accountId) params.companyId = query.accountId
    else if (query.accountid) params.companyId = query.accountid

    if (query.companyExternalId) params.companyExternalId = query.companyExternalId
    else if (query.companyexternalid) params.companyExternalId = query.companyexternalid
    else if (query.isExternalId) params.companyExternalId = query.isExternalId
    else if (query.isexternalid) params.companyExternalId = query.isexternalid

    if (query.enterpriseId) params.enterpriseId = query.enterpriseId
    else if (query.enterpriseid) params.enterpriseId = query.enterpriseid

    if (query.externalEnterpriseId) params.enterpriseSlug = query.externalEnterpriseId
    else if (query.externalenterpriseid) params.enterpriseSlug = query.externalenterpriseid
    else if (query.enterpriseExternalId) params.enterpriseSlug = query.enterpriseExternalId
    else if (query.enterpriseexternalid) params.enterpriseSlug = query.enterpriseexternalid

    const availableRegions = ['EUROPE', 'ASIA', 'LATIN_AMERICA', 'USA']
    if (query.region && availableRegions.includes(query.region)) params.region = query.region

    const routeQuery = getRouteQuery(router)

    // Check referrer and if it is facebook we need to close the window.
    // This check is added because of the Android and the new window which
    // is opened when try to book with Facebook Login.
    // DEPRECATED: if (document.referrer.indexOf('l.facebook.com') > -1) window.close()

    // Fix route history when the route is already selected
    // Example: I am on services and hit refresh, then refresh will not happen,
    // because it's the last route in history object
    handlers.navigateToPath(`/${routeQuery}`)

    // Must be on next tick
    setTimeout(() => handlers.appInitReady(), 0)

    // Load timezones & locales
    const staticData = (await q('getStaticData'))
    const { error: staticDataError } = staticData || { staticDataError: true }
    const { timezones = [], countries = [] } = staticData || {}
    if (!staticDataError) {
      handlers.timezonesPopulate({ timezones })
      handlers.countriesPopulate({ countries })
    }

    // If we have only enterpriseId or enterpriseSlug we must load basic information about the companies
    let enterprise
    let companies
    if ((params.enterpriseId || params.enterpriseSlug) && !params.companyId && !params.companyExternalId) {
      const result = await q('getOnlineDataEnterprise', { params, sessionId })
      const {
        error,
        enterprise: eEnterprise,
        companies: eCompanies
      } = result || { error: true }
      enterprise = eEnterprise || {}
      companies = eCompanies || []
      const { widgetCustomisation } = enterprise || {}

      // If we have special customisation we need to apply it
      if (widgetCustomisation) handlers.customisationsPopulate(widgetCustomisation)

      if (error) {
        // TODO: handle errors
        handlers.navigateToPath(`/not-found${routeQuery}`)
        handlers.languageChange('en-gb')
        handlers.appLoaderHide()
        return
      }
      if (!companies) {
        handlers.navigateToPath(`/not-found${routeQuery}`)
        handlers.languageChange('en-gb')
        handlers.appLoaderHide()
        return
      }

      if (enterprise) handlers.enterprisePopulate(enterprise)
      handlers.enterpriseCompaniesPopulate(companies)
      handlers.appRun()
    } else {
      // Otherwise we must load the widget for a specific company
      handlers.companySelect()
    }
  })

// App run

payloads$(actions.APP_RUN)
  .subscribe(async () => {
    const state = store.getState()
    const { attributes, enterprise, companies: realCompanies } = state
    const { companies } = enterprise || {}
    const { selectedId, list: companiesList = [] } = realCompanies || {}
    const selectedCompany = companiesList.find(c => c.id === selectedId)

    // Add event listener for closeWidget event. If we receive this event we must try to cancel the reservation
    if (window) {
      window.addEventListener('message', (event) => {
        if (event.data === 'closeWidget') {
          handlers.bookingCancelReservation()
        }
      }, false)
    }

    let companiesLocale = 'en-gb'
    if (companies && companies.length === 1) {
      handlers.companySelect(companies[0].id)
      companiesLocale = companies[0].locale
    } else if (selectedCompany && selectedCompany.locale) {
      companiesLocale = selectedCompany.locale
    } else if (enterprise && enterprise.locale) {
      companiesLocale = enterprise.locale
    }

    // Add google maps script
    const googleMapsElement = document.createElement('script')
    googleMapsElement.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&language=${companiesLocale.substring(0, 2)}`)
    googleMapsElement.onerror = () => {
      handlers.languageChange((attributes.locale || companiesLocale), true)
      handlers.appLoaderHide()
    }
    googleMapsElement.onload = () => {
      setTimeout(() => {
        handlers.languageChange((attributes.locale || companiesLocale), true)
        handlers.appLoaderHide()
      }, 1000)
    }
    await document.head.appendChild(googleMapsElement)
  })

payloads$(actions.APP_RESET)
  .subscribe(async () => {
    let { router, enterprise } = store.getState() || {}
    router = router || {}
    const { companies } = enterprise || {}
    const routeQuery = getRouteQuery(router)
    handlers.serviceSelect({ id: null, customerFieldIds: null, customerFields: null })
    handlers.courseSelect({ id: null, customerFieldIds: null, customerFields: null })
    handlers.availabilityReset()
    handlers.slotsReset()
    handlers.resourcesReset()
    handlers.paypalReset()
    handlers.customerFieldsFormPopulate()
    handlers.stripeReset()
    handlers.navigateToPath(companies?.length > 1 ? `/locations${routeQuery}` : `/services${routeQuery}`)
  })

payloads$(actions.APP_CUSTOMISATION_CSS)
  .subscribe(async () => {
    const state = store.getState()
    const { app } = state

    if (app && app.customisationCSS) {
      const activeCssCustomisations = document.querySelector('#ta-css-overwrite')
      if (!activeCssCustomisations || activeCssCustomisations.getAttribute('href') !== app.customisationCSS) {
        if (activeCssCustomisations) activeCssCustomisations.parentElement.removeChild(activeCssCustomisations)
        const cssUrlLink = getCssFileLink(app.customisationCSS)
        document.head.appendChild(cssUrlLink)
      }
    } else {
      const activeCssCustomisations = document.querySelector('#ta-css-overwrite')
      if (activeCssCustomisations) activeCssCustomisations.parentElement.removeChild(activeCssCustomisations)
    }
  })

// Window resize

fromEvent(window, 'resize')
  .pipe(startWith(null), debounceTime(100))
  .subscribe(() => handlers.windowResize(window))

// Language change

payloads$(actions.LANGUAGE_CHANGE)
  .subscribe(({ locale, initial }) => {
    if (!SUPPORTED_LOCALES.includes(locale)) locale = DEFAULT_LOCALE
    languageChange(locale, () => {
      handlers.languageLoaded(locale)
      // If this is the initial language change we need to go to the services or locations page
      if (initial) {
        const state = store.getState()
        const router = state.router || {}
        const routeQuery = getRouteQuery(router)
        const { enterprise, conflict } = state
        const { companies: companiesList = [] } = enterprise || {}
        const { error: conflictError = null } = conflict
        // If we have some error we need to go directly to the conflict page
        if (conflictError) {
          handlers.navigateToPath(`/conflict${routeQuery}`)
          return
        }
        // If there is more than one company we need to show locations page
        if (companiesList.length > 1) {
          handlers.navigateToPath(`/locations${routeQuery}`)
          return
        }
        handlers.navigateToPath(`/services${routeQuery}`)
      }
    })
    moment.locale(locale)
  })

payloads$(actions.LANGUAGE_LOADED).subscribe(() => {
  handlers.appDataLoaded()
  // Translates customer fields select options
  const state = store.getState()
  const { customerFieldsBeforeConfirmation } = state.forms || {}
  if (!customerFieldsBeforeConfirmation || !Object.keys(customerFieldsBeforeConfirmation).length) return
  const updatedFields = Object.entries(customerFieldsBeforeConfirmation)
    .filter(([_, { type }]) => type === 'SELECT')
    .reduce((acc, [key, field]) => ({
      ...acc,
      [key]: {
        ...field,
        options: field?.options?.map?.(option => ({ ...option, label: option.translationKey ? t(option.translationKey) : option.value }))
      }
    }), {})
  handlers.formFieldsUpdate('customerFieldsBeforeConfirmation', updatedFields)
})

payloads$(actions.APP_TRACKING_CHECK).subscribe(() => {
  const state = store.getState()
  const { companies: realCompanies } = state
  const { selectedId, list: companiesList = [] } = realCompanies || {}
  const selectedCompany = companiesList.find(c => c.id === selectedId)
  const { settings: companySettings } = selectedCompany || {}
  const { widgetConversions } = companySettings || {}

  const agreedToTrack = getCookie(`${selectedId}_cookie_agreement`)

  if (agreedToTrack && JSON.parse(agreedToTrack)) {
    let facebookCookieID = getCookie(`${selectedId}_facebook_ID`)
    let googleCookieID = getCookie(`${selectedId}_google_ID`)

    facebookCookieID = facebookCookieID || null
    googleCookieID = googleCookieID || null
    if ((facebookCookieID === widgetConversions.facebookPixelId) && (googleCookieID === widgetConversions.googleAnalyticsId)) {
      handlers.appTrackingInit()
    } else {
      handlers.appConsentMessageToggle()
    }
  } else {
    handlers.appConsentMessageToggle()
  }
})

payloads$(actions.APP_TRACKING_INIT).subscribe(() => {
  const state = store.getState()
  const { companies } = state
  const { list: companiesList, selectedId: selectedCompanyId } = companies || {}
  const selectedCompany = companiesList.find(item => item.id === selectedCompanyId) || {}
  const { settings } = selectedCompany
  const { widgetConversions } = settings

  const facebookPixelAgreement = JSON.parse(getCookie(`${selectedCompanyId}_facebook_agreement`) || 'null')
  const googleAnalyticsAgreement = JSON.parse(getCookie(`${selectedCompanyId}_google_agreement`) || 'null')

  if (googleAnalyticsAgreement) {
    try {
      if (document.getElementById('unique-gtm-js')) {
        const uniqueScript = document.getElementById('unique-gtm-js')
        uniqueScript.parentNode.removeChild(uniqueScript)
      }
      const script = document.createElement('script')
      script.setAttribute('id', 'unique-gtm-js')
      script.textContent = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtag/js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" + widgetConversions.googleAnalyticsId + "')"
      document.head.appendChild(script)
      window.dataLayer = window.dataLayer || []
      window.gtag = function () { window.dataLayer.push(arguments) }
      window.gtag('js', new Date())
      window.gtag('config', widgetConversions.googleAnalyticsId, {
        send_page_view: false,
        cookie_prefix: selectedCompanyId,
        cookie_flags: 'SameSite=None; Secure'
      })
    } catch (e) { }
  }

  if (facebookPixelAgreement) {
    if (document.getElementById('unique-gtm-js')) {
      const uniqueScript = document.getElementById('unique-gtm-js')
      uniqueScript.parentNode.removeChild(uniqueScript)
    }
    const script = document.createElement('script')
    script.setAttribute('id', 'unique-fbq-js')
    script.textContent = "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '" + widgetConversions.facebookPixelId + "');fbq.disablePushState = true;"
    document.head.appendChild(script)
  }
})

payloads$(actions.APP_CONSENT_MESSAGE_TOGGLE).subscribe(async () => {
  const state = store.getState()
  const { companies } = state || {}
  const { selectedId: selectedCompanyId, list: companiesList } = companies || []

  const company = companiesList.find(item => item.id === selectedCompanyId) || {}
  const { settings } = company || {}
  const { widgetConversions } = settings || {}

  const consentBoxCookie = JSON.parse(getCookie(`${selectedCompanyId}_cookie_consent_box`) || 'null')
  let facebookCookieID = getCookie(`${selectedCompanyId}_facebook_ID`)
  let googleCookieID = getCookie(`${selectedCompanyId}_google_ID`)

  facebookCookieID = facebookCookieID || null
  googleCookieID = googleCookieID || null

  if (!widgetConversions || (!widgetConversions.facebookPixelId && !widgetConversions.googleAnalyticsId)) {
    return handlers.appConsentMessageHide()
  }
  if (!consentBoxCookie && widgetConversions && widgetConversions.messageTarget === 'EU_ONLY') {
    const IP = await globalActions.fetchUserIP()
    const userAgent = navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0 ? 'safari' : null
    handlers.appRegisterClient({
      IP,
      userAgent
    })
    if (IP.continent_code !== 'EU') {
      return handlers.appConsentMessageHide()
    }
  }

  if (!consentBoxCookie) {
    return handlers.appConsentMessageShow()
  }

  if (consentBoxCookie) {
    if (facebookCookieID !== widgetConversions.facebookPixelId) {
      return handlers.appConsentMessageShow()
    }
    if (googleCookieID !== widgetConversions.googleAnalyticsId) {
      return handlers.appConsentMessageShow()
    }
  }

  return handlers.appConsentMessageHide()
})

payloads$(actions.APP_TRACK_EVENT).subscribe((trackingId) => {
  const { services, courses, companies, app } = store.getState()
  const { mode } = app || {}
  const { list: companiesList, selectedId: companySelectedId } = companies || {}
  const company = companiesList.find(({ id }) => companySelectedId === id)
  const { currency } = company
  const { list, selectedId } = services || {}
  const { list: coursesList, selectedId: courseSelectedId } = courses || {}
  const course = coursesList.find((item) => item.id === courseSelectedId) || {}
  const service = list.find((item) => item.id === selectedId) || course

  const facebookPixelAgreement = JSON.parse(getCookie(`${companySelectedId}_facebook_agreement`) || 'null')
  const googleAnalyticsAgreement = JSON.parse(getCookie(`${companySelectedId}_google_agreement`) || 'null')

  if (googleAnalyticsAgreement) {
    window && window.gtag && window.gtag('event', 'purchase', {
      transaction_id: trackingId,
      currency,
      value: mode === 'RESCHEDULE' ? 0 : service.totalPrice || 0,
      label: mode === 'RESCHEDULE' ? 'Rescheduled booking' : 'New booking',
      items: [
        {
          item_id: service.id,
          item_name: service.name,
          value: mode === 'RESCHEDULE' ? 0 : service.totalPrice || 0,
          currency
        }
      ]
    })
  }
  if (facebookPixelAgreement) {
    window && window.fbq && window.fbq('track', 'Schedule', {
      currency,
      content_name: `${mode === 'RESCHEDULE' ? 'Rescheduled booking' : 'New booking'}: ${service.name}`,
      content_ids: [trackingId],
      external_service_id: service.externalId,
      value: mode === 'RESCHEDULE' ? 0 : service.totalPrice || 0,
      content_type: 'product'
    })
  }
})
