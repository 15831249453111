import React from 'react'
import PropTypes from 'prop-types'
import {
  FontAwesome5,
  DashedTitle,
  Input,
  NoteBox,
  IconList
} from '../../../Common'

import './Confirmation.css'

const Confirmation = props => {
  const { isActive } = props
  const classNames = ['ta-popup', 'ta-auth-confirmation']
  if (isActive) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      {isActive && (
        <>
          <button className='ta-popup-close'>
            <FontAwesome5 icon='times' type='light' />
          </button>
          <div className='ta-auth-confirmation__container'>
            <div className='ta-auth-confirmation__inner'>

              <DashedTitle label='Register' className='ta-popup__main_title' />
              <div className='ta-text-box center'>
                In order to be sure that you are a real person we need to verify your mobile number. We will send a 2 digits verification code to:
              </div>
              <DashedTitle label='+43 170 9998 0009 11' className='ta-popup__secondary_title' />

              <div className='ta-auth-confirmation__code_field'>
                <Input
                  label='Verification code *'
                  placeholder='Verification code *'
                />
              </div>

              <div className='ta-auth-confirmation__buttons'>
                <button className='ta-cta-primary ta-cta-fluid'>
                  Verify my account
                </button>

                <button className='ta-cta-third ta-cta-fluid'>
                  Request new SMS code
                </button>

                <button className='ta-cta-ghost ta-cta-fluid'>
                  Wrong mobile number?
                </button>
              </div>

            </div>
          </div>

          <NoteBox canHide>
            <DashedTitle label='Why should you register?' />
            <div className='ta-text-box'>
              With Timify you can book appointments at any time from everywhere in a matter of seconds with many providers.
            </div>
            <IconList>
              <li>Donec id elit non mi porta gravida at eget metus.</li>
              <li>Nullam quis risus eget urna mollis ornare vel eu leo.</li>
              <li>Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</li>
              <li>Aenean lacinia bibendum nulla sed consectetur.</li>
            </IconList>
          </NoteBox>
        </>
      )}
    </div>
  )
}

Confirmation.propTypes = {
  isActive: PropTypes.bool
}

export default Confirmation
