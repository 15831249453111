import React from 'react'
import { t, FontAwesome5, DashedTitle } from '../../Common'
import { dangerousHTML } from '../../Utils'

import './NotFound.css'

const NotFound = props => {
  const { isActive, customUnavailableMessage } = props
  const classNames = ['ta-step', 'ta-not-found']
  if (isActive) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      {isActive && (
        <div className={classNames.join(' ')}>
          <div className='ta-not-found__container'>

            <FontAwesome5 icon='exclamation-triangle' type='solid' className='ta-not-found__icon' />

            <DashedTitle label={t('notfound.preview.section.info.title')} className='ta-popup__main_title' />
            <div className='ta-text-box center'>
              {customUnavailableMessage ? dangerousHTML(customUnavailableMessage) : t('notfound.preview.section.info.text')}
            </div>

          </div>
        </div>
      )}
    </div>
  )
}

export default NotFound
