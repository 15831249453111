import React from 'react'
import { renderToString } from 'react-dom/server'
import PropTypes from 'prop-types'
import { CustomerFieldsOnServiceWrapper } from '../../Beasts'
import {
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  SimpleListItemButtons,
  SimpleListItemBody,
  SimpleListItemDescription,
  SimpleListItemOffer,
  FontAwesome5,
  Price,
  LinedTitle,
  Message,
  format,
  getCustomDateFormat,
  getCustomTimeFormat,
  t
} from '../../Common'
import { dangerousHTML } from '../../Utils'

const ServicesListItem = props => {
  const {
    item,
    offer,
    selectedItemId,
    selectedItemCustomerFieldIds,
    onServiceClick,
    // timezone,
    hideServiceDuration = false,
    isUpsellingActive,
    onUpsellingClick,
    hasSelectedUpsellingServices,
    upsellingDuration,
    upsellingPrice,
    fileLoading,
    setFileLoading
  } = props
  const {
    id,
    name,
    duration,
    durationsPatternCalc,
    description,
    price,
    discountedPrice,
    isCourse
  } = item || {}
  const {
    discount: offerDiscount,
    from: offerFrom,
    until: offerUntil
  } = offer || {}
  const hasCustomDateAndTimeFormats = getCustomDateFormat() && getCustomTimeFormat()
  let offerFromDate = format(offerFrom, 'longWithTime', { isUTC: true })
  let offerUntilDate = format(offerUntil, 'longWithTime', { isUTC: true })
  if (hasCustomDateAndTimeFormats) {
    offerFromDate = `${format(offerFrom, getCustomDateFormat(), { isUTC: true })} ${format(offerFrom, getCustomTimeFormat(), { isUTC: true })}`
    offerUntilDate = `${format(offerUntil, getCustomDateFormat(), { isUTC: true })} ${format(offerUntil, getCustomTimeFormat(), { isUTC: true })}`
  }
  const classNames = ['ta-services__service']
  const buttonClassNames = ['ta-cta-third', 'ta-service__button']
  if (selectedItemId === id) {
    classNames.push('active')
    buttonClassNames.push('active')
  }
  if ((description || offer) && (price <= 0 || isCourse) && hideServiceDuration) buttonClassNames.push('short')
  const upsellingButtonClassNames = ['ta-cta-secondary', 'ta-cta-middle', 'ta-services__upselling-button']
  if (hasSelectedUpsellingServices) upsellingButtonClassNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      <SimpleListItem action={() => onServiceClick(item.id, !!item.isCourse)}>
        <SimpleListItemTitle title={name} />
        <SimpleListItemContent>
          <ul className='ta-simple-list__inline'>
            {(!hideServiceDuration &&
              <>
                {(duration &&
                  <li>
                    <FontAwesome5 icon='hourglass-half' type='solid' />
                    {duration} {t('services.list.section.category.service.min.label')}
                  </li>
                )}
                {(!duration && durationsPatternCalc &&
                  <li>
                    <FontAwesome5 icon='hourglass-half' type='solid' />
                    {durationsPatternCalc} {t('services.list.section.category.service.min.label')}
                  </li>
                )}
              </>
            )}
            {(price > 0 && !isCourse &&
              <li>
                <FontAwesome5 icon='money-bill' type='solid' />
                {!discountedPrice && <Price price={price} />}
                {(discountedPrice &&
                  <>
                    <strike className='ta-services__price'><Price price={price} /></strike>
                    <FontAwesome5 icon='long-arrow-right' type='regular' />
                    <Price price={discountedPrice} />
                  </>
                )}
              </li>
            )}
          </ul>
        </SimpleListItemContent>
        <SimpleListItemButtons>
          <button className={buttonClassNames.join(' ')}>
            {selectedItemId === id && (
              <FontAwesome5 icon='check' type='solid' />
            )}
            {(!selectedItemId || selectedItemId !== id) && (
              <FontAwesome5 icon='arrow-right' type='solid' />
            )}
          </button>
        </SimpleListItemButtons>
        {(selectedItemId === id &&
          <>
            {(selectedItemCustomerFieldIds || []).length > 0 && (
              <SimpleListItemBody onClick={e => e.stopPropagation()}>
                <LinedTitle label={t('services.list.section.form.customfields.title')} />
                <CustomerFieldsOnServiceWrapper
                  fileLoading={fileLoading}
                  setFileLoading={setFileLoading}
                />
              </SimpleListItemBody>
            )}
            {(isUpsellingActive &&
              <SimpleListItemBody onClick={e => e.stopPropagation()} className='ta-service__upselling'>
                <LinedTitle label={t('upselling.mobile.list.section.title')} />
                <button
                  className={upsellingButtonClassNames.join(' ')}
                  onClick={onUpsellingClick}
                >
                  {t('upselling.mobile.list.section.button.label')}
                  {(hasSelectedUpsellingServices &&
                    <span className='ta-service__upselling-button-icon-holder'>
                      <FontAwesome5 icon='check' type='solid' className='ta-service__upselling-button-icon' />
                    </span>
                  )}
                </button>

                {(hasSelectedUpsellingServices &&
                  <Message
                    type='neutral'
                    show
                    hideIcon
                    className='ta-service__upselling-message'
                  >
                    {(upsellingDuration > 0 && upsellingPrice > 0 &&
                      dangerousHTML(
                        t('upselling.footer.section.text.duration_and_price', [{
                          key: 'MINUTES',
                          value: upsellingDuration
                        }, {
                          key: 'PRICE',
                          value: renderToString(<Price price={upsellingPrice} />)
                        }], undefined, true)
                      )
                    )}
                    {(upsellingDuration > 0 && upsellingPrice === 0 &&
                      dangerousHTML(t('upselling.footer.section.text.duration', [{
                        key: 'MINUTES',
                        value: upsellingDuration
                      }]))
                    )}
                    {(upsellingDuration === 0 && upsellingPrice > 0 &&
                      dangerousHTML(
                        t('upselling.footer.section.text.price', [{
                          key: 'PRICE',
                          value: renderToString(<Price price={upsellingPrice} />)
                        }], undefined, true)
                      )
                    )}
                  </Message>
                )}
              </SimpleListItemBody>
            )}
          </>
        )}
        {(description &&
          <SimpleListItemDescription>
            {description}
          </SimpleListItemDescription>
        )}
        {(offer &&
          <SimpleListItemOffer>
            {dangerousHTML(t('services.list.section.service.offer.label', [
              { key: 'DISCOUNT', value: offerDiscount },
              { key: 'FROM', value: offerFromDate },
              { key: 'UNTIL', value: offerUntilDate }
            ]))}
          </SimpleListItemOffer>
        )}
      </SimpleListItem>
    </div>
  )
}

ServicesListItem.propTypes = {
  onServiceClick: PropTypes.func,
  item: PropTypes.object.isRequired,
  customerFields: PropTypes.array,
  selected: PropTypes.object,
  hideServiceDuration: PropTypes.bool,
  isUpsellingActive: PropTypes.bool,
  onUpsellingClick: PropTypes.func,
  hasSelectedUpsellingServices: PropTypes.bool,
  upsellingDuration: PropTypes.any,
  upsellingPrice: PropTypes.any
}

export default ServicesListItem
