import React from 'react'
import { Link, Image } from '../../../Common'

const PaymentStripeButton = ({ routeQuery }) => (
  <Link to={`/payments/stripe${routeQuery}`} className='ta-online-payment__stripe-button'>
    <div className='ta-online-payment__stripe-button__stripe-icon'>
      <Image src='/images/payments/stripe.png' alt='Stripe logo' />
    </div>
    {/* uncomment when doing new integration */}
    {/* <div className='ta-online-payment__stripe-button__methods'>
      <Image src='/images/payments/visa.png' alt='Visa logo' />
      <Image src='/images/payments/master-card.png' alt='Master card logo' />
      <Image src='/images/payments/american-express.png' alt='American Express logo' />
      <Image src='/images/payments/discover.png' alt='Discover logo' />
      <Image src='/images/payments/g-pay.png' alt='G-Pay logo' />
      <Image src='/images/payments/klarna.png' alt='Klarna logo' />
      <Image src='/images/payments/sepa.png' alt='Sepa logo' />
      <Image src='/images/payments/ali-pay.png' alt='Ali-pay logo' />
      <Image src='/images/payments/i-deal.png' alt='i-Deal logo' />
      <Image src='/images/payments/we-chat-pay.png' alt='We chat pay logo' />
      <Image src='/images/payments/bancontact.png' alt='bancontact logo' />
    </div> */}
  </Link>
)

export default PaymentStripeButton
