import { attributesTransform } from './utils'

const reducer = {}

reducer.initialState = {
  fullscreen: false
}

reducer.handlers = (dispatch, actions) => ({
  attributesReset: () => dispatch(actions.ATTRIBUTES_RESET),
  attributesPopulate: data => dispatch(actions.ATTRIBUTES_POPULATE, attributesTransform(data)),
  attributesUpdate: data => dispatch(actions.ATTRIBUTES_UPDATE, data)
})

reducer.ATTRIBUTES_RESET = state => reducer.initialState

reducer.ATTRIBUTES_POPULATE = (state, data) => ({
  ...data
})

reducer.ATTRIBUTES_UPDATE = (state, data) => ({
  ...state,
  ...data
})

export default reducer
