export default function (key, caseSensitive = false, withoutDefaultId) {
  let sortOrder = 1
  if (key[0] === '-') {
    sortOrder = -1
    key = key.substr(1)
  }
  if (caseSensitive) {
    return function (a, b) {
      let result = (a[key] < b[key]) ? -1 : (a[key] > b[key]) ? 1 : 0
      return result * sortOrder
    }
  } else {
    return function (a, b) {
      if (withoutDefaultId && a.defaultId) return 0
      let A = a[key] ? a[key].toString().toUpperCase() : ''
      let B = b[key] ? b[key].toString().toUpperCase() : ''
      let result = (A < B) ? -1 : (A > B) ? 1 : 0
      return result * sortOrder
    }
  }
}
