import React, { Component } from 'react'
import { connect, handlers, store } from '../../Store'
import { Auth } from '../../Beauties'
import { getRouteQuery } from '../../Utils'

class AuthWrapper extends Component {
  constructor (props) {
    super(props)

    this.onTimifyLoginClick = this.onTimifyLoginClick.bind(this)
    this.onTimifyRegisterClick = this.onTimifyRegisterClick.bind(this)
    this.onGuestBookingClick = this.onGuestBookingClick.bind(this)
  }

  onTimifyLoginClick () {
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/auth/login${routeQuery}`)
  }

  onTimifyRegisterClick () {
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/auth/register${routeQuery}`)
  }

  onGuestBookingClick () {
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/customer-fields${routeQuery}`)
  }

  onFacebookLogin (response) {
    handlers.loginWithFacebook(response)
  }

  onFacebookLoginClick (response) {
    handlers.appLoaderShow()
  }

  onFacebookFail () {
    handlers.appLoaderHide()
  }

  render () {
    const { routeName, companies, selectedCompanyId } = this.props
    const company = companies.find(item => item.id === selectedCompanyId) || {}
    const { settings, enterprise } = company || {}
    const isActive = ['auth', 'authLogin', 'authRegister', 'authForgotten', 'authConfirmation', 'authChangePhone', 'authGuestBooking', 'authEnforcePasswordChange'].includes(routeName)
    const isPrevious = ['customerFields', 'confirm'].includes(routeName)
    const { customersMiddlewareUrl } = enterprise || {}
    let { hasTimifyLogin, hasGuestBooking, hasFacebookLogin } = settings || {}
    if (customersMiddlewareUrl) {
      hasTimifyLogin = false
      hasFacebookLogin = false
      hasGuestBooking = true
    }

    return (
      <Auth
        isActive={isActive}
        isPrevious={isPrevious}
        hasTimifyLogin={hasTimifyLogin}
        hasFacebookLogin={hasFacebookLogin}
        hasGuestBooking={hasGuestBooking}
        onTimifyLoginClick={this.onTimifyLoginClick}
        onTimifyRegisterClick={this.onTimifyRegisterClick}
        onGuestBookingClick={this.onGuestBookingClick}
        onFacebookLoginClick={this.onFacebookLoginClick}
        onFacebookLogin={this.onFacebookLogin}
        onFacebookFail={this.onFacebookFail}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null
})

export default connect(maps)(AuthWrapper)
