import React from 'react'
import { renderToString } from 'react-dom/server'
import PropTypes from 'prop-types'
import {
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  SimpleListItemButtons,
  FontAwesome5,
  Price,
  t
} from '../../Common'
import { dangerousHTML } from '../../Utils'

import './UpSelling.css'

const UpSelling = props => {
  const {
    isActive,
    relations,
    selectedIds,
    hideServiceDuration,
    onUpsellingServiceClick,
    onUpsellingCancel,
    onUpsellingConfirm,
    duration,
    price,
    isOpenOnMobile
  } = props
  const classNames = ['ta-upselling__container']
  const footerClassNames = ['ta-upselling__footer']
  if (isActive) classNames.push('active')
  if (isOpenOnMobile) classNames.push('open-mobile')
  if (selectedIds && selectedIds.length > 0) footerClassNames.push('active')

  return (
    <>
      {(isActive &&
        <div className={classNames.join(' ')}>
          <div className='ta-upselling__header'>
            <div className='ta-upselling__inner'>
              <div className='ta-upselling__title'>{t('upselling.header.section.headline')}</div>
            </div>
          </div>
          <div className='ta-upselling__body'>
            <div className='ta-upselling__inner'>
              {relations.map(item => {
                const upsellingCartClasses = []
                const buttonClassNames = ['ta-cta-third', 'ta-upselling__button']
                if (selectedIds && selectedIds.includes(item.id)) buttonClassNames.push('active')
                if (item.shapeDisabled) upsellingCartClasses.push('ta-upselling__service-disable')

                return (
                  <SimpleListItem
                    key={item.id}
                    className={upsellingCartClasses.join(' ')}
                    action={() => !item.shapeDisabled && onUpsellingServiceClick(item.id)}
                  >
                    <SimpleListItemTitle title={item.name} />
                    <SimpleListItemContent>
                      <ul className='ta-simple-list__inline'>
                        {(!hideServiceDuration && item.add_extra_duration && item.duration &&
                          <li>
                            <FontAwesome5 icon='clock' type='solid' />
                            <strong>+{item.duration}</strong> {t('upselling.services.section.minutes.label')}
                          </li>
                        )}
                        {(!hideServiceDuration && !item.add_extra_duration &&
                          <li>
                            <FontAwesome5 icon='clock' type='solid' />
                            {t('upselling.services.section.no_extra_duration.label')}
                          </li>
                        )}
                        {(item.price &&
                          <li>
                            <FontAwesome5 icon='money-bill' type='solid' />
                            <Price price={item.price} />
                          </li>
                        )}
                      </ul>
                    </SimpleListItemContent>
                    <SimpleListItemButtons>
                      <button className={buttonClassNames.join(' ')}>
                        {((!selectedIds || !selectedIds.includes(item.id)) &&
                          <FontAwesome5 icon='plus' type='solid' />
                        )}
                        {(selectedIds && selectedIds.includes(item.id) &&
                          <FontAwesome5 icon='check' type='solid' />
                        )}
                      </button>
                    </SimpleListItemButtons>
                  </SimpleListItem>
                )
              })}
            </div>
          </div>
          <div className={footerClassNames.join(' ')}>
            <div className='ta-upselling__inner'>
              <div className='ta-upselling__message'>
                {(!hideServiceDuration && duration > 0 && price > 0 &&
                  dangerousHTML(t('upselling.footer.section.text.duration_and_price', [{
                    key: 'MINUTES',
                    value: duration
                  }, {
                    key: 'PRICE',
                    value: renderToString(<Price price={price} cleanHtml />)
                  }]))
                )}
                {(!hideServiceDuration && duration > 0 && price === 0 &&
                  dangerousHTML(t('upselling.footer.section.text.duration', [{
                    key: 'MINUTES',
                    value: duration
                  }]))
                )}
                {((hideServiceDuration || duration === 0) && price > 0 &&
                  dangerousHTML(t('upselling.footer.section.text.price', [{
                    key: 'PRICE',
                    value: renderToString(<Price price={price} />)
                  }]))
                )}
              </div>
              <div className='ta-upselling__buttons'>
                <button className='ta-cta-secondary' onClick={onUpsellingCancel}>
                  {t('upselling.footer.section.buttons.cancel.label')}
                </button>
                <button className='ta-cta-primary' onClick={onUpsellingConfirm}>
                  {t('upselling.footer.section.buttons.confirm.label')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

UpSelling.propTypes = {
  isActive: PropTypes.bool,
  isOpenOnMobile: PropTypes.bool,
  relations: PropTypes.array,
  selectedIds: PropTypes.array,
  onUpsellingServiceClick: PropTypes.func,
  onUpsellingCancel: PropTypes.func,
  onUpsellingConfirm: PropTypes.func,
  duration: PropTypes.number,
  price: PropTypes.number
}

export default UpSelling
