import React, { Component } from 'react'
import { connect, handlers, store } from '../../../Store'
import { AuthForgotten } from '../../../Beauties'
import { getRouteQuery } from '../../../Utils'

class ForgottenWrapper extends Component {
  constructor (props) {
    super(props)

    this.onCloseClick = this.onCloseClick.bind(this)
    this.onBackClick = this.onBackClick.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    handlers.forgottenFormPopulate()
  }

  onCloseClick () {
    handlers.forgottenFormPopulate()
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/auth${routeQuery}`)
  }

  onBackClick () {
    handlers.forgottenFormPopulate()
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/auth/login${routeQuery}`)
  }

  onSubmit (cb) {
    const { form } = this.props
    handlers.forgottenFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    const { routeName, forgotten } = this.props
    const { success = false } = forgotten || {}
    const isActive = routeName === 'authForgotten'

    return (
      <AuthForgotten
        isActive={isActive}
        onCloseClick={this.onCloseClick}
        onBackClick={this.onBackClick}
        onSubmit={this.onSubmit}
        formSuccess={success}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  forgotten: state.auth.forgotten || {},
  form: state.forms.forgotten,
  isForgotten: state.auth.isForgotten
})

export default connect(maps)(ForgottenWrapper)
