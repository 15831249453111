import { q } from '../../API'
import { payloads$, actions, store, handlers } from '../../../Store'

payloads$(actions.CHECK_VOUCHER_CODE)
  .subscribe(async ({ code }) => {
    const state = store.getState()

    const { booking, companies } = state || {}
    const { eventId } = booking || {}
    const { list: companiesList, selectedId: selectedCompanyId } = companies || {}
    const selectedCompany = companiesList.find(item => item.id === selectedCompanyId) || {}

    const voucherCheck = await q('checkVoucherCode', {
      companyId: selectedCompany.id,
      region: selectedCompany.region,
      eventId,
      code
    })

    // update the values in the form
    let { status, from, until, discount, error } = voucherCheck || { error: true }
    if (error) {
      error = error || {}
      handlers.formErrorsSet('voucherInput', [{ key: 'globalErrors', value: 'confirm.preview.section.voucher.error.message' }])
      return
    }
    status = status || 'EXPIRED'
    handlers.formFieldsUpdate('voucherInput', {
      voucherStatus: { value: status },
      voucherFrom: { value: from },
      voucherUntil: { value: until },
      voucherFinalCode: { value: status === 'AVAILABLE' ? code : null },
      voucherDiscount: { value: discount }
    })
  })
