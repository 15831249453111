import React, { Component } from 'react'
import { feedContextInProps } from '../../Utils'
import { AccordionContext } from '../../Common'

class AccordionTitle extends Component {
  constructor (props, context) {
    super(props)
  }

  render () {
    const { children, toggle } = this.props

    return (
      <div className='ta-accordion__title' onClick={toggle}>
        {children}
      </div>
    )
  }
}

export default feedContextInProps(AccordionTitle, AccordionContext)
