import React from 'react'
import PropTypes from 'prop-types'
import { DEFAULT_LOCALE, PASSWORD_RESET_URL } from '../../../Settings'
import { dangerousHTML } from '../../../Utils'
import {
  t,
  Form,
  Row,
  Col,
  Input,
  FontAwesome5,
  DashedTitle,
  NoteBox,
  Message,
  Link,
  Error
} from '../../../Common'

import './Login.css'

const Login = props => {
  const {
    locale,
    isActive,
    onCloseClick,
    onSubmit,
    onForgottenClick,
    onRegisterClick,
    showBlockedWarning,
    passwordFieldType,
    togglePasswordFieldType
  } = props
  const classNames = ['ta-popup', 'ta-auth-login']
  if (isActive) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      {isActive && (
        <>
          <button onClick={onCloseClick} className='ta-popup-close'>
            <FontAwesome5 icon='times' type='light' />
          </button>
          <div className='ta-auth-login__login'>
            <div className='ta-auth-login__inner'>
              <Form onSubmit={onSubmit} name='login'>
                <DashedTitle label={t('auth.login.section.form.title')} className='ta-popup__main_title' />
                <div className='ta-text-box center'>
                  {t('auth.login.section.form.text')}
                </div>
                <Row>
                  <Col size={50}>
                    <Input
                      mandatory
                      name='email'
                      label={t('global.email.label')}
                      placeholder={t('global.email.placeholder')}
                    />
                  </Col>
                  <Col className='relative' size={50}>
                    <Input
                      mandatory
                      name='password'
                      label={t('global.password.label')}
                      placeholder={t('global.password.placeholder')}
                      className='ta-auth-login__password-field'
                    />
                    <div
                      className='ta-auth-login__password-type-toggle-btn' onClick={togglePasswordFieldType}
                    >
                      <FontAwesome5 icon={passwordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!showBlockedWarning && <Error name='emailOrPassword' />}
                    {(showBlockedWarning &&
                      <Message
                        type='warning'
                        show={showBlockedWarning}
                      >
                        <p>{dangerousHTML(t('auth.userTemporaryBlockedStatus.note.text'))}</p>
                        <Link
                          to={PASSWORD_RESET_URL.replace(`/${DEFAULT_LOCALE}/`, `/${locale}/`)}
                          className='ta-cta-primary ta-cta-fluid'
                          target='_blank'
                          external
                        >{t('auth.userTemporaryBlockedStatus.note.button')}
                        </Link>
                      </Message>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col size={50}>
                    <button type='submit' className='ta-cta-primary ta-cta-fluid'>
                      {t('auth.login.section.form.buttons.loginNow')}
                    </button>
                  </Col>
                  <Col size={50} className='ta-auth-right'>
                    <button onClick={onForgottenClick} className='ta-auth-link'>
                      {t('auth.login.section.form.buttons.forgotten')}
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <NoteBox>
            <DashedTitle label={t('auth.login.section.register.title')} />
            <div className='ta-text-box'>
              {t('auth.login.section.register.text')}
            </div>
            <button onClick={onRegisterClick} className='ta-cta-primary ta-cta-fluid'>
              {t('auth.login.section.register.button.label')}
            </button>
          </NoteBox>
        </>
      )}
    </div>
  )
}

Login.propTypes = {
  isActive: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func,
  onForgottenClick: PropTypes.func,
  onRegisterClick: PropTypes.func
}

export default Login
