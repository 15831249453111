import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { t, format, FontAwesome5, getCustomDateFormat } from '../../Common'
import { connect } from '../../Store'

import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import './Datepicker.css'

class Datepicker extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showPicker: false,
      hasPrevMonth: false,
      hasNextMonth: true,
      hasPrevDay: false,
      hasNextDay: true
    }

    this.changeSelectedDate = this.changeSelectedDate.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.togglePicker = this.togglePicker.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.getDayClassNames = this.getDayClassNames.bind(this)
  }

  componentDidMount () {
    const { weekCalendar, availability, attributes } = this.props
    let { selectedDate } = this.props
    const {
      calendarEnd: maxDate = moment(),
      calendarBegin: minDate = moment()
    } = availability
    const { dateRange = null } = attributes

    // Calculate do we have prev & next days
    if (selectedDate) {
      let hasNextDay = false
      let hasPrevDay = false

      selectedDate = moment(selectedDate)
      const dateBeforeSelectedDate = selectedDate.subtract('1', 'day')
      if (dateRange && dateRange[0]) dateRange[0] = moment(dateRange[0])
      if (dateRange && dateRange[1]) dateRange[1] = moment(dateRange[1])

      if (weekCalendar) {
        const startWeekDate = selectedDate.clone()
        const endWeekDate = selectedDate.clone()
        const previousStartWeekDate = startWeekDate.subtract('1', 'week')
        const previousEndWeekDate = endWeekDate.subtract('1', 'week')
        startWeekDate.startOf('week')
        endWeekDate.endOf('week')
        previousStartWeekDate.startOf('week')
        previousEndWeekDate.endOf('week')

        if (endWeekDate.isBefore(maxDate, 'day') && (!dateRange || !dateRange[1] || endWeekDate.isBefore(dateRange[1]))) hasNextDay = true
        if (previousEndWeekDate.isBefore(minDate, 'day') && (!dateRange || !dateRange[1] || previousEndWeekDate.isBefore(dateRange[1]))) hasPrevDay = true
      } else {
        if (selectedDate.isBefore(maxDate, 'day') && (!dateRange || !dateRange[1] || selectedDate.isBefore(dateRange[1]))) hasNextDay = true
        if (selectedDate.isBefore(maxDate, 'day') && (!dateRange || !dateRange[0] || selectedDate.isAfter(dateRange[0]))) hasNextDay = true

        if (dateBeforeSelectedDate.isSameOrAfter(minDate, 'day') && (!dateRange || !dateRange[0] || dateBeforeSelectedDate.isSameOrAfter(dateRange[0]))) hasPrevDay = true
      }

      this.setState({
        hasNextDay,
        hasPrevDay
      })
    }

    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Use this method to change current date with one day in the past or future
   *
   * @param {bool} next - set next or previous day
   */
  changeDate (next) {
    const { selectedDate, weekCalendar } = this.props
    const { hasPrevDay, hasNextDay } = this.state
    const date = moment(selectedDate).clone()
    date.hour(0)
    date.minute(0)
    date.second(0)

    if (!next && !hasPrevDay) {
      return
    }
    if (next && !hasNextDay) {
      return
    }

    if (next) {
      if (weekCalendar) {
        date.add(7, 'day')
      } else {
        date.add(1, 'day')
      }
    } else {
      if (weekCalendar) {
        date.subtract(7, 'day')
      } else {
        date.subtract(1, 'day')
      }
    }

    this.changeSelectedDate(date)
  };

  /**
   * Use this method to change selected date
   * @param {moment} date
   */
  changeSelectedDate (date) {
    const { onDateChanged, weekCalendar, availability, attributes } = this.props
    let { calendarEnd: maxDate = moment() } = availability
    const { dateRange = null } = attributes
    const currentDate = moment()
    let hasPrevDay = false
    let hasNextDay = false
    currentDate.hour(0)
    currentDate.minute(0)
    currentDate.second(0)
    maxDate = moment(maxDate)
    maxDate.hour(0)
    maxDate.minute(0)
    maxDate.second(0)
    // maxDate.subtract(1, 'day')

    // Prepare dateRange dates
    if (dateRange && dateRange[0]) dateRange[0] = moment(dateRange[0])
    if (dateRange && dateRange[1]) dateRange[1] = moment(dateRange[1])

    const selectedDays = []
    if (weekCalendar) {
      const startWeekDate = date.clone()
      const endWeekDate = date.clone()

      startWeekDate.startOf('week')
      endWeekDate.endOf('week')

      if (currentDate.isBefore(startWeekDate, 'day') && (!dateRange || !dateRange[0] || dateRange[0].isBefore(startWeekDate))) hasPrevDay = true
      if (endWeekDate.isBefore(maxDate, 'day') && (!dateRange || !dateRange[1] || endWeekDate.isBefore(dateRange[1]))) hasNextDay = true

      for (let index = 0; index < 7; index++) {
        // If we have date range we must apply additional validation
        if (dateRange && dateRange[0] && dateRange[0].isValid() && dateRange[1] && dateRange[1].isValid()) {
          if (startWeekDate.isSameOrAfter(dateRange[0]) && startWeekDate.isSameOrBefore(dateRange[1])) selectedDays.push(startWeekDate.format('YYYY-MM-DD'))
        } else {
          selectedDays.push(startWeekDate.format('YYYY-MM-DD'))
        }
        startWeekDate.add(1, 'days')
      }
    } else {
      selectedDays.push(date.format('YYYY-MM-DD'))

      if ((currentDate.isBefore(date, 'day') || (dateRange && dateRange[0] && currentDate.isAfter(dateRange[0], 'day'))) && (!dateRange || !dateRange[0] || dateRange[0].isBefore(date))) hasPrevDay = true
      if (date.isBefore(maxDate, 'day') && (!dateRange || !dateRange[1] || date.isBefore(dateRange[1]))) hasNextDay = true
    }

    this.setState({
      showPicker: false,
      hasPrevDay,
      hasNextDay
    })

    if (onDateChanged) onDateChanged(selectedDays)
  };

  /**
   * Use this method to show date picker
   */
  togglePicker () {
    const { showPicker } = this.state
    this.setState({
      showPicker: !showPicker
    })
  };

  /**
   * Set the wrapper ref
   */
  setWrapperRef (node) {
    this.wrapperRef = node
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside (event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        showPicker: false
      })
    }
  }

  /**
   * Generate class of the day selector
   * @param {moment} date
   */
  getDayClassNames (date) {
    const { weekCalendar, selectedDate } = this.props
    const classNames = []
    if (weekCalendar) {
      if (moment(selectedDate).week() === date.week()) {
        classNames.push('react-datepicker__day--selected')
      }
    }
    return classNames.join(' ')
  }

  render () {
    const {
      availability,
      resources,
      attributes,
      weekCalendar,
      formatDate,
      formatDay,
      selectedDate,
      calendarLabelWithText,
      previousWeekLabel,
      nextWeekLabel
    } = this.props
    const { selectedId: selectedResourceId } = resources || {}
    let {
      calendarBegin: minDate = moment(),
      calendarEnd: maxDate = moment(),
      offDays
    } = availability || {}
    offDays = offDays || {}
    const selectedResourceOffDays = offDays[selectedResourceId || 'ALL'] || []
    const { dateRange = null } = attributes
    const { hasPrevMonth, hasNextMonth, hasPrevDay, hasNextDay, showPicker } = this.state
    const customDateFormat = getCustomDateFormat()
    const classNames = ['ta-datepicker']
    const calendarClasses = ['ta-datepicker__cnt']
    const calendarPickerClasses = ['']
    const prevDayClasses = ['ta-datepicker__nav', 'ta-datepicker__prev']
    const nextDayClasses = ['ta-datepicker__nav', 'ta-datepicker__next']
    let calendarLabel = ''

    if (!hasPrevMonth) calendarClasses.push('ta-datepicker__no_prev')
    if (!hasNextMonth) calendarClasses.push('ta-datepicker__no_next')
    if (!hasPrevDay) prevDayClasses.push('ta-datepicker__disabled')
    if (!hasNextDay) nextDayClasses.push('ta-datepicker__disabled')

    if (weekCalendar && calendarLabelWithText) classNames.push('options-with-text')

    if (weekCalendar) {
      classNames.push('weekly')
      calendarPickerClasses.push('week-calendar')

      const startWeekDate = moment(selectedDate).clone()
      const endWeekDate = moment(selectedDate).clone()

      startWeekDate.startOf('week')
      endWeekDate.endOf('week')

      calendarLabel = (
        <span>
          <span className='ta-datepicker__day'>
            {t('availability.list.section.datepicker.week.label')}{moment(selectedDate).format('w')},&nbsp;
          </span>
          <span className='ta-datepicker__date'>
            {startWeekDate.format(getCustomDateFormat({ isShort: true }) || 'L')} - {endWeekDate.format(getCustomDateFormat({ isShort: true }) || 'L')}
          </span>
        </span>
      )
    } else {
      calendarLabel = (
        <span>
          {formatDay && !customDateFormat
            ? (
              <span className='ta-calendar__day'>
                {format(selectedDate, 'longWeekDay', { isUTC: true })},&nbsp;
              </span>
              )
            : null}
          <span className='ta-calendar__date'>
            {format(selectedDate, customDateFormat || 'long', { isUTC: true })}
          </span>
        </span>
      )
    }

    if (showPicker) {
      classNames.push('opened')
    }

    // Check do we have additional parameters to limit the date range
    if (dateRange && dateRange[0] && dateRange[1]) {
      minDate = dateRange[0]
      maxDate = dateRange[1]
    }

    return (
      <div className={classNames.join(' ')} ref={this.setWrapperRef}>
        <button className={prevDayClasses.join(' ')} onClick={() => this.changeDate(false)}>
          <FontAwesome5 icon='angle-left' type='solid' className='ta-datepicker__icon' />
        </button>
        {!calendarLabelWithText && (
          <button
            className='ta-datepicker__main'
            onClick={this.togglePicker}
          >

            <FontAwesome5 icon='calendar-alt' type='solid' className='ta-datepicker__icon' />
            {calendarLabel}
            <FontAwesome5 icon={showPicker ? 'angle-up' : 'angle-down'} type='solid' className='ta-datepicker__icon-right' />

          </button>
        )}
        {weekCalendar && calendarLabelWithText && (
          <div className='ta-datepicker__main text'>
            <p className='ta-datepicker__main-text__option'>{previousWeekLabel}</p>
            <p className='ta-datepicker__main-text__option'>{nextWeekLabel}</p>
          </div>
        )}
        <button
          className={nextDayClasses.join(' ')}
          onClick={() => this.changeDate(true)}
        >

          <FontAwesome5 icon='angle-right' type='solid' className='ta-datepicker__icon' />
        </button>

        {showPicker
          ? (
            <div className={calendarClasses.join(' ')}>
              <DatePicker
                inline
                selected={moment(selectedDate)}
                onChange={this.changeSelectedDate}
                dateFormat={formatDate}
                minDate={moment(minDate)}
                maxDate={moment(maxDate)}
                excludeDates={selectedResourceOffDays}
                dayClassName={this.getDayClassNames}
                calendarClassName={weekCalendar ? 'datepicker-week' : 'datepicker-day'}
              />
            </div>
            )
          : null}
      </div>
    )
  }
}

Datepicker.propTypes = {
  formatDate: PropTypes.string.isRequired,
  formatDay: PropTypes.string,
  weekCalendar: PropTypes.bool,
  onDateChanged: PropTypes.func
}

const maps = state => ({
  availability: state.availability || {},
  resources: state.resources || {},
  attributes: state.attributes || {}
})

export default connect(maps)(Datepicker)
