import axios from 'axios'

import { MRSPEX_URL } from '../Settings'

const instance = axios.create({
  baseURL: MRSPEX_URL
})

export default {

  // Finalise booking
  finaliseBooking: (data, isEnterprise, region) => {
    return instance.post(`bookings/?region=${region}${isEnterprise ? '&isEnterprise=true' : ''}`, data)
      .then(response => {
        if (response && response.data && response.data.status && parseInt(response.data.status, 10) === 1) {
          return {
            icsText: (response.data.data && response.data.data.icsText) || '',
            icsData: (response.data.data && response.data.data.icsData) || ''
          }
        }
        return null
      })
      .catch(() => null)
  },

  // Init widget
  widgetInit: (companyId, region) => {
    return instance.get(`widget?companyId=${companyId}&region=${region}`)
  }
}
