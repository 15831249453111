import React from 'react'
import { WeekWrapper } from '../../Beasts'

const WeeklySlots = props => {
  const { slotsByDays } = props
  return (
    <>
      {slotsByDays && slotsByDays.length > 0 && slotsByDays.map((item, index) => (
        <WeekWrapper
          key={`week-${index}`}
          item={item}
        />
      ))}
    </>
  )
}

export default WeeklySlots
