import { resourcesTransform, resourceCategoriesTransform } from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  selectedId: null,
  selectedCategoryIdx: 0
}

reducer.handlers = (dispatch, actions, handlers) => ({
  resourcesReset: () => dispatch(actions.RESOURCES_RESET),
  resourcesPopulate: ({ resources, resourceCategories }) => dispatch(actions.RESOURCES_POPULATE, { resources: resourcesTransform(resources), resourceCategories: resourceCategoriesTransform(resourceCategories) }),
  resourceSelect: ({ id }) => dispatch(actions.RESOURCE_SELECT, { id }),
  resourcePreSelect: ({ id }) => dispatch(actions.RESOURCE_PRESELECT, { id }),
  resourceCategorySelect: ({ idx }) => dispatch(actions.RESOURCE_CATEGORY_SELECT, { idx })
})

reducer.RESOURCES_RESET = state => reducer.initialState

reducer.RESOURCES_POPULATE = (state, { resources, resourceCategories }) => ({
  ...state,
  list: resources,
  categoriesList: resourceCategories
})

reducer.RESOURCE_SELECT = (state, { id }) => ({
  ...state,
  selectedId: id
})

reducer.RESOURCE_PRESELECT = (state, { id }) => ({
  ...state,
  selectedId: id
})

reducer.RESOURCE_CATEGORY_SELECT = (state, { idx }) => ({
  ...state,
  selectedCategoryIdx: idx
})

export default reducer
