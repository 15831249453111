import { slotsTransform } from './utils'

const reducer = {}

reducer.initialState = {
  isPending: false,
  list: [],
  coursesList: [],
  selectedDay: null,
  selectedMinutes: null,
  selectedEventId: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  slotsReset: () => dispatch(actions.SLOTS_RESET),
  slotsGet: () => dispatch(actions.SLOTS_GET),
  slotsPopulate: ({ slots, events }) => dispatch(actions.SLOTS_POPULATE, { slots: slotsTransform(slots), events }),
  slotSelect: ({ day, minutes, offerId }) => dispatch(actions.SLOT_SELECT, { day, minutes, offerId }),
  slotForCourseSelect: ({ eventId, day, minutes, offerId }) => dispatch(actions.SLOT_FOR_COURSE_SELECT, { eventId, day, minutes, offerId })
})

reducer.SLOTS_RESET = state => reducer.initialState

reducer.SLOTS_GET = state => ({
  ...state,
  isPending: true
})

reducer.SLOTS_POPULATE = (state, { slots, events }) => ({
  ...state,
  list: slots,
  coursesList: events,
  isPending: false
})

reducer.SLOT_SELECT = (state, { day, minutes, offerId }) => ({
  ...state,
  selectedDay: day,
  selectedMinutes: minutes,
  selectedOfferId: offerId
})

reducer.SLOT_FOR_COURSE_SELECT = (state, { eventId, day, minutes, offerId }) => ({
  ...state,
  selectedEventId: eventId,
  selectedDay: day,
  selectedMinutes: minutes,
  selectedOfferId: offerId
})

export default reducer
