import { offersTransform, vouchersTransform } from './utils'

const reducer = {}

reducer.initialState = {
  list: []
}

reducer.handlers = (dispatch, actions) => ({
  offersReset: () => dispatch(actions.OFFERS_RESET),
  offersPopulate: offers => dispatch(actions.OFFERS_POPULATE, offersTransform(offers)),
  vouchersPopulate: offers => dispatch(actions.VOUCHERS_POPULATE, vouchersTransform(offers)),
  checkVoucherCode: ({ code }) => dispatch(actions.CHECK_VOUCHER_CODE, { code })
})

reducer.OFFERS_RESET = state => reducer.initialState

reducer.OFFERS_POPULATE = (state, offers) => ({
  ...state,
  list: offers
})
reducer.CHECK_VOUCHER_CODE = state => state

reducer.VOUCHERS_POPULATE = (state, offers) => ({
  ...state,
  listVouchers: offers
})

export default reducer
