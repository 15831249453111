import React from 'react'
import { Slot } from '../../Beauties'

const DailySlots = props => {
  const { slotsByDays, selectedMinutes, onClickSlot } = props

  return (
    <div className='ta-slots__daily'>
      {slotsByDays.map(item => (
        <div key={item.day}>
          {item.slots.map(slot => (
            <Slot
              key={slot.minutes}
              day={slot.day}
              date={slot.date}
              price={slot.price}
              discountedPrice={slot.discountedPrice}
              offerId={slot.offerId}
              slot={slot}
              showTooltip={item.hasOffer}
              isActive={slot.minutes === selectedMinutes}
              onClickSlot={onClickSlot}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

export default DailySlots
