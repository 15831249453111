import React from 'react'
import PropTypes from 'prop-types'

import './SmallTitle.css'

const SmallTitle = props => {
  const { label, className } = props
  const classNames = ['ta-small-title']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>{label}</div>
  )
}

SmallTitle.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default SmallTitle
