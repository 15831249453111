import { TWELVE_HOUR_CLOCK } from '../Settings'
import { store } from '../Store'

export default (minutes, skipConvertion) => {
  if (!minutes) return '00:00'

  const state = store.getState()
  const { app } = state
  const { locale } = app

  let h = Math.floor(minutes / 60)
  h = h < 10 ? `0${h}` : h

  let m = minutes % 60
  m = m < 10 ? `0${m}` : m

  let time = `${h}:${m}`

  // Conver hh:mm A > HH:mm first
  if (locale && TWELVE_HOUR_CLOCK.includes(locale.toLowerCase()) && (typeof skipConvertion === 'undefined' || !skipConvertion)) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time]

    if (time.length > 1) {
      time = time.slice(1)
      time[5] = +time[0] < 12 ? ' AM' : ' PM'
      time[0] = +time[0] % 12 || 12
    }

    time = time.join('')
  }

  return time
}
