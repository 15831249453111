import React from 'react'
import PropTypes from 'prop-types'

import './Title.css'

const Title = props => {
  const { label, children, extraText, customStyle, small, className } = props
  const classNames = ['ta-title']
  if (className) classNames.push(className)
  if (small) classNames.push('small')

  return (
    <div className={classNames.join(' ')} style={customStyle}>
      <div className='ta-title__label'>
        {label}
        <span className='ta-title__extra_text'>{extraText}</span>
      </div>
      <div className='ta-title__content'>{children}</div>
    </div>
  )
}

Title.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default Title
