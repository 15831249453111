import React from 'react'
import PropTypes from 'prop-types'
import { isIE } from 'react-device-detect'
import { LocationsList } from '../../Beauties'
import { t, SmallTitle, Row, Col, GoogleMaps, NoResults, FontAwesome5 } from '../../Common'
import PlacesAutocomplete from 'react-places-autocomplete'

import './Locations.css'

const Locations = props => {
  const {
    isActive,
    isPrevious,
    showMap,
    onMapUpdate,
    onPlaceSelect,
    hasSelectedPlace,
    onPlaceChange,
    onPlaceClear,
    searchValue,
    mapPosition,
    companiesList,
    companiesCountries,
    mapMarkers,
    mapZoom
  } = props
  const classNames = ['ta-step', 'ta-locations']
  const placeSearchClassNames = ['location-search-input']
  const locationsClassNames = []
  const locationsContainerClassNames = ['ta-locations-container']
  const locationsBoxContainerClassNames = []
  const locationsListClassNames = ['ta-locations-list']
  if (isActive) classNames.push('active')
  if (isPrevious) classNames.push('prev')
  if (showMap) {
    locationsClassNames.push('locations-box')
    locationsContainerClassNames.push('ta-locations-full-height')
    if (isIE) locationsBoxContainerClassNames.push('ta-locations__box-container')
  }
  if (!showMap) {
    locationsContainerClassNames.push('ta-locations-display-block')
    locationsListClassNames.push('ta-locations-display-block')
  }
  if (hasSelectedPlace) placeSearchClassNames.push('ta-locations__place-search_selected')

  return (
    <div className={classNames.join(' ')}>
      {isActive && (
        <Row className={locationsContainerClassNames.join(' ')}>
          {(showMap &&
            <Col size={50} className='ta-locations-map'>
              <GoogleMaps
                lat={mapPosition.lat}
                lng={mapPosition.lng}
                defaultOptions={{
                  streetViewControl: false,
                  scaleControl: false,
                  mapTypeControl: false,
                  panControl: false,
                  zoomControl: false,
                  rotateControl: false,
                  fullscreenControl: false
                }}
                onDragEnd={onMapUpdate}
                isMarkerShown={false}
                markers={mapMarkers}
                zoom={mapZoom}
              />
              <PlacesAutocomplete
                value={searchValue}
                onSelect={onPlaceSelect}
                onChange={onPlaceChange}
                searchOptions={{ componentRestrictions: { country: companiesCountries } }}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div className='ta-locations__auto-complete'>
                    <input
                      {...getInputProps({
                        placeholder: t('locations.list.section.locations.search.placeholder'),
                        className: placeSearchClassNames.join(' ')
                      })}
                    />
                    {(!hasSelectedPlace &&
                      <FontAwesome5 icon='location' type='solid' className='locations-search-icon' />
                    )}
                    {(hasSelectedPlace &&
                      <button
                        className='ta-cta-secondary locations-place-clear-btn'
                        onClick={onPlaceClear}
                      >
                        <FontAwesome5 icon='times' type='solid' />
                      </button>
                    )}
                    <div className='autocomplete-dropdown-container'>
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, index) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item'
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' }
                        return (
                          <div
                            key={`suggestions-${index}`}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
          )}
          <Col size={showMap ? 50 : 100} className={locationsListClassNames.join(' ')}>
            <div className={locationsBoxContainerClassNames.join(' ')}>
              <div className={locationsClassNames.join(' ')}>
                <SmallTitle
                  label={t('locations.list.section.locations.main.title')}
                  className='ta-locations__title'
                />
                {Object.entries(companiesList).map((companies, index) => {
                  return (
                    <LocationsList
                      key={index}
                      city={companies[0]}
                      companies={companies[1]}
                    />
                  )
                })}
                {(Object.keys(companiesList).length === 0 &&
                  <NoResults>{t('locations.list.section.locations.noResults.message')}</NoResults>
                )}
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}

Locations.propTypes = {
  isActive: PropTypes.bool,
  showMap: PropTypes.bool,
  companiesList: PropTypes.object,
  mapMarkers: PropTypes.array
}

export default Locations
