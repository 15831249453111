import React from 'react'
import { handlers, connect } from '../../Store'
import { getDiscountedPrice } from '../../Utils'
import { CoursesExtraPersonsPerParticipantForm } from '../../Beauties'

const CoursesExtraPersonsPerParticipantFormWrapper = ({
  offers,
  routeName,
  coursesList,
  selectedEventId,
  selectedOfferId,
  extraParticipants
}) => {
  const selectedEvent = coursesList.find(item => item.id === selectedEventId) || {}
  const offer = selectedOfferId ? offers.find(item => item.id === selectedOfferId) : {}
  const discountedPrice = getDiscountedPrice(selectedEvent.price, offer.discount)
  const selectedEventWithPrice = offer.discount ? { ...selectedEvent, price: discountedPrice } : selectedEvent
  const onChange = (name, value) => {
    if (selectedEvent && selectedEvent.extraPersonsPerParticipant && selectedEvent.extraPersonsPerParticipant >= value.replace(/[^\d]/, '')) {
      handlers.courseSetExtraParticipants(value.replace(/[^\d]/, ''))
    }
  }
  const onConfirmPage = routeName === 'confirmParticipants'

  return (
    <CoursesExtraPersonsPerParticipantForm
      selectedEvent={selectedEventWithPrice}
      extraParticipants={extraParticipants}
      onConfirmPage={onConfirmPage}
      onChange={onChange}
    />
  )
}
const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  coursesList: state.slots.coursesList || [],
  selectedEventId: state.slots.selectedEventId || null,
  extraParticipants: state.courses.extraParticipants || '',
  offers: state.offers.list || [],
  selectedOfferId: state.slots.selectedOfferId || null
})

export default connect(maps)(CoursesExtraPersonsPerParticipantFormWrapper)
