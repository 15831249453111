/**
 * Transforms object query to string
 */

export default query => {
  return Object.keys(query)
    .reduce((result, key) => {
      if (query[key] && !result.length) {
        return `?${key}=${query[key]}`
      }

      if (query[key] && result.length) {
        return `${result}&${key}=${query[key]}`
      }
      
      return result
    }, '')
}
