import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { PaymentOnlinePayment } from '../../Beauties'
import { getRouteQuery, getDiscountedPrice } from '../../Utils'

const PaymentOnlinePaymentWrapper = props => {
  const {
    router,
    offers,
    locale,
    courses,
    eventId,
    secret,
    services,
    companies,
    countries,
    conflictError,
    selectedOfferId,
    selectedCompanyId,
    extraParticipants,
    voucherCode
  } = props
  const isActive = router.name === 'paymentOnlinePayment'
  const isPrevious = ['success', 'auth', 'authLogin', 'authRegister', 'authForgotten', 'authConfirmation', 'authChangePhone', 'authGuestBooking', 'authEnforcePasswordChange'].includes(router.name)
  const routeQuery = getRouteQuery(router)
  const { selectedId: selectedServiceId, list: servicesList } = services || {}
  const { selectedId: selectedCourseId, list: coursesList } = courses || {}
  let selectedService = {}
  if (selectedServiceId) selectedService = servicesList.find(item => item.id === selectedServiceId)
  if (selectedCourseId) selectedService = coursesList.find(item => item.id === selectedCourseId)
  const offer = selectedOfferId ? offers.find(item => item.id === selectedOfferId) : null
  const company = companies.find(item => item.id === selectedCompanyId) || {}
  const country = countries.find(({ code }) => code.toLowerCase() === locale.split('-')[1])
  const { hasStripeActive, hasStripeFeesNotAllowed } = country || {}
  const discountedPrice = offer && selectedService && selectedService.price ? getDiscountedPrice(selectedService.price, offer.discount) : null
  let price = discountedPrice || (selectedService && selectedService.price) || 0
  let additionalParticipantsCount = parseInt(extraParticipants, 10)
  if (isNaN(additionalParticipantsCount)) additionalParticipantsCount = 0
  if (selectedService && selectedService.extraPersonsPerParticipant && additionalParticipantsCount > 0) {
    price = price * (additionalParticipantsCount + 1)
  }
  const {
    currency,
    stripeClientId,
    paypalMerchantId,
    id: companyId,
    region,
    hasStripeConnected,
    stripeFeePercentage,
    paypalConnectionStatus
  } = company || {}
  const hasPayPalConnected = paypalConnectionStatus === 'ON'
  if (selectedService) {
    // decide tax percent based on if paypal or stripe
    selectedService.taxProcent = 0
    if (hasStripeActive && !hasStripeFeesNotAllowed && router.name === 'paymentStripe') selectedService.taxProcent = stripeFeePercentage || 0
    selectedService.taxPrice = price - getDiscountedPrice(price, selectedService.taxProcent)
    selectedService.totalPrice = price + selectedService.taxPrice
  }

  useEffect(() => {
    isActive && handlers.appLoaderHide()
  }, [isActive])

  return (
    <PaymentOnlinePayment
      locale={locale}
      eventId={eventId}
      isActive={isActive}
      isPrevious={isPrevious}
      routeQuery={routeQuery}
      companyId={companyId}
      region={region}
      companyCurrency={currency}
      secret={secret}
      conflictError={conflictError}
      stripeAccount={stripeClientId}
      paypalMerchantId={paypalMerchantId}
      hasStripeConnected={hasStripeConnected}
      hasPayPalConnected={hasPayPalConnected}
      stripeFeePercentage={stripeFeePercentage}
      voucherCode={voucherCode}
    />
  )
}

const maps = state => ({
  router: state.router || {},
  services: state.services || {},
  courses: state.courses || {},
  offers: state.offers.list || [],
  companies: state.companies.list || [],
  countries: state.countries || [],
  selectedCompanyId: state.companies.selectedId || null,
  selectedOfferId: state.slots.selectedOfferId || null,
  extraParticipants: state.courses.extraParticipants || '',
  conflictError: state.conflict.error || null,
  locale: state.app.locale || 'en-gb',
  eventId: state.booking.eventId || '',
  secret: state.booking.secret || '',
  voucherCode: state.forms.voucherInput?.voucherFinalCode?.value || ''
})

export default connect(maps)(PaymentOnlinePaymentWrapper)
