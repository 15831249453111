const getCustomerFieldsWithValues = (state) => {
  const { forms } = state || {}
  const { customerFieldsOnService, customerFieldsBeforeConfirmation } = forms || {}
  const allCustomerFields = { ...customerFieldsOnService, ...customerFieldsBeforeConfirmation }

  return Object.entries(allCustomerFields).reduce((acc, item) => {
    if (item[0].indexOf('customerField') !== -1 && item[0].indexOf('Secondary') === -1 &&
      item[0].indexOf('Zoom') === -1 && item[0].indexOf('CropRadius') === -1 &&
      (
        (item[1].type === 'ADDRESS' && item[1].value) ||
        (item[1].type === 'PHONE' && item[1].phone && item[1].phone !== '') ||
        (item[1].value && item[1].value !== '') ||
        (item[1].values && item[1].values.length > 0) ||
        (item[1].selected && item[1].selected.length > 0)
      )
    ) {
      if (allCustomerFields[`${item[0]}Secondary`] && allCustomerFields[`${item[0]}Secondary`].value) item[1].details = allCustomerFields[`${item[0]}Secondary`].value
      acc.push(item[1])
    }
    return acc
  }, [])
}

export default getCustomerFieldsWithValues
