const reducer = {}

reducer.initialState = {
  payload: {}
}

reducer.handlers = (dispatch, actions) => ({
  customisationsReset: () => dispatch(actions.CUSTOMISATIONS_RESET),
  customisationsPopulate: customisation => dispatch(actions.CUSTOMISATIONS_POPULATE, customisation)
})

reducer.CUSTOMISATIONS_RESET = state => reducer.initialState

reducer.CUSTOMISATIONS_POPULATE = (state, customisation) => ({
  ...state,
  ...customisation
})

export default reducer
