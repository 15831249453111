import React from 'react'
import { CookieConsentMessage } from '../../Beauties'
import { connect, handlers } from '../../Store'
import { deleteCookies, setCookie } from '../../Utils'

const CookieConsentMessageWrapper = ({
  isCookieConsentMessageMaximised,
  isConsentBoxCookieHidden,
  facebookCookieActive,
  googleCookieActive,
  selectedCompanyId,
  userAgent,
  companies,
  form
}) => {
  const company = companies.find(item => item.id === selectedCompanyId) || {}
  const { settings } = company || {}
  let { widgetConversions, customPrivacyPolicyUrl } = settings || {}
  isCookieConsentMessageMaximised = !!isCookieConsentMessageMaximised
  googleCookieActive = !!googleCookieActive
  facebookCookieActive = !!facebookCookieActive
  widgetConversions = widgetConversions || {}

  const denyTracking = () => {
    deleteCookies(`${selectedCompanyId}_ga`)
    handlers.formFieldsUpdate('marketingCookies', { googleCookieActive: { value: false } })
    handlers.formFieldsUpdate('marketingCookies', { facebookCookieActive: { value: false } })
    setCookie(`${selectedCompanyId}_cookie_consent_box`, true, 365)
    setCookie(`${selectedCompanyId}_cookie_agreement`, false, 365)
    setCookie(`${selectedCompanyId}_facebook_agreement`, false, 90)
    setCookie(`${selectedCompanyId}_google_agreement`, false, 90)

    if (form.facebookCookieActive) {
      setCookie(`${selectedCompanyId}_facebook_ID`, widgetConversions.facebookPixelId || '', 90)
    }
    if (form.googleCookieActive) {
      setCookie(`${selectedCompanyId}_google_ID`, widgetConversions.googleAnalyticsId || '', 90)
    }
    handlers.appConsentMessageHide()
  }

  const startTracking = () => {
    handlers.appConsentMessageHide()
    setCookie(`${selectedCompanyId}_cookie_consent_box`, !!(widgetConversions.facebookPixelId || widgetConversions.googleAnalyticsId), 365)
    setCookie(`${selectedCompanyId}_cookie_agreement`, !!(widgetConversions.facebookPixelId || widgetConversions.googleAnalyticsId), 365)
    setCookie(`${selectedCompanyId}_facebook_ID`, widgetConversions.facebookPixelId || '', 90)
    setCookie(`${selectedCompanyId}_google_ID`, widgetConversions.googleAnalyticsId || '', 90)
    setCookie(`${selectedCompanyId}_facebook_agreement`, !!(form.facebookCookieActive && form.facebookCookieActive.value && widgetConversions.facebookPixelId), 90)
    setCookie(`${selectedCompanyId}_google_agreement`, !!(form.googleCookieActive && form.googleCookieActive.value && widgetConversions.googleAnalyticsId), 90)
    handlers.appTrackingInit()
  }

  return !isConsentBoxCookieHidden
    ? (
      <CookieConsentMessage
        startTracking={startTracking}
        denyTracking={denyTracking}
        trackEvent={handlers.appTrackEvent}
        toggleMaximise={handlers.toggleCookieConsentMessageCover}
        maximised={isCookieConsentMessageMaximised}
        googleCookieActive={googleCookieActive}
        googleAnalyticsId={widgetConversions.googleAnalyticsId}
        facebookPixelId={widgetConversions.facebookPixelId}
        facebookCookieActive={facebookCookieActive}
        companyName={company.name}
        privacyPolicyLink={customPrivacyPolicyUrl}
        customMessage={widgetConversions.customMessage}
      />
      )
    : null
}

const maps = state => ({
  googleCookieActive: state.forms.marketingCookies.googleCookieActive && state.forms.marketingCookies.googleCookieActive.value,
  facebookCookieActive: state.forms.marketingCookies.facebookCookieActive && state.forms.marketingCookies.facebookCookieActive.value,
  isCookieConsentMessageMaximised: state.app.isCookieConsentMessageMaximised,
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  form: state.forms.marketingCookies,
  isConsentBoxCookieHidden: state.app.isConsentBoxCookieHidden,
  userAgent: state.app.userAgent
})

export default connect(maps)(CookieConsentMessageWrapper)
