import React, { PureComponent } from 'react'
import { connect, handlers, store } from '../../Store'
import { Header } from '../../Beauties'
import { getRouteQuery } from '../../Utils'
import { DEFAULT_LOCALE } from '../../Settings'

class HeaderWrapper extends PureComponent {
  constructor (props) {
    super(props)

    this.onBackButtonClick = this.onBackButtonClick.bind(this)
  }

  onBackButtonClick () {
    const {
      routeName,
      appMode,
      companies,
      enterpriseCompanies,
      selectedCompanyId,
      selectedServiceId,
      selectedCourseId,
      customerFields,
      isGuest,
      skipCustomerFieldsSection,
      attributes,
      services,
      courses,
      conflictError
    } = this.props
    const company = companies.find(item => item.id === selectedCompanyId) || {}
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    const { settings = {}, paypalConnectionStatus, hasStripeConnected } = company
    const hasPayPalConnected = paypalConnectionStatus === 'ON'
    const { hasFacebookLogin = false, hasTimifyLogin = false } = settings
    const filterByDefaultId = ['firstName', 'lastName', 'email', 'mobilePhone']
    const selectedItemId = selectedServiceId || selectedCourseId
    const filteredCustomerFields = customerFields.filter(item =>
      item.companyId === selectedCompanyId &&
      item.isAfterAuth &&
      ((selectedServiceId && item.areAllServicesSelected) || item.serviceIds.includes(selectedItemId) || (selectedCourseId && item.areAllCoursesSelected) || item.courseIds.includes(selectedItemId)) &&
      !filterByDefaultId.includes(item.defaultId)
    )
    const selectedItemType = selectedServiceId ? services : courses
    const selectedItem = selectedItemType.find(item => item.id === selectedItemId) || {}
    const { isPaymentMandatory, hasOnlinePayment } = selectedItem || {}
    let backUrl = ''
    let emptyCustomerFields

    switch (routeName) {
      case 'services':
        if (enterpriseCompanies.length > 1) {
          backUrl = `/locations${routeQuery}`
        } else {
          backUrl = `/services${routeQuery}`
        }
        break
      case 'availability':
        backUrl = `/services${routeQuery}`
        break
      case 'auth':
        backUrl = `/availability${routeQuery}`
        break
      case 'payments':
        emptyCustomerFields = customerFields.filter(item =>
          item.companyId === selectedCompanyId &&
          item.isAfterAuth &&
          ((selectedServiceId && item.areAllServicesSelected) || item.serviceIds.includes(selectedItemId) || (selectedCourseId && item.areAllCoursesSelected) || item.courseIds.includes(selectedItemId)) &&
          !filterByDefaultId.includes(item.defaultId)
        )

        if (appMode === 'RESCHEDULE' || (skipCustomerFieldsSection && emptyCustomerFields.length === 0) || (attributes && attributes.externalCustomerId)) {
          backUrl = `/availability${routeQuery}`
        } else {
          backUrl = `/customer-fields${routeQuery}`
        }
        break
      case 'paymentOnlinePayment':
        backUrl = `/confirm${routeQuery}`
        handlers.conflictErrorReset()
        break
      case 'paymentStripe':
        backUrl = hasPayPalConnected
          ? `/payments/online-payment${routeQuery}`
          : `/confirm${routeQuery}`
        handlers.conflictErrorReset()
        handlers.stripePaymentIntentReset()
        break
      case 'customerFields':
        backUrl = isGuest && (hasFacebookLogin || hasTimifyLogin)
          ? `/auth${routeQuery}`
          : `/availability${routeQuery}`
        handlers.appCloseGuestBooking() // mark isGuestBookingOpened as false
        handlers.courseSetExtraParticipants('')
        break
      case 'confirm':
        emptyCustomerFields = customerFields.filter(item =>
          item.companyId === selectedCompanyId &&
          item.isAfterAuth &&
          ((selectedServiceId && item.areAllServicesSelected) || item.serviceIds.includes(selectedItemId) || (selectedCourseId && item.areAllCoursesSelected) || item.courseIds.includes(selectedItemId)) &&
          !filterByDefaultId.includes(item.defaultId)
        )

        if (appMode === 'RESCHEDULE' || (skipCustomerFieldsSection && emptyCustomerFields.length === 0) || (attributes && attributes.externalCustomerId)) {
          backUrl = `/availability${routeQuery}`
        } else {
          backUrl = (isGuest || filteredCustomerFields.length > 0) && !skipCustomerFieldsSection
            ? `/customer-fields${routeQuery}`
            : `/availability${routeQuery}`
          if (hasOnlinePayment) {
            backUrl = isPaymentMandatory
              ? `/customer-fields${routeQuery}`
              : (hasPayPalConnected || hasStripeConnected)
                  ? `/payments${routeQuery}`
                  : `/customer-fields${routeQuery}`
          }
        }
        break
      case 'conflict':
        backUrl = `/confirm${routeQuery}`
        if (['StripeSessionExpired', 'StripeIntentError'].includes(conflictError)) {
          backUrl = null
          handlers.appReset()
        }
        break
      default:
        backUrl = `/${routeQuery}`
        break
    }

    backUrl && handlers.navigateToPath(backUrl)
  }

  render () {
    const {
      routeName,
      appMode,
      companies,
      selectedCompanyId,
      selectedLocale,
      attributes,
      rescheduledEvent,
      enterpriseCompanies
    } = this.props
    const company = companies.find(item => item.id === selectedCompanyId)
    const { serviceId = [], courseId = [], serviceExternalIds = [], showCompanyNameInHeader = false, courseExternalIds = [] } = attributes
    const { title: rescheduledTitle = '' } = rescheduledEvent
    let hasLanguageSelector = false
    if (routeName === 'notFound' || routeName === 'conflict') {
      hasLanguageSelector = true
    } else if (company) {
      const { showWidgetLanguageSelector } = company.settings || {}
      hasLanguageSelector = showWidgetLanguageSelector
    }
    let showBackButton = ['availability', 'payments', 'paymentStripe', 'paymentOnlinePayment', 'auth', 'authLogin', 'authRegister', 'authForgotten', 'authConfirmation', 'authChangePhone', 'authEnforcePasswordChange', 'customerFields', 'confirm', 'conflict'].includes(routeName)
    if (((serviceId && serviceId.length > 0) || (serviceExternalIds && serviceExternalIds.length > 0)) && routeName === 'availability') showBackButton = false
    if (((courseId && courseId.length > 0) || (courseExternalIds && courseExternalIds.length > 0)) && routeName === 'availability') showBackButton = false
    if (routeName === 'availability' && appMode === 'RESCHEDULE' && rescheduledTitle !== '') showBackButton = false
    if (routeName === 'services' && enterpriseCompanies.length > 1) showBackButton = true

    return (
      <Header
        appMode={appMode}
        selectedLocale={selectedLocale}
        hasLanguageSelector={hasLanguageSelector}
        showBackButton={showBackButton}
        onBackButtonClick={this.onBackButtonClick}
        showCompanyNameInHeader={showCompanyNameInHeader}
        company={company}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  selectedLocale: state.app.locale || DEFAULT_LOCALE,
  appMode: state.app.mode || 'NORMAL',
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  selectedServiceId: state.services.selectedId || null,
  selectedCourseId: state.courses.selectedId || null,
  services: state.services.list || [],
  courses: state.courses.list || [],
  customerFields: state.customerFields.list || [],
  isGuest: !state.auth.tokens,
  skipCustomerFieldsSection: state.attributes.skipCustomerFieldsSection || false,
  attributes: state.attributes || {},
  rescheduledEvent: (state.booking && state.booking.rescheduledData) || {},
  enterpriseCompanies: state.enterprise.companies || [],
  conflictError: state.conflict.error || null
})

export default connect(maps)(HeaderWrapper)
