import React, { PureComponent } from 'react'
import momenttz from 'moment-timezone'
import { CustomerFieldPreview } from '../../Beauties'
import {
  t,
  format,
  getCustomDateFormat,
  getCustomTimeFormat,
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  SimpleListItemButtons,
  SimpleListItemBody,
  FontAwesome5,
  Row,
  Col,
  Price,
  OutsideClick,
  Countdown
} from '../../Common'
import { convertMinutesToHours, dangerousHTML } from '../../Utils'

import './BookingInfo.css'

class BookingInfo extends PureComponent {
  constructor (props) {
    super(props)

    this.state = { isClosed: true }

    this.onClick = this.onClick.bind(this)
    this.changeOpenState = this.changeOpenState.bind(this)
  }

  onClick () {
    const { isClosed } = this.state
    this.setState({ isClosed: !isClosed })
  }

  changeOpenState (isClosed) {
    this.setState({ isClosed })
  }

  render () {
    const {
      info,
      isActive,
      routeName,
      cardDetails,
      hasTransactionFee,
      voucherStatus
    } = this.props
    let { voucherDiscount } = this.props
    const { isClosed } = this.state
    const {
      service,
      resource,
      courseResources,
      selectedDay,
      selectedMinutes,
      fields,
      price,
      taxPrice,
      taxProcent,
      totalPrice,
      stripePaymentIntentExpireAt
    } = info || {}
    const customDateFormat = getCustomDateFormat()
    const customTimeFormat = getCustomTimeFormat()
    let selectedTime = convertMinutesToHours(selectedMinutes)
    if (customTimeFormat) selectedTime = format(selectedTime, customTimeFormat, { isUTC: true, format: 'LT' })
    const classNames = ['ta-booking-info']
    if (isActive) classNames.push('active')
    if (cardDetails) classNames.push('with-card')
    const countdownLabel = t('header.bookinginfo.countdown.label').split('%COUNTDOWN%')

    voucherDiscount = (voucherStatus !== 'AVAILABLE' ? 0 : voucherDiscount) || 0
    let discountPrice = service?.discountPrice || 0
    let discountedPrice = service?.discountedPrice || 0
    if (!service || !service.price || service.price < 0) voucherDiscount = 0
    else if (voucherDiscount > 0) {
      const voucherDiscountPrice = service.price * voucherDiscount / 100
      const offerDiscountPrice = discountedPrice !== 0 ? service.price - discountedPrice : 0
      if (voucherDiscountPrice > offerDiscountPrice) {
        discountPrice = voucherDiscountPrice
        discountedPrice = service.price - discountPrice
      }
    }

    return (
      <div className={classNames.join(' ')}>
        {(service &&
          <div>
            {(!cardDetails &&
              <Row noOffset>
                <Col size={70} className='ta-booking-info__title'>
                  {service.name}
                </Col>
                {(service.duration &&
                  <Col size={30} className='ta-booking-info__value'>
                    <span>{service.duration} {t('services.list.section.category.service.min.label')}</span>
                  </Col>
                )}
              </Row>
            )}
            {(cardDetails &&
              <OutsideClick onClickOutside={() => this.changeOpenState(true)}>
                <SimpleListItem>
                  <SimpleListItemTitle title={service.name} />
                  <SimpleListItemContent>
                    <ul className='ta-simple-list__inline'>
                      {(service.duration &&
                        <li>
                          <FontAwesome5 icon='hourglass-half' />
                          <strong>{service.duration}</strong> {t('services.list.section.category.service.min.label')}
                        </li>
                      )}
                      {(selectedDay &&
                        <li>
                          <FontAwesome5 icon='calendar-alt' />
                          {!customDateFormat && (
                            <>
                              <strong>{momenttz.utc(selectedDay).format('ddd')}</strong>,&nbsp;
                              {format(selectedDay, 'long', { isUTC: true, format: 'YYYY-MM-DD' })}
                            </>
                          )}
                          {customDateFormat && (
                            <>
                              <strong>
                                {format(selectedDay, customDateFormat, { isUTC: true, format: 'YYYY-MM-DD' })}
                              </strong>
                            </>
                          )}
                        </li>
                      )}
                      {(!isNaN(selectedMinutes) &&
                        <li>
                          <FontAwesome5 icon='clock' />
                          <strong>{selectedTime}</strong>
                        </li>
                      )}
                      {(resource && resource.name &&
                        <li>
                          <FontAwesome5 icon='user' />
                          {t('header.bookinginfo.section.resource.label')} <strong>{resource.name}</strong>
                        </li>
                      )}
                      {(courseResources &&
                        <li>
                          <FontAwesome5 icon='user' />
                          {dangerousHTML(t('groups.with', [{ key: 'RESOURCES', value: courseResources }]))}
                        </li>
                      )}
                      {((service.price > 0 && !service.extraPersonsPerParticipant) &&
                        <li>
                          <FontAwesome5 icon='money-bill' type='solid' />
                          {!discountedPrice && <strong><Price price={service.price} /></strong>}
                          {(!!discountedPrice &&
                            <>
                              <strike className='ta-services__price'><Price price={service.price} /></strike>
                              <FontAwesome5 icon='long-arrow-right' type='regular' />
                              <strong><Price price={discountedPrice} /></strong>
                            </>
                          )}
                          {routeName === 'paymentStripe' && hasTransactionFee && (
                            <span className='ta-booking-info__transaction-fee-marker'>{t('confirm.preview.section.transaction.fee.marker')}</span>
                          )}
                        </li>
                      )}
                      {((!discountedPrice && service.price > 0 && service.extraPersonsPerParticipant > 0) &&
                        <>
                          <FontAwesome5 icon='money-bill' type='solid' />
                          <Price price={(service.extraPersonsPerParticipant + 1) * service.price} />
                          &nbsp;
                          <span className='ta-confirm__icon__price-calculated'>
                            ({(service.extraPersonsPerParticipant + 1)} x <Price price={service.price} />)
                          </span>
                        </>
                      )}
                      {((!!discountedPrice && service.price > 0 && service.extraPersonsPerParticipant > 0) &&
                        <>
                          <strike className='ta-services__price'><Price price={(service.extraPersonsPerParticipant + 1) * service.price} /></strike>
                          <FontAwesome5 icon='long-arrow-right' type='regular' />
                          <Price price={(service.extraPersonsPerParticipant + 1) * discountedPrice} />
                          &nbsp;
                          <span className='ta-services__discounted__price ta-confirm__icon__price-calculated'>
                            ({(service.extraPersonsPerParticipant + 1)} x <Price price={discountedPrice} />)
                          </span>
                        </>
                      )}
                    </ul>
                  </SimpleListItemContent>
                  {(fields && Object.keys(fields).length > 0 &&
                    <div>
                      <SimpleListItemButtons>
                        <button className='ta-cta-third ta-service__button' onClick={this.onClick}>
                          <FontAwesome5 icon={isClosed ? 'chevron-down' : 'chevron-up'} type='solid' />
                        </button>
                      </SimpleListItemButtons>
                      <SimpleListItemBody isClosed={isClosed}>
                        <div className='ta-booking-info__customfields__container'>
                          {fields.map((item, index) => {
                            return (
                              <CustomerFieldPreview key={`customer-field-${index}`} item={item} />
                            )
                          })}
                        </div>
                      </SimpleListItemBody>
                    </div>
                  )}
                </SimpleListItem>
              </OutsideClick>
            )}
          </div>
        )}
        {(totalPrice &&
          <div className='ta-booking-info__online-payment-fields'>
            <Row noOffset>
              <Col size={70} className='ta-booking-info__title border'>
                {t('global.price')}: <Price price={!service.extraPersonsPerParticipant ? price : price * (service.extraPersonsPerParticipant + 1)} />
              </Col>
            </Row>
            {(taxPrice > 0 &&
              <Row noOffset>
                <Col size={70} className='ta-booking-info__title border'>
                  {t('global.fee')}: <Price price={taxPrice} /> {t('payments.stripe.transactionFee', [{ key: 'FEE', value: taxProcent }])}
                </Col>
              </Row>
            )}
            <Row noOffset>
              <Col size={70} className='ta-booking-info__title border'>
                {t('global.totalPrice')}: <Price price={!service.extraPersonsPerParticipant ? totalPrice : totalPrice * (service.extraPersonsPerParticipant + 1)} />
              </Col>
            </Row>
          </div>
        )}
        {stripePaymentIntentExpireAt && (
          <div className='ta-booking-info__online-payment__countdown'>
            {countdownLabel[0]}
            <strong>
              <Countdown date={stripePaymentIntentExpireAt} hideHours /> {t('services.list.section.category.service.min.label')}
            </strong>
            {countdownLabel[1]}
          </div>
        )}
      </div>
    )
  }
}

export default BookingInfo
