import React, { Component } from 'react'
import { connect, handlers, store } from '../../../Store'
import { AuthLogin } from '../../../Beauties'
import { getRouteQuery } from '../../../Utils'

class LoginWrapper extends Component {
  constructor (props) {
    super(props)

    this.onCloseClick = this.onCloseClick.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onForgottenClick = this.onForgottenClick.bind(this)
    this.onRegisterClick = this.onRegisterClick.bind(this)
    this.togglePasswordFieldType = this.togglePasswordFieldType.bind(this)
  }

  componentDidMount () {
    handlers.loginFormPopulate()
  }

  onSubmit (cb) {
    const { form } = this.props
    handlers.loginWithEmailAndPassword(form, (errors) => {
      cb && cb(errors)
    })
  }

  onCloseClick () {
    handlers.loginFormPopulate()
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/auth${routeQuery}`)
  }

  onForgottenClick () {
    handlers.loginFormPopulate()
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/auth/forgotten${routeQuery}`)
  }

  onRegisterClick () {
    handlers.loginFormPopulate()
    const router = store.getState().router
    const routeQuery = getRouteQuery(router)
    handlers.navigateToPath(`/auth/register${routeQuery}`)
  }

  togglePasswordFieldType () {
    const { form } = this.props
    let { password } = form || {}
    password = password || {}
    const { type: passwordFieldType } = password
    const passwordField = {
      ...password,
      type: passwordFieldType === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('login', { password: passwordField })
  }

  render () {
    const { routeName, showBlockedWarning = false, locale, form } = this.props
    const isActive = routeName === 'authLogin'
    const { password } = form || {}
    const { type: passwordFieldType } = password || {}

    return (
      <AuthLogin
        locale={locale}
        isActive={isActive}
        onCloseClick={this.onCloseClick}
        onSubmit={this.onSubmit}
        onForgottenClick={this.onForgottenClick}
        onRegisterClick={this.onRegisterClick}
        onResetClick={this.onResetClick}
        showBlockedWarning={showBlockedWarning}
        passwordFieldType={passwordFieldType}
        togglePasswordFieldType={this.togglePasswordFieldType}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  form: state.forms.login,
  showBlockedWarning: state.auth.showBlockedWarning,
  locale: state.app.locale || 'en-gb'
})

export default connect(maps)(LoginWrapper)
