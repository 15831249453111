import { countriesFields, timezonesFields } from './fields'

// Queries

export const getStaticData = `
  query getStaticData {
    getStaticData {
      timezones ${timezonesFields}    
      countries ${countriesFields}
    }
  }
`

// Mutations

// Subscriptions
