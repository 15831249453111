
export const accountFields = `{
  userId
  email
}`

export const address = `{
  placeId
  latitude
  longitude
  street
  streetNumber
  city
  country
  zipCode
  formatted
  details
}`

export const dateFormatFields = `{
  dateFormatPosition
  dateFormatYear
  dateFormatMonth
  dateFormatDay
  dateFormatSeparator
}`

export const timeFormatFields = `{
  timeFormatType
  timeFormatTwelve
  timeFormatTwentyFour
}`

export const companySettingsFields = `{
  snapToGridInterval
  hasFacebookLogin
  hasTimifyLogin
  hasGuestBooking
  showWidgetLanguageSelector
  showWidgetTimezoneSelector
  isWidgetWeeklyView
  disclaimerConfig {
    active
    text
    displayOn
  }
  showWidgetCategoriesClosed
  showWidgetFirstCategoryOpen
  customTermsAndConditionsUrl
  customPrivacyPolicyUrl
  hideServiceDuration
  hideResourcesSection
  widgetExternalUrlRedirect
  dateFormat ${dateFormatFields}
  timeFormat ${timeFormatFields}
  widgetConversions {
    facebookPixelId 
    googleAnalyticsId
    customMessage 
    messageTarget
  }
}`

export const offerFields = `{
  id
  serviceIds
  courseIds
  interval {
    begin
    end
  }
  publishInterval {
    begin
    end
  }
  type
  name
  discount
}`

export const serviceFields = `{
  id
  externalId
  name
  orderIndex
  categoryId
  description
  duration
  durationBefore
  durationAfter
  durationsPattern
  price
  hasOnlinePayment
  isPaymentMandatory
  isGlobal
  shape
}`

export const courseFields = `{
  id
  externalId
  name
  orderIndex
  categoryId
  description
  duration
  durationBefore
  durationAfter
  durationsPattern
  maxParticipants
  extraPersonsPerParticipant
  price
  hasOnlinePayment
  isPaymentMandatory
  shape
}`

export const categoryFields = `{
  id
  name
  orderIndex
}`

export const customerFieldFields = `{
  id
  defaultId
  label
  translationKey
  type
  internalId
  externalId
  selectOptions {
    label
    value
    translationKey
    extraPrice
    extraDuration
    isDefault
  }
  orderIndex
  categoryId
  hasOverwrite
  isMandatoryOnline
  placeholder
  isAfterAuth
  extraPrice
  extraDuration
  serviceIds
  courseIds
  value
  description
  areAllServicesSelected
  areAllCoursesSelected
  fileUploadTypes
  fileUploadLimit
}`

export const customerFieldCategoryFields = `{
  id
  name
  translationKey
  orderIndex
}`

export const enterpriseFields = `{
  id
  customersMiddlewareUrl
  name
  locale
  widgetCustomisation {
    payload
  }
}`

export const partnerFields = `{
  id
  name
  onlineSettings {
    widgetOverwriteCssUrl
  }
}`

export const companyFields = `{
  id
  externalId
  name
  tradingName
  region
  locale
  plan
  timezone
  currency
  hasStripeConnected
  stripeFeePercentage
  stripeClientId
  paypalConnectionStatus
  address ${address}
  onlineSettings ${companySettingsFields}
  onlineStatus {
    isOnline
    hasResources
    hasServices
    hasCustomerFields
    hasCoursesInTheFuture
  }
  serviceCategories ${categoryFields}
  services ${serviceFields}
  offers ${offerFields}
  courseCategories ${categoryFields}
  courses ${courseFields}
  customerFields ${customerFieldFields}
  customerFieldCategories ${customerFieldCategoryFields}
  addOns
  info
  paypalMerchantId
  enterprise ${enterpriseFields}
  widgetCustomisation {
    payload
  }
}`

export const companyFieldsShort = `{
  id
  name
  tradingName
  address ${address}
  locale
}`

export const onlineDataFields = `{
  account ${accountFields}
  enterpriseId
  company ${companyFields}
  widgetCustomisation {
    payload
  }
}`

export const onlineDataEnterpriseFields = `{
  enterpriseId
  enterprise ${enterpriseFields}
  companies ${companyFieldsShort}
}`

export const resourceFields = `{
  id
  abbreviation
  externalId
  name
  categoryId
  avatarUrl
  color
  orderIndex
}`

export const slotFields = `{
  day
  minutes
}`

export const dependencyFields = `{
  name
  resourceIds
}`

export const eventFields = `{
  id
  title
  externalId
  isCourse
  startDate
  day
  time
  resources ${resourceFields}
  intervals {
    interval {
      begin
      end
    }
    batchNumber
    recurringDay
  }
  rescheduleUntil
  maxParticipants
  spotsLeft
  extraPersonsPerParticipant
  participantsCount
  hasOnlinePayment
  isPaymentMandatory
  durationBefore
  durationAfter
  duration
  durationsPattern
  price
  currentBookerPresent
}`

export const serviceAvailabilityFields = `{
  dependencies ${dependencyFields}
  resources ${resourceFields}
  resourceCategories ${categoryFields}
  slots ${slotFields}
  calendarEnd
  calendarBegin
  onDays
  offDays
  allocationOffDays
}`

export const courseAvailabilityFields = `{
  dependencies ${dependencyFields}
  resources ${resourceFields}
  resourceCategories ${categoryFields}
  calendarEnd
  calendarBegin
  events ${eventFields}
  onDays
  offDays
}`

export const availabilitySlotsFields = `{
  slots ${slotFields}
}`

export const onlineEventReservation = `{
  companyId
  eventId
  secret
}`

export const eventReservation = `{
  id
  icsText
  icsData {
    description
    title
  }
}`

export const onlineEventStatusFields = `{
  id
  title
  duration
  durationsPattern
  isCourse
  rescheduleUntil
  startDate
  service {
    id
  }
  course {
    id
  }
  resources {
    id
    name
  }
  combination {
    eventId
    serviceId
    title
    startDate
    events {
      eventId
      serviceId
      resourceIds
      title
      color
      duration
    }
  }
}`
