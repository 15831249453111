import React from 'react'
import PropTypes from 'prop-types'
import { SlotsWrapper, ResourcesWrapper } from '../../Beasts'
import { t, Datepicker, SmallTitle, Form, SingleSelect, WeekPicker } from '../../Common'
import { TIMIFY_PLANS, INTERSPORT_ENTERPRISE_ID } from '../../Settings'

import './Availability.css'

const Availability = props => {
  const {
    isActive,
    isPrevious,
    isPendingSlots,
    selectedDate,
    onDateChanged,
    isWidgetWeeklyView,
    showWidgetTimezoneSelector,
    companyPlan,
    enterpriseId,
    timezones,
    onTimezoneChanged,
    isTitleCourse,
    selectedCompany,
    attributes,
    selectedItem,
    availabilityValue
  } = props
  const classNames = ['ta-step', 'ta-availability']
  const { addOns = [] } = selectedCompany
  const { isEnterprise = false, showFooterBackButton, disableOtherDates = false, horizontalCalendarLayout = false, horizontalMonthSelector = false } = attributes
  if (isActive) classNames.push('active')
  if (showFooterBackButton) classNames.push('has-button')
  if (isPrevious) classNames.push('prev')

  return (
    <div className={classNames.join(' ')}>
      {((isActive || isPrevious) &&
        <>
          {(selectedItem && selectedItem.name &&
            <div className='ta-availability-header-space'>
              {selectedItem.name}
            </div>
          )}

          {(enterpriseId && ['5c66f5673147e64eeead19c7', '5c813242d1b1b55743bdedc2'].includes(enterpriseId) &&
            <SmallTitle label={t('availability.list.section.main.title')} className='ta-availability__main__title' />
          )}

          {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
            <SmallTitle label={t('availability.list.header.title')} className='ta-enterprise__intersport-title' />
          )}
          {((showWidgetTimezoneSelector && companyPlan && companyPlan === TIMIFY_PLANS.ENTERPRISE) &&
            <Form name='availability' onSubmit={() => { }}>
              <div className='ta-availability__timezones'>
                <SingleSelect
                  name='timezone'
                  label={t('availability.list.section.timezones.label')}
                  placeholder={t('availability.list.section.timezones.placeholder')}
                  options={timezones}
                  searchable
                  onChangeAddon={onTimezoneChanged}
                  value={availabilityValue}
                  hideArrow={false}
                />
              </div>
            </Form>
          )}
          {(!isTitleCourse &&
            <>
              {(/* (!isEnterprise || !addOns || !addOns.includes('MRSPEX')) && */!horizontalCalendarLayout &&
                <>
                  {(!disableOtherDates &&
                    <Datepicker
                      formatDay='dddd'
                      formatDate='DD.MM.YYYY'
                      selectedDate={selectedDate}
                      onDateChanged={onDateChanged}
                      weekCalendar={isWidgetWeeklyView}
                    />
                  )}
                  <ResourcesWrapper />
                </>
              )}
              {((horizontalCalendarLayout/* || (isEnterprise && addOns && addOns.includes('MRSPEX')) */) &&
                <>
                  <WeekPicker
                    selectedDate={selectedDate}
                    onDateChanged={onDateChanged}
                    weekCalendar={isWidgetWeeklyView}
                    withMonthSelector={horizontalMonthSelector}
                    className={horizontalCalendarLayout ? 'default' : ''}
                  />
                  {((!isEnterprise || !addOns || !addOns.includes('MRSPEX')) &&
                    <ResourcesWrapper />
                  )}
                </>
              )}
            </>
          )}
          {!isPendingSlots && <SlotsWrapper isAvailabilityActive={isActive} />}
        </>
      )}
    </div>
  )
}

Availability.propTypes = {
  isActive: PropTypes.bool,
  isPrevious: PropTypes.bool,
  isPendingSlots: PropTypes.bool,
  selectedDate: PropTypes.string
}

export default Availability
