import React, { Component } from 'react'
import moment from 'moment-timezone'
import { connect } from '../../Store'
import { Success } from '../../Beauties'
import { t, format } from '../../Common'

class SuccessWrapper extends Component {
  constructor (props) {
    super(props)

    this.onMrSpexVoucherClick = this.onMrSpexVoucherClick.bind(this)
  }

  componentDidMount () {
  }

  async onMrSpexVoucherClick () {
    const { appLocale, companies, selectedCompanyId, services, selectedServiceId, courses, selectedCourseId } = this.props
    const company = companies.find(item => item.id === selectedCompanyId) || {}
    const selectedService = services.find(item => item.id === selectedServiceId)
    const selectedCourse = courses.find(item => item.id === selectedCourseId)
    const selectedItem = selectedService || selectedCourse

    window.parent.postMessage({
      action: 'timify:widget:mrspex:voucher',
      language: appLocale.substring(0, 2).toUpperCase(),
      country: (company && company.locale ? company.locale.substring(3, 5).toUpperCase() : ''),
      companyId: company.id || null,
      companyExternalId: company.externalId || null,
      serviceId: selectedServiceId || selectedCourseId,
      serviceExternalId: selectedItem.externalId || null
    }, '*')
  }

  render () {
    const {
      appLocale,
      routeName,
      appMode,
      attributes,
      companies,
      selectedCompanyId,
      services,
      selectedServiceId,
      courses,
      selectedCourseId,
      selectedDay,
      selectedMinutes,
      resources,
      selectedResourceId,
      customerFieldsBeforeConfirmation,
      customerFieldsOnService,
      booking,
      account,
      customisations
    } = this.props
    const { payload: customisationsPayload } = customisations || {}
    const { settings: customisationsSettings } = customisationsPayload || {}
    const {
      hideWidgetExternalUrlRedirect,
      widgetExternalUrlRedirect: widgetExternalUrlRedirectCustomisation
    } = customisationsSettings || {}
    let successMessage = 'successNoEmail.preview.section.info.text'
    if (account.email) successMessage = 'success.preview.section.info.text'
    const isActive = routeName === 'success'
    const company = companies.find(item => item.id === selectedCompanyId) || {}
    const { enterprise: companyEnterprise, settings: companySettings } = company
    let { widgetExternalUrlRedirect } = companySettings || {}
    if (widgetExternalUrlRedirectCustomisation) widgetExternalUrlRedirect = widgetExternalUrlRedirectCustomisation
    if (hideWidgetExternalUrlRedirect) widgetExternalUrlRedirect = null
    const { id: companyEnterpriseId } = companyEnterprise || {}
    const selectedService = services.find(item => item.id === selectedServiceId)
    const selectedCourse = courses.find(item => item.id === selectedCourseId)
    const selectedItem = selectedService || selectedCourse
    const selectedSlot = { day: selectedDay, minutes: selectedMinutes }
    const selectedResource = resources.find(item => item.id === selectedResourceId)
    const allCustomerFields = { ...customerFieldsOnService, ...customerFieldsBeforeConfirmation }
    const customerFieldsWithValues = Object.entries(allCustomerFields).reduce((acc, item) => {
      if (item[0].indexOf('customerField') !== -1 && item[0].indexOf('Secondary') === -1 &&
        item[0].indexOf('Zoom') === -1 && item[0].indexOf('CropRadius') === -1 &&
        (
          (item[1].type === 'ADDRESS' && item[1].value) ||
          (item[1].type === 'PHONE' && item[1].phone && item[1].phone !== '') ||
          (item[1].value && item[1].value !== '')
        ) &&
        !['firstName', 'lastName', 'email', 'mobilePhone'].includes(item[1].defaultId)
      ) {
        if (allCustomerFields[`${item[0]}Secondary`] && allCustomerFields[`${item[0]}Secondary`].value) item[1].details = allCustomerFields[`${item[0]}Secondary`].value
        acc.push(item[1])
      }
      return acc
    }, [])
    const { title: rescheduledTitle = '', duration: rescheduledDuration = null, redirectSeconds = 5 } = booking || {}

    // Prepare event for save in calendar
    const event = {
      title: '',
      location: '',
      description: '',
      startTime: null,
      endTime: null
    }

    if (selectedSlot) {
      const startDate = moment(selectedSlot.day).hour(0).minute(0).second(0).add(selectedSlot.minutes, 'minutes')
      let endDate = startDate.clone()
      if (selectedItem) {
        const duration = selectedItem.duration ||
          (selectedItem.durationsPattern && selectedItem.durationsPattern.reduce((acc, item) => {
            acc += parseInt(item, 10)
            return acc
          }, 0)) || 0
        endDate = endDate.add(duration, 'minutes')
        event.title = selectedItem.name
      } else if (rescheduledTitle !== '' && rescheduledDuration) {
        endDate = endDate.add(rescheduledDuration, 'minutes')
        event.title = rescheduledTitle
      }
      event.startTime = startDate.format('YYYY-MM-DDTHH:mm:ssZZ')
      event.endTime = endDate.format('YYYY-MM-DDTHH:mm:ssZZ')

      if (company) {
        if (company.address && company.address.formatted) event.location = company.address.formatted
        if (company.name) event.description = company?.tradingName || company.name + '\\n'
        if (company.id) event.url = `https://www.timify.com/${appLocale}/cancel-booking?eventId=${booking.eventId}&secret=${booking.secret}&accountId=${company.id}&region=${company.region}`
      }

      if (selectedResource && selectedResource.name) event.description += selectedResource.name + '\\n'

      if (customerFieldsWithValues) {
        customerFieldsWithValues.forEach(item => {
          event.description += (item.translationKey
            ? t(item.translationKey)
            : item.label || '') + ': '

          switch (item.type) {
            case 'EMAIL':
            case 'TEXT':
            case 'TEXTAREA':
              event.description += item.value
              break
            case 'ADDRESS':
              if (item.data && Object.keys(item.data).length > 0) event.description += item.data.formattedAddress
              break
            case 'PHONE':
              event.description += item.phone
              break
            case 'DATE':
              event.description += format(item.value, 'long', { isUTC: true })
              break
            case 'SELECT':
              let selectedOption = null
              if (item.type === 'SELECT') {
                selectedOption = item.options.find(option => option.value === item.value) || {}
              }

              event.description += selectedOption.label || t(selectedOption.translationKey)
              break
            case 'CHECKBOX':
              event.description += (item.value + '' === 'true' ? t('global.yes') : t('global.no'))
              break
            default:
          }

          event.description += '\\n'
        })
      }
    }
    Object.values(allCustomerFields || {}).forEach(customerField => {
      if (customerField.defaultId === 'email' && customerField.type === 'EMAIL' && customerField.value) {
        successMessage = 'success.preview.section.info.text'
      }
    })

    return (
      <Success
        isActive={isActive}
        appMode={appMode}
        company={company}
        selectedItem={selectedItem}
        selectedSlot={selectedSlot}
        selectedResource={selectedResource}
        customerFieldsWithValues={customerFieldsWithValues}
        successMessage={successMessage}
        booking={booking}
        event={event}
        attributes={attributes}
        companyEnterpriseId={companyEnterpriseId}
        widgetExternalUrlRedirect={widgetExternalUrlRedirect}
        redirectSeconds={redirectSeconds}
        onMrSpexVoucherClick={this.onMrSpexVoucherClick}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  appMode: state.app.mode || 'NORMAL',
  appLocale: state.app.locale || 'en-gb',
  attributes: state.attributes || {},
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  services: state.services.list || [],
  selectedServiceId: state.services.selectedId || null,
  courses: state.courses.list || [],
  selectedCourseId: state.courses.selectedId || null,
  selectedDay: state.slots.selectedDay || null,
  selectedMinutes: state.slots.selectedMinutes || 0,
  resources: state.resources.list || [],
  selectedResourceId: state.resources.selectedId || null,
  customerFieldsBeforeConfirmation: state.forms.customerFieldsBeforeConfirmation || {},
  customerFieldsOnService: state.forms.customerFieldsOnService || {},
  booking: state.booking || {},
  account: state.account || {},
  customisations: state.customisations || {}
})

export default connect(maps)(SuccessWrapper)
