import moment from 'moment'

export default (startDate, endDate) => {
  if (typeof startDate === 'string') startDate = moment(startDate)
  if (typeof endDate === 'string') endDate = moment(endDate)

  let dates = []
  while (endDate.diff(startDate, 'days') > 0) {
    dates.push(startDate.clone())
    startDate.add(1, 'day')
  }
  return dates
}
