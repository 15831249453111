import React, { Component } from 'react'
import { connect } from '../../Store'
import { Services } from '../../Beauties'
import { intersection, sortByOrderIndex } from '../../Utils'
import { DEUTSCHES_FRANCHISE_INSTITUT_ID } from '../../Settings'

class ServiceWrapper extends Component {
  render () {
    const {
      routeName,
      companies,
      selectedCompanyId,
      serviceCategories,
      selectedServiceCustomerFieldIds,
      selectedCourseCustomerFieldIds,
      courses,
      courseCategories,
      offers,
      showOnlyServices,
      hideServices,
      showOnlyServicesExternalIds,
      hideServicesExternalIds,
      showSelectedResourcesOnly,
      resourceId,
      isEnterprise,
      isPending,
      enterpriseId,
      courseIds,
      serviceIds,
      serviceExternalIds,
      fileLoading,
      setFileLoading
    } = this.props
    let { services, courseExternalIds } = this.props
    const isActive = !isPending && routeName === 'services'
    const isPrevious = ['availability', 'auth', 'authLogin', 'authRegister', 'authForgotten', 'authConfirmation', 'authChangePhone', 'authGuestBooking', 'authEnforcePasswordChange', 'customerFields', 'confirm', 'success'].includes(routeName)
    const company = companies.find(item => item.id === selectedCompanyId) || {}
    const { plan: companyPlan = '', addOns = [] } = company
    const filteredOffers = offers.filter(item => item.companyId === selectedCompanyId)

    if (isEnterprise && addOns.includes('MRSPEX')) {
      const servicesList = services
      services = services.filter(item => item.isGlobal)
      if (services.length === 0) services = servicesList
    }
    let filteredServices = services
      .filter(item => item.companyId === selectedCompanyId && (showOnlyServices.length === 0 || showOnlyServices.includes(item.id) || showOnlyServices.includes(item.internalId)) && (showOnlyServicesExternalIds.length === 0 || showOnlyServicesExternalIds.includes(item.externalId)) && !hideServices.includes(item.id) && !hideServicesExternalIds.includes(item.externalId) && (showSelectedResourcesOnly ? item.shape[0] && item.shape[0].find(shapeItem => shapeItem === resourceId) : true))
      .map(item => { return { ...item, isCourse: false } })
      .sort(sortByOrderIndex)
    let filteredServiceCategories = serviceCategories
      .filter(item => item.companyId === selectedCompanyId)
      .sort(sortByOrderIndex)
    let filteredCourses = courses
      .filter(item => item.companyId === selectedCompanyId && (showOnlyServices.length === 0 || showOnlyServices.includes(item.id) || showOnlyServices.includes(item.internalId)) && !hideServices.includes(item.id) && (!showSelectedResourcesOnly || (item.shape[0] && item.shape[0].find(shapeItem => shapeItem === resourceId))))
      .map(item => { return { ...item, isCourse: true } })
      .sort(sortByOrderIndex)
    let filteredCourseCategories = courseCategories
      .filter(item => item.companyId === selectedCompanyId)
      .sort(sortByOrderIndex)
    const hasServiceCustomerFieldIds = (selectedServiceCustomerFieldIds || []).length > 0
    const hasCourseCustomerFieldIds = (selectedCourseCustomerFieldIds || []).length > 0
    const hasSupportedBy = enterpriseId && DEUTSCHES_FRANCHISE_INSTITUT_ID.includes(enterpriseId)
    const hasPoweredBy = companyPlan === 'CLASSIC'
    const filteredCoursesExternalIds = filteredCourses.map(item => item.externalId)
    const commonCourseExternalIds = intersection(courseExternalIds, filteredCoursesExternalIds)
    if (hasCourseCustomerFieldIds && courseExternalIds.length && commonCourseExternalIds.length) {
      const filteredCoursesByExternalId = filteredCourses.filter(item => commonCourseExternalIds.includes(item.externalId))
      filteredCourses = filteredCoursesByExternalId
      filteredCourseCategories = filteredCourseCategories.filter(item => filteredCoursesByExternalId.find(({ categoryId }) => item.id === categoryId))
      filteredServices = []
      filteredServiceCategories = []
    }
    return (
      <Services
        isActive={isActive}
        isPrevious={isPrevious}
        hasSelectedItem={hasServiceCustomerFieldIds || hasCourseCustomerFieldIds}
        hasSupportedBy={hasSupportedBy}
        hasPoweredBy={hasPoweredBy}
        company={company}
        services={filteredServices}
        serviceCategories={filteredServiceCategories}
        courses={filteredCourses}
        courseCategories={filteredCourseCategories}
        offers={filteredOffers}
        courseIds={courseIds}
        courseExternalIds={courseExternalIds}
        serviceIds={serviceIds}
        serviceExternalIds={serviceExternalIds}
        fileLoading={fileLoading}
        setFileLoading={setFileLoading}
      />
    )
  }
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  services: state.services.list || [],
  serviceCategories: state.services.categoriesList || [],
  selectedServiceCustomerFieldIds: state.services.selectedCustomerFieldIds || null,
  selectedCourseCustomerFieldIds: state.courses.selectedCustomerFieldIds || null,
  courses: state.courses.list || [],
  courseCategories: state.courses.categoriesList || [],
  offers: state.offers.list || [],
  showOnlyServices: state.attributes.showServices || [],
  hideServices: state.attributes.hideServices || [],
  showOnlyServicesExternalIds: state.attributes.showServicesExternalIds || [],
  hideServicesExternalIds: state.attributes.hideServicesExternalIds || [],
  isEnterprise: state.attributes.isEnterprise || false,
  showSelectedResourcesOnly: state.attributes.showSelectedResourcesOnly || false,
  resourceId: state.attributes.resourceId || false,
  isPending: state.services.isPending || false,
  isAvailabilityPending: state.availability.isPending || false,
  enterpriseId: state.enterprise.id || null,
  courseExternalIds: state.attributes.courseExternalIds || [],
  courseIds: state.attributes.courseId || [],
  serviceIds: state.attributes.serviceId || [],
  serviceExternalIds: state.attributes.serviceExternalIds || []
})

export default connect(maps)(ServiceWrapper)
